import * as graphQlApi from '../API';
import { AnswerNotFoundException, CountryNotFoundException, OperatorNotImplementedException } from './CustomExceptions';

export interface DeviceClassificationNode {
    expressionId: string,
    questionId: string | undefined,
    classificationIfYes: graphQlApi.DeviceClassification | undefined,
    classificationFromQuestion: graphQlApi.DeviceClassification | undefined,
    classificationFromChildren: graphQlApi.DeviceClassification | undefined,
    classification: graphQlApi.DeviceClassification | undefined
    answer: boolean | undefined,
    operator: graphQlApi.DeviceExpressionOperator | undefined,
    children: DeviceClassificationNode[],
}

export class DeviceClassifier {
    private _expressions: {[key: string]: graphQlApi.DeviceClassificationExpression}
    private _answers: {[key: string]: boolean}

    constructor(
        expressions: {[key: string]: graphQlApi.DeviceClassificationExpression},
        answers: {[key: string]: boolean}
    ) {
        this._expressions = expressions
        this._answers = answers
    }

    public classify(): DeviceClassificationNode[] {
        const rootExpressions = this.getRootExpressions()

        const deviceClassificationNodes = []

        for (const rootExpression of rootExpressions) {
            const deviceClassificationNode = this.getExpressionClassification(rootExpression)
            deviceClassificationNodes.push(deviceClassificationNode)
        }

        return deviceClassificationNodes
    }

    private getExpressionClassification(expression: graphQlApi.DeviceClassificationExpression): DeviceClassificationNode {
        const answer: boolean | undefined = this.getAnswerFromExpression(expression)
        const classificationFromQuestion: graphQlApi.DeviceClassification | undefined = this.getClassificationFromQuestion(expression, answer)
        const childrenExpressions: graphQlApi.DeviceClassificationExpression[] = this.getChildExpressions(expression)
        const childrenNodes: DeviceClassificationNode[] = this.getChildrenNodes(childrenExpressions)
        const classificationFromChildren: graphQlApi.DeviceClassification | undefined = this.getClassificationFromChildren(expression, childrenNodes)
        const hasQuestion = expression.deviceClassificationExpressionYesNoQuestionId !== null && expression.deviceClassificationExpressionYesNoQuestionId !== undefined
        const hasAnswerYes = answer === true
        const classification: graphQlApi.DeviceClassification | undefined = this.getFinalClassification(expression, classificationFromQuestion, classificationFromChildren, hasQuestion, hasAnswerYes)
        
        const deviceClassificationNode = {
            expressionId: expression.id,
            questionId: expression.deviceClassificationExpressionYesNoQuestionId !== null ? expression.deviceClassificationExpressionYesNoQuestionId: undefined,
            classificationIfYes: expression.classificationIfYes !== null ? expression.classificationIfYes : undefined, 
            classificationFromQuestion: classificationFromQuestion,
            classificationFromChildren: classificationFromChildren,
            classification: classification,
            answer: answer,
            operator: expression.operator !== null ? expression.operator : undefined,
            children: childrenNodes,
        }

        return deviceClassificationNode
    }

    private getRootExpressions(): graphQlApi.DeviceClassificationExpression[] {
        return Object.entries(this._expressions)
            .map(([_, deviceClassificationExpression]) => deviceClassificationExpression)
            .filter(deviceClassificationExpression => deviceClassificationExpression.isRoot)
    }

    private getChildExpressions(expression: graphQlApi.DeviceClassificationExpression): graphQlApi.DeviceClassificationExpression[] {
        return Object.entries(this._expressions)
            .map(([_, deviceClassificationExpression]) => deviceClassificationExpression)
            .filter(deviceClassificationExpression => deviceClassificationExpression.deviceClassificationExpressionChildrenDeviceClassificationExpressionsId === expression.id)
    }

    private getAnswerFromExpression(expression: graphQlApi.DeviceClassificationExpression): boolean | undefined {
        const questionId = expression.deviceClassificationExpressionYesNoQuestionId !== null ? expression.deviceClassificationExpressionYesNoQuestionId : undefined

        if (questionId === undefined) {
            return undefined
        }

        if (!this._answers.hasOwnProperty(questionId)) {
            throw new AnswerNotFoundException(questionId)
        }

        return this._answers[questionId]
    }

    private getClassificationFromQuestion(expression: graphQlApi.DeviceClassificationExpression, answer: boolean | undefined): graphQlApi.DeviceClassification | undefined {
        if (answer === undefined || answer === false) {
            return undefined
        }

        const classificationIfYes = expression.classificationIfYes !== null ? expression.classificationIfYes : undefined
        return classificationIfYes
    }

    private getChildrenNodes(childExpressions: graphQlApi.DeviceClassificationExpression[]): DeviceClassificationNode[] {
        const deviceClassificationNodes = []

        for (const childExpression of childExpressions) {
            const deviceClassificationNode = this.getExpressionClassification(childExpression)
            deviceClassificationNodes.push(deviceClassificationNode)
        }

        return deviceClassificationNodes
    }

    private getMaxClassification(expression: graphQlApi.DeviceClassificationExpression, classifications: (graphQlApi.DeviceClassification | undefined)[]): graphQlApi.DeviceClassification | undefined {
        const specialQuestionCategory = expression.specialQuestionCategory !== null ? expression.specialQuestionCategory : undefined

        if (specialQuestionCategory === undefined) {
            return undefined
        }

        switch (specialQuestionCategory) {
            case graphQlApi.SpecialQuestionCategory.EU_CLASSIFICATION:
                for (const referenceClassification of [graphQlApi.DeviceClassification.EU_CLASS_3, graphQlApi.DeviceClassification.EU_CLASS_2_B, graphQlApi.DeviceClassification.EU_CLASS_2_A, graphQlApi.DeviceClassification.EU_CLASS_1]) {
                    const foundClassification = classifications.some(classification => classification === referenceClassification)

                    if (foundClassification) {
                        return referenceClassification
                    }
                }

                return undefined

            case graphQlApi.SpecialQuestionCategory.US_CLASSIFICATION:
                for (const referenceClassification of [graphQlApi.DeviceClassification.US_CLASS_3, graphQlApi.DeviceClassification.US_CLASS_2, graphQlApi.DeviceClassification.US_CLASS_1]) {
                    const foundClassification = classifications.some(classification => classification === referenceClassification)

                    if (foundClassification) {
                        return referenceClassification
                    }
                }

                return undefined

            case graphQlApi.SpecialQuestionCategory.CANADA_CLASSIFICATION:
                for (const referenceClassification of [graphQlApi.DeviceClassification.CANADA_CLASS_4, graphQlApi.DeviceClassification.CANADA_CLASS_3, graphQlApi.DeviceClassification.CANADA_CLASS_2, graphQlApi.DeviceClassification.CANADA_CLASS_1]) {
                    const foundClassification = classifications.some(classification => classification === referenceClassification)

                    if (foundClassification) {
                        return referenceClassification
                    }
                }

                return undefined

            default:
                throw new CountryNotFoundException()
        }
    }

    private getClassificationFromChildren(expression: graphQlApi.DeviceClassificationExpression, childExpressionClassifications: DeviceClassificationNode[]): graphQlApi.DeviceClassification | undefined {
        const operator = expression.operator !== null ? expression.operator : undefined

        if (operator === undefined) {
            return undefined
        }

        switch (operator) {
            case graphQlApi.DeviceExpressionOperator.MAX_OF:
            case graphQlApi.DeviceExpressionOperator.RIGHT_OVERRIDE_OF: 
                const childClassifications = []

                for (const childExpressionClassification of childExpressionClassifications) {
                    childClassifications.push(childExpressionClassification.classification)
                }

                return this.getMaxClassification(expression, childClassifications)

            default:
                throw new OperatorNotImplementedException(operator)
        }
    }

    private getFinalClassification(expression: graphQlApi.DeviceClassificationExpression, classificationFromQuestion: graphQlApi.DeviceClassification | undefined, classificationFromChildren: graphQlApi.DeviceClassification | undefined, hasQuestion: boolean, hasAnswerYes: boolean): graphQlApi.DeviceClassification | undefined {
        const operator = expression.operator !== null ? expression.operator : undefined

        if (operator === undefined) {
            return classificationFromQuestion
        }

        if (!hasQuestion) {
            return classificationFromChildren
        }

        if (!hasAnswerYes) {
            return undefined
        }

        switch (operator) {
            case graphQlApi.DeviceExpressionOperator.MAX_OF:
                const classifications = [classificationFromQuestion, classificationFromChildren]
                return this.getMaxClassification(expression, classifications)

            case graphQlApi.DeviceExpressionOperator.RIGHT_OVERRIDE_OF:
                if (classificationFromChildren !== undefined) {
                    return classificationFromChildren
                } else {
                    return classificationFromQuestion
                }

            default:
                throw new OperatorNotImplementedException(operator)
        }
    }
}