import React, { useEffect, useState } from 'react'
import { EntityName } from '../../model/EntityName';
import { FilledEntity } from '../../model/FilledEntity';
import { getFilledInstance } from './mixin-helper';

interface GenericRelatedsViewerProps {
    ids: string[]
    relationName?: string
    entityName: EntityName
    setEntityCreator: (entityName: EntityName) => void
    setEntityViewer: (entityName: EntityName, id: string) => void
    setEntityEditor: (entityName: EntityName, id: string) => void
    setEntityRemover: (entityName: EntityName, id: string) => void
}

export const GenericRelatedsViewer: React.FC<GenericRelatedsViewerProps> = ({ ids, relationName, entityName, setEntityCreator, setEntityViewer, setEntityEditor, setEntityRemover }) => {
    const [instances, setInstances] = useState<FilledEntity[]>([])

    useEffect(() => {
        const fetchInstances = async () => {
            const instancePromises = ids.map(id => getFilledInstance(entityName, id))
            const instances = await Promise.all(instancePromises)
            setInstances(instances.filter(instance => instance !== undefined) as FilledEntity[])
        }

        fetchInstances()
    }, [ids])
    
    return (
        <>
            <label className="form-label" htmlFor={'select-' + relationName}>{relationName} <span className="text-gray-500 font-normal">{entityName}</span></label>
            {
                instances.map(instance => {
                    const name = instance?.getStringMap().get('name')
                    const id = instance?.getIdMap().get('id')

                    if (id === undefined || id === null || id === "") {
                        return null
                    }

                    return (
                        <div className="card mb-4" key={'related-view-' + relationName + '-' + id}>
                            <div className="card-header">
                                {instance !== undefined &&
                                    <span id={'select-' + relationName}>{name}</span>
                                }
                                {instance === undefined &&
                                    <span id={'select-' + relationName} className="text-muted">There is no related {entityName}.</span>
                                }
                            </div>
                            <div className="card-body">
                                {instance === undefined && <button className="btn btn-primary btn-sm mx-2" onClick={() => setEntityCreator(entityName)}>Create {entityName}</button>}
                                {instance !== undefined && <button className="btn btn-primary btn-sm mx-2" onClick={() => setEntityViewer(entityName, id)}>View</button>}
                                {instance !== undefined && <button className="btn btn-warning btn-sm mx-2" onClick={() => setEntityEditor(entityName, id)}>Edit</button>}
                                {instance !== undefined && <button className="btn btn-danger btn-sm mx-2" onClick={() => setEntityRemover(entityName, id)}>Remove</button>}
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}