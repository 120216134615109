import { API } from "aws-amplify";
import { CreateStageQuestionnaireInput, DeleteStageQuestionnaireInput, StageQuestionnaire, GetStageQuestionnaireQuery, ListStageQuestionnairesQuery, UpdateStageQuestionnaireInput } from "../API";
import { createStageQuestionnaire, deleteStageQuestionnaire, updateStageQuestionnaire } from "../graphql/mutations";
import { getStageQuestionnaire, listStageQuestionnaires, listStageQuestionnairesByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createStageQuestionnaireApi = async (input: CreateStageQuestionnaireInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaire> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createStageQuestionnaire,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createStageQuestionnaire: StageQuestionnaire } };
    return response.data.createStageQuestionnaire;
}

export const updateStageQuestionnaireApi = async (input: UpdateStageQuestionnaireInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaire> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updateStageQuestionnaire,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updateStageQuestionnaire: StageQuestionnaire } };
    return response.data.updateStageQuestionnaire;
}

export const deleteStageQuestionnaireApi = async (input: DeleteStageQuestionnaireInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaire> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deleteStageQuestionnaire,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deleteStageQuestionnaire: StageQuestionnaire } };
    return response.data.deleteStageQuestionnaire;
}

export const listStageQuestionnairesApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaire[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listStageQuestionnaires,
    });

    const response = responseRaw as { data: ListStageQuestionnairesQuery };
    const items = response.data.listStageQuestionnaires?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as StageQuestionnaire[];
    return itemsExisting
}

export const getStageQuestionnaireApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaire|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getStageQuestionnaire,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetStageQuestionnaireQuery };
    const instance = response.data.getStageQuestionnaire;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as StageQuestionnaire
    return instanceNotUndefined
}

export const listStageQuestionnairesByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaire[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listStageQuestionnairesByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListStageQuestionnairesQuery };
    const items = response.data.listStageQuestionnaires?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as StageQuestionnaire[];
    return itemsExisting
}
