import React from 'react'
import { EntityName } from '../../model/EntityName'
import { GenericCreator } from '../mixins/GenericCreator'

interface EntityCreatorProps {
    entityName: EntityName
    userGroups: string[]
    discardEntityCreator: (entityName: EntityName) => void
}

export const EntityCreator: React.FC<EntityCreatorProps> = ({ entityName, userGroups, discardEntityCreator }) => {
    function cancel(event: React.MouseEvent<HTMLButtonElement>): void {
        event.preventDefault()
        discardEntityCreator(entityName)
    }

    return (
        <div className="card">
            <div className="card-body">
                <GenericCreator entityName={entityName} userGroups={userGroups} discardEntityCreator={discardEntityCreator} />
            </div>
            <div className="card-footer">
                <button className="btn btn-primary btn-sm" onClick={cancel}>Cancel</button>
            </div>
        </div>
    )
}