import { CreateStageQuestionnaireInput, DeleteStageQuestionnaireInput, StageQuestionnaire, UpdateStageQuestionnaireInput } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyStageQuestionnaireInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name'];
    const intKeys = ['order'];
    const deviceClassificationKeys = ['deviceClassification'];

    const entityRelationKeysEntities: Map<string, EntityName> = new Map([
        ['question', EntityName.Question],
        ['stage', EntityName.Stage],
        ['stageQuestionnaireSection', EntityName.StageQuestionnaireSection],
    ]);
    
    return new FilledEntity({
        idKeys,
        stringKeys,
        intKeys,
        deviceClassificationKeys,
        entityRelationKeysEntities
    });

}

export function getFilledStageQuestionnaireInstance(model: StageQuestionnaire): FilledEntity {
    const instance = getEmptyStageQuestionnaireInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name === null ? undefined : model.name);
    instance.addInt('order', model.order === null ? undefined : model.order);
    instance.addDeviceClassification('deviceClassification', model.deviceClassification === null ? undefined : model.deviceClassification);
    instance.addEntityRelation('question', {entityName: EntityName.Question, id: model.questionStageQuestionnairesId === null ? undefined : model.questionStageQuestionnairesId});
    instance.addEntityRelation('stage', {entityName: EntityName.Stage, id: model.stageStageQuestionnairesId === null ? undefined : model.stageStageQuestionnairesId});
    instance.addEntityRelation('stageQuestionnaireSection', {entityName: EntityName.StageQuestionnaireSection, id: model.stageQuestionnaireSectionStageQuestionnairesId === null ? undefined : model.stageQuestionnaireSectionStageQuestionnairesId});
    return instance;
}

export function getCreateStageQuestionnaireInput(instance: FilledEntity): CreateStageQuestionnaireInput {
    const name = instance.getStringMandatory('name');
    const order = instance.getIntMandatory('order');
    const deviceClassification = instance.getDeviceClassificationOptional('deviceClassification');
    const stageStageQuestionnairesId = instance.getEntityRelationOptional('stage')?.id;
    const questionStageQuestionnairesId = instance.getEntityRelationOptional('question')?.id;
    const stageQuestionnaireSectionStageQuestionnairesId = instance.getEntityRelationOptional('stageQuestionnaireSection')?.id;

    return {
        name,
        order,
        deviceClassification,
        stageStageQuestionnairesId,
        questionStageQuestionnairesId,
        stageQuestionnaireSectionStageQuestionnairesId
    };
}

export function getDeleteStageQuestionnaireInput(instance: FilledEntity): DeleteStageQuestionnaireInput {
    const id = instance.getIdMandatory('id');

    return {
        id
    };
}

export function getUpdateStageQuestionnaireInput(instance: FilledEntity): UpdateStageQuestionnaireInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const order = instance.getIntOptional('order');
    const deviceClassification = instance.getDeviceClassificationOptional('deviceClassification');
    const stageStageQuestionnairesId = instance.getEntityRelationOptional('stage')?.id;
    const questionStageQuestionnairesId = instance.getEntityRelationOptional('question')?.id;
    const stageQuestionnaireSectionStageQuestionnairesId = instance.getEntityRelationOptional('stageQuestionnaireSection')?.id;

    return {
        id,
        name,
        order,
        deviceClassification,
        stageStageQuestionnairesId,
        questionStageQuestionnairesId,
        stageQuestionnaireSectionStageQuestionnairesId
    };
}
