import * as graphQlApi from '../API';
import { AnswerNotFoundException, CountryNotFoundException, OperatorNotImplementedException } from './CustomExceptions';

export interface DeviceQualificationNode {
    expressionId: string,
    questionId: string | undefined,
    qualificationIfYes: graphQlApi.DeviceQualification | undefined,
    qualificationFromQuestion: graphQlApi.DeviceQualification | undefined,
    qualificationFromChildren: graphQlApi.DeviceQualification | undefined,
    qualification: graphQlApi.DeviceQualification | undefined
    answer: boolean | undefined,
    operator: graphQlApi.DeviceExpressionOperator | undefined,
    children: DeviceQualificationNode[],
}

export class DeviceQualifier {
    private _expressions: {[key: string]: graphQlApi.DeviceQualificationExpression}
    private _answers: {[key: string]: boolean}

    constructor(
        expressions: {[key: string]: graphQlApi.DeviceQualificationExpression},
        answers: {[key: string]: boolean}
    ) {
        this._expressions = expressions
        this._answers = answers
    }

    public qualify(): DeviceQualificationNode[] {
        const rootExpressions = this.getRootExpressions()

        const deviceQualificationNodes = []

        for (const rootExpression of rootExpressions) {
            const deviceQualificationNode = this.getExpressionQualification(rootExpression)
            deviceQualificationNodes.push(deviceQualificationNode)
        }

        return deviceQualificationNodes
    }

    private getExpressionQualification(expression: graphQlApi.DeviceQualificationExpression): DeviceQualificationNode {
        const answer: boolean | undefined = this.getAnswerFromExpression(expression)
        const qualificationFromQuestion: graphQlApi.DeviceQualification | undefined = this.getQualificationFromQuestion(expression, answer)
        const childrenExpressions: graphQlApi.DeviceQualificationExpression[] = this.getChildExpressions(expression)
        const childrenNodes: DeviceQualificationNode[] = this.getChildrenNodes(childrenExpressions)
        const qualificationFromChildren: graphQlApi.DeviceQualification | undefined = this.getQualificationFromChildren(expression, childrenNodes)
        const qualification: graphQlApi.DeviceQualification | undefined = this.getFinalQualification(expression, qualificationFromQuestion, qualificationFromChildren)
        
        const deviceQualificationNode = {
            expressionId: expression.id,
            questionId: expression.deviceQualificationExpressionYesNoQuestionId !== null ? expression.deviceQualificationExpressionYesNoQuestionId: undefined,
            qualificationIfYes: expression.qualificationIfYes !== null ? expression.qualificationIfYes : undefined, 
            qualificationFromQuestion: qualificationFromQuestion,
            qualificationFromChildren: qualificationFromChildren,
            qualification: qualification,
            answer: answer,
            operator: expression.operator !== null ? expression.operator : undefined,
            children: childrenNodes,
        }

        return deviceQualificationNode
    }

    private getRootExpressions(): graphQlApi.DeviceQualificationExpression[] {
        return Object.entries(this._expressions)
            .map(([_, deviceQualificationExpression]) => deviceQualificationExpression)
            .filter(deviceQualificationExpression => deviceQualificationExpression.isRoot)
    }

    private getChildExpressions(expression: graphQlApi.DeviceQualificationExpression): graphQlApi.DeviceQualificationExpression[] {
        return Object.entries(this._expressions)
            .map(([_, deviceQualificationExpression]) => deviceQualificationExpression)
            .filter(deviceQualificationExpression => deviceQualificationExpression.deviceQualificationExpressionChildrenDeviceQualificationExpressionsId === expression.id)
    }

    private getAnswerFromExpression(expression: graphQlApi.DeviceQualificationExpression): boolean | undefined {
        const questionId = expression.deviceQualificationExpressionYesNoQuestionId !== null ? expression.deviceQualificationExpressionYesNoQuestionId : undefined

        if (questionId === undefined) {
            return undefined
        }

        if (!this._answers.hasOwnProperty(questionId)) {
            throw new AnswerNotFoundException(questionId)
        }

        return this._answers[questionId]
    }

    private getQualificationFromQuestion(expression: graphQlApi.DeviceQualificationExpression, answer: boolean | undefined): graphQlApi.DeviceQualification | undefined {
        if (answer === undefined || answer === false) {
            return undefined
        }

        const qualificationIfYes = expression.qualificationIfYes !== null ? expression.qualificationIfYes : undefined
        return qualificationIfYes
    }

    private getChildrenNodes(childExpressions: graphQlApi.DeviceQualificationExpression[]): DeviceQualificationNode[] {
        const deviceQualificationNodes = []

        for (const childExpression of childExpressions) {
            const deviceQualificationNode = this.getExpressionQualification(childExpression)
            deviceQualificationNodes.push(deviceQualificationNode)
        }

        return deviceQualificationNodes
    }

    private getMaxQualification(expression: graphQlApi.DeviceQualificationExpression, qualifications: (graphQlApi.DeviceQualification | undefined)[]): graphQlApi.DeviceQualification | undefined {
        const specialQuestionCategory = expression.specialQuestionCategory !== null ? expression.specialQuestionCategory : undefined

        if (specialQuestionCategory === undefined) {
            return undefined
        }

        switch (specialQuestionCategory) {
            case graphQlApi.SpecialQuestionCategory.EU_QUALIFICATION_MEDICAL_DEVICE:
                for (const referenceQualification of [graphQlApi.DeviceQualification.MEDICAL_DEVICE_MDR_ARTICLE_2_1]) {
                    const foundQualification = qualifications.some(qualification => qualification === referenceQualification)

                    if (foundQualification) {
                        return referenceQualification
                    }
                }

                return undefined

            case graphQlApi.SpecialQuestionCategory.EU_QUALIFICATION_ACCESSORY_FOR_MEDICAL_DEVICE:
                for (const referenceQualification of [graphQlApi.DeviceQualification.ACCESSORY_FOR_MEDICAL_DEVICE_MDR_ARTICLE_2_2]) {
                    const foundQualification = qualifications.some(qualification => qualification === referenceQualification)

                    if (foundQualification) {
                        return referenceQualification
                    }
                }

                return undefined

            case graphQlApi.SpecialQuestionCategory.EU_QUALIFICATION_MEDICAL_DEVICE_WITHOUT_INTENDED_MEDICAL_PURPOSE:
                for (const referenceQualification of [graphQlApi.DeviceQualification.MEDICAL_DEVICE_WITHOUT_INTENDED_MEDICAL_PURPOSE_MDR_ANNEX_XVI]) {
                    const foundQualification = qualifications.some(qualification => qualification === referenceQualification)

                    if (foundQualification) {
                        return referenceQualification
                    }
                }

                return undefined

            default:
                throw new CountryNotFoundException()
        }
    }

    private getQualificationFromChildren(expression: graphQlApi.DeviceQualificationExpression, childExpressionQualifications: DeviceQualificationNode[]): graphQlApi.DeviceQualification | undefined {
        const operator = expression.operator !== null ? expression.operator : undefined

        if (operator === undefined) {
            return undefined
        }

        switch (operator) {
            case graphQlApi.DeviceExpressionOperator.MAX_OF:
            case graphQlApi.DeviceExpressionOperator.RIGHT_OVERRIDE_OF: 
                const childQualifications = []

                for (const childExpressionQualification of childExpressionQualifications) {
                    childQualifications.push(childExpressionQualification.qualification)
                }

                return this.getMaxQualification(expression, childQualifications)

            default:
                throw new OperatorNotImplementedException(operator)
        }
    }

    private getFinalQualification(expression: graphQlApi.DeviceQualificationExpression, qualificationFromQuestion: graphQlApi.DeviceQualification | undefined, qualificationFromChildren: graphQlApi.DeviceQualification | undefined): graphQlApi.DeviceQualification | undefined {
        const operator = expression.operator !== null ? expression.operator : undefined

        if (operator === undefined) {
            return qualificationFromQuestion
        }

        switch (operator) {
            case graphQlApi.DeviceExpressionOperator.MAX_OF:
                const qualifications = [qualificationFromQuestion, qualificationFromChildren]
                return this.getMaxQualification(expression, qualifications)

            case graphQlApi.DeviceExpressionOperator.RIGHT_OVERRIDE_OF:
                if (qualificationFromChildren !== undefined) {
                    return qualificationFromChildren
                } else {
                    return qualificationFromQuestion
                }

            default:
                throw new OperatorNotImplementedException(operator)
        }
    }
}