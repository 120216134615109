import { CreatePreviewOrganizationInput, DeletePreviewOrganizationInput, PreviewOrganization, UpdatePreviewOrganizationInput } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyPreviewOrganizationInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name', 'registrationNumber'];

    const entityRelationsKeysEntities: Map<string, EntityName> = new Map([
        ['previewUsers', EntityName.PreviewUser],
        ['previewProducts', EntityName.PreviewProduct]
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys,
        entityRelationsKeysEntities
    });
}

export function getFilledPreviewOrganizationInstance(model: PreviewOrganization): FilledEntity {
    const instance = getEmptyPreviewOrganizationInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name);
    instance.addString('registrationNumber', model.registrationNumber);
    
    const previewUserIds = model.previewUsers === undefined ? [] : model.previewUsers?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('previewUsers', {ids: previewUserIds, entityName: EntityName.PreviewUser});

    const previewProductIds = model.previewProducts === undefined ? [] : model.previewProducts?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('previewProducts', {ids: previewProductIds, entityName: EntityName.PreviewProduct});

    return instance;
}

export function getCreatePreviewOrganizationInput(instance: FilledEntity): CreatePreviewOrganizationInput {
    const name = instance.getStringMandatory('name');
    const registrationNumber = instance.getStringMandatory('registrationNumber');

    return {
        name,
        registrationNumber
    };
}

export function getUpdatePreviewOrganizationInput(instance: FilledEntity): UpdatePreviewOrganizationInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const registrationNumber = instance.getStringOptional('registrationNumber');

    return {
        id,
        name,
        registrationNumber
    };
}

export function getDeletePreviewOrganizationInput(instance: FilledEntity): DeletePreviewOrganizationInput {
    const id = instance.getIdMandatory('id');

    return {
        id
    };
}