import React from 'react'
import { EnumViewer } from '../mixins/EnumViewer'

interface RelatedEnumViewerProps {
    relationName: string
    value: any | undefined    
}

export const RelatedEnumViewer: React.FC<RelatedEnumViewerProps> = ({ relationName, value }) => {
    function getRelatedEnumViewer() {
        return <EnumViewer relationName={relationName} value={value} />
    }

    return (
        getRelatedEnumViewer()
    )
}