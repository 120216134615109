import React, { useState, useEffect } from 'react'
import { EntityName } from '../../model/EntityName';
import { FilledEntity } from '../../model/FilledEntity';
import { listFilledInstances } from './mixin-helper';

interface GenericRelatedChooserProps {
    relationName: string
    entityName: EntityName
    originalId: string|undefined
    setId: (relationName: string, entityName: EntityName, id: string|undefined) => void
}

export const GenericRelatedChooser: React.FC<GenericRelatedChooserProps> = ({relationName, entityName, originalId, setId }) => {
    const [instances, setInstances] = useState<FilledEntity[]>([])
    const [selectedId, setSelectedId] = useState<string|undefined>(originalId)

    useEffect(() => {
        if (selectedId === undefined) {
            setSelectedId(originalId)
        }
    }, [originalId])

    const asyncSetInstances = async () => {
        const instances = await listFilledInstances(entityName)
        setInstances(instances)
    }

    asyncSetInstances()

    function setIdString(event: React.ChangeEvent<HTMLSelectElement>): void {
        const id: string = event.target.value
        setId(relationName, entityName, id)
        setSelectedId(id)
    }

    return (
        <>
            <label className="form-label" htmlFor={'select-' + relationName}>{relationName}</label>
            <select name="entityName" id={'select-' + relationName} className="form-control" onChange={setIdString} value={selectedId}>
                <option key="">Select an option</option>
                {
                    instances.map(function(instance) {
                        const id = instance.getIdMap().get("id")

                        if (id === undefined || id === null || id === "") {
                            return null
                        }

                        const name = instance.getStringMap().get("name")
                        return <option key={'select-option-' + relationName + id} value={id}>{name}</option>
                    })
                }
            </select>
        </>
    )
}