import { API } from "aws-amplify";
import { CreatePreviewUserInput, DeletePreviewUserInput, PreviewUser, GetPreviewUserQuery, ListPreviewUsersQuery, UpdatePreviewUserInput } from "../API";
import { createPreviewUser, deletePreviewUser, updatePreviewUser } from "../graphql/mutations";
import { getPreviewUser, listPreviewUsers, listPreviewUsersByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createPreviewUserApi = async (input: CreatePreviewUserInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewUser> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createPreviewUser,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createPreviewUser: PreviewUser } };
    return response.data.createPreviewUser;
}

export const updatePreviewUserApi = async (input: UpdatePreviewUserInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewUser> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updatePreviewUser,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updatePreviewUser: PreviewUser } };
    return response.data.updatePreviewUser;
}

export const deletePreviewUserApi = async (input: DeletePreviewUserInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewUser> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deletePreviewUser,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deletePreviewUser: PreviewUser } };
    return response.data.deletePreviewUser;
}

export const listPreviewUsersApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewUser[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listPreviewUsers,
    });

    const response = responseRaw as { data: ListPreviewUsersQuery };
    const items = response.data.listPreviewUsers?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as PreviewUser[];
    return itemsExisting
}

export const getPreviewUserApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewUser|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getPreviewUser,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetPreviewUserQuery };
    const instance = response.data.getPreviewUser;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as PreviewUser
    return instanceNotUndefined
}

export const listPreviewUsersByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewUser[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listPreviewUsersByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListPreviewUsersQuery };
    const items = response.data.listPreviewUsers?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as PreviewUser[];
    return itemsExisting
}
