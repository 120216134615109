import { CreateQuestionRowColumnInput, DeleteQuestionRowColumnInput, UpdateQuestionRowColumnInput, QuestionRowColumn } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyQuestionRowColumnInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name'];
    const intKeys = ['order'];
    const answerDataTypeKeys = ['answerDataType'];

    const entityRelationKeysEntities: Map<string, EntityName> = new Map([
        ['question', EntityName.Question]
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys,
        intKeys,
        answerDataTypeKeys,
        entityRelationKeysEntities
    });
}

export function getFilledQuestionRowColumnInstance(model: QuestionRowColumn): FilledEntity {
    const instance = getEmptyQuestionRowColumnInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name === null ? undefined : model.name);
    instance.addInt('order', model.order === null ? undefined : model.order);
    instance.addAnswerDataType('answerDataType', model.answerDataType === null ? undefined : model.answerDataType);
    instance.addEntityRelation('question', {entityName: EntityName.Question, id: model.questionQuestionRowColumnsId === null ? undefined : model.questionQuestionRowColumnsId});
    return instance;
}

export function getCreateQuestionRowColumnInput(instance: FilledEntity): CreateQuestionRowColumnInput {
    const name = instance.getStringMandatory('name');
    const order = instance.getIntMandatory('order');
    const answerDataType = instance.getAnswerDataTypeMandatory('answerDataType');
    const questionQuestionRowColumnsId = instance.getEntityRelationOptional('question')?.id;

    return {
        name,
        order,
        answerDataType,
        questionQuestionRowColumnsId
    };
}

export function getUpdateQuestionRowColumnInput(instance: FilledEntity): UpdateQuestionRowColumnInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const order = instance.getIntOptional('order');
    const answerDataType = instance.getAnswerDataTypeOptional('answerDataType');
    const questionQuestionRowColumnsId = instance.getEntityRelationOptional('question')?.id;

    return {
        id,
        name,
        order,
        answerDataType,
        questionQuestionRowColumnsId
    };
}

export function getDeleteQuestionRowColumnInput(instance: FilledEntity): DeleteQuestionRowColumnInput {
    const id = instance.getIdMandatory('id');

    return {
        id
    };
}