import { DeviceQualificationNode } from '../../../algorithms/DeviceQualifier';
import * as graphQlApi from '../../../API';

function getRootQualificationExpressions(expressions: {[key: string]: graphQlApi.DeviceQualificationExpression}): graphQlApi.DeviceQualificationExpression[] {
    return Object.entries(expressions)
        .filter(([_, expression]) => expression.isRoot)
        .map(([_, expression]) => expression)
}

function getChildrenQualificationExpressions(expressionId: string, expressions: graphQlApi.DeviceQualificationExpression[], sorted: boolean): graphQlApi.DeviceQualificationExpression[] {
    const childrenExpressions = Object.entries(expressions)
        .map(([_, expression]) => expression)
        .filter(expression => expression.deviceQualificationExpressionChildrenDeviceQualificationExpressionsId === expressionId)

    if (!sorted) {
        return childrenExpressions
    }

    return childrenExpressions
        .sort(sortQualificationExpressions)
}

function sortQualificationExpressions(expression1: graphQlApi.DeviceQualificationExpression, expression2: graphQlApi.DeviceQualificationExpression): number {
    if (expression1.index === undefined || expression1.index === null) {
        return 0
    }

    if (expression2.index === undefined || expression2.index === null) {
        return 0
    }

    const diff = expression1.index - expression2.index
    return diff
}

function getQualificationQuestionIds(expressions: {[key: string]: graphQlApi.DeviceQualificationExpression}, sorted: boolean): string[] {
    const rootExpressions = getRootQualificationExpressions(expressions)
    const expressionsList = Object.entries(expressions).map(([_, expression]) => expression)

    return rootExpressions
        .sort(sortQualificationExpressions)
        .flatMap(rootExpression => {
            const childrenExpressions = getChildrenQualificationExpressions(rootExpression.id, expressionsList, sorted)
            
            return childrenExpressions
                .map(childExpression => childExpression.deviceQualificationExpressionYesNoQuestionId)
                .filter(questionId => questionId !== null && questionId !== undefined)
                .map(questionId => questionId as string)
        })
}

function getFirstQualificationQuestionId(expressions: {[key: string]: graphQlApi.DeviceQualificationExpression}, sorted: boolean): string | undefined {
    const questionIds = getQualificationQuestionIds(expressions, sorted)

    if (questionIds.length <= 0) {
        return undefined
    }

    return questionIds[0]
}

function getNextQualificationQuestionId(questionId: string, expressions: {[key: string]: graphQlApi.DeviceQualificationExpression}, sorted: boolean): string | undefined {
    const questionIds = getQualificationQuestionIds(expressions, sorted)
    const nextQuestionIndex = questionIds.indexOf(questionId) + 1

    if (nextQuestionIndex >= questionIds.length) {
        return undefined
    }

    return questionIds[nextQuestionIndex]
}

function getPreviousQualificationQuestionId(questionId: string, expressions: {[key: string]: graphQlApi.DeviceQualificationExpression}, sorted: boolean): string | undefined {
    const questionIds = getQualificationQuestionIds(expressions, sorted)
    const previousQuestionIndex = questionIds.indexOf(questionId) - 1

    if (previousQuestionIndex < 0) {
        return undefined
    }

    return questionIds[previousQuestionIndex]
}

function getQualificationText(qualification: graphQlApi.DeviceQualification | undefined): string {
    switch (qualification) {
        case graphQlApi.DeviceQualification.MEDICAL_DEVICE_MDR_ARTICLE_2_1:
            return "Medical Device (MDR Article 2.1)"
        case graphQlApi.DeviceQualification.ACCESSORY_FOR_MEDICAL_DEVICE_MDR_ARTICLE_2_2:
            return "Accessory for Medical Device (MDR Article 2.2)"
        case graphQlApi.DeviceQualification.MEDICAL_DEVICE_WITHOUT_INTENDED_MEDICAL_PURPOSE_MDR_ANNEX_XVI:
            return "Medical Device without Intended Medical Purpose (MDR Annex XVI)"
    }

    return "Not a medical device"
}

function renderQualificationQuestion(question: graphQlApi.Question, expressions: {[key: string]: graphQlApi.DeviceQualificationExpression}, answers: {[key: string]: boolean}, provideAnswer: ((answer: boolean) => void) | undefined, qualification: DeviceQualificationNode | undefined, showUnansweredQuestions: boolean) {
    const questionIds = getQualificationQuestionIds(expressions, true)
    const shownIndex = questionIds.indexOf(question.id) + 1
    const hasAnswer = answers.hasOwnProperty(question.id)
    const answer = hasAnswer ? answers[question.id] : undefined

    if (answer !== true && !showUnansweredQuestions) {
        return null
    }

    const answerYesNo = answer === true ? "yes" : "no"
    const isQualified = qualification !== undefined
    const isMedicalDevice = isQualified && qualification.qualification === graphQlApi.DeviceQualification.MEDICAL_DEVICE_MDR_ARTICLE_2_1

    const answeredContributed = answer && isMedicalDevice
    const justAnswered = answer && !isMedicalDevice
    const notAnsweredContributed = !answer && !isMedicalDevice
    const justNotAnswered = !answer && isMedicalDevice

    return <div className="card mb-4">
        <div className="card-body"> 
            <div className="d-flex pb-3">
                <div className="flex-none me-3">
                    {isQualified && answeredContributed &&
                        <div className="icon icon-shape w-9 h-9 bg-success text-white rounded-circle">
                            <i className="bi bi-check"></i>
                        </div>
                    }
                    {isQualified && justAnswered &&
                        <div className="icon icon-shape w-9 h-9 bg-secondary text-white rounded-circle">
                            <i className="bi bi-check"></i>
                        </div>
                    }
                    {isQualified && notAnsweredContributed &&
                        <div className="icon icon-shape w-9 h-9 bg-danger text-white rounded-circle">
                            <i className="bi bi-x"></i>
                        </div>
                    }
                    {isQualified && justNotAnswered &&
                        <div className="icon icon-shape w-9 h-9 bg-secondary text-white rounded-circle">
                            <i className="bi bi-x"></i>
                        </div>
                    }
                    {!isQualified &&
                        <div className="icon icon-shape w-9 h-9 bg-secondary text-white rounded-circle">
                            M
                        </div>
                    }
                </div>
                <div className="flex-fill">
                    <div className="d-block font-bold">
                        <h3 className="mb-2">Question {shownIndex}
                        &nbsp;
                            {question.tooltip !== undefined && question.tooltip !== null &&
                                <i className="bi bi-info-circle text-warning" data-bs-toggle="tooltip" data-bs-placement="bottom" title={question.tooltip} />
                            }
                        </h3>
                    </div>
                    <div className="d-block mt-4">
                        {question.statement}
                    </div>
                    {!isQualified &&
                        <div className="mt-4">
                            <hr />
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="qualification-answer" id="qualification-answer-yes" checked={answer === true} onChange={() => provideAnswer !== undefined && provideAnswer(true)} />
                                <label className="form-check-label" htmlFor="qualification-answer-yes">
                                Yes
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="qualification-answer" id="qualification-answer-no" checked={answer === false} onChange={() => provideAnswer !== undefined && provideAnswer(false)} />
                                <label className="form-check-label" htmlFor="qualification-answer-no">
                                No
                                </label>
                            </div>
                        </div>
                    }
                    {isQualified && answeredContributed &&
                        <div className="mt-4">
                            <div className="d-block text-primary mt-1">Your answer <span className="font-bold">{answerYesNo}</span> decided the overall qualification result.</div>
                        </div>
                    }
                    {isQualified && notAnsweredContributed &&
                        <div className="mt-4">
                            <div className="d-block text-danger mt-1">Your answer <span className="font-bold">{answerYesNo}</span> decided the overall qualification result.</div>
                        </div>
                    }
                    {question.description !== undefined && question.description !== null &&
                        <div className="mb-4">
                            <h4><a role="button" data-bs-toggle="collapse" aria-expanded="false" href={"#collapseQuestionDescription" + question.id} aria-controls={"collapseQuestionDescription" + question.id}>Description</a></h4>
                            <p className="collapse" id={"collapseQuestionDescription" + question.id}>{question.description}</p>
                        </div>
                    }
                    {question.explanations !== undefined && question.explanations !== null && question.explanations?.length > 0 &&
                        <div className="mt-4">
                            <h4><a data-bs-toggle="collapse" aria-expanded="false" href={"#collapseQuestionExplanations" + question.id} aria-controls={"collapseQuestionExplanations" + question.id}>Explanations</a></h4>
                            <ul className="collapse" id={"collapseQuestionExplanations" + question.id}>
                                {question.explanations?.map((explanation, index) => (
                                    <li key={index}>{explanation}</li>
                                ))}
                            </ul>
                        </div>
                    }
                    {question.examples !== undefined && question.examples !== null && question.examples?.length > 0 &&
                        <div className="mt-4">
                            <h4><a data-bs-toggle="collapse" aria-expanded="false" href={"#collapseQuestionExamples" + question.id} aria-controls={"collapseQuestionExamples" + question.id}>Examples</a></h4>
                            <ul className="collapse" id={"collapseQuestionExamples" + question.id}>
                                {question.examples?.map((example, index) => (
                                    <li key={index}>{example}</li>
                                ))}
                            </ul>
                        </div>
                    }
                    {question.notes !== undefined && question.notes !== null && question.notes?.length > 0 &&
                        <div className="mt-4">
                            <h4><a data-bs-toggle="collapse" aria-expanded="false" href={"#collapseQuestionNotes" + question.id} aria-controls={"collapseQuestionNotes" + question.id}>Notes</a></h4>
                            <ul className="collapse" id={"collapseQuestionNotes" + question.id}>
                                {question.notes?.map((note, index) => (
                                    <li key={index}>{note}</li>
                                ))}
                            </ul>
                        </div>
                    }
                    {question.tooltip !== undefined && question.tooltip !== null &&
                        <div className="mt-4">
                            <h4>Tooltip</h4>
                            <p>{question.tooltip}</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
}

export {
    getRootQualificationExpressions,
    getChildrenQualificationExpressions,
    getQualificationQuestionIds,
    getNextQualificationQuestionId,
    getPreviousQualificationQuestionId,
    getFirstQualificationQuestionId,
    renderQualificationQuestion,
    getQualificationText
}