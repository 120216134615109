import React, { useState } from 'react'
import { SpecialQuestionCategory } from '../../../models';

interface SpecialQuestionCategoryRelatedChooserProps {
    relationName: string
    originalEnumValue: SpecialQuestionCategory|undefined
    setEnumValue: (enumValue: SpecialQuestionCategory|undefined) => void
}

export const SpecialQuestionCategoryRelatedChooser: React.FC<SpecialQuestionCategoryRelatedChooserProps> = ({ relationName, originalEnumValue, setEnumValue }) => {
    const map = new Map(Object.entries(SpecialQuestionCategory) as [string, SpecialQuestionCategory][]);

    const [selectedEnumValue, setSelectedEnumValue] = useState<SpecialQuestionCategory|undefined>(undefined)

    function setEnumValueString(event: React.ChangeEvent<HTMLSelectElement>): void {
        const enumValueString: string = event.target.value
        const enumValue = map.get(enumValueString)
        setSelectedEnumValue(enumValue)
        setEnumValue(enumValue)
    }

    const entityNameKeys = Object.keys(SpecialQuestionCategory).filter((key) => isNaN(Number(key)))
    const entityNameVals = Object.values(SpecialQuestionCategory)
    
    return (
        <div className="mt-4">
            <label className="form-label" htmlFor={'select-' + relationName}>{relationName}</label>
            <select name="entityName" className="form-select" id={'select-' + relationName} onChange={setEnumValueString} value={selectedEnumValue || originalEnumValue || undefined}>
                <option key="">Select an option</option>
                {
                    entityNameKeys.map(function(key, i) {
                        const value = entityNameVals[i]
                        return <option key={relationName + key} value={value}>{value}</option>
                    })
                }
            </select>
        </div>
    )
}