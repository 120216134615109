interface HomeProps {
    userGroups: string[]
}

export const Home: React.FC<HomeProps> = (props) => {
    return (
        <div>
            <div className="py-4 py-xs-8 py-sm-12 py-md-16 py-lg-24 py-xl-32 py-xxl-32 bg-surface-dark overflow-hidden">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <h1 className="display-1 text-center text-primary">
                                med<span className="font-bold">flow</span>
                            </h1>
                            <h2 className="mb-10 text-center text-white">
                                Medical device certification software - step by step
                            </h2>
                            <p className="lead mb-0 text-center text-white">
                                Achieve the certification of your medical device - and more! Our platform provides you with an intuitive and easy-to-follow journey that simplifies the certification process, so you can focus on delivering innovative medical devices to the market.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-4 py-xs-8 py-sm-12 py-md-16 py-lg-24 py-xl-32 py-xxl-32 overflow-hidden">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <div className="row g-16 g-md-24 text-center">
                                <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4 mb-md-0 text-justify">
                                    <div className="icon icon-shape icon-lg bg-primary text-white text-lg rounded-circle mb-8">
                                        <i className="bi bi-star"></i>
                                    </div>
                                    <h5 className="h4 font-semibold mb-2">Expert Support</h5>
                                    <p className="lh-relaxed mb-2">
                                        Access to experienced professionals for consultation and assistance throughout.
                                    </p>
                                    <p className="text-xs font-bold text-primary text-uppercase">
                                        Available now
                                    </p>
                                </div>
                                <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4 mb-md-0 text-justify">
                                    <div className="icon icon-shape icon-lg bg-primary text-white text-lg rounded-circle mb-8">
                                        <i className="bi bi-signpost"></i>
                                    </div>
                                    <h5 className="h4 font-semibold mb-2">Certification Roadmap</h5>
                                    <p className="lh-relaxed mb-2">
                                        Comprehensive guidance from idea to certification, ensuring a smooth process throughout.
                                    </p>
                                    <p className="text-xs font-bold text-primary text-uppercase">
                                        In development
                                    </p>
                                </div>
                                <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4 mb-md-0 text-justify">
                                    <div className="icon icon-shape icon-lg bg-primary text-white text-lg rounded-circle mb-8">
                                        <i className="bi bi-text-paragraph"></i>
                                    </div>
                                    <h5 className="h4 font-semibold mb-2">Compliance Templates</h5>
                                    <p className="lh-relaxed mb-2">
                                        Customizable templates for easy and compliant regulatory documentation.
                                    </p>
                                    <p className="text-xs font-bold text-primary text-uppercase">
                                        In development
                                    </p>
                                </div>
                                <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4 mb-md-0 text-justify">
                                    <div className="icon icon-shape icon-lg bg-primary text-white text-lg rounded-circle mb-8">
                                        <i className="bi bi-people"></i>
                                    </div>
                                    <h5 className="h4 font-semibold mb-2">Collaborative Workspace</h5>
                                    <p className="lh-relaxed mb-2">
                                        Fast team collaboration and communication for efficient project management.
                                    </p>
                                    <p className="text-xs font-bold text-primary text-uppercase">
                                        Planned for development
                                    </p>
                                </div>
                                <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4 mb-md-0 text-justify">
                                    <div className="icon icon-shape icon-lg bg-primary text-white text-lg rounded-circle mb-8">
                                        <i className="bi bi-bar-chart"></i>
                                    </div>
                                    <h5 className="h4 font-semibold mb-2">Reporting &amp; Analytics</h5>
                                    <p className="lh-relaxed mb-2">
                                        Data-driven insights to optimize and accelerate the progress on certification.
                                    </p>
                                    <p className="text-xs font-bold text-primary text-uppercase">
                                        Planned for development
                                    </p>
                                </div>
                                <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4 mb-md-0 text-justify">
                                    <div className="icon icon-shape icon-lg bg-primary text-white text-lg rounded-circle mb-8">
                                        <i className="bi bi-cloud-check"></i>
                                    </div>
                                    <h5 className="h4 font-semibold mb-2">Cloud-Based Platform</h5>
                                    <p className="lh-relaxed mb-2">
                                        Cloud-based solution with scale, security, and resilience as features to sleep at night.
                                    </p>
                                    <p className="text-xs font-bold text-primary text-uppercase">
                                        In development
                                    </p>
                                </div>
                            </div>
                            <div className="mx-2 mt-24 text-center">
                                {
                                    props.userGroups.length <= 0 &&
                                    <a href="/onboarding/assess" className="btn btn-lg btn-primary shadow-sm mx-2 px-lg-8">
                                        Assess Your Medical Device
                                    </a>
                                }
                                {
                                    props.userGroups.find(group => group === 'Admins') &&
                                    <a href="/admin" className="btn btn-lg btn-danger shadow-sm mx-2 px-lg-8">
                                        Go to Administration <sup>Very Careful!!!</sup>
                                    </a>
                                }
                                {
                                    props.userGroups.find(group => group === 'Editors') &&
                                    <a href="/editor" className="btn btn-lg btn-warning shadow-sm mx-2 px-lg-8">
                                        Go to Editing <sup>Careful!</sup>
                                    </a>
                                }
                                {
                                    props.userGroups.find(group => group === 'Users') &&
                                    <a href="/user" className="btn btn-lg btn-primary shadow-sm mx-2 px-lg-8">
                                        Go to Products
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-n1 transform translate-y-1/4">
                    <div className="col-lg-11 col-xl-9 mx-auto">
                        <div className="row g-5">
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md6.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md4.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md3.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md5.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md2.jpg" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
