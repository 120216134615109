import * as graphQlApi from '../API';

export class AnswerNotFoundException extends Error {
    private _questionId: string
    
    constructor(questionId: string) {
        super("Answer not found.")
        this.name = "AnswerNotFoundException"
        this._questionId = questionId
    }

    get questionId(): string {
        return this._questionId
    }
}

export class CountryNotFoundException extends Error {
    constructor() {
        super("Country not found.")
        this.name = "CountryNotFoundException"
    }
}

export class OperatorNotImplementedException extends Error {
    private _operator: graphQlApi.DeviceExpressionOperator

    constructor(operator: graphQlApi.DeviceExpressionOperator) {
        super("Operator not implemented.")
        this.name = "OperatorNotImplementedException"
        this._operator = operator
    }

    get operator(): graphQlApi.DeviceExpressionOperator {
        return this._operator
    }
}