import { CreateStageInput, DeleteStageInput, Stage, UpdateStageInput } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyStageInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name'];
    const intKeys = ['order'];

    const entityRelationKeysEntities: Map<string, EntityName> = new Map([
        ['medflowVersion', EntityName.MedflowVersion],
        ['track', EntityName.Track],
        ['phase', EntityName.Phase],
    ]);

    const entityRelationsKeysEntities: Map<string, EntityName> = new Map([
        ['stageQuestionnaires', EntityName.StageQuestionnaire],
        ['stageQuestionnaireSections', EntityName.StageQuestionnaireSection],
        ['reports', EntityName.Report],
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys: stringKeys,
        intKeys: intKeys,
        entityRelationKeysEntities: entityRelationKeysEntities,
        entityRelationsKeysEntities: entityRelationsKeysEntities
    });
}

export function getFilledStageInstance(model: Stage): FilledEntity {
    const instance = getEmptyStageInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name === null ? undefined : model.name);
    instance.addInt('order', model.order === null ? undefined : model.order);
    instance.addEntityRelation('medflowVersion', {entityName: EntityName.MedflowVersion, id: model.stageMedflowVersionId === null ? undefined : model.stageMedflowVersionId});
    instance.addEntityRelation('phase', {entityName: EntityName.Phase, id: model.phaseStagesId === null ? undefined : model.phaseStagesId});
    instance.addEntityRelation('track', {entityName: EntityName.Track, id: model.trackStagesId === null ? undefined : model.trackStagesId});

    const stageQuestionnaireIds = model.stageQuestionnaires === undefined ? [] : model.stageQuestionnaires?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('stageQuestionnaires', {ids: stageQuestionnaireIds, entityName: EntityName.StageQuestionnaire});

    const stageQuestionnaireSectionIds = model.stageQuestionnaireSections === undefined ? [] : model.stageQuestionnaireSections?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('stageQuestionnaireSections', {ids: stageQuestionnaireSectionIds, entityName: EntityName.StageQuestionnaireSection});

    const reportIds = model.reports === undefined ? [] : model.reports?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('reports', {ids: reportIds, entityName: EntityName.Report});

    return instance;
}

export function getCreateStageInput(instance: FilledEntity): CreateStageInput {
    const name = instance.getStringMandatory('name');
    const order = instance.getIntMandatory('order');
    const phaseStagesId = instance.getEntityRelationOptional('phase')?.id;
    const trackStagesId = instance.getEntityRelationOptional('track')?.id;
    const stageMedflowVersionId = instance.getEntityRelationOptional('medflowVersion')?.id;

    return {
        name,
        order,
        phaseStagesId,
        trackStagesId,
        stageMedflowVersionId
    };
}

export function getUpdateStageInput(instance: FilledEntity): UpdateStageInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const order = instance.getIntOptional('order');
    const phaseStagesId = instance.getEntityRelationOptional('phase')?.id;
    const trackStagesId = instance.getEntityRelationOptional('track')?.id;
    const stageMedflowVersionId = instance.getEntityRelationOptional('medflowVersion')?.id;

    return {
        id,
        name,
        order,
        phaseStagesId,
        trackStagesId,
        stageMedflowVersionId
    };
}

export function getDeleteStageInput(instance: FilledEntity): DeleteStageInput {
    const id = instance.getIdMandatory('id');

    return {
        id
    };
}