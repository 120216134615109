import { API } from "aws-amplify";
import { CreateLabelInput, DeleteLabelInput, Label, GetLabelQuery, ListLabelsQuery, UpdateLabelInput } from "../API";
import { createLabel, deleteLabel, updateLabel } from "../graphql/mutations";
import { getLabel, listLabels, listLabelsByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createLabelApi = async (input: CreateLabelInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Label> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createLabel,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createLabel: Label } };
    return response.data.createLabel;
}

export const updateLabelApi = async (input: UpdateLabelInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Label> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updateLabel,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updateLabel: Label } };
    return response.data.updateLabel;
}

export const deleteLabelApi = async (input: DeleteLabelInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Label> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deleteLabel,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deleteLabel: Label } };
    return response.data.deleteLabel;
}

export const listLabelsApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Label[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listLabels,
    });

    const response = responseRaw as { data: ListLabelsQuery };
    const items = response.data.listLabels?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as Label[];
    return itemsExisting
}

export const getLabelApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Label|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getLabel,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetLabelQuery };
    const instance = response.data.getLabel;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as Label
    return instanceNotUndefined
}

export const listLabelsByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Label[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listLabelsByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListLabelsQuery };
    const items = response.data.listLabels?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as Label[];
    return itemsExisting
}
