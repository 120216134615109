import React, { useState } from 'react'
import { AnswerDataType } from '../../../models';

interface AnswerDataTypeRelatedChooserProps {
    relationName: string
    originalEnumValue: AnswerDataType|undefined
    setEnumValue: (enumValue: AnswerDataType|undefined) => void
}

export const AnswerDataTypeRelatedChooser: React.FC<AnswerDataTypeRelatedChooserProps> = ({ relationName, originalEnumValue, setEnumValue }) => {
    const map = new Map(Object.entries(AnswerDataType) as [string, AnswerDataType][]);

    const [selectedEnumValue, setSelectedEnumValue] = useState<AnswerDataType|undefined>(undefined)

    function setEnumValueString(event: React.ChangeEvent<HTMLSelectElement>): void {
        const enumValueString: string = event.target.value
        const enumValue = map.get(enumValueString)
        setSelectedEnumValue(enumValue)
        setEnumValue(enumValue)
    }

    const entityNameKeys = Object.keys(AnswerDataType).filter((key) => isNaN(Number(key)))
    const entityNameVals = Object.values(AnswerDataType)

    return (
        <div className="mt-4">
            <label className="form-label" htmlFor={'select-' + relationName}>{relationName}</label>
            <select name="entityName" className="form-select" id={'select-' + relationName} onChange={setEnumValueString} value={selectedEnumValue || originalEnumValue || undefined}>
                <option key="">Select an option</option>
                {
                    entityNameKeys.map(function(key, i) {
                        const value = entityNameVals[i]
                        return <option key={relationName + key} value={value}>{value}</option>
                    })
                }
            </select>
        </div>
    )
}