import { CreatePreviewUserInput, DeletePreviewUserInput, PreviewUser, UpdatePreviewUserInput } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyPreviewUserInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name', 'email', 'phoneNumber', 'area'];

    const entityRelationKeysEntities: Map<string, EntityName> = new Map([
        ['previewOrganization', EntityName.PreviewOrganization]
    ]);

    const entityRelationsKeysEntities: Map<string, EntityName> = new Map([
        ['previewProducts', EntityName.PreviewProduct],
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys,
        entityRelationKeysEntities,
        entityRelationsKeysEntities
    });
}

export function getFilledPreviewUserInstance(model: PreviewUser): FilledEntity {
    const instance = getEmptyPreviewUserInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name);
    instance.addString('email', model.email);
    instance.addString('phoneNumber', model.phoneNumber === null ? undefined : model.phoneNumber);
    instance.addString('area', model.area);
    instance.addEntityRelation('previewOrganization', {entityName: EntityName.PreviewOrganization, id: model.previewOrganizationPreviewUsersId === null ? undefined : model.previewOrganizationPreviewUsersId});

    const previewProductIds = model.previewProducts === undefined ? [] : model.previewProducts?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('previewProducts', {ids: previewProductIds, entityName: EntityName.PreviewProduct});

    return instance;
}

export function getCreatePreviewUserInput(instance: FilledEntity): CreatePreviewUserInput {
    const name = instance.getStringMandatory('name');
    const email = instance.getStringMandatory('email');
    const area = instance.getStringMandatory('area');
    const phoneNumber = instance.getStringOptional('phoneNumber');
    const previewOrganizationPreviewUsersId = instance.getEntityRelationOptional('previewOrganization')?.id;

    return {
        name,
        email,
        area,
        phoneNumber,
        previewOrganizationPreviewUsersId
    };
}

export function getUpdatePreviewUserInput(instance: FilledEntity): UpdatePreviewUserInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const email = instance.getStringOptional('email');
    const area = instance.getStringOptional('area');
    const phoneNumber = instance.getStringOptional('phoneNumber');
    const previewOrganizationPreviewUsersId = instance.getEntityRelationOptional('previewOrganization')?.id;

    return {
        id,
        name,
        email,
        area,
        phoneNumber,
        previewOrganizationPreviewUsersId
    };
}

export function getDeletePreviewUserInput(instance: FilledEntity): DeletePreviewUserInput {
    const id = instance.getIdMandatory('id');

    return {
        id
    };
}