import React from 'react'
import { Amplify, Hub } from 'aws-amplify'
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'
import awsExports from './aws-exports'
import { RoutesPanel } from './components/layout/RoutesPanel';
import studioTheme from './ui-components/studioTheme';

Amplify.configure({
  ...awsExports
})

const App: React.FC = () => {
  const listener = async (data: any) => {
    switch (data.payload.event) {
        case 'signIn':
            console.log('user signed in')
            break
        case 'signUp':
            console.log('user signed up')
            break
        case 'signOut':
            console.log('user signed out')
            break
        case 'signIn_failure':
            console.error('user sign in failed')
            break
        case 'tokenRefresh':
            console.log('token refresh succeeded')
            break
        case 'tokenRefresh_failure':
            console.error('token refresh failed')
            break
        case 'autoSignIn':
            console.log('Auto Sign In after Sign Up succeeded')
            break
        case 'autoSignIn_failure':
            console.error('Auto Sign In after Sign Up failed')
            break
        case 'configured':
            console.log('the Auth module is configured')
    }
  }

  Hub.listen('auth', listener)

  return (
    <ThemeProvider theme={studioTheme}>
      <Authenticator.Provider>
        <RoutesPanel />
      </Authenticator.Provider>
    </ThemeProvider>
  )
}

export default App