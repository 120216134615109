import * as graphQlApi from '../../../API';
import { getNextQualificationQuestionId, getPreviousQualificationQuestionId, renderQualificationQuestion } from './QualificationHelper';
import { hasAllAnswers } from './OnboardingHelper';

interface QualificationPanelProps {
    seeQuestion: (questionId: string) => void
    answerQuestion: (questionId: string, answer: boolean) => void
    answerAllMissing: () => void
    startQualified: () => void
    expressions: {[key: string]: graphQlApi.DeviceQualificationExpression}
    questions: {[key: string]: graphQlApi.Question}
    answers: {[key: string]: boolean}
    lastSeenQuestionId: string | undefined
}

export const QualificationPanel: React.FC<QualificationPanelProps> = ({ seeQuestion, answerQuestion, answerAllMissing, startQualified, expressions, questions, answers, lastSeenQuestionId }) => {
    function setPreviousQuestion() {
        if (lastSeenQuestionId === undefined) {
            return
        }

        const prevQuestionId = getPreviousQualificationQuestionId(lastSeenQuestionId, expressions, true)

        if (prevQuestionId === undefined) {
            return
        }
        
        window.scrollTo(0, 0)
        seeQuestion(prevQuestionId)
    }

    function hasPreviousQuestion() {
        if (lastSeenQuestionId === undefined) {
            return false
        }

        const prevQuestionId = getPreviousQualificationQuestionId(lastSeenQuestionId, expressions, true)

        if (prevQuestionId === undefined) {
            return false
        }
        
        return true
    }

    function setNextQuestion() {
        if (lastSeenQuestionId === undefined) {
            return
        }

        const nextQuestionId = getNextQualificationQuestionId(lastSeenQuestionId, expressions, true)

        if (nextQuestionId === undefined) {
            return
        }
        
        window.scrollTo(0, 0)
        seeQuestion(nextQuestionId)
    }

    function hasNextQuestion() {
        if (lastSeenQuestionId === undefined) {
            return false
        }

        const nextQuestionId = getNextQualificationQuestionId(lastSeenQuestionId, expressions, true)

        if (nextQuestionId === undefined) {
            return false
        }
        
        return true
    }

    function provideAnswer(answer: boolean) {
        if (lastSeenQuestionId === undefined) {
            return
        }

        answerQuestion(lastSeenQuestionId, answer)
    }

    const areAllQuestionsAnswered = hasAllAnswers(questions, answers)
    
    return (
        <div>
            <h1 className="mb-4">Qualification</h1>
            <div className="my-4">
                <p><i className="bi bi-exclamation-circle text-warning" /> The qualification is decided by any question answered yes.</p>
            </div>
            {
                lastSeenQuestionId !== undefined && questions.hasOwnProperty(lastSeenQuestionId) &&
                    renderQualificationQuestion(questions[lastSeenQuestionId], expressions, answers, provideAnswer, undefined, true)
            }
            <div className="mt-6">
                {areAllQuestionsAnswered &&
                    <a role="button" className="btn btn-success me-2" onClick={() => startQualified()}>Qualify Device</a>
                }
                {hasNextQuestion() &&
                    <a role="button" className="btn btn-primary me-2" onClick={() => setNextQuestion()}>Next Question</a>
                }
                {hasPreviousQuestion() &&
                    <a role="button" className="btn btn-link me-2" onClick={() => setPreviousQuestion()}>Previous Question</a>
                }
                {!areAllQuestionsAnswered &&
                    <a role="button" className="btn btn-link me-2" onClick={() => answerAllMissing()}>Answer All Missing Questions</a>
                }
            </div>
        </div>
    )
}