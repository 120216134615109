import React, { useEffect, useState } from 'react'
import { EntityName } from '../../model/EntityName'
import { StringChooser } from './primitive-choosers/StringChooser'
import { FilledEntity } from '../../model/FilledEntity'
import { deleteFilledInstance, getFilledInstance } from './mixin-helper'

interface GenericRemoverProps {
    entityName: EntityName
    id: string,
    discardEntityRemover: (entityName: EntityName, id: string) => void
}

export const GenericRemover: React.FC<GenericRemoverProps> = ({ entityName, id, discardEntityRemover }) => {
    const [instance, setInstance] = useState<FilledEntity|undefined>(undefined)
    const [confirmationId, setConfirmationId] = useState<string|undefined>(undefined)

    useEffect(() => {
        const asyncSetInstance = async () => {
            const filledInstance = await getFilledInstance(entityName, id)
            setInstance(filledInstance)
        }

        asyncSetInstance()
    }, [id])

    async function remove(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault()

        if (id !== confirmationId) {
            alert('The identifier and confirmation are different. Not removing it.')
            return
        }

        if (instance === undefined) {
            alert('The instance is undefined. Not removing it.')
            return
        }

        await deleteFilledInstance(entityName, instance)
        discardEntityRemover(entityName, id)
    }

    const name = instance?.getStringMap().get('name')

    return (
        <div className="card">
            <div className="card-header">
                <h2>Remofe {name} <span className="text-gray-500 font-normal">{entityName}</span></h2>
            </div>
            <div className="card-body">
                <form>
                    <p>Write the ID of the {entityName} to confirm the removal. Click to copy: <a className="font-semibold cursor-pointer" onClick={() => navigator.clipboard.writeText(id)}>{id}</a>.</p>
                    <div className="mt-4">
                        <StringChooser key="id" relationName="confirmation" value={confirmationId} setValue={setConfirmationId} />
                    </div>
                </form>
            </div>
            <div className="card-footer">
                <button className="btn btn-danger btn-sm" onClick={remove}>Remove {entityName}</button>
            </div>
        </div>
    )
}