import { EntityNameRoot } from './EntityNameRoot'
import { EntityName } from '../../model/EntityName'
import React from 'react'

interface EntitySelectorProps {
    setEntityName: (entityName: string) => void
}

export const EntitySelector: React.FC<EntitySelectorProps> = ({ setEntityName }) => {
    function setEntityNameString(event: React.ChangeEvent<HTMLSelectElement>): void {
        setEntityName(event.target.value)
    }

    const entityNameRootKeys = Object.keys(EntityNameRoot).filter((key) => isNaN(Number(key)))
    const entityNameKeys = Object.keys(EntityName).filter((key) => isNaN(Number(key)))
    const entityNameVals = Object.values(EntityName).filter((value) => !isNaN(Number(value)))

    return (
        <>
            <label className="form-label" htmlFor="select-entity">Select an entity</label>
            <select name="entityName" id="select-entity" className="form-control" onChange={setEntityNameString}>
                <option key="">Select an option</option>
                {
                    entityNameKeys.map(function(key, i) {
                        if (entityNameRootKeys.indexOf(key) === -1) {
                            return null
                        }

                        const value = entityNameVals[i]
                        return <option key={key} value={value}>{key}</option>
                    })
                }
            </select>
        </>
    )
}