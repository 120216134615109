import { API } from "aws-amplify";
import { CreateQuestionLabelInput, DeleteQuestionLabelInput, QuestionLabel, GetQuestionLabelQuery, ListQuestionLabelsQuery, UpdateQuestionLabelInput } from "../API";
import { createQuestionLabel, deleteQuestionLabel, updateQuestionLabel } from "../graphql/mutations";
import { getQuestionLabel, listQuestionLabels, listQuestionLabelsByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createQuestionLabelApi = async (input: CreateQuestionLabelInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionLabel> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createQuestionLabel,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createQuestionLabel: QuestionLabel } };
    return response.data.createQuestionLabel;
}

export const updateQuestionLabelApi = async (input: UpdateQuestionLabelInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionLabel> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updateQuestionLabel,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updateQuestionLabel: QuestionLabel } };
    return response.data.updateQuestionLabel;
}

export const deleteQuestionLabelApi = async (input: DeleteQuestionLabelInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionLabel> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deleteQuestionLabel,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deleteQuestionLabel: QuestionLabel } };
    return response.data.deleteQuestionLabel;
}

export const listQuestionLabelsApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionLabel[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listQuestionLabels,
    });

    const response = responseRaw as { data: ListQuestionLabelsQuery };
    const items = response.data.listQuestionLabels?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as QuestionLabel[];
    return itemsExisting
}

export const getQuestionLabelApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionLabel|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getQuestionLabel,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetQuestionLabelQuery };
    const instance = response.data.getQuestionLabel;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as QuestionLabel
    return instanceNotUndefined
}

export const listQuestionLabelsByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionLabel[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listQuestionLabelsByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListQuestionLabelsQuery };
    const items = response.data.listQuestionLabels?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as QuestionLabel[];
    return itemsExisting
}
