import React from 'react'
import { EntityName } from '../../model/EntityName'
import { GenericRelatedViewer } from '../mixins/GenericRelatedViewer'

interface RelatedEntityViewerProps {
    relationName: string|undefined
    entityName: EntityName
    id: string
    setEntityCreator: (entityName: EntityName) => void
    setEntityViewer: (entityName: EntityName, id: string) => void
    setEntityEditor: (entityName: EntityName, id: string) => void
    setEntityRemover: (entityName: EntityName, id: string) => void
}

export const RelatedEntityViewer: React.FC<RelatedEntityViewerProps> = ({ relationName, entityName, id, setEntityCreator, setEntityViewer, setEntityEditor, setEntityRemover }) => {
    return (
        <GenericRelatedViewer id={id} entityName={entityName} setEntityCreator={setEntityCreator} setEntityViewer={setEntityViewer} setEntityEditor={setEntityEditor} setEntityRemover={setEntityRemover} relationName={relationName} />
    )
}