import { useEffect, useState } from 'react';
import { AnswerNotFoundException, CountryNotFoundException, OperatorNotImplementedException } from '../../../algorithms/CustomExceptions';
import { DeviceQualificationNode, DeviceQualifier } from '../../../algorithms/DeviceQualifier';
import * as graphQlApi from '../../../API';

interface QualifiedPanelProps {
    startClassification: () => void
    startReport: () => void
    expressions: {[key: string]: graphQlApi.DeviceQualificationExpression}
    questions: {[key: string]: graphQlApi.Question}
    answers: {[key: string]: boolean}
}

export const QualifiedPanel: React.FC<QualifiedPanelProps> = ({ startClassification, startReport, expressions, questions, answers }) => {
    function qualify(): DeviceQualificationNode | undefined {
        const deviceQualifier = new DeviceQualifier(expressions, answers)
        
        try {
            const deviceQualifications = deviceQualifier.qualify()
            
            if (deviceQualifications.length <= 0) {
                return undefined
            }

            return deviceQualifications[0]
        } catch (e) {
            if (e instanceof AnswerNotFoundException) {
                console.log(e.name, e.message)
            }
            
            if (e instanceof CountryNotFoundException) {
                console.log(e.name, e.message)
            }
            
            if (e instanceof OperatorNotImplementedException) {
                console.log(e.name, e.message)
            }
        }
    }

    const qualification = qualify()
    const isMedicalDevice = qualification !== undefined && qualification.qualification === graphQlApi.DeviceQualification.MEDICAL_DEVICE_MDR_ARTICLE_2_1

    return (
        <div>
            <h1 className="mb-4">Qualification</h1>
            <div className="my-4">
                <p><i className="bi bi-exclamation-circle text-warning" /> The qualification is decided by any question answered Yes.</p>
            </div>
            {
                qualification !== undefined &&
                <div className="card mb-4">
                    <div className="card-body">
                        <h2 className="mb-2">Result:&nbsp;{isMedicalDevice ? <span className="text-success">medical device</span>: <span className="text-danger">not a medical device</span>}</h2>
                        {isMedicalDevice &&
                            <div>
                                <p>This device meets the criteria for being a medical device.</p>
                            </div>
                        }
                        {!isMedicalDevice &&
                            <div>
                                <p>This device does not meet any of the criteria for being a medical device.</p>
                            </div>
                        }
                    </div>
                </div>
            }
            <div className="mt-6">
                {!isMedicalDevice &&
                    <a role="button" className="btn btn-primary" onClick={() => startReport()}>See Report</a>
                }
                {isMedicalDevice &&
                    <a role="button" className="btn btn-primary" onClick={() => startClassification()}>Classify Device</a>
                }
            </div>
        </div>
    )
}