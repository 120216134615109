import { CreateTrackInput, DeleteTrackInput, Track, UpdateTrackInput } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyTrackInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name'];

    const entityRelationKeysEntities: Map<string, EntityName> = new Map([
        ['medflowVersion', EntityName.MedflowVersion]
    ]);

    const entityRelationsKeysEntities: Map<string, EntityName> = new Map([
        ['stages', EntityName.Stage]
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys,
        entityRelationKeysEntities,
        entityRelationsKeysEntities
    });
}

export function getFilledTrackInstance(model: Track): FilledEntity {
    const instance = getEmptyTrackInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name === null ? undefined : model.name);
    instance.addEntityRelation('medflowVersion', {entityName: EntityName.MedflowVersion, id: model.trackMedflowVersionId === null ? undefined : model.trackMedflowVersionId});

    const stageIds = model.stages === undefined ? [] : model.stages?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('stages', {ids: stageIds, entityName: EntityName.Stage});

    return instance;
}

export function getCreateTrackInput(instance: FilledEntity): CreateTrackInput {
    const name = instance.getStringMandatory('name');
    const trackMedflowVersionId = instance.getEntityRelationOptional('medflowVersion')?.id;

    return {
        name,
        trackMedflowVersionId
    };
}

export function getUpdateTrackInput(instance: FilledEntity): UpdateTrackInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const trackMedflowVersionId = instance.getEntityRelationOptional('medflowVersion')?.id;

    return {
        id,
        name,
        trackMedflowVersionId
    };
}

export function getDeleteTrackInput(instance: FilledEntity): DeleteTrackInput {
    const id = instance.getIdMandatory('id');
    
    return {
        id
    };
}