import React from 'react'

interface EnumViewerProps {
    relationName: string
    value: any | undefined
}

export const EnumViewer: React.FC<EnumViewerProps> = ({ relationName, value }) => {
    return (
        <>
            <span className="form-label">{relationName}</span>
            <input id={relationName} value={value} disabled className="form-control w-full" />
        </>
    )
}