import React, { useEffect, useState } from 'react'
import { EntityName } from '../../model/EntityName';
import { FilledEntity } from '../../model/FilledEntity';
import { getFilledInstance } from './mixin-helper';

interface GenericRelatedViewerProps {
    id: string
    relationName?: string
    entityName: EntityName
    setEntityCreator: (entityName: EntityName) => void
    setEntityViewer: (entityName: EntityName, id: string) => void
    setEntityEditor: (entityName: EntityName, id: string) => void
    setEntityRemover: (entityName: EntityName, id: string) => void
}

export const GenericRelatedViewer: React.FC<GenericRelatedViewerProps> = ({ id, relationName, entityName, setEntityCreator, setEntityViewer, setEntityEditor, setEntityRemover }) => {
    const [instance, setInstance] = useState<FilledEntity | undefined>(undefined)

    useEffect(() => {
        const asyncSetInstance = async () => {
            const filledInstance = await getFilledInstance(entityName, id)
            setInstance(filledInstance)
        }

        asyncSetInstance()

    }, [id])
    
    const name = instance?.getStringMap().get('name')
    
    return (
        <>
            <label className="form-label" htmlFor={'select-' + relationName}>{relationName} <span className="text-gray-500 font-normal">{entityName}</span></label>
            <div className="card">
                <div className="card-header">
                    {instance !== undefined &&
                        <span id={'select-' + relationName}>{name}</span>
                    }
                    {instance === undefined &&
                        <span id={'select-' + relationName} className="text-muted">There is no related {entityName}.</span>
                    }
                </div>
                <div className="card-body">
                    {instance === undefined && <button className="btn btn-primary btn-sm mx-2" onClick={() => setEntityCreator(entityName)}>Create {entityName}</button>}
                    {instance !== undefined && <button className="btn btn-primary btn-sm mx-2" onClick={() => setEntityViewer(entityName, id)}>View</button>}
                    {instance !== undefined && <button className="btn btn-warning btn-sm mx-2" onClick={() => setEntityEditor(entityName, id)}>Edit</button>}
                    {instance !== undefined && <button className="btn btn-danger btn-sm mx-2" onClick={() => setEntityRemover(entityName, id)}>Remove</button>}
                </div>
            </div>
        </>
    )
}