import { PDFDocument, StandardFonts, rgb, PDFImage } from 'pdf-lib'
import { DeviceClassification, DeviceClassificationExpression, DeviceExpressionOperator, DeviceQualification, DeviceQualificationExpression, PreviewOrganization, PreviewProduct, PreviewUser, Question } from '../API'
import { getChildrenClassificationExpressions, getClassificationAbbreviatedText, getClassificationQuestionIndices, getClassificationText } from '../components/public/onboarding/ClassificationHelper'
import { getQualificationQuestionIds, getQualificationText } from '../components/public/onboarding/QualificationHelper'
import { DeviceClassificationNode } from './DeviceClassifier'
import { DeviceQualificationNode } from './DeviceQualifier'
import { PDFTextElement, writePDF } from './PDFWriter'

const margin = 40
const spacing = 10
const pageWidth = 595
const lineHeight = 1.25
const successColor = rgb(0, 0.8, 0.533)
const dangerColor = rgb(1.0, 0.2, 0.4)
const normalColor = rgb(0, 0, 0)
const paleColor = rgb(0.5, 0.5, 0.5)
const labelWidth = 100

async function getTitle(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 20
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: successColor,
        }
    }
}

async function getHeader(pdfDoc: PDFDocument, text: string): Promise<PDFTextElement> {
    const fontSize = 8
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2,
            lineHeight,
            color: paleColor,
        }
    }
}

async function getFooter(pdfDoc: PDFDocument, text: string): Promise<PDFTextElement> {
    const fontSize = 8
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2,
            lineHeight,
            color: paleColor,
        }
    }
}

async function getSectionHeading(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 20
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: successColor,
        }
    }
}

async function getRuleHeading(pdfDoc: PDFDocument, text: string, indent: number, image: PDFImage|undefined): Promise<PDFTextElement> {
    const fontSize = 16
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: image,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: successColor,
        }
    }
}

async function getRuleStatement(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 10
    const font = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: normalColor,
        }
    }
}

async function getQuestionHeading(pdfDoc: PDFDocument, text: string, indent: number, image: PDFImage|undefined): Promise<PDFTextElement> {
    const fontSize = 14
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: image,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: successColor,
        }
    }
}

async function getSubheading(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 12
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: successColor,
        }
    }
}

async function getQuestionSubheading(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 12
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: successColor,
        }
    }
}

async function getQuestionStatement(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 10
    const font = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: normalColor,
        }
    }
}

async function getSuccessRemark(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 10
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: successColor,
        }
    }
}

async function getDangerRemark(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 10
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: dangerColor,
        }
    }
}

async function getMiniExplanation(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 10
    const font = await pdfDoc.embedFont(StandardFonts.HelveticaOblique)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: normalColor,
        }
    }
}

async function getNormalRemark(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 10
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: normalColor,
        }
    }
}

async function getPaleRemark(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 10
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: true,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - indent * margin,
            lineHeight,
            color: paleColor,
        }
    }
}

async function getLabel(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 10
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: true,
        isSpaced: false,
        image: undefined,
        opts: {
            font,
            x: margin + indent * margin,
            size: fontSize,
            maxWidth: labelWidth,
            lineHeight,
            color: successColor,
        }
    }
}

async function getLabelValue(pdfDoc: PDFDocument, text: string, indent: number): Promise<PDFTextElement> {
    const fontSize = 10
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)

    return {
        text,
        isFloating: false,
        isSpaced: false,
        image: undefined,
        opts: {
            font,
            x: margin + labelWidth + spacing + indent * margin,
            size: fontSize,
            maxWidth: pageWidth - margin * 2 - labelWidth - spacing - indent * margin,
            lineHeight,
            color: normalColor,
        }
    }
}

async function getYesImage(pdfDoc: PDFDocument): Promise<PDFImage> {
    const pngImageBytes = await fetch("/images/badges/yes.png").then((res) => res.arrayBuffer())
    return pdfDoc.embedPng(pngImageBytes)
}

async function getClass1Image(pdfDoc: PDFDocument): Promise<PDFImage> {
    const pngImageBytes = await fetch("/images/badges/1.png").then((res) => res.arrayBuffer())
    return pdfDoc.embedPng(pngImageBytes)
}

async function getClass2aImage(pdfDoc: PDFDocument): Promise<PDFImage> {
    const pngImageBytes = await fetch("/images/badges/2a.png").then((res) => res.arrayBuffer())
    return pdfDoc.embedPng(pngImageBytes)
}

async function getClass2bImage(pdfDoc: PDFDocument): Promise<PDFImage> {
    const pngImageBytes = await fetch("/images/badges/2b.png").then((res) => res.arrayBuffer())
    return pdfDoc.embedPng(pngImageBytes)
}

async function getClass3Image(pdfDoc: PDFDocument): Promise<PDFImage> {
    const pngImageBytes = await fetch("/images/badges/3.png").then((res) => res.arrayBuffer())
    return pdfDoc.embedPng(pngImageBytes)
}

async function getClass1ImageNeutral(pdfDoc: PDFDocument): Promise<PDFImage> {
    const pngImageBytes = await fetch("/images/badges/1neutral.png").then((res) => res.arrayBuffer())
    return pdfDoc.embedPng(pngImageBytes)
}

async function getClass2aImageNeutral(pdfDoc: PDFDocument): Promise<PDFImage> {
    const pngImageBytes = await fetch("/images/badges/2aneutral.png").then((res) => res.arrayBuffer())
    return pdfDoc.embedPng(pngImageBytes)
}

async function getClass2bImageNeutral(pdfDoc: PDFDocument): Promise<PDFImage> {
    const pngImageBytes = await fetch("/images/badges/2bneutral.png").then((res) => res.arrayBuffer())
    return pdfDoc.embedPng(pngImageBytes)
}

async function getClass3ImageNeutral(pdfDoc: PDFDocument): Promise<PDFImage> {
    const pngImageBytes = await fetch("/images/badges/3neutral.png").then((res) => res.arrayBuffer())
    return pdfDoc.embedPng(pngImageBytes)
}

async function getNoImage(pdfDoc: PDFDocument): Promise<PDFImage> {
    const pngImageBytes = await fetch("/images/badges/no.png").then((res) => res.arrayBuffer())
    return pdfDoc.embedPng(pngImageBytes)
}

async function getNeutralImage(pdfDoc: PDFDocument): Promise<PDFImage> {
    const pngImageBytes = await fetch("/images/badges/neutral.png").then((res) => res.arrayBuffer())
    return pdfDoc.embedPng(pngImageBytes)
}

async function getQualificationQuestionElements(pdfDoc: PDFDocument, question: Question, expressions: {[key: string]: DeviceQualificationExpression}, answers: {[key: string]: boolean}, qualification: DeviceQualificationNode | undefined, showNotes: boolean, showUnanswered: boolean) {
    const questionIds = getQualificationQuestionIds(expressions, true)
    const shownIndex = questionIds.indexOf(question.id) + 1
    const hasAnswer = answers.hasOwnProperty(question.id)
    const answer = hasAnswer ? answers[question.id] : undefined

    const elements: PDFTextElement[] = []

    if (!showUnanswered && !answer) {
        return elements
    }

    const answerYesNo = answer === true ? "yes" : "no"
    const isQualified = qualification !== undefined
    const isMedicalDevice = isQualified && qualification.qualification === DeviceQualification.MEDICAL_DEVICE_MDR_ARTICLE_2_1

    const answeredContributed = answer && isMedicalDevice
    const justAnswered = answer && !isMedicalDevice
    const notAnsweredContributed = !answer && !isMedicalDevice
    const justNotAnswered = !answer && isMedicalDevice

    var image = undefined

    if (answeredContributed) {
        image = await getYesImage(pdfDoc)
    } else if (justAnswered) {
        image = await getNeutralImage(pdfDoc)
    } else if (notAnsweredContributed) {
        image = await getNoImage(pdfDoc)
    } else if (justNotAnswered) {
        image = await getNeutralImage(pdfDoc)
    }

    elements.push(await getQuestionHeading(pdfDoc, "Question " + shownIndex, 1, image))
    elements.push(await getQuestionStatement(pdfDoc, question.statement + " ", 1))

    if (showNotes) {
        if (question.description !== undefined && question.description !== null) {
            elements.push(await getQuestionSubheading(pdfDoc, "Description", 1))
            elements.push(await getNormalRemark(pdfDoc, question.description, 1))
        }

        if (question.explanations !== undefined && question.explanations !== null && question.explanations.length > 0) {
            elements.push(await getQuestionSubheading(pdfDoc, "Explanations", 1))

            for (const explanation of question.explanations) {
                if (explanation !== null) {
                    elements.push(await getNormalRemark(pdfDoc, "• " + explanation, 1))
                }
            }
        }

        if (question.examples !== undefined && question.examples !== null && question.examples.length > 0) {
            elements.push(await getQuestionSubheading(pdfDoc, "Examples", 1))

            for (const example of question.examples) {
                if (example !== null) {
                    elements.push(await getNormalRemark(pdfDoc, "• " + example, 1))
                }
            }
        }

        if (question.notes !== undefined && question.notes !== null && question.notes.length > 0) {
            elements.push(await getQuestionSubheading(pdfDoc, "Notes", 1))

            for (const note of question.notes) {
                if (note !== null) {
                    elements.push(await getNormalRemark(pdfDoc, "• " + note, 1))
                }
            }
        }

        if (question.tooltip !== undefined && question.tooltip !== null) {
            elements.push(await getQuestionSubheading(pdfDoc, "Tooltip", 1))
            elements.push(await getNormalRemark(pdfDoc, question.tooltip, 1))
        }
    }

    if (isQualified && answeredContributed) {
        elements.push(await getPaleRemark(pdfDoc, "Answered: " + answerYesNo, 1))
    }

    if (isQualified && justAnswered) {
        elements.push(await getPaleRemark(pdfDoc, "Answered: " + answerYesNo, 1))
    }

    if (isQualified && notAnsweredContributed) {
        elements.push(await getDangerRemark(pdfDoc, "Answered: " + answerYesNo, 1))
    }

    if (isQualified && justNotAnswered) {
        elements.push(await getPaleRemark(pdfDoc, "Answered: " + answerYesNo, 1))
    }

    if (!isQualified) {
        elements.push(await getPaleRemark(pdfDoc, "Answered: " + answerYesNo, 1))
    }

    if (isQualified && answeredContributed) {
        elements.push(await getPaleRemark(pdfDoc, "The answer " + answerYesNo + " decided the overall qualification result.", 1))
    }

    if (isQualified && notAnsweredContributed) {
        elements.push(await getPaleRemark(pdfDoc, "The answer " + answerYesNo + " decided the overall qualification result.", 1))
    }

    return elements
}

async function getQualificationElements(pdfDoc: PDFDocument, qualification: DeviceQualificationNode, qualificationExpressions: {[key: string]: DeviceQualificationExpression}, qualificationQuestions: {[key: string]: Question}, qualificationAnswers: {[key: string]: boolean}, showNotes: boolean, showUnanswered: boolean, title: string) {
    const qualificationQuestionIds = getQualificationQuestionIds(qualificationExpressions, true)
    const elements: PDFTextElement[] = []
    elements.push(await getSectionHeading(pdfDoc, title, 0))

    elements.push(await getMiniExplanation(pdfDoc, "The qualification is decided by any question answered yes.", 0))

    if (qualification === undefined) {
        return elements
    }

    for (const questionId of qualificationQuestionIds) {
        const question = qualificationQuestions[questionId]
        const questionElements = await getQualificationQuestionElements(pdfDoc, question, qualificationExpressions, qualificationAnswers, qualification, showNotes, showUnanswered)
        elements.push(...questionElements)
    }

    return elements
}

async function getClassificationQuestionElements(pdfDoc: PDFDocument, questionIndices: {[key: string]: number}, questions: {[key: string]: Question}, expression: DeviceClassificationExpression, answers: {[key: string]: boolean}, classification: DeviceClassificationNode | undefined, rootClassification: DeviceClassificationNode | undefined, expressionDepth: number, ruleIndex: number, showNotes: boolean, showUnanswered: boolean) {
    const questionId = expression.deviceClassificationExpressionYesNoQuestionId

    const elements: PDFTextElement[] = []

    if (questionId === undefined || questionId === null) {
        return elements
    }

    const hasAnswer = answers.hasOwnProperty(questionId)
    const answer = hasAnswer ? answers[questionId] : undefined

    if (!showUnanswered && !answer) {
        return elements
    }

    const index = questionIndices.hasOwnProperty(questionId) ? 1 + questionIndices[questionId] : undefined
    const question = questions[questionId]
    const isClassified = classification !== undefined
    const answerYesNo = answer === true ? "yes" : "no"

    const isEuClassI = rootClassification !== undefined && rootClassification.classification === DeviceClassification.EU_CLASS_1
    const isEuClassIIa = rootClassification !== undefined && rootClassification.classification === DeviceClassification.EU_CLASS_2_A
    const isEuClassIIb = rootClassification !== undefined && rootClassification.classification === DeviceClassification.EU_CLASS_2_B
    const isEuClassIII = rootClassification !== undefined && rootClassification.classification === DeviceClassification.EU_CLASS_3
    const isEuClassAny = isEuClassI || isEuClassIIa || isEuClassIIb || isEuClassIII

    const isUsClassI = rootClassification !== undefined && rootClassification.classification === DeviceClassification.US_CLASS_1
    const isUsClassII = rootClassification !== undefined && rootClassification.classification === DeviceClassification.US_CLASS_2
    const isUsClassIII = rootClassification !== undefined && rootClassification.classification === DeviceClassification.US_CLASS_3
    const isUsClassAny = isUsClassI || isUsClassII || isUsClassIII

    const isCanadaClassI = rootClassification !== undefined && rootClassification.classification === DeviceClassification.CANADA_CLASS_1
    const isCanadaClassII = rootClassification !== undefined && rootClassification.classification === DeviceClassification.CANADA_CLASS_2
    const isCanadaClassIII = rootClassification !== undefined && rootClassification.classification === DeviceClassification.CANADA_CLASS_3
    const isCanadaClassIV = rootClassification !== undefined && rootClassification.classification === DeviceClassification.CANADA_CLASS_4
    const isCanadaClassAny = isCanadaClassI || isCanadaClassII || isCanadaClassIII || isCanadaClassIV

    const isClassAny = isEuClassAny || isUsClassAny || isCanadaClassAny

    const answeredContributed = answer && classification?.classificationFromQuestion === rootClassification?.classification && classification?.classificationFromQuestion === classification?.classification
    const justAnswered = answer && classification?.classificationFromQuestion !== rootClassification?.classification
    const notAnsweredContributed = !answer && !isClassAny
    const justNotAnswered = !answer && isClassAny

    var image = undefined
    var classificationQuestionClass = classification?.classificationFromQuestion

    if (answeredContributed) {
        if (classificationQuestionClass === DeviceClassification.EU_CLASS_1) {
            image = await getClass1Image(pdfDoc)
        } else if (classificationQuestionClass === DeviceClassification.EU_CLASS_2_A) {
            image = await getClass2aImage(pdfDoc)
        } else if (classificationQuestionClass === DeviceClassification.EU_CLASS_2_B) {
            image = await getClass2bImage(pdfDoc)
        } else if (classificationQuestionClass === DeviceClassification.EU_CLASS_3) {
            image = await getClass3Image(pdfDoc)
        }
    } else if (justAnswered) {
        if (classificationQuestionClass === DeviceClassification.EU_CLASS_1) {
            image = await getClass1ImageNeutral(pdfDoc)
        } else if (classificationQuestionClass === DeviceClassification.EU_CLASS_2_A) {
            image = await getClass2aImageNeutral(pdfDoc)
        } else if (classificationQuestionClass === DeviceClassification.EU_CLASS_2_B) {
            image = await getClass2bImageNeutral(pdfDoc)
        } else if (classificationQuestionClass === DeviceClassification.EU_CLASS_3) {
            image = await getClass3ImageNeutral(pdfDoc)
        }
    } else {
        image = await getNeutralImage(pdfDoc)
    }

    elements.push(await getQuestionHeading(pdfDoc, "Question " + index, expressionDepth, image))

    if (question.statement !== undefined && question.statement !== null) {
        elements.push(await getQuestionStatement(pdfDoc, question.statement, expressionDepth))
    }

    if (showNotes) {
        if (question.description !== undefined && question.description !== null) {
            elements.push(await getQuestionSubheading(pdfDoc, "Description", expressionDepth))
            elements.push(await getNormalRemark(pdfDoc, question.description, expressionDepth))
        }

        if (question.explanations !== undefined && question.explanations !== null && question.explanations?.length > 0) {
            elements.push(await getQuestionSubheading(pdfDoc, "Explanations", expressionDepth))

            for (const explanation of question.explanations) {
                if (explanation !== undefined && explanation !== null) {
                    elements.push(await getNormalRemark(pdfDoc, "• " + explanation, expressionDepth))
                }
            }
        }

        if (question.examples !== undefined && question.examples !== null && question.examples?.length > 0) {
            elements.push(await getQuestionSubheading(pdfDoc, "Examples", expressionDepth))

            for (const example of question.examples) {
                if (example !== undefined && example !== null) {
                    elements.push(await getNormalRemark(pdfDoc, "• " + example, expressionDepth))
                }
            }
        }

        if (question.notes !== undefined && question.notes !== null && question.notes?.length > 0) {
            elements.push(await getQuestionSubheading(pdfDoc, "Notes", expressionDepth))

            for (const note of question.notes) {
                if (note !== undefined && note !== null) {
                    elements.push(await getNormalRemark(pdfDoc, "• " + note, expressionDepth))
                }
            }
        }
    }

    if (isClassified && answeredContributed) {
        elements.push(await getPaleRemark(pdfDoc, "Answered: " + answerYesNo, expressionDepth))
    }

    if (isClassified && justAnswered) {
        elements.push(await getPaleRemark(pdfDoc, "Answered: " + answerYesNo, expressionDepth))

    }

    if (isClassified && notAnsweredContributed) {
        elements.push(await getPaleRemark(pdfDoc, "Answered: " + answerYesNo, expressionDepth))
    }

    if (isClassified && justNotAnswered) {
        elements.push(await getPaleRemark(pdfDoc, "Answered: " + answerYesNo, expressionDepth))
    }

    if (!isClassified) {
        elements.push(await getPaleRemark(pdfDoc, "Answered: " + answerYesNo, expressionDepth))
    }

    if (isClassified && answeredContributed) {
        elements.push(await getSuccessRemark(pdfDoc, "The answer " + answerYesNo + " decided the overall classification result.", expressionDepth))
    }

    return elements
}

async function getClassificationExpressionElements(pdfDoc: PDFDocument, expression: DeviceClassificationExpression, expressions: {[key: string]: DeviceClassificationExpression}, expressionIndex: number, expressionDepth: number, questions: {[key: string]: Question}, answers: {[key: string]: boolean}, classification: DeviceClassificationNode | undefined, rootClassification: DeviceClassificationNode | undefined, questionIndices: {[key: string]: number} = {}, showNotes: boolean, showUnanswered: boolean) {
    const questionIndicesFinal = expressionDepth === 1 ? getClassificationQuestionIndices(expression, expressions): questionIndices
    const expressionsList = Object.values(expressions)
    const childrenExpressions = getChildrenClassificationExpressions(expression.id, expressionsList, true)
    const questionId = expression.deviceClassificationExpressionYesNoQuestionId !== undefined && expression.deviceClassificationExpressionYesNoQuestionId !== null ? expression.deviceClassificationExpressionYesNoQuestionId : undefined
    const hasQuestion = questionId !== undefined
    const isAnsweredYes = !hasQuestion || (hasQuestion && answers[questionId] === true)
    const hasAnsweredChildren = classification?.classificationFromChildren !== undefined && classification?.classificationFromChildren !== null
    const ruleContributed = isAnsweredYes && classification?.classification === rootClassification?.classification

    const elements: PDFTextElement[] = []

    const ruleIndex = expression.index !== null && expression.index !== undefined ? (1 + expression.index) : 0;

    if (expressionDepth === 1) {
        if (expression.statement !== undefined && expression.statement !== null) {
            var image = undefined
            var classificationClass = classification?.classification

            if (ruleContributed) {
                if (classificationClass === DeviceClassification.EU_CLASS_1) {
                    image = await getClass1Image(pdfDoc)
                } else if (classificationClass === DeviceClassification.EU_CLASS_2_A) {
                    image = await getClass2aImage(pdfDoc)
                } else if (classificationClass === DeviceClassification.EU_CLASS_2_B) {
                    image = await getClass2bImage(pdfDoc)
                } else if (classificationClass === DeviceClassification.EU_CLASS_3) {
                    image = await getClass3Image(pdfDoc)
                } else {
                    image = await getNeutralImage(pdfDoc)
                }
            } else {
                if (classificationClass === DeviceClassification.EU_CLASS_1) {
                    image = await getClass1ImageNeutral(pdfDoc)
                } else if (classificationClass === DeviceClassification.EU_CLASS_2_A) {
                    image = await getClass2aImageNeutral(pdfDoc)
                } else if (classificationClass === DeviceClassification.EU_CLASS_2_B) {
                    image = await getClass2bImageNeutral(pdfDoc)
                } else if (classificationClass === DeviceClassification.EU_CLASS_3) {
                    image = await getClass3ImageNeutral(pdfDoc)
                } else {
                    image = await getNeutralImage(pdfDoc)
                }
            }
            
            elements.push(await getRuleHeading(pdfDoc, "Rule " + ruleIndex, expressionDepth, image))
            elements.push(await getRuleStatement(pdfDoc, expression.statement, expressionDepth))
        }
    }

    if (expressionDepth > 1) {
        var image = undefined
        var classificationClass = classification?.classification

        if (ruleContributed) {
            if (classificationClass === DeviceClassification.EU_CLASS_1) {
                image = await getClass1Image(pdfDoc)
            } else if (classificationClass === DeviceClassification.EU_CLASS_2_A) {
                image = await getClass2aImage(pdfDoc)
            } else if (classificationClass === DeviceClassification.EU_CLASS_2_B) {
                image = await getClass2bImage(pdfDoc)
            } else if (classificationClass === DeviceClassification.EU_CLASS_3) {
                image = await getClass3Image(pdfDoc)
            } else {
                image = await getNeutralImage(pdfDoc)
            }
        } else {
            if (classificationClass === DeviceClassification.EU_CLASS_1) {
                image = await getClass1ImageNeutral(pdfDoc)
            } else if (classificationClass === DeviceClassification.EU_CLASS_2_A) {
                image = await getClass2aImageNeutral(pdfDoc)
            } else if (classificationClass === DeviceClassification.EU_CLASS_2_B) {
                image = await getClass2bImageNeutral(pdfDoc)
            } else if (classificationClass === DeviceClassification.EU_CLASS_3) {
                image = await getClass3ImageNeutral(pdfDoc)
            } else {
                image = await getNeutralImage(pdfDoc)
            }
        }

        elements.push(await getRuleHeading(pdfDoc, "Level Below", expressionDepth, image))
    }

    if (showNotes) {
        if (expression.description !== undefined && expression.description !== null) {
            elements.push(await getQuestionSubheading(pdfDoc, "Description", expressionDepth))
            elements.push(await getQuestionStatement(pdfDoc, expression.description, expressionDepth))
        }

        if (expression.explanations !== undefined && expression.explanations !== null && expression.explanations.length > 0) {
            elements.push(await getQuestionSubheading(pdfDoc, "Explanations", expressionDepth))

            for (const explanation of expression.explanations) {
                if (explanation !== null) {
                    elements.push(await getNormalRemark(pdfDoc, "• " + explanation, expressionDepth))
                }
            }
        }

        if (expression.notes !== undefined && expression.notes !== null && expression.notes.length > 0) {
            elements.push(await getQuestionSubheading(pdfDoc, "Notes", expressionDepth))

            for (const note of expression.notes) {
                if (note !== null) {
                    elements.push(await getNormalRemark(pdfDoc, "• " + note, expressionDepth))
                }
            }
        }
    }

    if (classification !== undefined) {
        var textTheory = ""
        var textPractice = ""

        if (expressionDepth === 0) {
            elements.push(await getPaleRemark(pdfDoc, "Final class: " + getClassificationAbbreviatedText(classification.classification), expressionDepth))
                
            const decidingRulesNameValueText = classification.children.filter((child) => {
                return child.classification === classification.classification
            }).map((child) => {
                const expression = expressions[child.expressionId]
                const index = expression.index !== null && expression.index !== undefined ? expression.index : 0
                return 'Rule ' + (1 + index)
            }).join(' and ')

            textTheory = "The final class " + getClassificationAbbreviatedText(classification.classification) + " is decided by the highest class from the rules below with questions answered yes."
            textPractice = "The rule/rules in which questions answered yes contributed to this final class are: " + decidingRulesNameValueText + "."
        } else {
            if (expression.operator === DeviceExpressionOperator.MAX_OF) {
                if (hasQuestion && childrenExpressions.length > 0) {
                    textTheory = "The class " + getClassificationAbbreviatedText(classification.classification) + " from this level is decided by the highest class from the question if answered yes and the levels below (equal priorities)."
                } else if (hasQuestion) {
                    textTheory = "The class " + getClassificationAbbreviatedText(classification.classification) + " from this level is decided by the class from the question if answered yes."
                } else if (childrenExpressions.length > 0) {
                    textTheory = "The class " + getClassificationAbbreviatedText(classification.classification) + " from this level is decided by the class from the levels below."
                }
            } else if (expression.operator === DeviceExpressionOperator.RIGHT_OVERRIDE_OF) {
                if (hasQuestion && childrenExpressions.length > 0) {
                    textTheory = "The class " + getClassificationAbbreviatedText(classification.classification) + " from this level is decided by the highest class from the levels below (first priority) and then from the question (second priority)."
                } else if (hasQuestion) {
                    textTheory = "The class " + getClassificationAbbreviatedText(classification.classification) + " from this level is decided by the class from the question if answered yes."
                } else if (childrenExpressions.length > 0) {
                    textTheory = "The class " + getClassificationAbbreviatedText(classification.classification) + " from this level is decided by the class from the levels below."
                }
            }

            if (hasQuestion) {
                if (isAnsweredYes) {
                    textPractice = (textPractice === "" ? "" : " ") + "The question at this level was answered yes and tried to contribute with the class " + getClassificationAbbreviatedText(classification.classificationFromQuestion) + "."
                } else {
                    textPractice = (textPractice === "" ? "" : " ") + "The question at this level was answered no."
                }
            } else {
                textPractice = (textPractice === "" ? "" : " ") + "There was no question at this level."
            }

            if (childrenExpressions.length > 0) {
                if (hasAnsweredChildren) {
                    textPractice = (textPractice === "" ? "" : " ") + "The levels below had at least a question answered yes and tried to contribute with the class " + getClassificationAbbreviatedText(classification.classificationFromChildren) + "."
                } else {
                    textPractice = (textPractice === "" ? "" : " ") + "The levels below had no questions answered yes."
                }
            } else {
                textPractice = (textPractice === "" ? "" : " ") + "There were no levels below."
            }

            const questionContributed = classification.classificationFromQuestion === classification.classification
            const levelsContributed = classification.classificationFromChildren === classification.classification

            if (questionContributed && levelsContributed) {
                textPractice = (textPractice === "" ? "" : " ") + "Both question and levels below contributed to the class " + getClassificationAbbreviatedText(classification.classification) + " at this level."
            } else if (questionContributed) {
                textPractice = (textPractice === "" ? "" : " ") + "Only the question answered yes contributed with its class " + getClassificationAbbreviatedText(classification.classificationFromQuestion) + " to the class " + getClassificationAbbreviatedText(classification.classification) + " at this level."

                if (childrenExpressions.length > 0) {
                    if (hasAnsweredChildren) {
                        textPractice = (textPractice === "" ? "" : " ") + "Despite having questions answered yes, the levels below did not contribute, because its class " + getClassificationAbbreviatedText(classification.classificationFromChildren) + "  is smaller than the class " + getClassificationAbbreviatedText(classification.classificationFromQuestion) + " of the question."
                    } else {
                        textPractice = (textPractice === "" ? "" : " ") + "The levels below did not contribute, because it had no questions answered yes."
                    }
                }
            } else if (levelsContributed) {
                textPractice = (textPractice === "" ? "" : " ") + "Only the levels below with at least a question answered yes contributed with its resulting class " + getClassificationAbbreviatedText(classification.classificationFromChildren) + " to the class " + getClassificationAbbreviatedText(classification.classification) + " at this level."

                if (hasQuestion) {
                    if (isAnsweredYes) {
                        if (expression.operator === DeviceExpressionOperator.MAX_OF) {
                            textPractice = (textPractice === "" ? "" : " ") + "Despite being answered yes, the question at this level did not contribute, because its class " + getClassificationAbbreviatedText(classification.classificationFromQuestion) + " is smaller than the class " + getClassificationAbbreviatedText(classification.classificationFromChildren) + " of the levels below."
                        } else if (expression.operator === DeviceExpressionOperator.RIGHT_OVERRIDE_OF) {
                            textPractice = (textPractice === "" ? "" : " ") + "Despite being answered yes, the question at this level did not contribute, because there were questions answered yes in the levels below."
                        }
                    } else {
                        textPractice = (textPractice === "" ? "" : " ") + "The question at this level did not contribute, because it was answered no."
                    }
                }
            } else {
                textPractice = (textPractice === "" ? "" : " ") + "Neither the question nor the levels below contributed to the class " + getClassificationAbbreviatedText(classification.classification) + " at this level."
            }
        }

        elements.push(await getMiniExplanation(pdfDoc, textTheory + " " + textPractice, expressionDepth))
    }

    const questionElements = await getClassificationQuestionElements(pdfDoc, questionIndicesFinal, questions, expression, answers, classification, rootClassification, 1 + expressionDepth, ruleIndex, showNotes, showUnanswered)
    elements.push(...questionElements)

    if (hasAnsweredChildren || showUnanswered) {
        for (var i = 0; i < childrenExpressions.length; i++) {
            const childExpression = childrenExpressions[i]
            const childClassification = classification !== undefined ? classification.children.find(node => node.expressionId === childExpression.id) : undefined
            const childElements = await getClassificationExpressionElements(pdfDoc, childExpression, expressions, i, 1 + expressionDepth, questions, answers, childClassification, rootClassification, questionIndicesFinal, showNotes, showUnanswered)
            elements.push(...childElements)
        }
    }

    return elements
} 

async function getClassificationElements(pdfDoc: PDFDocument, classification: DeviceClassificationNode, classificationExpressions: {[key: string]: DeviceClassificationExpression}, classificationQuestions: {[key: string]: Question}, classificationAnswers: {[key: string]: boolean}, showNotes: boolean, showUnanswered: boolean, title: string) {
    const elements: PDFTextElement[] = []
    elements.push(await getSectionHeading(pdfDoc, title, 0))

    if (classification === undefined) {
        return elements
    }

    const newElements = await getClassificationExpressionElements(pdfDoc, classificationExpressions[classification.expressionId], classificationExpressions, 0, 0, classificationQuestions, classificationAnswers, classification, classification, undefined, showNotes, showUnanswered)
    elements.push(...newElements)
    return elements
}

async function getExportedPDFNew(qualification: DeviceQualificationNode, classification: DeviceClassificationNode, previewUser: PreviewUser|undefined, previewOrganization: PreviewOrganization|undefined, previewProduct: PreviewProduct|undefined,
    qualificationExpressions: {[key: string]: DeviceQualificationExpression}, qualificationQuestions: {[key: string]: Question}, qualificationAnswers: {[key: string]: boolean},
    classificationExpressions: {[key: string]: DeviceClassificationExpression}, classificationQuestions: {[key: string]: Question}, classificationAnswers: {[key: string]: boolean}) {

    const pdfDoc = await PDFDocument.create()
    pdfDoc.setTitle(previewProduct?.name + ': Qualification and Classification Report')
    pdfDoc.setAuthor('Meddoc Flow ApS | Medflow')
    pdfDoc.setSubject(previewProduct?.name + ': Qualification and Classification Report')
    pdfDoc.setKeywords(['medical', 'device', 'qualification', 'classification', 'report', 'medflow'])
    pdfDoc.setProducer('Medflow')
    pdfDoc.setCreator('pdf-lib (https://github.com/Hopding/pdf-lib)')
    pdfDoc.setCreationDate(new Date())
    pdfDoc.setModificationDate(new Date())

    const productNameText = previewProduct?.name ? previewProduct?.name : 'PRODUCT'
    const organizationNameText = previewOrganization?.name ? previewOrganization?.name : 'COMPANY'

    const title = await getTitle(pdfDoc, 'Medical Device Qualification and Classification Report', 0)

    const purposeTitle = await getSectionHeading(pdfDoc, 'Purpose', 0)
    const purpose = await getNormalRemark(pdfDoc, 'The purpose of this qualification and classification report is to document the analysis of applicability of the relevant definitions and classification rules of the Medical Device Regulation [1] to ' + productNameText + '.', 0)

    const scopeTitle = await getSectionHeading(pdfDoc, 'Scope', 0)
    const scope = await getNormalRemark(pdfDoc, 'The scope of the analysis is the relevant definitions and classification rules of the Medical Device Regulation [1] as applied to the ' + productNameText + ' manufactured by ' + organizationNameText + '.', 0)

    const introductionTitle = await getSectionHeading(pdfDoc, '1. Introduction', 0)
    const introduction1 = await getNormalRemark(pdfDoc, productNameText + ' (the product) is developed and manufactured by ' + organizationNameText + ' (the manufacturer).', 0)
    const introduction2 = await getNormalRemark(pdfDoc, 'This qualification and classification report is intended to document the assessment of application of the Medical Device Regulation (EU) 2017/745 (MDR).', 0)
    const introduction3 = await getNormalRemark(pdfDoc, 'The description of the product, as defined by the manufacturer, is provided in section 2.', 0)
    const introduction4 = await getNormalRemark(pdfDoc, 'The intended use of the product, as defined by the manufacturer, is provided in section 3.', 0)
    const introduction5 = await getNormalRemark(pdfDoc, 'Qualification highlights, according to the MDR definitions of medical device, are provided in section 4.', 0)
    const introduction6 = await getNormalRemark(pdfDoc, 'Classification highlights, according to the MDR classification rules for medical devices, are provided in section 5.', 0)
    const introduction7 = await getNormalRemark(pdfDoc, 'An in-depth explanation of the qualification assessment is provided in section 6.', 0)
    const introduction8 = await getNormalRemark(pdfDoc, 'An in-depth explanation of the classification assessment is provided in section 7.', 0)
    const resultTitle = await getSubheading(pdfDoc, 'Result', 0)
    const qualificationName = await getLabel(pdfDoc, 'Qualification', 0)
    const qualificationNameValue = await getLabelValue(pdfDoc, qualification === undefined || qualification.qualification === undefined ? "" : getQualificationText(qualification.qualification), 0)
    const classificationName = await getLabel(pdfDoc, 'Classification', 0)
    const classificationNameValue = await getLabelValue(pdfDoc, classification === undefined || classification.classification === undefined ? "" : getClassificationText(classification.classification), 0)
    const decidingRulesName = await getLabel(pdfDoc, 'Contributing Rules', 0)
    const decidingRulesNameValueText = classification.children.filter((child) => {
        return child.classification === classification.classification
    }).map((child) => {
        const expression = classificationExpressions[child.expressionId]
        const index = expression.index !== null && expression.index !== undefined ? expression.index : 0
        return 'Rule ' + (1 + index)
    }).join(' and ')
    const decidingRulesNameValue = await getLabelValue(pdfDoc, decidingRulesNameValueText, 0)
    
    const descriptionTitle = await getSectionHeading(pdfDoc, '2. Description', 0)
    const descriptionRemark = await getNormalRemark(pdfDoc, 'The description according to the manufacturer:', 0)
    const descriptionGiven = await getMiniExplanation(pdfDoc, previewProduct?.description || '', 0) 

    const intendedUseTitle = await getSectionHeading(pdfDoc, '3. Intended Use', 0)
    const intendedUseRemark = await getNormalRemark(pdfDoc, 'The intended use according to the manufacturer:', 0)
    const intendedUseGiven = await getMiniExplanation(pdfDoc, previewProduct?.intendedUse || '', 0) 

    const showNotes = false
    const showUnanswered = false
    const qualificationElements = await getQualificationElements(pdfDoc, qualification, qualificationExpressions, qualificationQuestions, qualificationAnswers, showNotes, showUnanswered, "4. Qualification Highlights")
    const classificationElements = await getClassificationElements(pdfDoc, classification, classificationExpressions, classificationQuestions, classificationAnswers, showNotes, showUnanswered, "5. Classification Highlights")

    const showNotesInDepth = true
    const showUnansweredInDepth = true
    const qualificationElementsInDepth = await getQualificationElements(pdfDoc, qualification, qualificationExpressions, qualificationQuestions, qualificationAnswers, showNotesInDepth, showUnansweredInDepth, "6. Qualification in Depth")
    const classificationElementsInDepth = await getClassificationElements(pdfDoc, classification, classificationExpressions, classificationQuestions, classificationAnswers, showNotesInDepth, showUnansweredInDepth, "7. Classification in Depth")

    const elements: PDFTextElement[] = [
        title,
        purposeTitle,
        purpose,
        scopeTitle,
        scope,
        
        // 1. Introduction
        introductionTitle,
        introduction1,
        introduction2,
        introduction3,
        introduction4,
        introduction5,
        introduction6,
        introduction7,
        introduction8,
        resultTitle,
        qualificationName,
        qualificationNameValue,
        classificationName,
        classificationNameValue,
        decidingRulesName,
        decidingRulesNameValue,

        // 2. Description
        descriptionTitle,
        descriptionRemark,
        descriptionGiven,

        // 3. Intended Use
        intendedUseTitle,
        intendedUseRemark,
        intendedUseGiven,

    ]

    elements.push(...qualificationElements)
    elements.push(...classificationElements)
    elements.push(...qualificationElementsInDepth)
    elements.push(...classificationElementsInDepth)

    const header = await getHeader(pdfDoc, productNameText + ' by ' + organizationNameText + ' - Medical Device Qualification and Classification Report')
    const footer = await getFooter(pdfDoc, 'This report was generated automatically by the Medflow software by Meddoc Flow ApS, based on information provided by the manufacturer.')
    writePDF(pdfDoc, elements, header, footer, margin, spacing)
    return await pdfDoc.save()
}

export {
    getExportedPDFNew
}