import { API } from "aws-amplify";
import { CreatePreviewAnswerInput, DeletePreviewAnswerInput, PreviewAnswer, GetPreviewAnswerQuery, ListPreviewAnswersQuery, UpdatePreviewAnswerInput } from "../API";
import { createPreviewAnswer, deletePreviewAnswer, updatePreviewAnswer } from "../graphql/mutations";
import { getPreviewAnswer, listPreviewAnswers, listPreviewAnswersByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createPreviewAnswerApi = async (input: CreatePreviewAnswerInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewAnswer> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createPreviewAnswer,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createPreviewAnswer: PreviewAnswer } };
    return response.data.createPreviewAnswer;
}

export const updatePreviewAnswerApi = async (input: UpdatePreviewAnswerInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewAnswer> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updatePreviewAnswer,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updatePreviewAnswer: PreviewAnswer } };
    return response.data.updatePreviewAnswer;
}

export const deletePreviewAnswerApi = async (input: DeletePreviewAnswerInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewAnswer> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deletePreviewAnswer,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deletePreviewAnswer: PreviewAnswer } };
    return response.data.deletePreviewAnswer;
}

export const listPreviewAnswersApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewAnswer[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listPreviewAnswers,
    });

    const response = responseRaw as { data: ListPreviewAnswersQuery };
    const items = response.data.listPreviewAnswers?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as PreviewAnswer[];
    return itemsExisting
}

export const getPreviewAnswerApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewAnswer|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getPreviewAnswer,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetPreviewAnswerQuery };
    const instance = response.data.getPreviewAnswer;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as PreviewAnswer
    return instanceNotUndefined
}

export const listPreviewAnswersByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewAnswer[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listPreviewAnswersByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListPreviewAnswersQuery };
    const items = response.data.listPreviewAnswers?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as PreviewAnswer[];
    return itemsExisting
}
