import { CreatePhaseInput, DeletePhaseInput, Phase, UpdatePhaseInput } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyPhaseInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name'];
    const intKeys = ['order'];

    const entityRelationKeysEntities: Map<string, EntityName> = new Map([
        ['medflowVersion', EntityName.MedflowVersion]
    ]);

    const entityRelationsKeysEntities: Map<string, EntityName> = new Map([
        ['stages', EntityName.Stage]
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys,
        intKeys,
        entityRelationKeysEntities,
        entityRelationsKeysEntities
    });
}

export function getFilledPhaseInstance(model: Phase): FilledEntity {
    const instance = getEmptyPhaseInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name === null ? undefined : model.name);
    instance.addInt('order', model.order === null ? undefined : model.order);
    instance.addEntityRelation('medflowVersion', {entityName: EntityName.MedflowVersion, id: model.phaseMedflowVersionId === null ? undefined : model.phaseMedflowVersionId});

    const stageIds = model.stages === undefined ? [] : model.stages?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('stages', {ids: stageIds, entityName: EntityName.Stage});

    return instance;
}

export function getCreatePhaseInput(instance: FilledEntity): CreatePhaseInput {
    const name = instance.getStringMandatory('name');
    const order = instance.getIntMandatory('order');
    const phaseMedflowVersionId = instance.getEntityRelationOptional('medflowVersion')?.id;

    return {
        name,
        order,
        phaseMedflowVersionId
    };
}

export function getUpdatePhaseInput(instance: FilledEntity): UpdatePhaseInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const order = instance.getIntOptional('order');
    const phaseMedflowVersionId = instance.getEntityRelationOptional('medflowVersion')?.id;
    
    return {
        id,
        name,
        order,
        phaseMedflowVersionId
    };
}

export function getDeletePhaseInput(instance: FilledEntity): DeletePhaseInput {
    const id = instance.getIdMandatory('id');
    
    return {
        id
    };
}