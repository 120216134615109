import React from 'react'

interface FloatViewerProps {
    relationName: string
    value: number|undefined
}

export const FloatViewer: React.FC<FloatViewerProps> = ({ relationName, value }) => {
    return (
        <>
            <label className="form-label" htmlFor={'input-' + relationName}>{relationName}</label>
            <input id={'input-' + relationName} type="number" value={value} disabled className="form-control w-full" />
        </>
    )
}