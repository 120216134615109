import { API } from "aws-amplify";
import { CreatePreviewOrganizationInput, DeletePreviewOrganizationInput, PreviewOrganization, GetPreviewOrganizationQuery, ListPreviewOrganizationsQuery, UpdatePreviewOrganizationInput } from "../API";
import { createPreviewOrganization, deletePreviewOrganization, updatePreviewOrganization } from "../graphql/mutations";
import { getPreviewOrganization, listPreviewOrganizations, listPreviewOrganizationsByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createPreviewOrganizationApi = async (input: CreatePreviewOrganizationInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewOrganization> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createPreviewOrganization,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createPreviewOrganization: PreviewOrganization } };
    return response.data.createPreviewOrganization;
}

export const updatePreviewOrganizationApi = async (input: UpdatePreviewOrganizationInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewOrganization> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updatePreviewOrganization,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updatePreviewOrganization: PreviewOrganization } };
    return response.data.updatePreviewOrganization;
}

export const deletePreviewOrganizationApi = async (input: DeletePreviewOrganizationInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewOrganization> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deletePreviewOrganization,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deletePreviewOrganization: PreviewOrganization } };
    return response.data.deletePreviewOrganization;
}

export const listPreviewOrganizationsApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewOrganization[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listPreviewOrganizations,
    });

    const response = responseRaw as { data: ListPreviewOrganizationsQuery };
    const items = response.data.listPreviewOrganizations?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as PreviewOrganization[];
    return itemsExisting
}

export const getPreviewOrganizationApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewOrganization|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getPreviewOrganization,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetPreviewOrganizationQuery };
    const instance = response.data.getPreviewOrganization;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as PreviewOrganization
    return instanceNotUndefined
}

export const listPreviewOrganizationsByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewOrganization[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listPreviewOrganizationsByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListPreviewOrganizationsQuery };
    const items = response.data.listPreviewOrganizations?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as PreviewOrganization[];
    return itemsExisting
}
