import { API } from "aws-amplify";
import { CreateQuestionInput, DeleteQuestionInput, Question, GetQuestionQuery, ListQuestionsQuery, UpdateQuestionInput } from "../API";
import { createQuestion, deleteQuestion, updateQuestion } from "../graphql/mutations";
import { getQuestion, listQuestions, listQuestionsByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createQuestionApi = async (input: CreateQuestionInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Question> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createQuestion,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createQuestion: Question } };
    return response.data.createQuestion;
}

export const updateQuestionApi = async (input: UpdateQuestionInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Question> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updateQuestion,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updateQuestion: Question } };
    return response.data.updateQuestion;
}

export const deleteQuestionApi = async (input: DeleteQuestionInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Question> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deleteQuestion,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deleteQuestion: Question } };
    return response.data.deleteQuestion;
}

export const listQuestionsApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Question[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listQuestions,
    });

    const response = responseRaw as { data: ListQuestionsQuery };
    const items = response.data.listQuestions?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as Question[];
    return itemsExisting
}

export const getQuestionApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Question|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getQuestion,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetQuestionQuery };
    const instance = response.data.getQuestion;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as Question
    return instanceNotUndefined
}

export const listQuestionsByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Question[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listQuestionsByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListQuestionsQuery };
    const items = response.data.listQuestions?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as Question[];
    return itemsExisting
}
