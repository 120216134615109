import React from 'react'

interface BooleanViewerProps {
    relationName: string
    value: boolean|undefined
}

export const BooleanViewer: React.FC<BooleanViewerProps> = ({ relationName, value }) => {
    return (
        <>
            <span className="form-label">{relationName}</span>
            <div className="form-check">
                <input className="form-check-input toggle" disabled type="checkbox" checked={value === true} id={'qualification-answer-' + relationName} />
            </div>
        </>
    )
}