import { breakTextIntoLines, PDFDocument, PDFImage, PDFPageDrawTextOptions } from "pdf-lib";

export interface PDFTextElement {
    text: string,
    isFloating: boolean,
    isSpaced: boolean,
    image: PDFImage|undefined,
    opts: PDFPageDrawTextOptions & Required<Pick<PDFPageDrawTextOptions, "font" | "size" | "maxWidth" | "lineHeight">>
}

export interface PDFLinesElement {
    lines: string[],
    isFloating: boolean,
    isSpaced: boolean,
    image: PDFImage|undefined,
    opts: PDFPageDrawTextOptions & Required<Pick<PDFPageDrawTextOptions, "font" | "size" | "maxWidth" | "lineHeight">>
}

function calculateLineHeight(element: PDFTextElement|PDFLinesElement): number {
    return element.opts.lineHeight * element.opts.size
}

function calculateLines(pdfDoc: PDFDocument, element: PDFTextElement): string[] {
    return breakTextIntoLines(
        element.text,
        element.opts.wordBreaks || pdfDoc.defaultWordBreaks,
        element.opts.maxWidth,
        (t) => element.opts.font.widthOfTextAtSize(t, element.opts.size)
    );
}

function printElementsOnPages(pdfDoc: PDFDocument, elements: PDFLinesElement[], headerText: PDFTextElement, footerText: PDFTextElement, margin: number, spacing: number) {
    var page = pdfDoc.addPage()
    const height = page.getSize().height

    page.drawText(headerText.text, { ...headerText.opts, y: height - margin })
    page.drawText(footerText.text, { ...footerText.opts, y: margin })

    const usableHeight = height - 4 * margin
    var usedHeight = 0

    for (var i = 0; i < elements.length; i++) {
        const element = elements[i]

        const lineHeight = calculateLineHeight(element)
        var innerHeight = usedHeight

        if (element.isSpaced && innerHeight > 0) {
            innerHeight += spacing
        }

        for (var j = 0; j < element.lines.length; j++) {
            var nextPosition = innerHeight
            var newUsedHeight = nextPosition + lineHeight

            if (newUsedHeight > usableHeight) {
                page = pdfDoc.addPage()

                page.drawText(headerText.text, { ...headerText.opts, y: height - margin })
                page.drawText(footerText.text, { ...footerText.opts, y: margin })
                
                innerHeight = 0
                nextPosition = 0
                newUsedHeight = lineHeight
            }

            const actualPosition = height - nextPosition - 2 * margin - lineHeight
            page.drawText(element.lines[j], { ...element.opts, y: actualPosition })

            if (element.image) {
                const position = element.opts.x || 0
                const size = element.opts.size
                page.drawImage(element.image, { ...element, x: position - size - 5, y: actualPosition - 2, width: size, height: size })
            }
    
            innerHeight = newUsedHeight
    
            if (!element.isFloating) {
                usedHeight = innerHeight
            }
        }
    }
}

export function writePDF(pdfDoc: PDFDocument, elements: PDFTextElement[], headerElement: PDFTextElement, footerElement: PDFTextElement, margin: number, spacing: number) {
    const elementsLines = elements.map(element => {
        const lines = calculateLines(pdfDoc, element)
        
        const elementLines: PDFLinesElement = {
            lines: lines,
            isFloating: element.isFloating,
            isSpaced: element.isSpaced,
            opts: element.opts,
            image: element.image
        }

        return elementLines
    })

    printElementsOnPages(pdfDoc, elementsLines, headerElement, footerElement, margin, spacing)
}