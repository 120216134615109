import React, { useEffect, useState } from 'react'

interface BooleanChooserProps {
    relationName: string
    value: boolean|undefined
    setValue: (value: boolean|undefined) => void
}

export const BooleanChooser: React.FC<BooleanChooserProps> = ({ relationName, value, setValue }) => {
    const [innerValue, setInnerValue] = useState<boolean|undefined>(value)

    useEffect(() => {
        setInnerValue(value)
    }, [value])

    function toggle(_: React.ChangeEvent<HTMLInputElement>): void {
        const currentValue = innerValue
        setInnerValue(!currentValue)
        setValue(!currentValue)
    }

    return (
        <>
            <span className="form-label">{relationName}</span>
            <div className="form-check">
                <input className="form-check-input toggle" type="checkbox" checked={innerValue === true} onChange={toggle} id={'qualification-answer-' + relationName} />
            </div>
        </>
    )
}