import React from 'react'
import { EntityName } from '../../model/EntityName'
import { GenericRelatedChooser } from '../mixins/GenericRelatedChooser'

interface RelatedEntityChooserProps {
    relationName: string
    entityName: EntityName
    originalId: string|undefined
    setId: (relationName: string, entityName: EntityName, id: string|undefined) => void
}

export const RelatedEntityChooser: React.FC<RelatedEntityChooserProps> = ({ relationName, entityName, originalId, setId }) => {
    return (
        <GenericRelatedChooser relationName={relationName} originalId={originalId} entityName={entityName} setId={setId} />
    )
}