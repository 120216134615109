export const Privacy: React.FC = () => {
    return (
        <div>
            <div className="py-4 py-xs-8 py-sm-12 py-md-16 py-lg-24 py-xl-32 py-xxl-32 overflow-hidden">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <h1 className="display-1 text-center text-primary">
                                privacy
                            </h1>
                            <h2 className="mb-10 text-center">
                                Last updated: 25/Mar/2023
                            </h2>
                            <ul>
                                <li className="mb-2">
                                    <span className="font-bold">Introduction</span>: We value your privacy and are committed to protecting your personal information. This Privacy Policy explains what information we collect, how we use it, and your rights regarding your data.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Information Collection</span>: We may collect personal information from you when you use our service, such as your name, email address, and any other information you voluntarily provide. We also collect non-personal information, such as your IP address, browser type, and usage data.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Use of Information</span>: We use the collected information for purposes such as: to provide our service, to communicate with you, to improve our service including its security and performance, to analyze usage trends and preferences, and to provide you with information about our service.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Information Sharing</span>: We will not sell, rent, or share your personal information with third parties without your consent, except when required by law, to protect our rights, or to comply with a legal obligation.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Data Retention</span>: We retain your personal information for as long as necessary to fulfill the purposes for which it was collected or to comply with legal requirements.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Security</span>: We take reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. However, no method of data transmission or storage is 100% secure, and we cannot guarantee absolute security.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Cookies</span>: We may use cookies to enhance your experience while using our service. You have the option to accept or reject cookies through your browser settings.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Third-Party Links</span>: Our service may contain links to third-party websites. We are not responsible for the privacy practices of these external sites. We encourage you to review the privacy policies of any third-party websites you visit.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Changes to Privacy Policy</span>: We reserve the right to update this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on our website. Your continued use of our service constitutes your acceptance of the updated Privacy Policy.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Your Rights</span>: You have the right to access, update, or delete your personal information at any time. To exercise these rights, please contact us using the contact information provided on our website.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Contact Us</span>: If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us using the contact information provided on our website.
                                </li>
                            </ul>
                            <h1 className="display-1 text-center text-primary">
                                🧿
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-n1 transform translate-y-1/4">
                    <div className="col-lg-11 col-xl-9 mx-auto">
                        <div className="row g-5">
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md6.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md4.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md3.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md5.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md2.jpg" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
