export const Careers: React.FC = () => {
    return (
        <div>
            <div className="py-4 py-xs-8 py-sm-12 py-md-16 py-lg-24 py-xl-32 py-xxl-32 overflow-hidden">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <h1 className="display-1 text-center text-primary">
                                careers
                            </h1>
                            <h2 className="mb-10 text-center">
                                Let's work together for better healthcare
                            </h2>
                            <p className="lead mb-10 text-center">
                                If you take pride in your area, work collaboratively, and find our mission motivating, get in touch. We look for the proof (or potential) of excellence as well as the attitude to continue your personal and professional growth beyond your immediate focus. We welcome unsolicited but serious applications.
                            </p>
                            <div className="mx-2 text-center">
                                <a href="https://thehub.io/startups/meddoc-flow-aps-1" target="_blank" className="btn btn-lg btn-primary shadow-sm mx-2 px-lg-8">
                                    See Open Positions
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-n1 transform translate-y-1/4">
                    <div className="col-lg-11 col-xl-9 mx-auto">
                        <div className="row g-5">
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md6.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md4.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md3.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md5.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md2.jpg" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
