import * as graphQlApi from '../../../API';

function hasAnswer(questionId: string, answers: {[key: string]: boolean}): boolean {
    return answers.hasOwnProperty(questionId)
}

function hasAllAnswers(questions: {[key: string]: graphQlApi.Question}, answers: {[key: string]: boolean}): boolean {
    if (Object.entries(questions).length <= 0 || Object.entries(answers).length <= 0) {
        return false
    }

    const every = Object.entries(questions)
        .every(([questionId, _]) => {
            return hasAnswer(questionId, answers)
        })

    return every
}

function getOperatorText(operator: graphQlApi.DeviceExpressionOperator | undefined, isLeaf: boolean): string {
    if (isLeaf) {
        return 'highest class from the question at this level'
    }

    switch (operator) {
        case graphQlApi.DeviceExpressionOperator.MAX_OF:
            return 'highest class from the question at this level and the classes from the lower levels (same priority)'
        case graphQlApi.DeviceExpressionOperator.RIGHT_OVERRIDE_OF:
            return 'highest class from the lower levels (first priority) or the question at this level (second priority)'
    }

    return "none"
}

export {
    hasAnswer,
    hasAllAnswers,
    getOperatorText
}