import { API } from "aws-amplify";
import { CreateTrackInput, DeleteTrackInput, Track, GetTrackQuery, ListTracksQuery, UpdateTrackInput } from "../API";
import { createTrack, deleteTrack, updateTrack } from "../graphql/mutations";
import { getTrack, listTracks, listTracksByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createTrackApi = async (input: CreateTrackInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Track> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createTrack,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createTrack: Track } };
    return response.data.createTrack;
}

export const updateTrackApi = async (input: UpdateTrackInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Track> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updateTrack,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updateTrack: Track } };
    return response.data.updateTrack;
}

export const deleteTrackApi = async (input: DeleteTrackInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Track> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deleteTrack,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deleteTrack: Track } };
    return response.data.deleteTrack;
}

export const listTracksApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Track[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listTracks,
    });

    const response = responseRaw as { data: ListTracksQuery };
    const items = response.data.listTracks?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as Track[];
    return itemsExisting
}

export const getTrackApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Track|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getTrack,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetTrackQuery };
    const instance = response.data.getTrack;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as Track
    return instanceNotUndefined
}

export const listTracksByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Track[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listTracksByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListTracksQuery };
    const items = response.data.listTracks?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as Track[];
    return itemsExisting
}
