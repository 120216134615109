import React, { useState, useEffect } from 'react'
import { EntityName } from '../../model/EntityName'
import { BooleanChooser } from './primitive-choosers/BooleanChooser'
import { StringChooser } from './primitive-choosers/StringChooser'
import { IntChooser } from './primitive-choosers/IntChooser'
import { FloatChooser } from './primitive-choosers/FloatChooser'
import { FilledEntity } from '../../model/FilledEntity'
import { AnswerDataType, DeviceClassification, DeviceExpressionOperator, DeviceQualification, EntityEnum, SpecialQuestionCategory } from '../../API'
import { AnswerDataTypeRelatedChooser } from './enum-related-choosers/AnswerDataTypeRelatedChooser'
import { DeviceExpressionOperatorRelatedChooser } from './enum-related-choosers/DeviceExpressionOperatorRelatedChooser'
import { SpecialQuestionCategoryRelatedChooser } from './enum-related-choosers/SpecialQuestionCategoryRelatedChooser'
import { DeviceClassificationRelatedChooser } from './enum-related-choosers/DeviceClassificationRelatedChooser'
import { DeviceQualificationRelatedChooser } from './enum-related-choosers/DeviceQualificationRelatedChooser'
import { EntityEnumRelatedChooser } from './enum-related-choosers/EntityEnumRelatedChooser'
import { RelatedEntityChooser } from '../common/RelatedEntityChooser'
import { getFilledInstance, updateFilledInstance } from './mixin-helper'

interface GenericEditorProps {
    entityName: EntityName
    id: string
    discardEntityEditor: (entityName: EntityName, id: string) => void
}

export const GenericEditor: React.FC<GenericEditorProps> = ({entityName, id, discardEntityEditor}) => {
    const [instance, setInstance] = useState<FilledEntity|undefined>(undefined)

    function stringToEnum<T>(str: string|undefined, enumType: T): T[keyof T] | undefined {
        if (str === undefined) {
            return undefined
        }

        return enumType[str as keyof T];
    }

    useEffect(() => {
        const asyncSetInstance = async () => {
            const filledInstance = await getFilledInstance(entityName, id)
            setInstance(filledInstance)
        }

        asyncSetInstance()
    }, [id])

    function changeString(key: string, value: string|undefined): void {
        instance?.addString(key, value)
    }

    function changeInt(key: string, value: number|undefined): void {
        instance?.addInt(key, value)
    }

    function changeFloat(key: string, value: number|undefined): void {
        instance?.addFloat(key, value)
    }

    function changeBoolean(key: string, value: boolean|undefined): void {
        instance?.addBoolean(key, value)
    }

    function changeRelation(key: string, entityName: EntityName, id: string|undefined): void {
        instance?.addEntityRelation(key, {entityName: entityName, id: id})
    }

    function changeAnswerDataType(key: string, value: AnswerDataType|undefined): void {
        instance?.addAnswerDataType(key, value)
    }

    function changeSpecialQuestionCategory(key: string, value: SpecialQuestionCategory|undefined): void {
        instance?.addSpecialQuestionCategory(key, value)
    }

    function changeDeviceExpressionOperator(key: string, value: DeviceExpressionOperator|undefined): void {
        instance?.addDeviceExpressionOperator(key, value)
    }

    function changeEntityEnum(key: string, value: EntityEnum|undefined): void {
        instance?.addEntityEnum(key, value)
    }

    function changeDeviceClassification(key: string, value: DeviceClassification|undefined): void {
        instance?.addDeviceClassification(key, value)
    }

    function changeDeviceQualification(key: string, value: DeviceQualification|undefined): void {
        instance?.addDeviceQualification(key, value)
    }

    async function edit(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault()

        if (instance === undefined) {
            alert('The entity instance does not exist. Not updating it.')
        }

        await updateFilledInstance(entityName, instance)
        discardEntityEditor(entityName, id)
    }

    if (instance === undefined) {
        return null
    }

    const name = instance?.getStringMap().get('name')

    return (
        <div className="card">
            <div className="card-header">
                <h2>Edit {name} <span className="text-gray-500 font-normal">{entityName}</span></h2>
            </div>
            <div className="card-body">
                <form>
                    {
                        Array.from(instance.getStringMap()).map(([key, value]) => (
                            <div className="mb-4" key={key}>
                                <StringChooser relationName={key} value={value} setValue={(value) => changeString(key, value)} />
                            </div>
                        ))
                    }
                    {
                        Array.from(instance.getIntMap()).map(([key, value]) => (
                            <div className="mb-4" key={key}>
                                <IntChooser relationName={key} value={value} setValue={(value) => changeInt(key, value)} />
                            </div>
                        ))
                    }
                    {
                        Array.from(instance.getFloatMap()).map(([key, value]) => (
                            <div className="mb-4" key={key}>
                                <FloatChooser relationName={key} value={value} setValue={(value) => changeFloat(key, value)} />
                            </div>
                        ))
                    }
                    {
                        Array.from(instance.getBooleanMap()).map(([key, value]) => (
                            <div className="mb-4" key={key}>
                                <BooleanChooser relationName={key} value={value} setValue={(value) => changeBoolean(key, value)} />
                            </div>
                        ))
                    }
                    {
                        Array.from(instance.getAnswerDataTypeMap()).map(([key, value]) => (
                            <div className="mb-4" key={key}>
                                <AnswerDataTypeRelatedChooser originalEnumValue={value} relationName={key} setEnumValue={(enumValue: AnswerDataType|undefined) => changeAnswerDataType(key, enumValue)} />
                            </div>
                        ))
                    }
                    {
                        Array.from(instance.getSpecialQuestionCategoryMap()).map(([key, value]) => (
                            <div className="mb-4" key={key}>
                                <SpecialQuestionCategoryRelatedChooser originalEnumValue={value} relationName={key} setEnumValue={(enumValue: SpecialQuestionCategory|undefined) => changeSpecialQuestionCategory(key, enumValue)} />
                            </div>
                        ))
                    }
                    {
                        Array.from(instance.getDeviceExpressionOperatorMap()).map(([key, value]) => (
                            <div className="mb-4" key={key}>
                                <DeviceExpressionOperatorRelatedChooser originalEnumValue={value} relationName={key} setEnumValue={(enumValue: DeviceExpressionOperator|undefined) => changeDeviceExpressionOperator(key, enumValue)} />
                            </div>
                        ))
                    }
                    {
                        Array.from(instance.getDeviceClassificationMap()).map(([key, value]) => (
                            <div className="mb-4" key={key}>
                                <DeviceClassificationRelatedChooser originalEnumValue={value} relationName={key} setEnumValue={(enumValue: DeviceClassification|undefined) => changeDeviceClassification(key, enumValue)} />
                            </div>
                        ))
                    }
                    {
                        Array.from(instance.getDeviceQualificationMap()).map(([key, value]) => (
                            <div className="mb-4" key={key}>
                                <DeviceQualificationRelatedChooser originalEnumValue={value} relationName={key} setEnumValue={(enumValue: DeviceQualification|undefined) => changeDeviceQualification(key, enumValue)} />
                            </div>
                        ))
                    }
                    {
                        Array.from(instance.getEntityEnumMap()).map(([key, value]) => (
                            <div className="mb-4" key={key}>
                                <EntityEnumRelatedChooser originalEnumValue={value} relationName={key} setEnumValue={(enumValue: EntityEnum|undefined) => changeEntityEnum(key, enumValue)} />
                            </div>
                        ))
                    }
                    {
                        Array.from(instance.getEntityRelationMap()).map(([key, value]) => {
                            const givenEntityNameString = value?.entityName.toString()

                            if (givenEntityNameString === undefined) {
                                return null
                            }

                            const givenEntityName = stringToEnum(givenEntityNameString, EntityName)

                            if (givenEntityName === undefined) {
                                return null
                            }

                            const givenId = value?.id

                            return (
                                <div className="mb-4" key={key}>
                                    <RelatedEntityChooser relationName={key}
                                    entityName={givenEntityName} originalId={givenId}
                                    setId={changeRelation} />
                                </div>
                            )
                        })
                    }
                </form>
            </div>
            <div className="card-footer">
                <button className="btn btn-warning btn-sm mb-4" onClick={edit}>Edit {entityName}</button>
            </div>
        </div>
    )
}