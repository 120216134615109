import { API } from "aws-amplify";
import { CreateStageInput, DeleteStageInput, Stage, GetStageQuery, ListStagesQuery, UpdateStageInput } from "../API";
import { createStage, deleteStage, updateStage } from "../graphql/mutations";
import { getStage, listStages, listStagesByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createStageApi = async (input: CreateStageInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Stage> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createStage,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createStage: Stage } };
    return response.data.createStage;
}

export const updateStageApi = async (input: UpdateStageInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Stage> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updateStage,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updateStage: Stage } };
    return response.data.updateStage;
}

export const deleteStageApi = async (input: DeleteStageInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Stage> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deleteStage,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deleteStage: Stage } };
    return response.data.deleteStage;
}

export const listStagesApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Stage[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listStages,
    });

    const response = responseRaw as { data: ListStagesQuery };
    const items = response.data.listStages?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as Stage[];
    return itemsExisting
}

export const getStageApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Stage|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getStage,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetStageQuery };
    const instance = response.data.getStage;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as Stage
    return instanceNotUndefined
}

export const listStagesByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Stage[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listStagesByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListStagesQuery };
    const items = response.data.listStages?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as Stage[];
    return itemsExisting
}
