import React, { useEffect, useState } from 'react'

interface IntChooserProps {
    relationName: string
    value: number|undefined
    setValue: (value: number|undefined) => void
}

export const IntChooser: React.FC<IntChooserProps> = ({ relationName, value, setValue }) => {
    const [innerValue, setInnerValue] = useState<number|undefined>(value)

    useEffect(() => {
        setInnerValue(value)
    }, [value])

    function setValueInt(event: React.ChangeEvent<HTMLInputElement>): void {
        const intValue = parseInt(event.target.value)
        const intValueNumber = isNaN(intValue) ? undefined: intValue
        setInnerValue(intValueNumber)
        setValue(intValueNumber)
    }

    return (
        <>
            <span className="form-label">{relationName}</span>
            <input id={relationName} type="number" value={innerValue === null ? undefined : innerValue} onChange={setValueInt} className="form-control w-full" />
        </>
    )
}