import { CreateQuestionLabelInput, DeleteQuestionLabelInput, QuestionLabel, UpdateQuestionLabelInput } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyQuestionLabelInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name'];

    const entityRelationKeysEntities: Map<string, EntityName> = new Map([
        ['question', EntityName.Question],
        ['label', EntityName.Label],
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys,
        entityRelationKeysEntities
    });
}

export function getFilledQuestionLabelInstance(model: QuestionLabel): FilledEntity {
    const instance = getEmptyQuestionLabelInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name === null ? undefined : model.name);
    instance.addEntityRelation('question', {entityName: EntityName.Question, id: model.questionQuestionLabelsId === null ? undefined : model.questionQuestionLabelsId});
    instance.addEntityRelation('label', {entityName: EntityName.Label, id: model.labelQuestionLabelsId === null ? undefined : model.labelQuestionLabelsId});
    return instance;
}

export function getCreateQuestionLabelInput(instance: FilledEntity): CreateQuestionLabelInput {
    const name = instance.getStringMandatory('name');
    const questionQuestionLabelsId = instance.getEntityRelationOptional('question')?.id;
    const labelQuestionLabelsId = instance.getEntityRelationOptional('label')?.id;

    return {
        name,
        questionQuestionLabelsId,
        labelQuestionLabelsId
    };
}

export function getUpdateQuestionLabelInput(instance: FilledEntity): UpdateQuestionLabelInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const questionQuestionLabelsId = instance.getEntityRelationOptional('question')?.id;
    const labelQuestionLabelsId = instance.getEntityRelationOptional('label')?.id;

    return {
        id,
        name,
        questionQuestionLabelsId,
        labelQuestionLabelsId
    };
}

export function getDeleteQuestionLabelInput(instance: FilledEntity): DeleteQuestionLabelInput {
    const id = instance.getIdMandatory('id');

    return {
        id: id,
    };
}