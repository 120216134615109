import { ChangeEvent, FC } from 'react'
import Papa, { ParseResult } from 'papaparse'
import { saveAs } from 'file-saver'

import { createPhaseApi, getPhaseApi, listPhasesApi, listPhasesByNameApi, updatePhaseApi } from '../../api-wrapper/Phase'
import { createTrackApi, getTrackApi, listTracksApi, listTracksByNameApi, updateTrackApi } from '../../api-wrapper/Track'
import { createStageApi, getStageApi, listStagesApi, listStagesByNameApi, updateStageApi } from '../../api-wrapper/Stage'
import { createLabelApi, getLabelApi, listLabelsApi, listLabelsByNameApi, updateLabelApi } from '../../api-wrapper/Label'
import { createQuestionLabelApi, getQuestionLabelApi, listQuestionLabelsApi, listQuestionLabelsByNameApi, updateQuestionLabelApi } from '../../api-wrapper/QuestionLabel'
import { createQuestionApi, getQuestionApi, listQuestionsApi, listQuestionsByNameApi, updateQuestionApi } from '../../api-wrapper/Question'
import { createStageQuestionnaireSectionApi, getStageQuestionnaireSectionApi, listStageQuestionnaireSectionsApi, listStageQuestionnaireSectionsByNameApi, updateStageQuestionnaireSectionApi } from '../../api-wrapper/StageQuestionnaireSection'
import { createStageQuestionnaireApi, getStageQuestionnaireApi, listStageQuestionnairesApi, listStageQuestionnairesByNameApi, updateStageQuestionnaireApi } from '../../api-wrapper/StageQuestionnaire'
import { createQuestionRowColumnApi, getQuestionRowColumnApi, listQuestionRowColumnsApi, listQuestionRowColumnsByNameApi, updateQuestionRowColumnApi } from '../../api-wrapper/QuestionRowColumn'

import { getCreatePhaseInput, getEmptyPhaseInstance, getUpdatePhaseInput } from '../../model/Phase'
import { getCreateTrackInput, getEmptyTrackInstance, getUpdateTrackInput } from '../../model/Track'
import { getCreateStageInput, getEmptyStageInstance, getUpdateStageInput } from '../../model/Stage'
import { getCreateQuestionLabelInput, getEmptyQuestionLabelInstance, getUpdateQuestionLabelInput } from '../../model/QuestionLabel'
import { getCreateQuestionInput, getEmptyQuestionInstance, getUpdateQuestionInput } from '../../model/Question'
import { getCreateLabelInput, getEmptyLabelInstance, getUpdateLabelInput } from '../../model/Label'
import { getCreateStageQuestionnaireSectionInput, getEmptyStageQuestionnaireSectionInstance, getUpdateStageQuestionnaireSectionInput } from '../../model/StageQuestionnaireSection'
import { getCreateStageQuestionnaireInput, getEmptyStageQuestionnaireInstance, getUpdateStageQuestionnaireInput } from '../../model/StageQuestionnaire'
import { getCreateQuestionRowColumnInput, getEmptyQuestionRowColumnInstance, getUpdateQuestionRowColumnInput } from '../../model/QuestionRowColumn'

import { AnswerDataType, Label, Phase, Question, QuestionLabel, QuestionRowColumn, Stage, StageQuestionnaire, StageQuestionnaireSection, Track } from '../../API'
import { EntityName } from '../../model/EntityName'

interface IdentifiedRow {
    phaseID: string
    trackID: string
    stageID: string
    stageQuestionnaireID: string
    stageQuestionnaireSectionID: string|undefined
    questionID: string
    labelID: string|undefined
    questionLabelID: string|undefined
    questionRowColumnID: string|undefined
}

interface ExternalRow {
    phaseName: string
    trackName: string
    stageName: string
    stageQuestionnaireSectionName: string | undefined
    questionName: string
    questionStatement: string | undefined
    questionLabelName: string | undefined
    questionGuidingBeforeText: string | undefined
    questionAnswerPlaceholder: string | undefined
    questionExamples: string | undefined
    answerDataType: AnswerDataType
    answerOptionName: string | undefined
    answerOptionType: AnswerDataType | undefined
    phaseID: string
    trackID: string
    stageID: string
    stageQuestionnaireID: string
    stageQuestionnaireSectionID: string | undefined
    labelID: string | undefined
    questionID: string
    questionLabelID: string | undefined
    questionRowColumnID: string | undefined
}

interface Context {
    phase?: Phase
    track?: Track
    stage?: Stage
    label?: Label
    questionLabel?: QuestionLabel
    question?: Question
    stageQuestionnaireSection?: StageQuestionnaireSection
    stageQuestionnaire?: StageQuestionnaire
    questionRowColumn?: QuestionRowColumn
}

const DataImporterCsv: FC = () => {
    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0]

        Papa.parse<ExternalRow>(file, {
            header: true,
            complete: async function (results: ParseResult<ExternalRow>) {
                await processData(results.data)
            }
        })
    }

    async function processData(originalRows: ExternalRow[]) {
        const context: Context = {}
        const phases: Map<string, Phase> = (await listPhasesApi()).reduce((map, phase) => map.set(phase.id, phase), new Map<string, Phase>());
        const tracks: Map<string, Track> = (await listTracksApi()).reduce((map, track) => map.set(track.id, track), new Map<string, Track>());
        const stages: Map<string, Stage> = (await listStagesApi()).reduce((map, stage) => map.set(stage.id, stage), new Map<string, Stage>());
        const labels: Map<string, Label> = (await listLabelsApi()).reduce((map, label) => map.set(label.id, label), new Map<string, Label>());
        const questionLabels: Map<string, QuestionLabel> = (await listQuestionLabelsApi()).reduce((map, questionLabel) => map.set(questionLabel.id, questionLabel), new Map<string, QuestionLabel>());
        const questions: Map<string, Question> = (await listQuestionsApi()).reduce((map, question) => map.set(question.id, question), new Map<string, Question>());
        const stageQuestionnaireSections: Map<string, StageQuestionnaireSection> = (await listStageQuestionnaireSectionsApi()).reduce((map, stageQuestionnaireSection) => map.set(stageQuestionnaireSection.id, stageQuestionnaireSection), new Map<string, StageQuestionnaireSection>());
        const stageQuestionnaires: Map<string, StageQuestionnaire> = (await listStageQuestionnairesApi()).reduce((map, stageQuestionnaire) => map.set(stageQuestionnaire.id, stageQuestionnaire), new Map<string, StageQuestionnaire>());
        const questionRowColumns: Map<string, QuestionRowColumn> = (await listQuestionRowColumnsApi()).reduce((map, questionRowColumn) => map.set(questionRowColumn.id, questionRowColumn), new Map<string, QuestionRowColumn>());
        const identifiedRows: IdentifiedRow[] = []

        for (const originalRow of originalRows) {
            const {
                phaseName,
                trackName,
                stageName,
                stageQuestionnaireSectionName,
                questionName,
                questionStatement,
                questionLabelName,
                questionGuidingBeforeText,
                questionAnswerPlaceholder,
                questionExamples,
                answerDataType,
                answerOptionName,
                answerOptionType,
                phaseID,
                trackID,
                stageID,
                stageQuestionnaireID,
                stageQuestionnaireSectionID,
                labelID,
                questionID,
                questionLabelID,
                questionRowColumnID
            } = originalRow

            if (phaseName === "") {
                alert("Phase name is required")
                throw new Error("Phase name is required")
            }

            if (trackName === "") {
                alert("Track name is required")
                throw new Error("Track name is required")
            }

            if (stageName === "") {
                alert("Stage name is required")
                throw new Error("Stage name is required")
            }

            if (questionName === "") {
                alert("Question name is required")
                throw new Error("Question name is required")
            }

            const phaseNameFiltered = phaseName
            const trackNameFiltered = trackName
            const stageNameFiltered = stageName
            const stageQuestionnaireSectionNameFiltered = stageQuestionnaireSectionName !== undefined && stageQuestionnaireSectionName !== null && stageQuestionnaireSectionName !== "" ? stageQuestionnaireSectionName : undefined
            const questionNameFiltered = questionName
            const questionStatementFiltered = questionStatement !== undefined && questionStatement !== null && questionStatement !== "" ? questionStatement : undefined
            const questionLabelNameFiltered = questionLabelName !== undefined && questionLabelName !== null && questionLabelName !== "" ? questionLabelName : undefined
            const questionGuidingBeforeTextFiltered = questionGuidingBeforeText !== undefined && questionGuidingBeforeText !== null && questionGuidingBeforeText !== "" ? questionGuidingBeforeText : undefined
            const questionAnswerPlaceholderFiltered = questionAnswerPlaceholder !== undefined && questionAnswerPlaceholder !== null && questionAnswerPlaceholder !== "" ? questionAnswerPlaceholder : undefined
            const questionExamplesFiltered = questionExamples !== undefined && questionExamples !== null && questionExamples !== "" ? questionExamples : undefined
            const answerDataTypeFiltered = answerDataType
            const answerOptionNameFiltered = answerOptionName !== undefined && answerOptionName !== null && answerOptionName !== "" ? answerOptionName : undefined
            const answerOptionTypeFiltered = answerOptionType !== undefined && answerOptionType !== null ? answerOptionType : undefined
            const phaseIDFiltered = phaseID !== undefined && phaseID !== null && phaseID !== "" ? phaseID : undefined
            const trackIDFiltered = trackID !== undefined && trackID !== null && trackID !== "" ? trackID : undefined
            const stageIDFiltered = stageID !== undefined && stageID !== null && stageID !== "" ? stageID : undefined
            const stageQuestionnaireIDFiltered = stageQuestionnaireID !== undefined && stageQuestionnaireID !== null && stageQuestionnaireID !== "" ? stageQuestionnaireID : undefined
            const stageQuestionnaireSectionIDFiltered = stageQuestionnaireSectionID !== undefined && stageQuestionnaireSectionID !== null && stageQuestionnaireSectionID !== "" ? stageQuestionnaireSectionID : undefined
            const labelIDFiltered = labelID !== undefined && labelID !== null && labelID !== "" ? labelID : undefined
            const questionIDFiltered = questionID !== undefined && questionID !== null && questionID !== "" ? questionID : undefined
            const questionLabelIDFiltered = questionLabelID !== undefined && questionLabelID !== null && questionLabelID !== "" ? questionLabelID : undefined
            const questionRowColumnIDFiltered = questionRowColumnID !== undefined && questionRowColumnID !== null && questionRowColumnID !== "" ? questionRowColumnID : undefined

        
            // apply rule for Phase
            context.phase = await getOrCreateOrUpdatePhase(
                phaseIDFiltered,
                phaseNameFiltered,
                phases
            )

            phases.set(context.phase.id, context.phase)
        
            // apply rule for Track
            context.track = await getOrCreateOrUpdateTrack(
                trackIDFiltered,
                trackNameFiltered,
                tracks
            )

            tracks.set(context.track.id, context.track)

            // apply rule for Stage
            context.stage = await getOrCreateOrUpdateStage(
                stageIDFiltered,
                stageNameFiltered,
                context.phase,
                context.track,
                stages
            )

            stages.set(context.stage.id, context.stage)

            // apply rule for Question
            context.question = await getOrCreateOrUpdateQuestion(
                questionIDFiltered,
                questionNameFiltered,
                questionStatementFiltered,
                questionAnswerPlaceholderFiltered,
                questionExamplesFiltered,
                answerDataTypeFiltered,
                questionGuidingBeforeTextFiltered,
                questions
            )

            questions.set(context.question.id, context.question)

            if (questionLabelNameFiltered !== null && questionLabelNameFiltered !== undefined && questionLabelNameFiltered !== "") {
        
                // apply rule for Label
                context.label = await getOrCreateOrUpdateLabel(
                    labelIDFiltered,
                    questionLabelNameFiltered,
                    labels
                )

                labels.set(context.label.id, context.label)

                // apply rule for QuestionLabel
                context.questionLabel = await getOrCreateOrUpdateQuestionLabel(
                    questionLabelIDFiltered,
                    questionLabelNameFiltered,
                    context.question,
                    questionLabels
                )

                questionLabels.set(context.questionLabel.id, context.questionLabel)
            } else {
                context.label = undefined
                context.questionLabel = undefined
            }

            if (answerOptionNameFiltered !== null && answerOptionNameFiltered !== undefined && answerOptionNameFiltered !== "" && answerOptionTypeFiltered !== null && answerOptionTypeFiltered !== undefined) {
        
                // apply rule for QuestionRowColumn
                context.questionRowColumn = await getOrCreateOrUpdateQuestionRowColumn(
                    questionRowColumnIDFiltered,
                    answerOptionNameFiltered,
                    context.question,
                    answerOptionTypeFiltered,
                    questionRowColumns
                )

                questionRowColumns.set(context.questionRowColumn.id, context.questionRowColumn)
            } else {
                context.questionRowColumn = undefined
            }

            if (stageQuestionnaireSectionNameFiltered !== null && stageQuestionnaireSectionNameFiltered !== undefined && stageQuestionnaireSectionNameFiltered !== "") {
            
                // apply rule for StageQuestionnaireSection
                context.stageQuestionnaireSection = await getOrCreateOrUpdateStageQuestionnaireSection(
                    stageQuestionnaireSectionIDFiltered,
                    stageQuestionnaireSectionNameFiltered,
                    context.stage,
                    stageQuestionnaireSections
                )

                stageQuestionnaireSections.set(context.stageQuestionnaireSection.id, context.stageQuestionnaireSection)
            } else {
                context.stageQuestionnaireSection = undefined
            }
        
            // apply rule for StageQuestionnaire
            context.stageQuestionnaire = await getOrCreateOrUpdateStageQuestionnaire(
                stageQuestionnaireIDFiltered,
                questionNameFiltered,
                context.stageQuestionnaireSection,
                context.stage,
                stageQuestionnaires
            )

            stageQuestionnaires.set(context.stageQuestionnaire.id, context.stageQuestionnaire)
                
            const identifiedRow: IdentifiedRow = {
                phaseID: context.phase?.id.toString(),
                trackID: context.track?.id.toString(),
                stageID: context.stage?.id.toString(),
                questionID: context.question?.id.toString(),
                labelID: context.label?.id.toString(),
                questionLabelID: context.questionLabel?.id.toString(),
                questionRowColumnID: context.questionRowColumn?.id.toString(),
                stageQuestionnaireSectionID: context.stageQuestionnaireSection?.id.toString(),
                stageQuestionnaireID: context.stageQuestionnaire?.id.toString()
            }

            identifiedRows.push(identifiedRow)
        }

        const outputRows: ExternalRow[] = []

        identifiedRows.forEach(row => {
            const phase = phases.get(row.phaseID)
            const track = tracks.get(row.trackID)
            const stage = stages.get(row.stageID)
    
            const question = questions.get(row.questionID)
            const stageQuestionnaire = stageQuestionnaires.get(row.stageQuestionnaireID)
          
            if (phase && track && stage && question && stageQuestionnaire) {
                const questionExamples = question.examples === null ? undefined : question.examples
                const questionExample = questionExamples === undefined ? undefined : (questionExamples.length > 0 ? (questionExamples[0] === null ? undefined : questionExamples[0]) : undefined)
                const questionRowColumn = row.questionRowColumnID !== undefined ? questionRowColumns.get(row.questionRowColumnID) : undefined
                const stageQuestionnaireSection = row.stageQuestionnaireSectionID !== undefined ? stageQuestionnaireSections.get(row.stageQuestionnaireSectionID) : undefined
                const label = row.labelID !== undefined && row.questionLabelID !== undefined ? labels.get(row.labelID) : undefined
                
                const externalRow: ExternalRow = {
                    phaseName: phase.name,
                    trackName: track.name,
                    stageName: stage.name,
                    stageQuestionnaireSectionName: stageQuestionnaireSection?.name,
                    questionName: question.name,
                    questionStatement: question.statement === null ? undefined : question.statement,
                    questionLabelName: label?.name,
                    questionGuidingBeforeText: question.guidingBeforeText === null ? undefined : question.guidingBeforeText,
                    questionAnswerPlaceholder: question.placeholder === null ? undefined : question.placeholder,
                    questionExamples: questionExample,
                    answerDataType: question.answerDataType,
                    answerOptionName: questionRowColumn?.name,
                    answerOptionType: questionRowColumn?.answerDataType,
                    phaseID: row.phaseID,
                    trackID: row.trackID,
                    stageID: row.stageID,
                    questionID: row.questionID,
                    labelID: row.labelID,
                    questionLabelID: row.questionLabelID,
                    questionRowColumnID: row.questionRowColumnID,
                    stageQuestionnaireSectionID: row.stageQuestionnaireSectionID,
                    stageQuestionnaireID: row.stageQuestionnaireID
                }
          
                outputRows.push(externalRow)
            }
        })

        const csvContent = Papa.unparse(outputRows)
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
        saveAs(blob, 'updated.csv')
    }

    function getPhaseByName(name: string, phases: Map<string, Phase>): Phase|undefined {
        return Array.from(phases.values()).find(phase => phase.name === name)
    }

    function getTrackByName(name: string, tracks: Map<string, Track>): Track|undefined {
        return Array.from(tracks.values()).find(track => track.name === name)
    }

    function getStageByName(name: string, stages: Map<string, Stage>): Stage|undefined {
        return Array.from(stages.values()).find(stage => stage.name === name)
    }

    function getQuestionByName(name: string, questions: Map<string, Question>): Question|undefined {
        return Array.from(questions.values()).find(question => question.name === name)
    }

    function getLabelByName(name: string, labels: Map<string, Label>): Label|undefined {
        return Array.from(labels.values()).find(label => label.name === name)
    }

    function getQuestionLabelByName(name: string, questionLabels: Map<string, QuestionLabel>): QuestionLabel|undefined {
        return Array.from(questionLabels.values()).find(questionLabel => questionLabel.name === name)
    }

    function getQuestionRowColumnByName(name: string, questionRowColumns: Map<string, QuestionRowColumn>): QuestionRowColumn|undefined {
        return Array.from(questionRowColumns.values()).find(questionRowColumn => questionRowColumn.name === name)
    }

    function getStageQuestionnaireSectionByName(name: string, stageQuestionnaireSections: Map<string, StageQuestionnaireSection>): StageQuestionnaireSection|undefined {
        return Array.from(stageQuestionnaireSections.values()).find(stageQuestionnaireSection => stageQuestionnaireSection.name === name)
    }

    function getStageQuestionnaireByName(name: string, stageQuestionnaires: Map<string, StageQuestionnaire>): StageQuestionnaire|undefined {
        return Array.from(stageQuestionnaires.values()).find(stageQuestionnaire => stageQuestionnaire.name === name)
    }

    function getPhaseById(id: string, phases: Map<string, Phase>): Phase|undefined {
        return Array.from(phases.values()).find(phase => phase.id === id)
    }

    function getTrackById(id: string, tracks: Map<string, Track>): Track|undefined {
        return Array.from(tracks.values()).find(track => track.id === id)
    }

    function getStageById(id: string, stages: Map<string, Stage>): Stage|undefined {
        return Array.from(stages.values()).find(stage => stage.id === id)
    }

    function getQuestionById(id: string, questions: Map<string, Question>): Question|undefined {
        return Array.from(questions.values()).find(question => question.id === id)
    }

    function getLabelById(id: string, labels: Map<string, Label>): Label|undefined {
        return Array.from(labels.values()).find(label => label.id === id)
    }

    function getQuestionLabelById(id: string, questionLabels: Map<string, QuestionLabel>): QuestionLabel|undefined {
        return Array.from(questionLabels.values()).find(questionLabel => questionLabel.id === id)
    }

    function getQuestionRowColumnById(id: string, questionRowColumns: Map<string, QuestionRowColumn>): QuestionRowColumn|undefined {
        return Array.from(questionRowColumns.values()).find(questionRowColumn => questionRowColumn.id === id)
    }

    function getStageQuestionnaireSectionById(id: string, stageQuestionnaireSections: Map<string, StageQuestionnaireSection>): StageQuestionnaireSection|undefined {
        return Array.from(stageQuestionnaireSections.values()).find(stageQuestionnaireSection => stageQuestionnaireSection.id === id)
    }

    function getStageQuestionnaireById(id: string, stageQuestionnaires: Map<string, StageQuestionnaire>): StageQuestionnaire|undefined {
        return Array.from(stageQuestionnaires.values()).find(stageQuestionnaire => stageQuestionnaire.id === id)
    }

    function getPhaseMaxOrder(phases: Map<string, Phase>): number {
        return Array.from(phases.values()).reduce((max, phase) => phase.order > max ? phase.order : max, 0)
    }

    function getStageMaxOrder(stages: Map<string, Stage>): number {
        return Array.from(stages.values()).reduce((max, stage) => stage.order > max ? stage.order : max, 0)
    }

    function getQuestionRowColumnMaxOrder(questionRowColumns: Map<string, QuestionRowColumn>): number {
        return Array.from(questionRowColumns.values()).reduce((max, questionRowColumn) => questionRowColumn.order > max ? questionRowColumn.order : max, 0)
    }

    function getStageQuestionnaireSectionMaxOrder(stageQuestionnaireSections: Map<string, StageQuestionnaireSection>): number {
        return Array.from(stageQuestionnaireSections.values()).reduce((max, stageQuestionnaireSection) => stageQuestionnaireSection.order > max ? stageQuestionnaireSection.order : max, 0)
    }

    function getStageQuestionnaireMaxOrder(stageQuestionnaires: Map<string, StageQuestionnaire>): number {
        return Array.from(stageQuestionnaires.values()).reduce((max, stageQuestionnaire) => stageQuestionnaire.order > max ? stageQuestionnaire.order : max, 0)
    }

    async function getOrCreateOrUpdatePhase(id: string|undefined, name: string, phases: Map<string, Phase>): Promise<Phase> {
        const phaseQueriedByIdLocal = id !== undefined && id !== "" ? getPhaseById(id, phases) : undefined
        const phaseQueriedByIdRemote = phaseQueriedByIdLocal === undefined && id !== undefined && id !== "" ? await getPhaseApi(id) : phaseQueriedByIdLocal
        const phaseQueriedByIdNameLocal = name !== undefined && name !== "" && phaseQueriedByIdRemote !== undefined ? phaseQueriedByIdRemote : getPhaseByName(name, phases)
        const phaseQueriedByIdNameRemote = name !== undefined && name !== "" && phaseQueriedByIdNameLocal === undefined ? await getPhaseByNameApi(name) : phaseQueriedByIdNameLocal
        const order = getPhaseMaxOrder(phases) + 1
        const phaseCreated = phaseQueriedByIdNameRemote !== undefined ? phaseQueriedByIdNameRemote : await createPhase(name, order)
        const phaseUpdated = phaseCreated && phaseCreated.name !== name ? await updatePhase(phaseCreated.id, name) : phaseCreated
        return phaseUpdated
    }

    async function getOrCreateOrUpdateTrack(id: string|undefined, name: string, tracks: Map<string, Track>): Promise<Track> {
        const trackQueriedByIdLocal = id !== undefined && id !== "" ? getTrackById(id, tracks) : undefined
        const trackQueriedByIdRemote = trackQueriedByIdLocal === undefined && id !== undefined && id !== "" ? await getTrackApi(id) : trackQueriedByIdLocal
        const trackQueriedByIdNameLocal = name !== undefined && name !== "" && trackQueriedByIdRemote !== undefined ? trackQueriedByIdRemote : getTrackByName(name, tracks)
        const trackQueriedByIdNameRemote = name !== undefined && name !== "" && trackQueriedByIdNameLocal === undefined ? await getTrackByNameApi(name) : trackQueriedByIdNameLocal
        const trackCreated = trackQueriedByIdNameRemote !== undefined ? trackQueriedByIdNameRemote : await createTrack(name)
        const trackUpdated = trackCreated && trackCreated.name !== name ? await updateTrack(trackCreated.id, name) : trackCreated
        return trackUpdated
    }

    async function getOrCreateOrUpdateStage(id: string|undefined, name: string, phase: Phase, track: Track, stages: Map<string, Stage>): Promise<Stage> {
        const stageQueriedByIdLocal = id !== undefined && id !== "" ? getStageById(id, stages) : undefined
        const stageQueriedByIdRemote = stageQueriedByIdLocal === undefined && id !== undefined && id !== "" ? await getStageApi(id) : stageQueriedByIdLocal
        const stageQueriedByIdNameLocal = name !== undefined && name !== "" && stageQueriedByIdRemote !== undefined ? stageQueriedByIdRemote : getStageByName(name, stages)
        const stageQueriedByIdNameRemote = name !== undefined && name !== "" && stageQueriedByIdNameLocal === undefined ? await getStageByNameApi(name) : stageQueriedByIdNameLocal
        const order = getStageMaxOrder(stages) + 1
        const stageCreated = stageQueriedByIdNameRemote !== undefined ? stageQueriedByIdNameRemote : await createStage(name, phase, track, order)
        const stageUpdated = stageCreated && stageCreated.name !== name ? await updateStage(stageCreated.id, name) : stageCreated
        return stageUpdated
    }

    async function getOrCreateOrUpdateLabel(id: string|undefined, name: string, labels: Map<string, Label>): Promise<Label> {
        const labelQueriedByIdLocal = id !== undefined && id !== "" ? getLabelById(id, labels) : undefined
        const labelQueriedByIdRemote = labelQueriedByIdLocal === undefined && id !== undefined && id !== "" ? await getLabelApi(id) : labelQueriedByIdLocal
        const labelQueriedByIdNameLocal = name !== undefined && name !== "" && labelQueriedByIdRemote !== undefined ? labelQueriedByIdRemote : getLabelByName(name, labels)
        const labelQueriedByIdNameRemote = name !== undefined && name !== "" && labelQueriedByIdNameLocal === undefined ? await getLabelByNameApi(name) : labelQueriedByIdNameLocal
        const labelCreated = labelQueriedByIdNameRemote !== undefined ? labelQueriedByIdNameRemote : await createLabel(name)
        const labelUpdated = labelCreated && labelCreated.name !== name ? await updateLabel(labelCreated.id, name) : labelCreated
        return labelUpdated
    }

    async function getOrCreateOrUpdateQuestionLabel(id: string|undefined, name: string, question: Question, questionLabels: Map<string, QuestionLabel>): Promise<QuestionLabel> {
        const questionLabelQueriedByIdLocal = id !== undefined && id !== "" ? getQuestionLabelById(id, questionLabels) : undefined
        const questionLabelQueriedByIdRemote = questionLabelQueriedByIdLocal === undefined && id !== undefined && id !== "" ? await getQuestionLabelApi(id) : questionLabelQueriedByIdLocal
        const questionLabelQueriedByIdNameLocal = name !== undefined && name !== "" && questionLabelQueriedByIdRemote !== undefined ? questionLabelQueriedByIdRemote : getQuestionLabelByName(name, questionLabels)
        const questionLabelQueriedByIdNameRemote = name !== undefined && name !== "" && questionLabelQueriedByIdNameLocal === undefined ? await getQuestionLabelByNameApi(name) : questionLabelQueriedByIdNameLocal
        const questionLabelCreated = questionLabelQueriedByIdNameRemote !== undefined ? questionLabelQueriedByIdNameRemote : await createQuestionLabel(name, question)
        const questionLabelUpdated = questionLabelCreated && questionLabelCreated.name !== name ? await updateQuestionLabel(questionLabelCreated.id, name) : questionLabelCreated
        return questionLabelUpdated
    }

    async function getOrCreateOrUpdateQuestion(id: string|undefined, name: string, statement: string|undefined, placeholder: string|undefined, examples: string|undefined, answerDataType: AnswerDataType, guidingBeforeText: string|undefined, questions: Map<string, Question>): Promise<Question> {
        const questionQueriedByIdLocal = id !== undefined && id !== "" ? getQuestionById(id, questions) : undefined
        const questionQueriedByIdRemote = questionQueriedByIdLocal === undefined && id !== undefined && id !== "" ? await getQuestionApi(id) : questionQueriedByIdLocal
        const questionQueriedByIdNameLocal = name !== undefined && name !== "" && questionQueriedByIdRemote !== undefined ? questionQueriedByIdRemote : getQuestionByName(name, questions)
        const questionQueriedByIdNameRemote = name !== undefined && name !== "" && questionQueriedByIdNameLocal === undefined ? await getQuestionByNameApi(name) : questionQueriedByIdNameLocal
        const questionCreated = questionQueriedByIdNameRemote !== undefined ? questionQueriedByIdNameRemote : await createQuestion(name, statement, placeholder, examples !== undefined ? [examples] : [], answerDataType, guidingBeforeText)
        const questionUpdated = questionCreated && (questionCreated.name !== name || questionCreated.statement !== statement || questionCreated.placeholder !== placeholder || (questionCreated.examples !== null && questionCreated.examples !== undefined && questionCreated.examples.length > 0 && questionCreated.examples[0] !== examples) || questionCreated.answerDataType !== answerDataType || questionCreated.guidingBeforeText !== guidingBeforeText) ? await updateQuestion(questionCreated.id, name, statement, placeholder, examples !== undefined ? [examples] : [], answerDataType, guidingBeforeText) : questionCreated
        return questionUpdated
    }

    async function getOrCreateOrUpdateStageQuestionnaireSection(id: string|undefined, name: string, stage: Stage, stageQuestionnaireSections: Map<string, StageQuestionnaireSection>): Promise<StageQuestionnaireSection> {
        const stageQuestionnaireSectionQueriedByIdLocal = id !== undefined && id !== "" ? getStageQuestionnaireSectionById(id, stageQuestionnaireSections) : undefined
        const stageQuestionnaireSectionQueriedByIdRemote = stageQuestionnaireSectionQueriedByIdLocal === undefined && id !== undefined && id !== "" ? await getStageQuestionnaireSectionApi(id) : stageQuestionnaireSectionQueriedByIdLocal
        const stageQuestionnaireSectionQueriedByIdNameLocal = name !== undefined && name !== "" && stageQuestionnaireSectionQueriedByIdRemote !== undefined ? stageQuestionnaireSectionQueriedByIdRemote : getStageQuestionnaireSectionByName(name, stageQuestionnaireSections)
        const stageQuestionnaireSectionQueriedByIdNameRemote = name !== undefined && name !== "" && stageQuestionnaireSectionQueriedByIdNameLocal === undefined ? await getStageQuestionnaireSectionByNameApi(name) : stageQuestionnaireSectionQueriedByIdNameLocal
        const order = getStageQuestionnaireSectionMaxOrder(stageQuestionnaireSections) + 1
        const stageQuestionnaireSectionCreated = stageQuestionnaireSectionQueriedByIdNameRemote !== undefined ? stageQuestionnaireSectionQueriedByIdNameRemote : await createStageQuestionnaireSection(name, stage, order)
        const stageQuestionnaireSectionUpdated = stageQuestionnaireSectionCreated && stageQuestionnaireSectionCreated.name !== name ? await updateStageQuestionnaireSection(stageQuestionnaireSectionCreated.id, name) : stageQuestionnaireSectionCreated
        return stageQuestionnaireSectionUpdated
    }

    async function getOrCreateOrUpdateQuestionRowColumn(id: string|undefined, name: string, question: Question, answerDataType: AnswerDataType, questionRowColumns: Map<string, QuestionRowColumn>): Promise<QuestionRowColumn> {
        const questionRowColumnQueriedByIdLocal = id !== undefined && id !== "" ? getQuestionRowColumnById(id, questionRowColumns) : undefined
        const questionRowColumnQueriedByIdRemote = questionRowColumnQueriedByIdLocal === undefined && id !== undefined && id !== "" ? await getQuestionRowColumnApi(id) : questionRowColumnQueriedByIdLocal
        const questionRowColumnQueriedByIdNameLocal = name !== undefined && name !== "" && questionRowColumnQueriedByIdRemote !== undefined ? questionRowColumnQueriedByIdRemote : getQuestionRowColumnByName(name, questionRowColumns)
        const questionRowColumnQueriedByIdNameRemote = name !== undefined && name !== "" && questionRowColumnQueriedByIdNameLocal === undefined ? await getQuestionRowColumnByNameApi(name) : questionRowColumnQueriedByIdNameLocal
        const order = getQuestionRowColumnMaxOrder(questionRowColumns) + 1
        const questionRowColumnCreated = questionRowColumnQueriedByIdNameRemote !== undefined ? questionRowColumnQueriedByIdNameRemote : await createQuestionRowColumn(name, question, answerDataType, order)
        const questionRowColumnUpdated = questionRowColumnCreated && questionRowColumnCreated.name !== name ? await updateQuestionRowColumn(questionRowColumnCreated.id, name, answerDataType) : questionRowColumnCreated
        return questionRowColumnUpdated
    }

    async function getOrCreateOrUpdateStageQuestionnaire(id: string|undefined, name: string, stageQuestionnaireSection: StageQuestionnaireSection|undefined, stage: Stage, stageQuestionnaires: Map<string, StageQuestionnaire>): Promise<StageQuestionnaire> {
        const stageQuestionnaireQueriedByIdLocal = id !== undefined && id !== "" ? getStageQuestionnaireById(id, stageQuestionnaires) : undefined
        const stageQuestionnaireQueriedByIdRemote = stageQuestionnaireQueriedByIdLocal === undefined && id !== undefined && id !== "" ? await getStageQuestionnaireApi(id) : stageQuestionnaireQueriedByIdLocal
        const stageQuestionnaireQueriedByIdNameLocal = name !== undefined && name !== "" && stageQuestionnaireQueriedByIdRemote !== undefined ? stageQuestionnaireQueriedByIdRemote : getStageQuestionnaireByName(name, stageQuestionnaires)
        const stageQuestionnaireQueriedByIdNameRemote = name !== undefined && name !== "" && stageQuestionnaireQueriedByIdNameLocal === undefined ? await getStageQuestionnaireByNameApi(name) : stageQuestionnaireQueriedByIdNameLocal
        const order = getStageQuestionnaireMaxOrder(stageQuestionnaires) + 1
        const stageQuestionnaireCreated = stageQuestionnaireQueriedByIdNameRemote !== undefined ? stageQuestionnaireQueriedByIdNameRemote : await createStageQuestionnaire(name, stageQuestionnaireSection, stage, order)
        const stageQuestionnaireUpdated = stageQuestionnaireCreated && stageQuestionnaireCreated.name !== name ? await updateStageQuestionnaire(stageQuestionnaireCreated.id, name) : stageQuestionnaireCreated
        return stageQuestionnaireUpdated
    }

    async function getPhaseByNameApi(name: string): Promise<Phase|undefined> {
        const phases = await listPhasesByNameApi(name)
        return phases !== undefined && phases.length > 0 ? phases[0] : undefined
    }

    async function getTrackByNameApi(name: string): Promise<Track|undefined> {
        const tracks = await listTracksByNameApi(name)
        return tracks !== undefined && tracks.length > 0 ? tracks[0] : undefined
    }

    async function getStageByNameApi(name: string): Promise<Stage|undefined> {
        const stages = await listStagesByNameApi(name)
        return stages !== undefined && stages.length > 0 ? stages[0] : undefined
    }

    async function getStageQuestionnaireSectionByNameApi(name: string): Promise<StageQuestionnaireSection|undefined> {
        const stageQuestionnaireSections = await listStageQuestionnaireSectionsByNameApi(name)
        return stageQuestionnaireSections !== undefined && stageQuestionnaireSections.length > 0 ? stageQuestionnaireSections[0] : undefined
    }

    async function getStageQuestionnaireByNameApi(name: string): Promise<StageQuestionnaire|undefined> {
        const stageQuestionnaires = await listStageQuestionnairesByNameApi(name)
        return stageQuestionnaires !== undefined && stageQuestionnaires.length > 0 ? stageQuestionnaires[0] : undefined
    }

    async function getQuestionRowColumnByNameApi(name: string): Promise<QuestionRowColumn|undefined> {
        const questionRowColumns = await listQuestionRowColumnsByNameApi(name)
        return questionRowColumns !== undefined && questionRowColumns.length > 0 ? questionRowColumns[0] : undefined
    }

    async function getQuestionLabelByNameApi(name: string): Promise<QuestionLabel|undefined> {
        const questionLabels = await listQuestionLabelsByNameApi(name)
        return questionLabels !== undefined && questionLabels.length > 0 ? questionLabels[0] : undefined
    }

    async function getQuestionByNameApi(name: string): Promise<Question|undefined> {
        const questions = await listQuestionsByNameApi(name)
        return questions !== undefined && questions.length > 0 ? questions[0] : undefined
    }

    async function getLabelByNameApi(name: string): Promise<Label|undefined> {
        const labels = await listLabelsByNameApi(name)
        return labels !== undefined && labels.length > 0 ? labels[0] : undefined
    }

    async function createPhase(name: string, order: number) {
        const instance = getEmptyPhaseInstance()
        instance.addString('name', name)
        instance.addInt('order', order)
        const input = getCreatePhaseInput(instance)
        const phase = await createPhaseApi(input)
        console.log('create phase', phase)
        return phase
    }
    
    async function createTrack(name: string) {
        const instance = getEmptyTrackInstance()
        instance.addString('name', name)
        const input = getCreateTrackInput(instance)
        const track = await createTrackApi(input)
        console.log('create track', track)
        return track
    }
    
    async function createStage(name: string, phase: Phase, track: Track, order: number) {
        const instance = getEmptyStageInstance()
        instance.addString('name', name)
        instance.addInt('order', order)
        instance.addEntityRelation('phase', { entityName: EntityName.Phase, id: phase.id })
        instance.addEntityRelation('track', { entityName: EntityName.Track, id: track.id })
        const input = getCreateStageInput(instance)
        const stage = await createStageApi(input)
        console.log('create stage', stage)
        return stage
    }
    
    async function createQuestionLabel(name: string, question: Question) {
        const instance = getEmptyQuestionLabelInstance()
        instance.addString('name', name)
        instance.addEntityRelation('question', { entityName: EntityName.Question, id: question.id })
        const input = getCreateQuestionLabelInput(instance)
        const questionLabel = await createQuestionLabelApi(input)
        console.log('create question label', questionLabel)
        return questionLabel
    }
    
    async function createQuestion(name: string, statement: string|undefined, placeholder: string|undefined, examples: string[], answerDataType: AnswerDataType, guidingBeforeText: string|undefined) {
        const instance = getEmptyQuestionInstance()
        instance.addString('name', name)
        instance.addString('statement', statement === null ? undefined: statement)
        instance.addString('placeholder', placeholder === null ? undefined: placeholder)
        instance.addStringArray('examples', examples)
        instance.addAnswerDataType('answerDataType', answerDataType)
        instance.addString('guidingBeforeText', guidingBeforeText === null ? undefined: guidingBeforeText)
        const input = getCreateQuestionInput(instance)
        const question = await createQuestionApi(input)
        console.log('create question', question)
        return question
    }

    async function createLabel(name: string) {
        const instance = getEmptyLabelInstance()
        instance.addString('name', name)
        const input = getCreateLabelInput(instance)
        const label = await createLabelApi(input)
        console.log('create label', label)
        return label
    }
    
    async function createStageQuestionnaireSection(name: string, stage: Stage, order: number) {
        const instance = getEmptyStageQuestionnaireSectionInstance()
        instance.addString('name', name)
        instance.addInt('order', order)
        instance.addEntityRelation('stage', { entityName: EntityName.Stage, id: stage.id })
        const input = getCreateStageQuestionnaireSectionInput(instance)
        const stageQuestionnaireSection = await createStageQuestionnaireSectionApi(input)
        console.log('create stage questionnaire section', stageQuestionnaireSection)
        return stageQuestionnaireSection
    }
    
    async function createStageQuestionnaire(name: string, stageQuestionnaireSection: StageQuestionnaireSection|undefined, stage: Stage, order: number) {
        const instance = getEmptyStageQuestionnaireInstance()
        instance.addString('name', name)
        instance.addInt('order', order)
        
        if (stageQuestionnaireSection !== undefined) {
            instance.addEntityRelation('stageQuestionnaireSection', { entityName: EntityName.StageQuestionnaireSection, id: stageQuestionnaireSection.id })
        } else {
            instance.addEntityRelation('stage', { entityName: EntityName.Stage, id: stage.id })
        }
        
        const input = getCreateStageQuestionnaireInput(instance)
        const stageQuestionnaire = await createStageQuestionnaireApi(input)
        console.log('create stage questionnaire', stageQuestionnaire)
        return stageQuestionnaire
    }
    
    async function createQuestionRowColumn(name: string, question: Question, answerDataType: AnswerDataType, order: number) {
        const instance = getEmptyQuestionRowColumnInstance()
        instance.addString('name', name)
        instance.addInt('order', order)
        instance.addEntityRelation('question', { entityName: EntityName.Question, id: question.id })
        instance.addAnswerDataType('answerDataType', answerDataType)
        const input = getCreateQuestionRowColumnInput(instance)
        const questionRowColumn = await createQuestionRowColumnApi(input)
        console.log('create question row column', questionRowColumn)
        return questionRowColumn
    }
    
    async function updatePhase(id: string, name: string) {
        const instance = getEmptyPhaseInstance()
        instance.setId('id', id)
        instance.addString('name', name)
        const input = getUpdatePhaseInput(instance)
        const phase = await updatePhaseApi(input)
        console.log('update phase', phase)
        return phase
    }
    
    async function updateTrack(id: string, name: string) {
        const instance = getEmptyTrackInstance()
        instance.setId('id', id)
        instance.addString('name', name)
        const input = getUpdateTrackInput(instance)
        const track = await updateTrackApi(input)
        console.log('update track', track)
        return track
    }
    
    async function updateStage(id: string, name: string) {
        const instance = getEmptyStageInstance()
        instance.setId('id', id)
        instance.addString('name', name)
        const input = getUpdateStageInput(instance)
        const stage = await updateStageApi(input)
        console.log('update stage', stage)
        return stage
    }
    
    async function updateQuestionLabel(id: string, name: string) {
        const instance = getEmptyQuestionLabelInstance()
        instance.setId('id', id)
        instance.addString('name', name)
        const input = getUpdateQuestionLabelInput(instance)
        const questionLabel = await updateQuestionLabelApi(input)
        console.log('update question label', questionLabel)
        return questionLabel
    }
    
    async function updateQuestion(id: string, name: string, statement: string|undefined, placeholder: string|undefined, examples: string[], answerDataType: AnswerDataType, guidingBeforeText: string|undefined) {
        const instance = getEmptyQuestionInstance()
        instance.setId('id', id)
        instance.addString('name', name)
        instance.addString('statement', statement === null ? undefined : statement)
        instance.addString('placeholder', placeholder === null ? undefined : placeholder)
        instance.addStringArray('examples', examples)
        instance.addAnswerDataType('answerDataType', answerDataType)
        instance.addString('guidingBeforeText', guidingBeforeText === null ? undefined : guidingBeforeText)
        const input = getUpdateQuestionInput(instance)
        const question = await updateQuestionApi(input)
        console.log('update question', question)
        return question
    }
    
    async function updateLabel(id: string, name: string) {
        const instance = getEmptyLabelInstance()
        instance.setId('id', id)
        instance.addString('name', name)
        const input = getUpdateLabelInput(instance)
        const label = await updateLabelApi(input)
        console.log('update label', label)
        return label
    }
    
    async function updateStageQuestionnaireSection(id: string, name: string) {
        const instance = getEmptyStageQuestionnaireSectionInstance()
        instance.setId('id', id)
        instance.addString('name', name)
        const input = getUpdateStageQuestionnaireSectionInput(instance)
        const stageQuestionnaireSection = await updateStageQuestionnaireSectionApi(input)
        console.log('update stage questionnaire section', stageQuestionnaireSection)
        return stageQuestionnaireSection
    }
    
    async function updateStageQuestionnaire(id: string, name: string) {
        const instance = getEmptyStageQuestionnaireInstance()
        instance.setId('id', id)
        instance.addString('name', name)
        const input = getUpdateStageQuestionnaireInput(instance)
        const stageQuestionnaire = await updateStageQuestionnaireApi(input)
        console.log('update stage questionnaire', stageQuestionnaire)
        return stageQuestionnaire
    }
    
    async function updateQuestionRowColumn(id: string, name: string, answerDataType: AnswerDataType) {
        const instance = getEmptyQuestionRowColumnInstance()
        instance.setId('id', id)
        instance.addString('name', name)
        instance.addAnswerDataType('answerDataType', answerDataType)
        const input = getUpdateQuestionRowColumnInput(instance)
        const questionRowColumn = await updateQuestionRowColumnApi(input)
        console.log('update question row column', questionRowColumn)
        return questionRowColumn
    }

    return (
        <div>
            <h2 className="text-success">Data Upload CSV</h2>
            <p><strong>Upload the Google spreadsheet (exported as .csv)</strong></p>
            <p>Do not forget to check that it has the IDs in the last columns!</p>
            <p>After clicking <strong>Choose file</strong>, please <span className="text-danger">wait</span> for a new version to download!</p>
            <p>Then, replace all rows with the new ones in the Google spreadsheet.</p>
            <hr />
            <input type="file" accept=".csv" onChange={handleFileUpload} />
        </div>
    )
}

export default DataImporterCsv