export const Team: React.FC = () => {
    return (
        <div>
            <div className="py-4 py-xs-8 py-sm-12 py-md-16 py-lg-24 py-xl-32 py-xxl-32 overflow-hidden">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <h1 className="display-1 text-center text-primary">
                                team
                            </h1>
                            <h2 className="mb-10 text-center">
                                A melting pot: business, clinical, design, tech, and a bit of magic too
                            </h2>
                            <p className="lead mb-10 text-center">
                                Behind our software lies an innovative team with diverse and complementary skills. Together, we aim at making medical device certification by building an intuitive and easy to follow platform and having fun while enabling amazing journeys for the medical device manufacturers.
                            </p>
                            <div className="row g-8 pt-8 pb-8">
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                                    <img alt="Alberto González Olmos" className="img-fluid rounded shadow" src="../images/team/alberto.png" />
                                    <div className="mt-5">
                                        <a href="#" className="h5 d-block mb-1">Alberto González Olmos</a>
                                        <p className="text-muted text-sm">Ph.D. fellow intern in data science; ongoing Ph.D. in machine learning at Aarhus University</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                                    <img alt="Bartosz Ziolkowski" className="img-fluid rounded shadow" src="../images/team/bart.png" />
                                    <div className="mt-5">
                                        <a href="#" className="h5 d-block mb-1">Bartosz Ziolkowski</a>
                                        <p className="text-muted text-sm">Back-end development intern; ongoing M.Sc. in computer science and engineering from the Technical University of Denmark</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                                    <img alt="Greta Tirūnaitė" className="img-fluid rounded shadow" src="../images/team/greta.png" />
                                    <div className="mt-5">
                                        <a href="#" className="h5 d-block mb-1">Greta Tirūnaitė</a>
                                        <p className="text-muted text-sm">Business development intern; all M.Sc. courses in international business from Aalborg University</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                                    <img alt="Hugo Sant'Ana Lima" className="img-fluid rounded shadow" src="../images/team/hugo.png" />
                                    <div className="mt-5">
                                        <a href="#" className="h5 d-block mb-1">Hugo Sant'Ana Lima</a>
                                        <p className="text-muted text-sm">User experience designer intern; ongoing M.A. in experience design at Aalborg University</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                                    <img alt="Iraklis Chrysikopoulos" className="img-fluid rounded shadow" src="../images/team/iraklis.png" />
                                    <div className="mt-5">
                                        <a href="#" className="h5 d-block mb-1">Iraklis Chrysikopoulos</a>
                                        <p className="text-muted text-sm">Data scientist intern; M.Sc. in wind energy from the Technical University of Denmark</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                                    <img alt="Kyle Papa" className="img-fluid rounded shadow" src="../images/team/kyle.png" />
                                    <div className="mt-5">
                                        <a href="#" className="h5 d-block mb-1">Kyle Papa</a>
                                        <p className="text-muted text-sm">Project coordinator; ongoing M.Sc. in medical market access at Aalborg University</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                                    <img alt="Mariana Raposo" className="img-fluid rounded shadow" src="../images/team/mariana.png" />
                                    <div className="mt-5">
                                        <a href="#" className="h5 d-block mb-1">Mariana Raposo</a>
                                        <p className="text-muted text-sm">User experience designer intern; M.F.A. in art and design from the Royal Academy of Fine Arts and the Royal Conservatory of Ghent</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                                    <img alt="Morten Togo Sørensen" className="img-fluid rounded shadow" src="../images/team/morten.png" />
                                    <div className="mt-5">
                                        <a href="#" className="h5 d-block mb-1">Morten Togo Sørensen</a>
                                        <p className="text-muted text-sm">Co-founder, chief executive officer; M.D. in medicine from the University of Copenhagen</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                                    <img alt="Nikolaos Giannoulis" className="img-fluid rounded shadow" src="../images/team/nikos.png" />
                                    <div className="mt-5">
                                        <a href="#" className="h5 d-block mb-1">Nikolaos Giannoulis</a>
                                        <p className="text-muted text-sm">Data scientist intern; M.Sc. in bio- and medical physics from the University of Copenhagen</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                                    <img alt="Sravanthi Kurri" className="img-fluid rounded shadow" src="../images/team/sravanthi.png" />
                                    <div className="mt-5">
                                        <a href="#" className="h5 d-block mb-1">Sravanthi Kurri</a>
                                        <p className="text-muted text-sm">User experience designer; Pharm.D. in pharmacy from the Acharya Nagarjuna University</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                                    <img alt="Vlad Manea" className="img-fluid rounded shadow" src="../images/team/vlad.png" />
                                    <div className="mt-5">
                                        <a href="#" className="h5 d-block mb-1">Vlad Manea</a>
                                        <p className="text-muted text-sm">Co-founder, technical advisor; Ph.D. in computer science from the University of Copenhagen</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-2 text-center">
                                <a href="/company/contact" className="btn btn-lg btn-primary shadow-sm mx-2 px-lg-8">
                                    Meet Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-n1 transform translate-y-1/4">
                    <div className="col-lg-11 col-xl-9 mx-auto">
                        <div className="row g-5">
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md6.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md4.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md3.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md5.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md2.jpg" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
