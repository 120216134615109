import React from 'react'
import { EntityName } from '../../model/EntityName'
import { GenericRemover } from '../mixins/GenericRemover'

interface EntityRemoverProps {
    entityName: EntityName
    id: string
    discardEntityRemover: (entityName: EntityName, id: string) => void
}

export const EntityRemover: React.FC<EntityRemoverProps> = ({ entityName, id, discardEntityRemover }) => {
    function cancel(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault()
        discardEntityRemover(entityName, id)
    }

    return (
        <div className="card">
            <div className="card-body">
                <GenericRemover entityName={entityName} id={id} discardEntityRemover={discardEntityRemover} />
            </div>
            <div className="card-footer">
                <button className="btn btn-primary btn-sm" onClick={cancel}>Cancel</button>
            </div>
        </div>
    )
}