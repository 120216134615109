import React from 'react'
import '@aws-amplify/ui-react/styles.css'
import { AdminPanel } from '../admin/AdminPanel'
import { EditorPanel } from '../editor/EditorPanel'
import { UserPanel } from '../user/UserPanel'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from './Layout';
import { Home } from '../public/Home';
import { Team } from '../public/company/Team';
import { Careers } from '../public/company/Careers';
import { Contact } from '../public/company/Contact';
import { Login } from './Login';
import { RequireAuth } from './RequireAuth';
import { useEffect, useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Assess } from '../public/onboarding/Assess'
import { Terms } from '../public/legal/Terms'
import { Privacy } from '../public/legal/Privacy'
import { Cookies } from '../public/legal/Cookies'
import { ErrorNotFound } from '../public/errors/ErrorNotFound'

export const RoutesPanel: React.FC = () => {
    const [userGroups, setUserGroups] = useState<string[]>([])
    const { user } = useAuthenticator((context) => [context.user])
  
    useEffect(() => {
        const groupsSignIn: string[] = user?.getSignInUserSession()?.getAccessToken()?.payload["cognito:groups"] || []
        setUserGroups(groupsSignIn)
    }, [user])
  
    return (
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<Layout userGroups={userGroups} />}>
                <Route index element={<Home userGroups={userGroups} />} />
                <Route path="/company/team" element={<Team />} />
                <Route path="/company/careers" element={<Careers />} />
                <Route path="/company/contact" element={<Contact />} />
                <Route path="/legal/terms" element={<Terms />} />
                <Route path="/legal/privacy" element={<Privacy />} />
                <Route path="/legal/cookies" element={<Cookies />} />
                <Route path="/admin" element={userGroups.find(group => group === 'Admins') &&
                <RequireAuth>
                    <AdminPanel userGroups={userGroups} />
                </RequireAuth>
                } />
                <Route path="/editor" element={userGroups.find(group => group === 'Editors') &&
                <RequireAuth>
                    <EditorPanel userGroups={userGroups} />
                </RequireAuth>
                } />
                <Route path="/user" element={userGroups.find(group => group === 'Users') &&
                <RequireAuth>
                    <UserPanel userGroups={userGroups} />
                </RequireAuth>
                } />
                <Route path="/login" element={<Login />} />
                <Route path="/onboarding/assess" element={<Assess userGroups={userGroups} />} />
            </Route>
            <Route path="*" element={<ErrorNotFound />} />
            </Routes>
      </BrowserRouter>
    )
}