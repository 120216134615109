import React from "react";
import { useEffect, useState } from 'react';
import * as queries from '../../../graphql/queries';
import { API } from 'aws-amplify';
import * as graphQlApi from '../../../API';
import { LandingPanel } from "./LandingPanel";
import { QualificationPanel } from "./QualificationPanel";
import { ClassificationPanel } from "./ClassificationPanel";
import { QualifiedPanel } from "./QualifiedPanel";
import { ClassifiedPanel } from "./ClassifiedPanel";
import { ReportPanel } from "./ReportPanel";
import { OnboardingMenu } from "./OnboardingMenu";
import { getFirstQualificationQuestionId, getQualificationQuestionIds } from "./QualificationHelper";
import { getFirstClassificationExpressionId } from "./ClassificationHelper";

export enum OnboardingStage {
    Landing,
    Qualify,
    Qualified,
    Classify,
    Classified,
    Report
}

interface AssessProps {
    userGroups: string[]
}

export const Assess: React.FC<AssessProps> = (props) => {
    const [stage, setStage] = useState(OnboardingStage.Landing);
    const [previewOrganization, setPreviewOrganization] = useState<graphQlApi.PreviewOrganization|undefined>(undefined);
    const [previewProduct, setPreviewProduct] = useState<graphQlApi.PreviewProduct|undefined>(undefined);
    const [previewUser, setPreviewUser] = useState<graphQlApi.PreviewUser|undefined>(undefined);

    // Qualification
    const [qualificationExpressions, setQualificationExpressions] = useState<{[key: string]: graphQlApi.DeviceQualificationExpression}>({})
    const [qualificationQuestions, setQualificationQuestions] = useState<{[key: string]: graphQlApi.Question}>({})
    const [qualificationAnswers, setQualificationAnswers] = useState<{[key: string]: boolean}>({})
    const [lastSeenQualificationQuestionId, setLastSeenQualificationQuestionId] = useState<string|undefined>(undefined)
    
    // Classification
    const [classificationExpressions, setClassificationExpressions] = useState<{[key: string]: graphQlApi.DeviceClassificationExpression}>({})
    const [classificationQuestions, setClassificationQuestions] = useState<{[key: string]: graphQlApi.Question}>({})
    const [classificationAnswers, setClassificationAnswers] = useState<{[key: string]: boolean}>({})
    const [lastSeenClassificationExpressionId, setLastSeenClassificationExpressionId] = useState<string|undefined>(undefined)

    // Report
    const [showQualificationResult, setShowQualificationResult] = useState<boolean>(false)
    const [showClassificationResult, setShowClassificationResult] = useState<boolean>(false)

    useEffect(() => {
        downloadClassificationExpressions()
        downloadQualificationExpressions()
    }, [])

    useEffect(() => {
        if (stage === OnboardingStage.Qualify && lastSeenQualificationQuestionId === undefined) {
            const firstQualificationQuestionId = getFirstQualificationQuestionId(qualificationExpressions, true)
            setLastSeenQualificationQuestionId(firstQualificationQuestionId)
        }

    }, [stage, lastSeenQualificationQuestionId, qualificationExpressions])

    useEffect(() => {
        if (stage === OnboardingStage.Classify && lastSeenClassificationExpressionId === undefined) {
            const firstClassificationExpressionId = getFirstClassificationExpressionId(classificationExpressions, true)
            setLastSeenClassificationExpressionId(firstClassificationExpressionId)
        }
    }, [stage, lastSeenClassificationExpressionId, classificationExpressions])

    function setOrganization(organization: graphQlApi.PreviewOrganization|undefined) {
        setPreviewOrganization(organization)
    }

    function setProduct(product: graphQlApi.PreviewProduct|undefined) {
        setPreviewProduct(product)
    }

    function setUser(user: graphQlApi.PreviewUser|undefined) {
        setPreviewUser(user)
    }

    // Handlers of qualification.

    async function downloadQualificationExpressions() {
        const filter = {
            specialQuestionCategory: {
                eq: graphQlApi.SpecialQuestionCategory.EU_QUALIFICATION_MEDICAL_DEVICE
            }
        }

        var deviceQualificationExpressions = undefined
        
        try {
            deviceQualificationExpressions = await API.graphql({
                query: queries.listDeviceQualificationExpressions,
                variables: {
                    filter: filter,
                    limit: 1000000
                },
                authMode: 'AWS_IAM'
            }) as { data: {listDeviceQualificationExpressions: {items: graphQlApi.DeviceQualificationExpression[]}} }
        } catch (e) {
            deviceQualificationExpressions = e as { data: {listDeviceQualificationExpressions: {items: graphQlApi.DeviceQualificationExpression[]}} }
        }

        if (deviceQualificationExpressions) {
            const returnDeviceQualificationExpressions: {[key: string]: graphQlApi.DeviceQualificationExpression} = {}
            const returnQuestions: {[key: string]: graphQlApi.Question} = {}

            if (deviceQualificationExpressions.data.listDeviceQualificationExpressions && deviceQualificationExpressions.data.listDeviceQualificationExpressions.items) {
                for (const deviceQualificationExpression of deviceQualificationExpressions.data.listDeviceQualificationExpressions.items) {
                    returnDeviceQualificationExpressions[deviceQualificationExpression.id] = deviceQualificationExpression

                    const yesNoQuestionId = deviceQualificationExpression.deviceQualificationExpressionYesNoQuestionId

                    if (yesNoQuestionId !== null || yesNoQuestionId !== undefined) {
                        const yesNoQuestionIdExisting = yesNoQuestionId as string
                        const yesNoQuestion = deviceQualificationExpression.yesNoQuestion

                        if (yesNoQuestion !== null || yesNoQuestion !== undefined) {
                            const yesNoQuestionExisting = yesNoQuestion as graphQlApi.Question

                            if (yesNoQuestionExisting !== undefined && yesNoQuestionExisting !== null) {
                                returnQuestions[yesNoQuestionIdExisting] = yesNoQuestionExisting
                            }
                        }
                    }
                }

                setQualificationExpressions(returnDeviceQualificationExpressions)
                setQualificationQuestions(returnQuestions)
            } else {
                console.error('Could not obtain the qualification expressions', deviceQualificationExpressions)
                alert('Could not obtain the qualification expressions, likely due to an internet connection issue.')
            }
        }
    }

    // Handlers of classification.

    async function downloadClassificationExpressions() {
        const filter = {
            specialQuestionCategory: {
                eq: graphQlApi.SpecialQuestionCategory.EU_CLASSIFICATION
            }
        }

        var deviceClassificationExpressions = undefined

        try {
            deviceClassificationExpressions = await API.graphql({
                query: queries.listDeviceClassificationExpressions,
                variables: {
                    filter: filter,
                    limit: 1000000
                },
                authMode: 'AWS_IAM'
            }) as { data: {listDeviceClassificationExpressions: {items: graphQlApi.DeviceClassificationExpression[]}} }
        } catch (e) {
            deviceClassificationExpressions = e as { data: {listDeviceClassificationExpressions: {items: graphQlApi.DeviceClassificationExpression[]}} }
        }

        if (deviceClassificationExpressions) {
            const returnDeviceClassificationExpressions: {[key: string]: graphQlApi.DeviceClassificationExpression} = {}
            const returnQuestions: {[key: string]: graphQlApi.Question} = {}

            if (deviceClassificationExpressions.data.listDeviceClassificationExpressions && deviceClassificationExpressions.data.listDeviceClassificationExpressions.items) {
                for (const deviceClassificationExpression of deviceClassificationExpressions.data.listDeviceClassificationExpressions.items) {
                    returnDeviceClassificationExpressions[deviceClassificationExpression.id] = deviceClassificationExpression

                    const yesNoQuestionId = deviceClassificationExpression.deviceClassificationExpressionYesNoQuestionId

                    if (yesNoQuestionId !== null || yesNoQuestionId !== undefined) {
                        const yesNoQuestionIdExisting = yesNoQuestionId as string
                        const yesNoQuestion = deviceClassificationExpression.yesNoQuestion

                        if (yesNoQuestion !== null || yesNoQuestion !== undefined) {
                            const yesNoQuestionExisting = yesNoQuestion as graphQlApi.Question

                            if (yesNoQuestionExisting !== undefined && yesNoQuestionExisting !== null) {
                                returnQuestions[yesNoQuestionIdExisting] = yesNoQuestionExisting
                            }
                        }
                    }
                }

                setClassificationExpressions(returnDeviceClassificationExpressions)
                setClassificationQuestions(returnQuestions)
            } else {
                console.error('Could not obtain the classification expressions', deviceClassificationExpressions)
                alert('Could not obtain the classification expressions, likely due to an internet connection issue.')
            }
        }
    }

    function answerAllMissingQualifications() {
        const questionIds = getQualificationQuestionIds(qualificationExpressions, true)

        var newAnswers = qualificationAnswers

        for (const questionId of questionIds) {
            if (!qualificationAnswers.hasOwnProperty(questionId)) {
                newAnswers = {...newAnswers, [questionId]: false}
            }
        }

        setQualificationAnswers(newAnswers)
    }

    function answerAllMissingClassifications() {
        var newAnswers = classificationAnswers
        const questionIds = Object.values(classificationQuestions).map(question => question.id)

        for (const questionId of questionIds) {
            if (!classificationAnswers.hasOwnProperty(questionId)) {
                newAnswers = {...newAnswers, [questionId]: false}
            }
        }

        setClassificationAnswers(newAnswers)
    }
    
    // Handlers of report.

    function selectQualificationResult(value: boolean) {
        window.scrollTo(0, 0)
        setShowQualificationResult(value)

        if (value) {
            setShowClassificationResult(false)
        }
    }

    function selectClassificationResult(value: boolean) {
        window.scrollTo(0, 0)

        if (value) {
            setShowQualificationResult(false)
        }

        setShowClassificationResult(value)
    }

    // Handlers of stage.

    function startQualification() {
        window.scrollTo(0, 0)
        setStage(OnboardingStage.Qualify);
    }

    function startQualified() {
        window.scrollTo(0, 0)
        setStage(OnboardingStage.Qualified);
    }

    function startClassification() {
        window.scrollTo(0, 0)
        setStage(OnboardingStage.Classify);
    }

    function startClassified() {
        window.scrollTo(0, 0)
        setStage(OnboardingStage.Classified);
    }

    function startReport() {
        window.scrollTo(0, 0)
        setStage(OnboardingStage.Report);
    }

    function seeQualificationQuestion(questionId: string) {
        window.scrollTo(0, 0)
        setLastSeenQualificationQuestionId(questionId);
    }

    function seeClassificationExpression(expressionId: string) {
        window.scrollTo(0, 0)
        setLastSeenClassificationExpressionId(expressionId);
    }

    function answerQualificationQuestion(questionId: string, answer: boolean) {
        const newAnswers = {...qualificationAnswers, [questionId]: answer}
        setQualificationAnswers(newAnswers)
    }

    function answerClassificationQuestion(questionId: string, answer: boolean) {
        const newAnswers = {...classificationAnswers, [questionId]: answer}
        setClassificationAnswers(newAnswers)
    }

    function renderMenu() {
        if (stage === OnboardingStage.Landing) {
            return null
        }

        return <OnboardingMenu
            stage={stage}
            qualificationExpressions={qualificationExpressions}
            qualificationQuestions={qualificationQuestions}
            qualificationAnswers={qualificationAnswers}
            classificationExpressions={classificationExpressions}
            classificationQuestions={classificationQuestions}
            classificationAnswers={classificationAnswers}
            lastSeenQualificationQuestionId={lastSeenQualificationQuestionId}
            lastSeenClassificationExpressionId={lastSeenClassificationExpressionId}
            seeQualificationQuestion={seeQualificationQuestion}
            seeClassificationExpression={seeClassificationExpression}
            seeQualificationResult={selectQualificationResult}
            seeClassificationResult={selectClassificationResult}
        />
    }

    function renderPanel() {
        switch (stage) {
            case OnboardingStage.Qualify:
                return <QualificationPanel seeQuestion={seeQualificationQuestion} answerQuestion={answerQualificationQuestion} expressions={qualificationExpressions} questions={qualificationQuestions} answers={qualificationAnswers} lastSeenQuestionId={lastSeenQualificationQuestionId} startQualified={startQualified} answerAllMissing={answerAllMissingQualifications} />
            case OnboardingStage.Qualified:
                return <QualifiedPanel startClassification={startClassification} startReport={startReport} expressions={qualificationExpressions} questions={qualificationQuestions} answers={qualificationAnswers} />
            case OnboardingStage.Classify:
                return <ClassificationPanel seeExpression={seeClassificationExpression} answerQuestion={answerClassificationQuestion} expressions={classificationExpressions} questions={classificationQuestions} answers={classificationAnswers} lastSeenExpressionId={lastSeenClassificationExpressionId} startClassified={startClassified} answerAllMissing={answerAllMissingClassifications} />
            case OnboardingStage.Classified:
                return <ClassifiedPanel setOrganization={setOrganization} setProduct={setProduct} setUser={setUser} startReport={startReport} expressions={classificationExpressions} questions={classificationQuestions} answers={classificationAnswers} />
            case OnboardingStage.Report:
                return <ReportPanel previewOrganization={previewOrganization} previewProduct={previewProduct} previewUser={previewUser} qualificationExpressions={qualificationExpressions} qualificationQuestions={qualificationQuestions} qualificationAnswers={qualificationAnswers} classificationExpressions={classificationExpressions} classificationQuestions={classificationQuestions} classificationAnswers={classificationAnswers} showQualificationResult={showQualificationResult} showClassificationResult={showClassificationResult} seeClassificationResult={selectClassificationResult} seeQualificationResult={selectQualificationResult} />
        }
    }

    if (stage === OnboardingStage.Landing) {
        return <LandingPanel startQualification={startQualification} />
    }

    return <div className="py-4 py-xs-8 py-sm-12 py-md-16 py-lg-24 py-xl-32 py-xxl-32 d-flex flex-column flex-lg-row h-lg-full">
        <div className="container">
            <div className="row">
                <div className="d-none d-xs-none d-md-none d-lg-none d-xl-table-cell d-xxl-table-cell col-0 col-xs-0 col-sm-0 col-md-0 col-lg-0 col-xl-3 col-xxl-3">
                    {renderMenu()}
                </div>
                <div className="d-table-cell d-xs-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell d-xxl-table-cell col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9">
                    {renderPanel()}                    
                </div>
            </div>
        </div>
    </div>
}