import { useLocation, Navigate } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import React from 'react';

interface RequireAuthProps {
    children: React.ReactNode
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children }): JSX.Element => {
    const location = useLocation()
    const { route } = useAuthenticator((context) => [context.route]);

    if (route !== 'authenticated') {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    if (!Array.isArray(children)) return <>{children}</>

    return (
        <>
          {children.map((child, i, arr) => {
            if (i + 1 === arr.length) return child
            return <>{child}<br /></>
          })}
        </>
    )
}
