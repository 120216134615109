import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

interface LayoutProps {
    userGroups: string[]
}

export const Layout: React.FC<LayoutProps> = (props) => {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }

  return (
    <>
      <div className="container-fluid bg-surface-accent">
        <nav className="navbar navbar-expand-lg max-w-screen-xl navbar-light px-0 py-3 mx-auto">
          <div className="container max-w-screen-xl">
            <a role="link" className="navbar-brand" href="/">
              <img src="/medflow-logo.svg" className="h-10" alt="medflow"/>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav mx-auto bg-surface-accent">
                {route === 'authenticated' &&
                  <li className="nav-item">
                    <a role="button" className="nav-link" onClick={() => navigate('/')}>Home</a>
                  </li>
                }
                {route === 'authenticated' && props.userGroups.find(group => group === 'Admins') &&
                  <li className="nav-item">
                    <a role="button" className="nav-link" onClick={() => navigate('/admin')}>Admin</a>
                  </li>
                }
                {route === 'authenticated' && props.userGroups.find(group => group === 'Editors') &&
                  <li className="nav-item">
                    <a role="button" className="nav-link" onClick={() => navigate('/editor')}>Editor</a>
                  </li>
                }
                {route === 'authenticated' && props.userGroups.find(group => group === 'Users') &&
                  <li className="nav-item">
                    <a role="button" className="nav-link" onClick={() => navigate('/user')}>User</a>
                  </li>
                }
              </ul>
              <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
                {
                  route !== 'authenticated' ? (
                    <a role="button" href="#" className="btn btn-sm btn-primary w-full w-lg-auto" onClick={() => navigate('/login')}>
                      Login
                    </a>
                  ) : (
                    <a role="button" href="#" className="btn btn-sm btn-primary w-full w-lg-auto" onClick={() => logOut()}>
                      Logout
                    </a>
                  )
                }
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Outlet />
      <footer className="pt-24 bg-surface-accent">
        <div className="container max-w-screen-xl">
          <div className="row">
            <div className="col-lg-3 mb-5 mb-lg-0">
              <img src="/medflow-logo.svg" className="h-10" alt="medflow"/>
              <p className="text-sm text-muted mt-5 mb-5">
                Medical device certification software
              </p>
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a role="button" className="text-base text-muted px-1" href="https://www.linkedin.com/company/meddoc-flow-aps/about/" target="_blank">
                    <i className="bi bi-linkedin" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-base text-muted px-1" href="#">
                    <i className="bi bi-twitter" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-base text-muted px-1" href="#">
                    <i className="bi bi-youtube" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-sm-3 col-6 ms-lg-auto mb-4 mb-sm-0">
              <h6 className="mb-3">Company</h6>
              <ul className="list-unstyled text-sm">
                <li className="py-1"><a href="/company/team" className="text-muted">Team</a></li>
                <li className="py-1"><a href="/company/careers" className="text-muted">Careers</a></li>
                <li className="py-1"><a href="/company/contact" className="text-muted">Contact</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-sm-3 col-6 mb-4 mb-sm-0">
              <h6 className="mb-3">Legal</h6>
              <ul className="list-unstyled text-sm">
                <li className="py-1"><a href="/legal/terms" className="text-muted">Terms</a></li>
                <li className="py-1"><a href="/legal/privacy" className="text-muted">Privacy</a></li>
                <li className="py-1"><a href="/legal/cookies" className="text-muted">Cookies</a></li>
              </ul>
            </div>
          </div>
          <hr className="mt-20 mb-7" />
          <div className="row align-items-center justify-content-md-between pb-7">
            <div className="col-md-6">
              <div className="copyright text-sm text-center text-md-start">
                &copy; 2023 <a href="https://datacvr.virk.dk/enhed/virksomhed/43723987" target="_blank" className="h6 text-sm font-bold">Meddoc Flow ApS</a> (CVR 43723987). All rights reserved.
              </div>
            </div>
            <div className="col-md-6">
              <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0 mx-n4">
                <li className="nav-item">
                  <a className="nav-link text-muted" href="/legal/terms">
                    Terms
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-muted" href="/legal/privacy">
                    Privacy
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-muted" href="/legal/cookies">
                    Cookies
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}