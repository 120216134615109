export const Cookies: React.FC = () => {
    return (
        <div>
            <div className="py-4 py-xs-8 py-sm-12 py-md-16 py-lg-24 py-xl-32 py-xxl-32 overflow-hidden">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-8">
                        <h1 className="display-1 text-center text-primary">
                                cookies
                            </h1>
                            <h2 className="mb-10 text-center">
                                Last updated: 25/Mar/2023
                            </h2>
                            <ul>
                                <li className="mb-2">
                                    <span className="font-bold">Introduction</span>: Our website uses cookies to enhance your browsing experience and provide certain functionalities. This Cookies Policy explains what cookies are, how we use them, and your choices regarding their use.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">What Cookies Are</span>: Cookies are small text files stored on your device when you visit a website. They help websites remember information about your visit, such as your preferred language, to improve your browsing experience on future visits.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">How We Use Cookies</span>: We use cookies for various purposes, including personalizing content and remembering preferences, analyzing website traffic and usage patterns, improving the performance and security of our website.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Types of Cookies</span>: We use both first-party and third-party cookies on our website. First-party cookies: These cookies are issued by our website and are used for essential functions and user experience improvements. Third-party cookies: These cookies are set by external websites or services, such as social media platforms, and are used to provide additional features or analyze website usage.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Managing Cookies</span>: You can manage your cookie preferences through your web browser settings. Most browsers allow you to view and delete cookies, block all or specific cookies, and allow or disallow cookies from certain websites. Please note that disabling cookies may impact your browsing experience and the functionality of our website.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Changes to Cookies Policy</span>: We reserve the right to update this Cookies Policy at any time. We will notify you of any changes by posting the updated policy on our website. Your continued use of our website constitutes your acceptance of the updated Cookies Policy.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Contact Us</span>: If you have any questions or concerns about this Cookies Policy or our use of cookies, please contact us using the contact information provided on our website.
                                </li>
                            </ul>
                            <h1 className="display-1 text-center text-primary">
                                🍪
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-n1 transform translate-y-1/4">
                    <div className="col-lg-11 col-xl-9 mx-auto">
                        <div className="row g-5">
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md6.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md4.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md3.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md5.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md2.jpg" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
