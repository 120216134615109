/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAnswerInput = {
  id?: string | null,
  name: string,
  version?: number | null,
  valueString?: string | null,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  productAnswersId?: string | null,
  answerQuestionId?: string | null,
};

export type ModelAnswerConditionInput = {
  name?: ModelStringInput | null,
  version?: ModelIntInput | null,
  valueString?: ModelStringInput | null,
  valueInt?: ModelIntInput | null,
  valueFloat?: ModelFloatInput | null,
  valueBoolean?: ModelBooleanInput | null,
  readers?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  and?: Array< ModelAnswerConditionInput | null > | null,
  or?: Array< ModelAnswerConditionInput | null > | null,
  not?: ModelAnswerConditionInput | null,
  productAnswersId?: ModelIDInput | null,
  answerQuestionId?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Answer = {
  __typename: "Answer",
  id: string,
  name: string,
  version?: number | null,
  valueString?: string | null,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  question?: Question | null,
  product?: Product | null,
  rowColumnAnswers?: ModelRowColumnAnswerConnection | null,
  createdAt: string,
  updatedAt: string,
  productAnswersId?: string | null,
  answerQuestionId?: string | null,
  owner?: string | null,
};

export type Question = {
  __typename: "Question",
  id: string,
  name: string,
  description?: string | null,
  guidingBeforeText?: string | null,
  placeholder?: string | null,
  statement?: string | null,
  tooltip?: string | null,
  answerDataType: AnswerDataType,
  specialQuestionCategory?: SpecialQuestionCategory | null,
  examples?: Array< string | null > | null,
  explanations?: Array< string | null > | null,
  notes?: Array< string | null > | null,
  questionLabels?: ModelQuestionLabelConnection | null,
  questionRowColumns?: ModelQuestionRowColumnConnection | null,
  reportQuestionnaires?: ModelReportQuestionnaireConnection | null,
  stageQuestionnaires?: ModelStageQuestionnaireConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum AnswerDataType {
  BOOLEAN = "BOOLEAN",
  INT = "INT",
  FLOAT = "FLOAT",
  ROW = "ROW",
  STRING = "STRING",
}


export enum SpecialQuestionCategory {
  EU_QUALIFICATION_MEDICAL_DEVICE = "EU_QUALIFICATION_MEDICAL_DEVICE",
  EU_QUALIFICATION_ACCESSORY_FOR_MEDICAL_DEVICE = "EU_QUALIFICATION_ACCESSORY_FOR_MEDICAL_DEVICE",
  EU_QUALIFICATION_MEDICAL_DEVICE_WITHOUT_INTENDED_MEDICAL_PURPOSE = "EU_QUALIFICATION_MEDICAL_DEVICE_WITHOUT_INTENDED_MEDICAL_PURPOSE",
  EU_CLASSIFICATION = "EU_CLASSIFICATION",
  US_CLASSIFICATION = "US_CLASSIFICATION",
  CANADA_CLASSIFICATION = "CANADA_CLASSIFICATION",
}


export type ModelQuestionLabelConnection = {
  __typename: "ModelQuestionLabelConnection",
  items:  Array<QuestionLabel | null >,
  nextToken?: string | null,
};

export type QuestionLabel = {
  __typename: "QuestionLabel",
  id: string,
  name: string,
  label?: Label | null,
  question?: Question | null,
  createdAt: string,
  updatedAt: string,
  labelQuestionLabelsId?: string | null,
  questionQuestionLabelsId?: string | null,
};

export type Label = {
  __typename: "Label",
  id: string,
  name: string,
  questionLabels?: ModelQuestionLabelConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelQuestionRowColumnConnection = {
  __typename: "ModelQuestionRowColumnConnection",
  items:  Array<QuestionRowColumn | null >,
  nextToken?: string | null,
};

export type QuestionRowColumn = {
  __typename: "QuestionRowColumn",
  id: string,
  name: string,
  order: number,
  answerDataType: AnswerDataType,
  question?: Question | null,
  createdAt: string,
  updatedAt: string,
  questionQuestionRowColumnsId?: string | null,
};

export type ModelReportQuestionnaireConnection = {
  __typename: "ModelReportQuestionnaireConnection",
  items:  Array<ReportQuestionnaire | null >,
  nextToken?: string | null,
};

export type ReportQuestionnaire = {
  __typename: "ReportQuestionnaire",
  id: string,
  name: string,
  order: number,
  deviceClassification?: DeviceClassification | null,
  question?: Question | null,
  report?: Report | null,
  reportQuestionnaireSection?: ReportQuestionnaireSection | null,
  createdAt: string,
  updatedAt: string,
  questionReportQuestionnairesId?: string | null,
  reportReportQuestionnairesId?: string | null,
  reportQuestionnaireSectionReportQuestionnairesId?: string | null,
};

export enum DeviceClassification {
  EU_CLASS_1 = "EU_CLASS_1",
  EU_CLASS_2_A = "EU_CLASS_2_A",
  EU_CLASS_2_B = "EU_CLASS_2_B",
  EU_CLASS_3 = "EU_CLASS_3",
  US_CLASS_1 = "US_CLASS_1",
  US_CLASS_2 = "US_CLASS_2",
  US_CLASS_3 = "US_CLASS_3",
  CANADA_CLASS_1 = "CANADA_CLASS_1",
  CANADA_CLASS_2 = "CANADA_CLASS_2",
  CANADA_CLASS_3 = "CANADA_CLASS_3",
  CANADA_CLASS_4 = "CANADA_CLASS_4",
}


export type Report = {
  __typename: "Report",
  id: string,
  name: string,
  stage?: Stage | null,
  reportQuestionnaires?: ModelReportQuestionnaireConnection | null,
  reportQuestionnaireSections?: ModelReportQuestionnaireSectionConnection | null,
  createdAt: string,
  updatedAt: string,
  stageReportsId?: string | null,
};

export type Stage = {
  __typename: "Stage",
  id: string,
  name: string,
  order: number,
  medflowVersion?: MedflowVersion | null,
  phase?: Phase | null,
  track?: Track | null,
  stageQuestionnaires?: ModelStageQuestionnaireConnection | null,
  stageQuestionnaireSections?: ModelStageQuestionnaireSectionConnection | null,
  reports?: ModelReportConnection | null,
  createdAt: string,
  updatedAt: string,
  phaseStagesId?: string | null,
  trackStagesId?: string | null,
  stageMedflowVersionId?: string | null,
};

export type MedflowVersion = {
  __typename: "MedflowVersion",
  id?: string | null,
  name: string,
  number: number,
  createdAt: string,
  updatedAt: string,
};

export type Phase = {
  __typename: "Phase",
  id: string,
  name: string,
  order: number,
  medflowVersion?: MedflowVersion | null,
  stages?: ModelStageConnection | null,
  createdAt: string,
  updatedAt: string,
  phaseMedflowVersionId?: string | null,
};

export type ModelStageConnection = {
  __typename: "ModelStageConnection",
  items:  Array<Stage | null >,
  nextToken?: string | null,
};

export type Track = {
  __typename: "Track",
  id: string,
  name: string,
  medflowVersion?: MedflowVersion | null,
  stages?: ModelStageConnection | null,
  createdAt: string,
  updatedAt: string,
  trackMedflowVersionId?: string | null,
};

export type ModelStageQuestionnaireConnection = {
  __typename: "ModelStageQuestionnaireConnection",
  items:  Array<StageQuestionnaire | null >,
  nextToken?: string | null,
};

export type StageQuestionnaire = {
  __typename: "StageQuestionnaire",
  id: string,
  name: string,
  order: number,
  deviceClassification?: DeviceClassification | null,
  question?: Question | null,
  stageQuestionnaireSection?: StageQuestionnaireSection | null,
  stage?: Stage | null,
  createdAt: string,
  updatedAt: string,
  questionStageQuestionnairesId?: string | null,
  stageStageQuestionnairesId?: string | null,
  stageQuestionnaireSectionStageQuestionnairesId?: string | null,
};

export type StageQuestionnaireSection = {
  __typename: "StageQuestionnaireSection",
  id: string,
  name: string,
  order: number,
  deviceClassification?: DeviceClassification | null,
  stage?: Stage | null,
  stageQuestionnaires?: ModelStageQuestionnaireConnection | null,
  createdAt: string,
  updatedAt: string,
  stageStageQuestionnaireSectionsId?: string | null,
};

export type ModelStageQuestionnaireSectionConnection = {
  __typename: "ModelStageQuestionnaireSectionConnection",
  items:  Array<StageQuestionnaireSection | null >,
  nextToken?: string | null,
};

export type ModelReportConnection = {
  __typename: "ModelReportConnection",
  items:  Array<Report | null >,
  nextToken?: string | null,
};

export type ModelReportQuestionnaireSectionConnection = {
  __typename: "ModelReportQuestionnaireSectionConnection",
  items:  Array<ReportQuestionnaireSection | null >,
  nextToken?: string | null,
};

export type ReportQuestionnaireSection = {
  __typename: "ReportQuestionnaireSection",
  id: string,
  name: string,
  order: number,
  deviceClassification?: DeviceClassification | null,
  report?: Report | null,
  reportQuestionnaires?: ModelReportQuestionnaireConnection | null,
  createdAt: string,
  updatedAt: string,
  reportReportQuestionnaireSectionsId?: string | null,
};

export type Product = {
  __typename: "Product",
  id: string,
  name: string,
  description?: string | null,
  productCode?: string | null,
  gmdnDescription?: string | null,
  deviceClassification?: DeviceClassification | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  medflowVersion?: MedflowVersion | null,
  answers?: ModelAnswerConnection | null,
  createdAt: string,
  updatedAt: string,
  productMedflowVersionId?: string | null,
  owner?: string | null,
};

export type ModelAnswerConnection = {
  __typename: "ModelAnswerConnection",
  items:  Array<Answer | null >,
  nextToken?: string | null,
};

export type ModelRowColumnAnswerConnection = {
  __typename: "ModelRowColumnAnswerConnection",
  items:  Array<RowColumnAnswer | null >,
  nextToken?: string | null,
};

export type RowColumnAnswer = {
  __typename: "RowColumnAnswer",
  id: string,
  name: string,
  version?: number | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  valueString?: string | null,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  questionRowColumn?: QuestionRowColumn | null,
  answer?: Answer | null,
  createdAt: string,
  updatedAt: string,
  answerRowColumnAnswersId?: string | null,
  rowColumnAnswerQuestionRowColumnId?: string | null,
  owner?: string | null,
};

export type UpdateAnswerInput = {
  id: string,
  name?: string | null,
  version?: number | null,
  valueString?: string | null,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  productAnswersId?: string | null,
  answerQuestionId?: string | null,
};

export type DeleteAnswerInput = {
  id: string,
};

export type CreateDeviceClassificationExpressionInput = {
  id?: string | null,
  name: string,
  category?: string | null,
  description?: string | null,
  index?: number | null,
  isRoot?: boolean | null,
  statement?: string | null,
  tooltip?: string | null,
  explanations?: Array< string | null > | null,
  notes?: Array< string | null > | null,
  classificationIfYes?: DeviceClassification | null,
  operator?: DeviceExpressionOperator | null,
  specialQuestionCategory?: SpecialQuestionCategory | null,
  deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
  deviceClassificationExpressionYesNoQuestionId?: string | null,
};

export enum DeviceExpressionOperator {
  MAX_OF = "MAX_OF",
  RIGHT_OVERRIDE_OF = "RIGHT_OVERRIDE_OF",
}


export type ModelDeviceClassificationExpressionConditionInput = {
  name?: ModelStringInput | null,
  category?: ModelStringInput | null,
  description?: ModelStringInput | null,
  index?: ModelIntInput | null,
  isRoot?: ModelBooleanInput | null,
  statement?: ModelStringInput | null,
  tooltip?: ModelStringInput | null,
  explanations?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  classificationIfYes?: ModelDeviceClassificationInput | null,
  operator?: ModelDeviceExpressionOperatorInput | null,
  specialQuestionCategory?: ModelSpecialQuestionCategoryInput | null,
  and?: Array< ModelDeviceClassificationExpressionConditionInput | null > | null,
  or?: Array< ModelDeviceClassificationExpressionConditionInput | null > | null,
  not?: ModelDeviceClassificationExpressionConditionInput | null,
  deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: ModelIDInput | null,
  deviceClassificationExpressionYesNoQuestionId?: ModelIDInput | null,
};

export type ModelDeviceClassificationInput = {
  eq?: DeviceClassification | null,
  ne?: DeviceClassification | null,
};

export type ModelDeviceExpressionOperatorInput = {
  eq?: DeviceExpressionOperator | null,
  ne?: DeviceExpressionOperator | null,
};

export type ModelSpecialQuestionCategoryInput = {
  eq?: SpecialQuestionCategory | null,
  ne?: SpecialQuestionCategory | null,
};

export type DeviceClassificationExpression = {
  __typename: "DeviceClassificationExpression",
  id: string,
  name: string,
  category?: string | null,
  description?: string | null,
  index?: number | null,
  isRoot?: boolean | null,
  statement?: string | null,
  tooltip?: string | null,
  explanations?: Array< string | null > | null,
  notes?: Array< string | null > | null,
  classificationIfYes?: DeviceClassification | null,
  operator?: DeviceExpressionOperator | null,
  specialQuestionCategory?: SpecialQuestionCategory | null,
  yesNoQuestion?: Question | null,
  parentDeviceClassificationExpression?: DeviceClassificationExpression | null,
  childrenDeviceClassificationExpressions?: ModelDeviceClassificationExpressionConnection | null,
  createdAt: string,
  updatedAt: string,
  deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
  deviceClassificationExpressionYesNoQuestionId?: string | null,
};

export type ModelDeviceClassificationExpressionConnection = {
  __typename: "ModelDeviceClassificationExpressionConnection",
  items:  Array<DeviceClassificationExpression | null >,
  nextToken?: string | null,
};

export type UpdateDeviceClassificationExpressionInput = {
  id: string,
  name?: string | null,
  category?: string | null,
  description?: string | null,
  index?: number | null,
  isRoot?: boolean | null,
  statement?: string | null,
  tooltip?: string | null,
  explanations?: Array< string | null > | null,
  notes?: Array< string | null > | null,
  classificationIfYes?: DeviceClassification | null,
  operator?: DeviceExpressionOperator | null,
  specialQuestionCategory?: SpecialQuestionCategory | null,
  deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
  deviceClassificationExpressionYesNoQuestionId?: string | null,
};

export type DeleteDeviceClassificationExpressionInput = {
  id: string,
};

export type CreateDeviceQualificationExpressionInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  index?: number | null,
  isRoot?: boolean | null,
  statement?: string | null,
  qualificationIfYes?: DeviceQualification | null,
  operator?: DeviceExpressionOperator | null,
  specialQuestionCategory?: SpecialQuestionCategory | null,
  deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
  deviceQualificationExpressionYesNoQuestionId?: string | null,
};

export enum DeviceQualification {
  MEDICAL_DEVICE_MDR_ARTICLE_2_1 = "MEDICAL_DEVICE_MDR_ARTICLE_2_1",
  ACCESSORY_FOR_MEDICAL_DEVICE_MDR_ARTICLE_2_2 = "ACCESSORY_FOR_MEDICAL_DEVICE_MDR_ARTICLE_2_2",
  MEDICAL_DEVICE_WITHOUT_INTENDED_MEDICAL_PURPOSE_MDR_ANNEX_XVI = "MEDICAL_DEVICE_WITHOUT_INTENDED_MEDICAL_PURPOSE_MDR_ANNEX_XVI",
}


export type ModelDeviceQualificationExpressionConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  index?: ModelIntInput | null,
  isRoot?: ModelBooleanInput | null,
  statement?: ModelStringInput | null,
  qualificationIfYes?: ModelDeviceQualificationInput | null,
  operator?: ModelDeviceExpressionOperatorInput | null,
  specialQuestionCategory?: ModelSpecialQuestionCategoryInput | null,
  and?: Array< ModelDeviceQualificationExpressionConditionInput | null > | null,
  or?: Array< ModelDeviceQualificationExpressionConditionInput | null > | null,
  not?: ModelDeviceQualificationExpressionConditionInput | null,
  deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: ModelIDInput | null,
  deviceQualificationExpressionYesNoQuestionId?: ModelIDInput | null,
};

export type ModelDeviceQualificationInput = {
  eq?: DeviceQualification | null,
  ne?: DeviceQualification | null,
};

export type DeviceQualificationExpression = {
  __typename: "DeviceQualificationExpression",
  id: string,
  name: string,
  description?: string | null,
  index?: number | null,
  isRoot?: boolean | null,
  statement?: string | null,
  qualificationIfYes?: DeviceQualification | null,
  operator?: DeviceExpressionOperator | null,
  specialQuestionCategory?: SpecialQuestionCategory | null,
  yesNoQuestion?: Question | null,
  parentDeviceQualificationExpression?: DeviceQualificationExpression | null,
  childrenDeviceQualificationExpressions?: ModelDeviceQualificationExpressionConnection | null,
  createdAt: string,
  updatedAt: string,
  deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
  deviceQualificationExpressionYesNoQuestionId?: string | null,
};

export type ModelDeviceQualificationExpressionConnection = {
  __typename: "ModelDeviceQualificationExpressionConnection",
  items:  Array<DeviceQualificationExpression | null >,
  nextToken?: string | null,
};

export type UpdateDeviceQualificationExpressionInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  index?: number | null,
  isRoot?: boolean | null,
  statement?: string | null,
  qualificationIfYes?: DeviceQualification | null,
  operator?: DeviceExpressionOperator | null,
  specialQuestionCategory?: SpecialQuestionCategory | null,
  deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
  deviceQualificationExpressionYesNoQuestionId?: string | null,
};

export type DeleteDeviceQualificationExpressionInput = {
  id: string,
};

export type CreateEntityTemplateInput = {
  id?: string | null,
  name: string,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  entityEnum?: EntityEnum | null,
  entityTemplateBelongsToEntityTemplatesId?: string | null,
  entityTemplateEntityTemplateHasOneId?: string | null,
};

export enum EntityEnum {
  ENUM1 = "ENUM1",
  ENUM2 = "ENUM2",
}


export type ModelEntityTemplateConditionInput = {
  name?: ModelStringInput | null,
  valueInt?: ModelIntInput | null,
  valueFloat?: ModelFloatInput | null,
  valueBoolean?: ModelBooleanInput | null,
  entityEnum?: ModelEntityEnumInput | null,
  and?: Array< ModelEntityTemplateConditionInput | null > | null,
  or?: Array< ModelEntityTemplateConditionInput | null > | null,
  not?: ModelEntityTemplateConditionInput | null,
  entityTemplateBelongsToEntityTemplatesId?: ModelIDInput | null,
  entityTemplateEntityTemplateHasOneId?: ModelIDInput | null,
};

export type ModelEntityEnumInput = {
  eq?: EntityEnum | null,
  ne?: EntityEnum | null,
};

export type EntityTemplate = {
  __typename: "EntityTemplate",
  id: string,
  name: string,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  entityEnum?: EntityEnum | null,
  entityTemplateHasOne?: EntityTemplateHasOne | null,
  entityTemplateBelongsTo?: EntityTemplateBelongsTo | null,
  createdAt: string,
  updatedAt: string,
  entityTemplateBelongsToEntityTemplatesId?: string | null,
  entityTemplateEntityTemplateHasOneId?: string | null,
};

export type EntityTemplateHasOne = {
  __typename: "EntityTemplateHasOne",
  id: string,
  name: string,
  createdAt: string,
  updatedAt: string,
};

export type EntityTemplateBelongsTo = {
  __typename: "EntityTemplateBelongsTo",
  id: string,
  name: string,
  entityTemplates?: ModelEntityTemplateConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelEntityTemplateConnection = {
  __typename: "ModelEntityTemplateConnection",
  items:  Array<EntityTemplate | null >,
  nextToken?: string | null,
};

export type UpdateEntityTemplateInput = {
  id: string,
  name?: string | null,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  entityEnum?: EntityEnum | null,
  entityTemplateBelongsToEntityTemplatesId?: string | null,
  entityTemplateEntityTemplateHasOneId?: string | null,
};

export type DeleteEntityTemplateInput = {
  id: string,
};

export type CreateEntityTemplateBelongsToInput = {
  id?: string | null,
  name: string,
};

export type ModelEntityTemplateBelongsToConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelEntityTemplateBelongsToConditionInput | null > | null,
  or?: Array< ModelEntityTemplateBelongsToConditionInput | null > | null,
  not?: ModelEntityTemplateBelongsToConditionInput | null,
};

export type UpdateEntityTemplateBelongsToInput = {
  id: string,
  name?: string | null,
};

export type DeleteEntityTemplateBelongsToInput = {
  id: string,
};

export type CreateEntityTemplateHasOneInput = {
  id?: string | null,
  name: string,
};

export type ModelEntityTemplateHasOneConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelEntityTemplateHasOneConditionInput | null > | null,
  or?: Array< ModelEntityTemplateHasOneConditionInput | null > | null,
  not?: ModelEntityTemplateHasOneConditionInput | null,
};

export type UpdateEntityTemplateHasOneInput = {
  id: string,
  name?: string | null,
};

export type DeleteEntityTemplateHasOneInput = {
  id: string,
};

export type CreateLabelInput = {
  id?: string | null,
  name: string,
};

export type ModelLabelConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelLabelConditionInput | null > | null,
  or?: Array< ModelLabelConditionInput | null > | null,
  not?: ModelLabelConditionInput | null,
};

export type UpdateLabelInput = {
  id: string,
  name?: string | null,
};

export type DeleteLabelInput = {
  id: string,
};

export type CreateMedflowVersionInput = {
  id?: string | null,
  name: string,
  number: number,
};

export type ModelMedflowVersionConditionInput = {
  name?: ModelStringInput | null,
  number?: ModelIntInput | null,
  and?: Array< ModelMedflowVersionConditionInput | null > | null,
  or?: Array< ModelMedflowVersionConditionInput | null > | null,
  not?: ModelMedflowVersionConditionInput | null,
};

export type UpdateMedflowVersionInput = {
  id: string,
  name?: string | null,
  number?: number | null,
};

export type DeleteMedflowVersionInput = {
  id: string,
};

export type CreatePhaseInput = {
  id?: string | null,
  name: string,
  order: number,
  phaseMedflowVersionId?: string | null,
};

export type ModelPhaseConditionInput = {
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelPhaseConditionInput | null > | null,
  or?: Array< ModelPhaseConditionInput | null > | null,
  not?: ModelPhaseConditionInput | null,
  phaseMedflowVersionId?: ModelIDInput | null,
};

export type UpdatePhaseInput = {
  id: string,
  name?: string | null,
  order?: number | null,
  phaseMedflowVersionId?: string | null,
};

export type DeletePhaseInput = {
  id: string,
};

export type CreatePreviewAnswerInput = {
  id?: string | null,
  name: string,
  valueString?: string | null,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  previewProductPreviewAnswersId?: string | null,
  previewAnswerQuestionId?: string | null,
};

export type ModelPreviewAnswerConditionInput = {
  name?: ModelStringInput | null,
  valueString?: ModelStringInput | null,
  valueInt?: ModelIntInput | null,
  valueFloat?: ModelFloatInput | null,
  valueBoolean?: ModelBooleanInput | null,
  and?: Array< ModelPreviewAnswerConditionInput | null > | null,
  or?: Array< ModelPreviewAnswerConditionInput | null > | null,
  not?: ModelPreviewAnswerConditionInput | null,
  previewProductPreviewAnswersId?: ModelIDInput | null,
  previewAnswerQuestionId?: ModelIDInput | null,
};

export type PreviewAnswer = {
  __typename: "PreviewAnswer",
  id: string,
  name: string,
  valueString?: string | null,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  question?: Question | null,
  previewProduct?: PreviewProduct | null,
  createdAt: string,
  updatedAt: string,
  previewProductPreviewAnswersId?: string | null,
  previewAnswerQuestionId?: string | null,
};

export type PreviewProduct = {
  __typename: "PreviewProduct",
  id: string,
  name: string,
  intendedUse: string,
  description: string,
  previewUser?: PreviewUser | null,
  previewOrganization?: PreviewOrganization | null,
  previewAnswers?: ModelPreviewAnswerConnection | null,
  createdAt: string,
  updatedAt: string,
  previewOrganizationPreviewProductsId?: string | null,
  previewUserPreviewProductsId?: string | null,
};

export type PreviewUser = {
  __typename: "PreviewUser",
  id: string,
  name: string,
  email: string,
  phoneNumber?: string | null,
  area: string,
  previewProducts?: ModelPreviewProductConnection | null,
  previewOrganization?: PreviewOrganization | null,
  createdAt: string,
  updatedAt: string,
  previewOrganizationPreviewUsersId?: string | null,
};

export type ModelPreviewProductConnection = {
  __typename: "ModelPreviewProductConnection",
  items:  Array<PreviewProduct | null >,
  nextToken?: string | null,
};

export type PreviewOrganization = {
  __typename: "PreviewOrganization",
  id: string,
  name: string,
  registrationNumber: string,
  previewUsers?: ModelPreviewUserConnection | null,
  previewProducts?: ModelPreviewProductConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelPreviewUserConnection = {
  __typename: "ModelPreviewUserConnection",
  items:  Array<PreviewUser | null >,
  nextToken?: string | null,
};

export type ModelPreviewAnswerConnection = {
  __typename: "ModelPreviewAnswerConnection",
  items:  Array<PreviewAnswer | null >,
  nextToken?: string | null,
};

export type UpdatePreviewAnswerInput = {
  id: string,
  name?: string | null,
  valueString?: string | null,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  previewProductPreviewAnswersId?: string | null,
  previewAnswerQuestionId?: string | null,
};

export type DeletePreviewAnswerInput = {
  id: string,
};

export type CreatePreviewProductInput = {
  id?: string | null,
  name: string,
  intendedUse: string,
  description: string,
  previewOrganizationPreviewProductsId?: string | null,
  previewUserPreviewProductsId?: string | null,
};

export type ModelPreviewProductConditionInput = {
  name?: ModelStringInput | null,
  intendedUse?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelPreviewProductConditionInput | null > | null,
  or?: Array< ModelPreviewProductConditionInput | null > | null,
  not?: ModelPreviewProductConditionInput | null,
  previewOrganizationPreviewProductsId?: ModelIDInput | null,
  previewUserPreviewProductsId?: ModelIDInput | null,
};

export type UpdatePreviewProductInput = {
  id: string,
  name?: string | null,
  intendedUse?: string | null,
  description?: string | null,
  previewOrganizationPreviewProductsId?: string | null,
  previewUserPreviewProductsId?: string | null,
};

export type DeletePreviewProductInput = {
  id: string,
};

export type CreatePreviewOrganizationInput = {
  id?: string | null,
  name: string,
  registrationNumber: string,
};

export type ModelPreviewOrganizationConditionInput = {
  name?: ModelStringInput | null,
  registrationNumber?: ModelStringInput | null,
  and?: Array< ModelPreviewOrganizationConditionInput | null > | null,
  or?: Array< ModelPreviewOrganizationConditionInput | null > | null,
  not?: ModelPreviewOrganizationConditionInput | null,
};

export type UpdatePreviewOrganizationInput = {
  id: string,
  name?: string | null,
  registrationNumber?: string | null,
};

export type DeletePreviewOrganizationInput = {
  id: string,
};

export type CreatePreviewUserInput = {
  id?: string | null,
  name: string,
  email: string,
  phoneNumber?: string | null,
  area: string,
  previewOrganizationPreviewUsersId?: string | null,
};

export type ModelPreviewUserConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  area?: ModelStringInput | null,
  and?: Array< ModelPreviewUserConditionInput | null > | null,
  or?: Array< ModelPreviewUserConditionInput | null > | null,
  not?: ModelPreviewUserConditionInput | null,
  previewOrganizationPreviewUsersId?: ModelIDInput | null,
};

export type UpdatePreviewUserInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  area?: string | null,
  previewOrganizationPreviewUsersId?: string | null,
};

export type DeletePreviewUserInput = {
  id: string,
};

export type CreateProductInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  productCode?: string | null,
  gmdnDescription?: string | null,
  deviceClassification?: DeviceClassification | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  productMedflowVersionId?: string | null,
};

export type ModelProductConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  productCode?: ModelStringInput | null,
  gmdnDescription?: ModelStringInput | null,
  deviceClassification?: ModelDeviceClassificationInput | null,
  readers?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
  productMedflowVersionId?: ModelIDInput | null,
};

export type UpdateProductInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  productCode?: string | null,
  gmdnDescription?: string | null,
  deviceClassification?: DeviceClassification | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  productMedflowVersionId?: string | null,
};

export type DeleteProductInput = {
  id: string,
};

export type CreateQuestionInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  guidingBeforeText?: string | null,
  placeholder?: string | null,
  statement?: string | null,
  tooltip?: string | null,
  answerDataType: AnswerDataType,
  specialQuestionCategory?: SpecialQuestionCategory | null,
  examples?: Array< string | null > | null,
  explanations?: Array< string | null > | null,
  notes?: Array< string | null > | null,
};

export type ModelQuestionConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  guidingBeforeText?: ModelStringInput | null,
  placeholder?: ModelStringInput | null,
  statement?: ModelStringInput | null,
  tooltip?: ModelStringInput | null,
  answerDataType?: ModelAnswerDataTypeInput | null,
  specialQuestionCategory?: ModelSpecialQuestionCategoryInput | null,
  examples?: ModelStringInput | null,
  explanations?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  and?: Array< ModelQuestionConditionInput | null > | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  not?: ModelQuestionConditionInput | null,
};

export type ModelAnswerDataTypeInput = {
  eq?: AnswerDataType | null,
  ne?: AnswerDataType | null,
};

export type UpdateQuestionInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  guidingBeforeText?: string | null,
  placeholder?: string | null,
  statement?: string | null,
  tooltip?: string | null,
  answerDataType?: AnswerDataType | null,
  specialQuestionCategory?: SpecialQuestionCategory | null,
  examples?: Array< string | null > | null,
  explanations?: Array< string | null > | null,
  notes?: Array< string | null > | null,
};

export type DeleteQuestionInput = {
  id: string,
};

export type CreateQuestionLabelInput = {
  id?: string | null,
  name: string,
  labelQuestionLabelsId?: string | null,
  questionQuestionLabelsId?: string | null,
};

export type ModelQuestionLabelConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelQuestionLabelConditionInput | null > | null,
  or?: Array< ModelQuestionLabelConditionInput | null > | null,
  not?: ModelQuestionLabelConditionInput | null,
  labelQuestionLabelsId?: ModelIDInput | null,
  questionQuestionLabelsId?: ModelIDInput | null,
};

export type UpdateQuestionLabelInput = {
  id: string,
  name?: string | null,
  labelQuestionLabelsId?: string | null,
  questionQuestionLabelsId?: string | null,
};

export type DeleteQuestionLabelInput = {
  id: string,
};

export type CreateQuestionRowColumnInput = {
  id?: string | null,
  name: string,
  order: number,
  answerDataType: AnswerDataType,
  questionQuestionRowColumnsId?: string | null,
};

export type ModelQuestionRowColumnConditionInput = {
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  answerDataType?: ModelAnswerDataTypeInput | null,
  and?: Array< ModelQuestionRowColumnConditionInput | null > | null,
  or?: Array< ModelQuestionRowColumnConditionInput | null > | null,
  not?: ModelQuestionRowColumnConditionInput | null,
  questionQuestionRowColumnsId?: ModelIDInput | null,
};

export type UpdateQuestionRowColumnInput = {
  id: string,
  name?: string | null,
  order?: number | null,
  answerDataType?: AnswerDataType | null,
  questionQuestionRowColumnsId?: string | null,
};

export type DeleteQuestionRowColumnInput = {
  id: string,
};

export type CreateReportInput = {
  id?: string | null,
  name: string,
  stageReportsId?: string | null,
};

export type ModelReportConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelReportConditionInput | null > | null,
  or?: Array< ModelReportConditionInput | null > | null,
  not?: ModelReportConditionInput | null,
  stageReportsId?: ModelIDInput | null,
};

export type UpdateReportInput = {
  id: string,
  name?: string | null,
  stageReportsId?: string | null,
};

export type DeleteReportInput = {
  id: string,
};

export type CreateReportQuestionnaireInput = {
  id?: string | null,
  name: string,
  order: number,
  deviceClassification?: DeviceClassification | null,
  questionReportQuestionnairesId?: string | null,
  reportReportQuestionnairesId?: string | null,
  reportQuestionnaireSectionReportQuestionnairesId?: string | null,
};

export type ModelReportQuestionnaireConditionInput = {
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  deviceClassification?: ModelDeviceClassificationInput | null,
  and?: Array< ModelReportQuestionnaireConditionInput | null > | null,
  or?: Array< ModelReportQuestionnaireConditionInput | null > | null,
  not?: ModelReportQuestionnaireConditionInput | null,
  questionReportQuestionnairesId?: ModelIDInput | null,
  reportReportQuestionnairesId?: ModelIDInput | null,
  reportQuestionnaireSectionReportQuestionnairesId?: ModelIDInput | null,
};

export type UpdateReportQuestionnaireInput = {
  id: string,
  name?: string | null,
  order?: number | null,
  deviceClassification?: DeviceClassification | null,
  questionReportQuestionnairesId?: string | null,
  reportReportQuestionnairesId?: string | null,
  reportQuestionnaireSectionReportQuestionnairesId?: string | null,
};

export type DeleteReportQuestionnaireInput = {
  id: string,
};

export type CreateReportQuestionnaireSectionInput = {
  id?: string | null,
  name: string,
  order: number,
  deviceClassification?: DeviceClassification | null,
  reportReportQuestionnaireSectionsId?: string | null,
};

export type ModelReportQuestionnaireSectionConditionInput = {
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  deviceClassification?: ModelDeviceClassificationInput | null,
  and?: Array< ModelReportQuestionnaireSectionConditionInput | null > | null,
  or?: Array< ModelReportQuestionnaireSectionConditionInput | null > | null,
  not?: ModelReportQuestionnaireSectionConditionInput | null,
  reportReportQuestionnaireSectionsId?: ModelIDInput | null,
};

export type UpdateReportQuestionnaireSectionInput = {
  id: string,
  name?: string | null,
  order?: number | null,
  deviceClassification?: DeviceClassification | null,
  reportReportQuestionnaireSectionsId?: string | null,
};

export type DeleteReportQuestionnaireSectionInput = {
  id: string,
};

export type CreateRowColumnAnswerInput = {
  id?: string | null,
  name: string,
  version?: number | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  valueString?: string | null,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  answerRowColumnAnswersId?: string | null,
  rowColumnAnswerQuestionRowColumnId?: string | null,
};

export type ModelRowColumnAnswerConditionInput = {
  name?: ModelStringInput | null,
  version?: ModelIntInput | null,
  readers?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  valueString?: ModelStringInput | null,
  valueInt?: ModelIntInput | null,
  valueFloat?: ModelFloatInput | null,
  valueBoolean?: ModelBooleanInput | null,
  and?: Array< ModelRowColumnAnswerConditionInput | null > | null,
  or?: Array< ModelRowColumnAnswerConditionInput | null > | null,
  not?: ModelRowColumnAnswerConditionInput | null,
  answerRowColumnAnswersId?: ModelIDInput | null,
  rowColumnAnswerQuestionRowColumnId?: ModelIDInput | null,
};

export type UpdateRowColumnAnswerInput = {
  id: string,
  name?: string | null,
  version?: number | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  valueString?: string | null,
  valueInt?: number | null,
  valueFloat?: number | null,
  valueBoolean?: boolean | null,
  answerRowColumnAnswersId?: string | null,
  rowColumnAnswerQuestionRowColumnId?: string | null,
};

export type DeleteRowColumnAnswerInput = {
  id: string,
};

export type CreateStageInput = {
  id?: string | null,
  name: string,
  order: number,
  phaseStagesId?: string | null,
  trackStagesId?: string | null,
  stageMedflowVersionId?: string | null,
};

export type ModelStageConditionInput = {
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelStageConditionInput | null > | null,
  or?: Array< ModelStageConditionInput | null > | null,
  not?: ModelStageConditionInput | null,
  phaseStagesId?: ModelIDInput | null,
  trackStagesId?: ModelIDInput | null,
  stageMedflowVersionId?: ModelIDInput | null,
};

export type UpdateStageInput = {
  id: string,
  name?: string | null,
  order?: number | null,
  phaseStagesId?: string | null,
  trackStagesId?: string | null,
  stageMedflowVersionId?: string | null,
};

export type DeleteStageInput = {
  id: string,
};

export type CreateStageQuestionnaireInput = {
  id?: string | null,
  name: string,
  order: number,
  deviceClassification?: DeviceClassification | null,
  questionStageQuestionnairesId?: string | null,
  stageStageQuestionnairesId?: string | null,
  stageQuestionnaireSectionStageQuestionnairesId?: string | null,
};

export type ModelStageQuestionnaireConditionInput = {
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  deviceClassification?: ModelDeviceClassificationInput | null,
  and?: Array< ModelStageQuestionnaireConditionInput | null > | null,
  or?: Array< ModelStageQuestionnaireConditionInput | null > | null,
  not?: ModelStageQuestionnaireConditionInput | null,
  questionStageQuestionnairesId?: ModelIDInput | null,
  stageStageQuestionnairesId?: ModelIDInput | null,
  stageQuestionnaireSectionStageQuestionnairesId?: ModelIDInput | null,
};

export type UpdateStageQuestionnaireInput = {
  id: string,
  name?: string | null,
  order?: number | null,
  deviceClassification?: DeviceClassification | null,
  questionStageQuestionnairesId?: string | null,
  stageStageQuestionnairesId?: string | null,
  stageQuestionnaireSectionStageQuestionnairesId?: string | null,
};

export type DeleteStageQuestionnaireInput = {
  id: string,
};

export type CreateStageQuestionnaireSectionInput = {
  id?: string | null,
  name: string,
  order: number,
  deviceClassification?: DeviceClassification | null,
  stageStageQuestionnaireSectionsId?: string | null,
};

export type ModelStageQuestionnaireSectionConditionInput = {
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  deviceClassification?: ModelDeviceClassificationInput | null,
  and?: Array< ModelStageQuestionnaireSectionConditionInput | null > | null,
  or?: Array< ModelStageQuestionnaireSectionConditionInput | null > | null,
  not?: ModelStageQuestionnaireSectionConditionInput | null,
  stageStageQuestionnaireSectionsId?: ModelIDInput | null,
};

export type UpdateStageQuestionnaireSectionInput = {
  id: string,
  name?: string | null,
  order?: number | null,
  deviceClassification?: DeviceClassification | null,
  stageStageQuestionnaireSectionsId?: string | null,
};

export type DeleteStageQuestionnaireSectionInput = {
  id: string,
};

export type CreateTrackInput = {
  id?: string | null,
  name: string,
  trackMedflowVersionId?: string | null,
};

export type ModelTrackConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelTrackConditionInput | null > | null,
  or?: Array< ModelTrackConditionInput | null > | null,
  not?: ModelTrackConditionInput | null,
  trackMedflowVersionId?: ModelIDInput | null,
};

export type UpdateTrackInput = {
  id: string,
  name?: string | null,
  trackMedflowVersionId?: string | null,
};

export type DeleteTrackInput = {
  id: string,
};

export type ModelAnswerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  version?: ModelIntInput | null,
  valueString?: ModelStringInput | null,
  valueInt?: ModelIntInput | null,
  valueFloat?: ModelFloatInput | null,
  valueBoolean?: ModelBooleanInput | null,
  readers?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  and?: Array< ModelAnswerFilterInput | null > | null,
  or?: Array< ModelAnswerFilterInput | null > | null,
  not?: ModelAnswerFilterInput | null,
  productAnswersId?: ModelIDInput | null,
  answerQuestionId?: ModelIDInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelDeviceClassificationExpressionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  category?: ModelStringInput | null,
  description?: ModelStringInput | null,
  index?: ModelIntInput | null,
  isRoot?: ModelBooleanInput | null,
  statement?: ModelStringInput | null,
  tooltip?: ModelStringInput | null,
  explanations?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  classificationIfYes?: ModelDeviceClassificationInput | null,
  operator?: ModelDeviceExpressionOperatorInput | null,
  specialQuestionCategory?: ModelSpecialQuestionCategoryInput | null,
  and?: Array< ModelDeviceClassificationExpressionFilterInput | null > | null,
  or?: Array< ModelDeviceClassificationExpressionFilterInput | null > | null,
  not?: ModelDeviceClassificationExpressionFilterInput | null,
  deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: ModelIDInput | null,
  deviceClassificationExpressionYesNoQuestionId?: ModelIDInput | null,
};

export type ModelDeviceQualificationExpressionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  index?: ModelIntInput | null,
  isRoot?: ModelBooleanInput | null,
  statement?: ModelStringInput | null,
  qualificationIfYes?: ModelDeviceQualificationInput | null,
  operator?: ModelDeviceExpressionOperatorInput | null,
  specialQuestionCategory?: ModelSpecialQuestionCategoryInput | null,
  and?: Array< ModelDeviceQualificationExpressionFilterInput | null > | null,
  or?: Array< ModelDeviceQualificationExpressionFilterInput | null > | null,
  not?: ModelDeviceQualificationExpressionFilterInput | null,
  deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: ModelIDInput | null,
  deviceQualificationExpressionYesNoQuestionId?: ModelIDInput | null,
};

export type ModelEntityTemplateFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  valueInt?: ModelIntInput | null,
  valueFloat?: ModelFloatInput | null,
  valueBoolean?: ModelBooleanInput | null,
  entityEnum?: ModelEntityEnumInput | null,
  and?: Array< ModelEntityTemplateFilterInput | null > | null,
  or?: Array< ModelEntityTemplateFilterInput | null > | null,
  not?: ModelEntityTemplateFilterInput | null,
  entityTemplateBelongsToEntityTemplatesId?: ModelIDInput | null,
  entityTemplateEntityTemplateHasOneId?: ModelIDInput | null,
};

export type ModelEntityTemplateBelongsToFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelEntityTemplateBelongsToFilterInput | null > | null,
  or?: Array< ModelEntityTemplateBelongsToFilterInput | null > | null,
  not?: ModelEntityTemplateBelongsToFilterInput | null,
};

export type ModelEntityTemplateBelongsToConnection = {
  __typename: "ModelEntityTemplateBelongsToConnection",
  items:  Array<EntityTemplateBelongsTo | null >,
  nextToken?: string | null,
};

export type ModelEntityTemplateHasOneFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelEntityTemplateHasOneFilterInput | null > | null,
  or?: Array< ModelEntityTemplateHasOneFilterInput | null > | null,
  not?: ModelEntityTemplateHasOneFilterInput | null,
};

export type ModelEntityTemplateHasOneConnection = {
  __typename: "ModelEntityTemplateHasOneConnection",
  items:  Array<EntityTemplateHasOne | null >,
  nextToken?: string | null,
};

export type ModelLabelFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelLabelFilterInput | null > | null,
  or?: Array< ModelLabelFilterInput | null > | null,
  not?: ModelLabelFilterInput | null,
};

export type ModelLabelConnection = {
  __typename: "ModelLabelConnection",
  items:  Array<Label | null >,
  nextToken?: string | null,
};

export type ModelMedflowVersionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  number?: ModelIntInput | null,
  and?: Array< ModelMedflowVersionFilterInput | null > | null,
  or?: Array< ModelMedflowVersionFilterInput | null > | null,
  not?: ModelMedflowVersionFilterInput | null,
};

export type ModelMedflowVersionConnection = {
  __typename: "ModelMedflowVersionConnection",
  items:  Array<MedflowVersion | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelPhaseFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelPhaseFilterInput | null > | null,
  or?: Array< ModelPhaseFilterInput | null > | null,
  not?: ModelPhaseFilterInput | null,
  phaseMedflowVersionId?: ModelIDInput | null,
};

export type ModelPhaseConnection = {
  __typename: "ModelPhaseConnection",
  items:  Array<Phase | null >,
  nextToken?: string | null,
};

export type ModelPreviewAnswerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  valueString?: ModelStringInput | null,
  valueInt?: ModelIntInput | null,
  valueFloat?: ModelFloatInput | null,
  valueBoolean?: ModelBooleanInput | null,
  and?: Array< ModelPreviewAnswerFilterInput | null > | null,
  or?: Array< ModelPreviewAnswerFilterInput | null > | null,
  not?: ModelPreviewAnswerFilterInput | null,
  previewProductPreviewAnswersId?: ModelIDInput | null,
  previewAnswerQuestionId?: ModelIDInput | null,
};

export type ModelPreviewProductFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  intendedUse?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelPreviewProductFilterInput | null > | null,
  or?: Array< ModelPreviewProductFilterInput | null > | null,
  not?: ModelPreviewProductFilterInput | null,
  previewOrganizationPreviewProductsId?: ModelIDInput | null,
  previewUserPreviewProductsId?: ModelIDInput | null,
};

export type ModelPreviewOrganizationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  registrationNumber?: ModelStringInput | null,
  and?: Array< ModelPreviewOrganizationFilterInput | null > | null,
  or?: Array< ModelPreviewOrganizationFilterInput | null > | null,
  not?: ModelPreviewOrganizationFilterInput | null,
};

export type ModelPreviewOrganizationConnection = {
  __typename: "ModelPreviewOrganizationConnection",
  items:  Array<PreviewOrganization | null >,
  nextToken?: string | null,
};

export type ModelPreviewUserFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  area?: ModelStringInput | null,
  and?: Array< ModelPreviewUserFilterInput | null > | null,
  or?: Array< ModelPreviewUserFilterInput | null > | null,
  not?: ModelPreviewUserFilterInput | null,
  previewOrganizationPreviewUsersId?: ModelIDInput | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  productCode?: ModelStringInput | null,
  gmdnDescription?: ModelStringInput | null,
  deviceClassification?: ModelDeviceClassificationInput | null,
  readers?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
  productMedflowVersionId?: ModelIDInput | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type ModelQuestionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  guidingBeforeText?: ModelStringInput | null,
  placeholder?: ModelStringInput | null,
  statement?: ModelStringInput | null,
  tooltip?: ModelStringInput | null,
  answerDataType?: ModelAnswerDataTypeInput | null,
  specialQuestionCategory?: ModelSpecialQuestionCategoryInput | null,
  examples?: ModelStringInput | null,
  explanations?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  and?: Array< ModelQuestionFilterInput | null > | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  not?: ModelQuestionFilterInput | null,
};

export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items:  Array<Question | null >,
  nextToken?: string | null,
};

export type ModelQuestionLabelFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelQuestionLabelFilterInput | null > | null,
  or?: Array< ModelQuestionLabelFilterInput | null > | null,
  not?: ModelQuestionLabelFilterInput | null,
  labelQuestionLabelsId?: ModelIDInput | null,
  questionQuestionLabelsId?: ModelIDInput | null,
};

export type ModelQuestionRowColumnFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  answerDataType?: ModelAnswerDataTypeInput | null,
  and?: Array< ModelQuestionRowColumnFilterInput | null > | null,
  or?: Array< ModelQuestionRowColumnFilterInput | null > | null,
  not?: ModelQuestionRowColumnFilterInput | null,
  questionQuestionRowColumnsId?: ModelIDInput | null,
};

export type ModelReportFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
  stageReportsId?: ModelIDInput | null,
};

export type ModelReportQuestionnaireFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  deviceClassification?: ModelDeviceClassificationInput | null,
  and?: Array< ModelReportQuestionnaireFilterInput | null > | null,
  or?: Array< ModelReportQuestionnaireFilterInput | null > | null,
  not?: ModelReportQuestionnaireFilterInput | null,
  questionReportQuestionnairesId?: ModelIDInput | null,
  reportReportQuestionnairesId?: ModelIDInput | null,
  reportQuestionnaireSectionReportQuestionnairesId?: ModelIDInput | null,
};

export type ModelReportQuestionnaireSectionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  deviceClassification?: ModelDeviceClassificationInput | null,
  and?: Array< ModelReportQuestionnaireSectionFilterInput | null > | null,
  or?: Array< ModelReportQuestionnaireSectionFilterInput | null > | null,
  not?: ModelReportQuestionnaireSectionFilterInput | null,
  reportReportQuestionnaireSectionsId?: ModelIDInput | null,
};

export type ModelRowColumnAnswerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  version?: ModelIntInput | null,
  readers?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  valueString?: ModelStringInput | null,
  valueInt?: ModelIntInput | null,
  valueFloat?: ModelFloatInput | null,
  valueBoolean?: ModelBooleanInput | null,
  and?: Array< ModelRowColumnAnswerFilterInput | null > | null,
  or?: Array< ModelRowColumnAnswerFilterInput | null > | null,
  not?: ModelRowColumnAnswerFilterInput | null,
  answerRowColumnAnswersId?: ModelIDInput | null,
  rowColumnAnswerQuestionRowColumnId?: ModelIDInput | null,
};

export type ModelStageFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelStageFilterInput | null > | null,
  or?: Array< ModelStageFilterInput | null > | null,
  not?: ModelStageFilterInput | null,
  phaseStagesId?: ModelIDInput | null,
  trackStagesId?: ModelIDInput | null,
  stageMedflowVersionId?: ModelIDInput | null,
};

export type ModelStageQuestionnaireFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  deviceClassification?: ModelDeviceClassificationInput | null,
  and?: Array< ModelStageQuestionnaireFilterInput | null > | null,
  or?: Array< ModelStageQuestionnaireFilterInput | null > | null,
  not?: ModelStageQuestionnaireFilterInput | null,
  questionStageQuestionnairesId?: ModelIDInput | null,
  stageStageQuestionnairesId?: ModelIDInput | null,
  stageQuestionnaireSectionStageQuestionnairesId?: ModelIDInput | null,
};

export type ModelStageQuestionnaireSectionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  order?: ModelIntInput | null,
  deviceClassification?: ModelDeviceClassificationInput | null,
  and?: Array< ModelStageQuestionnaireSectionFilterInput | null > | null,
  or?: Array< ModelStageQuestionnaireSectionFilterInput | null > | null,
  not?: ModelStageQuestionnaireSectionFilterInput | null,
  stageStageQuestionnaireSectionsId?: ModelIDInput | null,
};

export type ModelTrackFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelTrackFilterInput | null > | null,
  or?: Array< ModelTrackFilterInput | null > | null,
  not?: ModelTrackFilterInput | null,
  trackMedflowVersionId?: ModelIDInput | null,
};

export type ModelTrackConnection = {
  __typename: "ModelTrackConnection",
  items:  Array<Track | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionAnswerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionIntInput | null,
  valueString?: ModelSubscriptionStringInput | null,
  valueInt?: ModelSubscriptionIntInput | null,
  valueFloat?: ModelSubscriptionFloatInput | null,
  valueBoolean?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionAnswerFilterInput | null > | null,
  or?: Array< ModelSubscriptionAnswerFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionDeviceClassificationExpressionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  index?: ModelSubscriptionIntInput | null,
  isRoot?: ModelSubscriptionBooleanInput | null,
  statement?: ModelSubscriptionStringInput | null,
  tooltip?: ModelSubscriptionStringInput | null,
  explanations?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  classificationIfYes?: ModelSubscriptionStringInput | null,
  operator?: ModelSubscriptionStringInput | null,
  specialQuestionCategory?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDeviceClassificationExpressionFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeviceClassificationExpressionFilterInput | null > | null,
};

export type ModelSubscriptionDeviceQualificationExpressionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  index?: ModelSubscriptionIntInput | null,
  isRoot?: ModelSubscriptionBooleanInput | null,
  statement?: ModelSubscriptionStringInput | null,
  qualificationIfYes?: ModelSubscriptionStringInput | null,
  operator?: ModelSubscriptionStringInput | null,
  specialQuestionCategory?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDeviceQualificationExpressionFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeviceQualificationExpressionFilterInput | null > | null,
};

export type ModelSubscriptionEntityTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  valueInt?: ModelSubscriptionIntInput | null,
  valueFloat?: ModelSubscriptionFloatInput | null,
  valueBoolean?: ModelSubscriptionBooleanInput | null,
  entityEnum?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEntityTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionEntityTemplateFilterInput | null > | null,
};

export type ModelSubscriptionEntityTemplateBelongsToFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEntityTemplateBelongsToFilterInput | null > | null,
  or?: Array< ModelSubscriptionEntityTemplateBelongsToFilterInput | null > | null,
};

export type ModelSubscriptionEntityTemplateHasOneFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEntityTemplateHasOneFilterInput | null > | null,
  or?: Array< ModelSubscriptionEntityTemplateHasOneFilterInput | null > | null,
};

export type ModelSubscriptionLabelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLabelFilterInput | null > | null,
  or?: Array< ModelSubscriptionLabelFilterInput | null > | null,
};

export type ModelSubscriptionMedflowVersionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  number?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionMedflowVersionFilterInput | null > | null,
  or?: Array< ModelSubscriptionMedflowVersionFilterInput | null > | null,
};

export type ModelSubscriptionPhaseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  order?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionPhaseFilterInput | null > | null,
  or?: Array< ModelSubscriptionPhaseFilterInput | null > | null,
};

export type ModelSubscriptionPreviewAnswerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  valueString?: ModelSubscriptionStringInput | null,
  valueInt?: ModelSubscriptionIntInput | null,
  valueFloat?: ModelSubscriptionFloatInput | null,
  valueBoolean?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionPreviewAnswerFilterInput | null > | null,
  or?: Array< ModelSubscriptionPreviewAnswerFilterInput | null > | null,
};

export type ModelSubscriptionPreviewProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  intendedUse?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPreviewProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionPreviewProductFilterInput | null > | null,
};

export type ModelSubscriptionPreviewOrganizationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  registrationNumber?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPreviewOrganizationFilterInput | null > | null,
  or?: Array< ModelSubscriptionPreviewOrganizationFilterInput | null > | null,
};

export type ModelSubscriptionPreviewUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  area?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPreviewUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionPreviewUserFilterInput | null > | null,
};

export type ModelSubscriptionProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  productCode?: ModelSubscriptionStringInput | null,
  gmdnDescription?: ModelSubscriptionStringInput | null,
  deviceClassification?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductFilterInput | null > | null,
};

export type ModelSubscriptionQuestionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  guidingBeforeText?: ModelSubscriptionStringInput | null,
  placeholder?: ModelSubscriptionStringInput | null,
  statement?: ModelSubscriptionStringInput | null,
  tooltip?: ModelSubscriptionStringInput | null,
  answerDataType?: ModelSubscriptionStringInput | null,
  specialQuestionCategory?: ModelSubscriptionStringInput | null,
  examples?: ModelSubscriptionStringInput | null,
  explanations?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
};

export type ModelSubscriptionQuestionLabelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuestionLabelFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionLabelFilterInput | null > | null,
};

export type ModelSubscriptionQuestionRowColumnFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  order?: ModelSubscriptionIntInput | null,
  answerDataType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuestionRowColumnFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionRowColumnFilterInput | null > | null,
};

export type ModelSubscriptionReportFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReportFilterInput | null > | null,
  or?: Array< ModelSubscriptionReportFilterInput | null > | null,
};

export type ModelSubscriptionReportQuestionnaireFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  order?: ModelSubscriptionIntInput | null,
  deviceClassification?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReportQuestionnaireFilterInput | null > | null,
  or?: Array< ModelSubscriptionReportQuestionnaireFilterInput | null > | null,
};

export type ModelSubscriptionReportQuestionnaireSectionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  order?: ModelSubscriptionIntInput | null,
  deviceClassification?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReportQuestionnaireSectionFilterInput | null > | null,
  or?: Array< ModelSubscriptionReportQuestionnaireSectionFilterInput | null > | null,
};

export type ModelSubscriptionRowColumnAnswerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionIntInput | null,
  valueString?: ModelSubscriptionStringInput | null,
  valueInt?: ModelSubscriptionIntInput | null,
  valueFloat?: ModelSubscriptionFloatInput | null,
  valueBoolean?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionRowColumnAnswerFilterInput | null > | null,
  or?: Array< ModelSubscriptionRowColumnAnswerFilterInput | null > | null,
};

export type ModelSubscriptionStageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  order?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionStageFilterInput | null > | null,
  or?: Array< ModelSubscriptionStageFilterInput | null > | null,
};

export type ModelSubscriptionStageQuestionnaireFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  order?: ModelSubscriptionIntInput | null,
  deviceClassification?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStageQuestionnaireFilterInput | null > | null,
  or?: Array< ModelSubscriptionStageQuestionnaireFilterInput | null > | null,
};

export type ModelSubscriptionStageQuestionnaireSectionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  order?: ModelSubscriptionIntInput | null,
  deviceClassification?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStageQuestionnaireSectionFilterInput | null > | null,
  or?: Array< ModelSubscriptionStageQuestionnaireSectionFilterInput | null > | null,
};

export type ModelSubscriptionTrackFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTrackFilterInput | null > | null,
  or?: Array< ModelSubscriptionTrackFilterInput | null > | null,
};

export type CreateAnswerMutationVariables = {
  input: CreateAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type CreateAnswerMutation = {
  createAnswer?:  {
    __typename: "Answer",
    id: string,
    name: string,
    version?: number | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      productCode?: string | null,
      gmdnDescription?: string | null,
      deviceClassification?: DeviceClassification | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productMedflowVersionId?: string | null,
      owner?: string | null,
    } | null,
    rowColumnAnswers?:  {
      __typename: "ModelRowColumnAnswerConnection",
      items:  Array< {
        __typename: "RowColumnAnswer",
        id: string,
        name: string,
        version?: number | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        answerRowColumnAnswersId?: string | null,
        rowColumnAnswerQuestionRowColumnId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productAnswersId?: string | null,
    answerQuestionId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateAnswerMutationVariables = {
  input: UpdateAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type UpdateAnswerMutation = {
  updateAnswer?:  {
    __typename: "Answer",
    id: string,
    name: string,
    version?: number | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      productCode?: string | null,
      gmdnDescription?: string | null,
      deviceClassification?: DeviceClassification | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productMedflowVersionId?: string | null,
      owner?: string | null,
    } | null,
    rowColumnAnswers?:  {
      __typename: "ModelRowColumnAnswerConnection",
      items:  Array< {
        __typename: "RowColumnAnswer",
        id: string,
        name: string,
        version?: number | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        answerRowColumnAnswersId?: string | null,
        rowColumnAnswerQuestionRowColumnId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productAnswersId?: string | null,
    answerQuestionId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteAnswerMutationVariables = {
  input: DeleteAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type DeleteAnswerMutation = {
  deleteAnswer?:  {
    __typename: "Answer",
    id: string,
    name: string,
    version?: number | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      productCode?: string | null,
      gmdnDescription?: string | null,
      deviceClassification?: DeviceClassification | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productMedflowVersionId?: string | null,
      owner?: string | null,
    } | null,
    rowColumnAnswers?:  {
      __typename: "ModelRowColumnAnswerConnection",
      items:  Array< {
        __typename: "RowColumnAnswer",
        id: string,
        name: string,
        version?: number | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        answerRowColumnAnswersId?: string | null,
        rowColumnAnswerQuestionRowColumnId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productAnswersId?: string | null,
    answerQuestionId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateDeviceClassificationExpressionMutationVariables = {
  input: CreateDeviceClassificationExpressionInput,
  condition?: ModelDeviceClassificationExpressionConditionInput | null,
};

export type CreateDeviceClassificationExpressionMutation = {
  createDeviceClassificationExpression?:  {
    __typename: "DeviceClassificationExpression",
    id: string,
    name: string,
    category?: string | null,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    tooltip?: string | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    classificationIfYes?: DeviceClassification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceClassificationExpression?:  {
      __typename: "DeviceClassificationExpression",
      id: string,
      name: string,
      category?: string | null,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      tooltip?: string | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      classificationIfYes?: DeviceClassification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceClassificationExpression?:  {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceClassificationExpressions?:  {
        __typename: "ModelDeviceClassificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
      deviceClassificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceClassificationExpressions?:  {
      __typename: "ModelDeviceClassificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
    deviceClassificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type UpdateDeviceClassificationExpressionMutationVariables = {
  input: UpdateDeviceClassificationExpressionInput,
  condition?: ModelDeviceClassificationExpressionConditionInput | null,
};

export type UpdateDeviceClassificationExpressionMutation = {
  updateDeviceClassificationExpression?:  {
    __typename: "DeviceClassificationExpression",
    id: string,
    name: string,
    category?: string | null,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    tooltip?: string | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    classificationIfYes?: DeviceClassification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceClassificationExpression?:  {
      __typename: "DeviceClassificationExpression",
      id: string,
      name: string,
      category?: string | null,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      tooltip?: string | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      classificationIfYes?: DeviceClassification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceClassificationExpression?:  {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceClassificationExpressions?:  {
        __typename: "ModelDeviceClassificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
      deviceClassificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceClassificationExpressions?:  {
      __typename: "ModelDeviceClassificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
    deviceClassificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type DeleteDeviceClassificationExpressionMutationVariables = {
  input: DeleteDeviceClassificationExpressionInput,
  condition?: ModelDeviceClassificationExpressionConditionInput | null,
};

export type DeleteDeviceClassificationExpressionMutation = {
  deleteDeviceClassificationExpression?:  {
    __typename: "DeviceClassificationExpression",
    id: string,
    name: string,
    category?: string | null,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    tooltip?: string | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    classificationIfYes?: DeviceClassification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceClassificationExpression?:  {
      __typename: "DeviceClassificationExpression",
      id: string,
      name: string,
      category?: string | null,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      tooltip?: string | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      classificationIfYes?: DeviceClassification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceClassificationExpression?:  {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceClassificationExpressions?:  {
        __typename: "ModelDeviceClassificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
      deviceClassificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceClassificationExpressions?:  {
      __typename: "ModelDeviceClassificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
    deviceClassificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type CreateDeviceQualificationExpressionMutationVariables = {
  input: CreateDeviceQualificationExpressionInput,
  condition?: ModelDeviceQualificationExpressionConditionInput | null,
};

export type CreateDeviceQualificationExpressionMutation = {
  createDeviceQualificationExpression?:  {
    __typename: "DeviceQualificationExpression",
    id: string,
    name: string,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    qualificationIfYes?: DeviceQualification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceQualificationExpression?:  {
      __typename: "DeviceQualificationExpression",
      id: string,
      name: string,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      qualificationIfYes?: DeviceQualification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceQualificationExpression?:  {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceQualificationExpressions?:  {
        __typename: "ModelDeviceQualificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
      deviceQualificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceQualificationExpressions?:  {
      __typename: "ModelDeviceQualificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
    deviceQualificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type UpdateDeviceQualificationExpressionMutationVariables = {
  input: UpdateDeviceQualificationExpressionInput,
  condition?: ModelDeviceQualificationExpressionConditionInput | null,
};

export type UpdateDeviceQualificationExpressionMutation = {
  updateDeviceQualificationExpression?:  {
    __typename: "DeviceQualificationExpression",
    id: string,
    name: string,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    qualificationIfYes?: DeviceQualification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceQualificationExpression?:  {
      __typename: "DeviceQualificationExpression",
      id: string,
      name: string,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      qualificationIfYes?: DeviceQualification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceQualificationExpression?:  {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceQualificationExpressions?:  {
        __typename: "ModelDeviceQualificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
      deviceQualificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceQualificationExpressions?:  {
      __typename: "ModelDeviceQualificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
    deviceQualificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type DeleteDeviceQualificationExpressionMutationVariables = {
  input: DeleteDeviceQualificationExpressionInput,
  condition?: ModelDeviceQualificationExpressionConditionInput | null,
};

export type DeleteDeviceQualificationExpressionMutation = {
  deleteDeviceQualificationExpression?:  {
    __typename: "DeviceQualificationExpression",
    id: string,
    name: string,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    qualificationIfYes?: DeviceQualification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceQualificationExpression?:  {
      __typename: "DeviceQualificationExpression",
      id: string,
      name: string,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      qualificationIfYes?: DeviceQualification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceQualificationExpression?:  {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceQualificationExpressions?:  {
        __typename: "ModelDeviceQualificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
      deviceQualificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceQualificationExpressions?:  {
      __typename: "ModelDeviceQualificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
    deviceQualificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type CreateEntityTemplateMutationVariables = {
  input: CreateEntityTemplateInput,
  condition?: ModelEntityTemplateConditionInput | null,
};

export type CreateEntityTemplateMutation = {
  createEntityTemplate?:  {
    __typename: "EntityTemplate",
    id: string,
    name: string,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    entityEnum?: EntityEnum | null,
    entityTemplateHasOne?:  {
      __typename: "EntityTemplateHasOne",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    entityTemplateBelongsTo?:  {
      __typename: "EntityTemplateBelongsTo",
      id: string,
      name: string,
      entityTemplates?:  {
        __typename: "ModelEntityTemplateConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    entityTemplateBelongsToEntityTemplatesId?: string | null,
    entityTemplateEntityTemplateHasOneId?: string | null,
  } | null,
};

export type UpdateEntityTemplateMutationVariables = {
  input: UpdateEntityTemplateInput,
  condition?: ModelEntityTemplateConditionInput | null,
};

export type UpdateEntityTemplateMutation = {
  updateEntityTemplate?:  {
    __typename: "EntityTemplate",
    id: string,
    name: string,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    entityEnum?: EntityEnum | null,
    entityTemplateHasOne?:  {
      __typename: "EntityTemplateHasOne",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    entityTemplateBelongsTo?:  {
      __typename: "EntityTemplateBelongsTo",
      id: string,
      name: string,
      entityTemplates?:  {
        __typename: "ModelEntityTemplateConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    entityTemplateBelongsToEntityTemplatesId?: string | null,
    entityTemplateEntityTemplateHasOneId?: string | null,
  } | null,
};

export type DeleteEntityTemplateMutationVariables = {
  input: DeleteEntityTemplateInput,
  condition?: ModelEntityTemplateConditionInput | null,
};

export type DeleteEntityTemplateMutation = {
  deleteEntityTemplate?:  {
    __typename: "EntityTemplate",
    id: string,
    name: string,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    entityEnum?: EntityEnum | null,
    entityTemplateHasOne?:  {
      __typename: "EntityTemplateHasOne",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    entityTemplateBelongsTo?:  {
      __typename: "EntityTemplateBelongsTo",
      id: string,
      name: string,
      entityTemplates?:  {
        __typename: "ModelEntityTemplateConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    entityTemplateBelongsToEntityTemplatesId?: string | null,
    entityTemplateEntityTemplateHasOneId?: string | null,
  } | null,
};

export type CreateEntityTemplateBelongsToMutationVariables = {
  input: CreateEntityTemplateBelongsToInput,
  condition?: ModelEntityTemplateBelongsToConditionInput | null,
};

export type CreateEntityTemplateBelongsToMutation = {
  createEntityTemplateBelongsTo?:  {
    __typename: "EntityTemplateBelongsTo",
    id: string,
    name: string,
    entityTemplates?:  {
      __typename: "ModelEntityTemplateConnection",
      items:  Array< {
        __typename: "EntityTemplate",
        id: string,
        name: string,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        entityEnum?: EntityEnum | null,
        createdAt: string,
        updatedAt: string,
        entityTemplateBelongsToEntityTemplatesId?: string | null,
        entityTemplateEntityTemplateHasOneId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEntityTemplateBelongsToMutationVariables = {
  input: UpdateEntityTemplateBelongsToInput,
  condition?: ModelEntityTemplateBelongsToConditionInput | null,
};

export type UpdateEntityTemplateBelongsToMutation = {
  updateEntityTemplateBelongsTo?:  {
    __typename: "EntityTemplateBelongsTo",
    id: string,
    name: string,
    entityTemplates?:  {
      __typename: "ModelEntityTemplateConnection",
      items:  Array< {
        __typename: "EntityTemplate",
        id: string,
        name: string,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        entityEnum?: EntityEnum | null,
        createdAt: string,
        updatedAt: string,
        entityTemplateBelongsToEntityTemplatesId?: string | null,
        entityTemplateEntityTemplateHasOneId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEntityTemplateBelongsToMutationVariables = {
  input: DeleteEntityTemplateBelongsToInput,
  condition?: ModelEntityTemplateBelongsToConditionInput | null,
};

export type DeleteEntityTemplateBelongsToMutation = {
  deleteEntityTemplateBelongsTo?:  {
    __typename: "EntityTemplateBelongsTo",
    id: string,
    name: string,
    entityTemplates?:  {
      __typename: "ModelEntityTemplateConnection",
      items:  Array< {
        __typename: "EntityTemplate",
        id: string,
        name: string,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        entityEnum?: EntityEnum | null,
        createdAt: string,
        updatedAt: string,
        entityTemplateBelongsToEntityTemplatesId?: string | null,
        entityTemplateEntityTemplateHasOneId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEntityTemplateHasOneMutationVariables = {
  input: CreateEntityTemplateHasOneInput,
  condition?: ModelEntityTemplateHasOneConditionInput | null,
};

export type CreateEntityTemplateHasOneMutation = {
  createEntityTemplateHasOne?:  {
    __typename: "EntityTemplateHasOne",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEntityTemplateHasOneMutationVariables = {
  input: UpdateEntityTemplateHasOneInput,
  condition?: ModelEntityTemplateHasOneConditionInput | null,
};

export type UpdateEntityTemplateHasOneMutation = {
  updateEntityTemplateHasOne?:  {
    __typename: "EntityTemplateHasOne",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEntityTemplateHasOneMutationVariables = {
  input: DeleteEntityTemplateHasOneInput,
  condition?: ModelEntityTemplateHasOneConditionInput | null,
};

export type DeleteEntityTemplateHasOneMutation = {
  deleteEntityTemplateHasOne?:  {
    __typename: "EntityTemplateHasOne",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLabelMutationVariables = {
  input: CreateLabelInput,
  condition?: ModelLabelConditionInput | null,
};

export type CreateLabelMutation = {
  createLabel?:  {
    __typename: "Label",
    id: string,
    name: string,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLabelMutationVariables = {
  input: UpdateLabelInput,
  condition?: ModelLabelConditionInput | null,
};

export type UpdateLabelMutation = {
  updateLabel?:  {
    __typename: "Label",
    id: string,
    name: string,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLabelMutationVariables = {
  input: DeleteLabelInput,
  condition?: ModelLabelConditionInput | null,
};

export type DeleteLabelMutation = {
  deleteLabel?:  {
    __typename: "Label",
    id: string,
    name: string,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMedflowVersionMutationVariables = {
  input: CreateMedflowVersionInput,
  condition?: ModelMedflowVersionConditionInput | null,
};

export type CreateMedflowVersionMutation = {
  createMedflowVersion?:  {
    __typename: "MedflowVersion",
    id?: string | null,
    name: string,
    number: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMedflowVersionMutationVariables = {
  input: UpdateMedflowVersionInput,
  condition?: ModelMedflowVersionConditionInput | null,
};

export type UpdateMedflowVersionMutation = {
  updateMedflowVersion?:  {
    __typename: "MedflowVersion",
    id?: string | null,
    name: string,
    number: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMedflowVersionMutationVariables = {
  input: DeleteMedflowVersionInput,
  condition?: ModelMedflowVersionConditionInput | null,
};

export type DeleteMedflowVersionMutation = {
  deleteMedflowVersion?:  {
    __typename: "MedflowVersion",
    id?: string | null,
    name: string,
    number: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePhaseMutationVariables = {
  input: CreatePhaseInput,
  condition?: ModelPhaseConditionInput | null,
};

export type CreatePhaseMutation = {
  createPhase?:  {
    __typename: "Phase",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseMedflowVersionId?: string | null,
  } | null,
};

export type UpdatePhaseMutationVariables = {
  input: UpdatePhaseInput,
  condition?: ModelPhaseConditionInput | null,
};

export type UpdatePhaseMutation = {
  updatePhase?:  {
    __typename: "Phase",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseMedflowVersionId?: string | null,
  } | null,
};

export type DeletePhaseMutationVariables = {
  input: DeletePhaseInput,
  condition?: ModelPhaseConditionInput | null,
};

export type DeletePhaseMutation = {
  deletePhase?:  {
    __typename: "Phase",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseMedflowVersionId?: string | null,
  } | null,
};

export type CreatePreviewAnswerMutationVariables = {
  input: CreatePreviewAnswerInput,
  condition?: ModelPreviewAnswerConditionInput | null,
};

export type CreatePreviewAnswerMutation = {
  createPreviewAnswer?:  {
    __typename: "PreviewAnswer",
    id: string,
    name: string,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewProduct?:  {
      __typename: "PreviewProduct",
      id: string,
      name: string,
      intendedUse: string,
      description: string,
      previewUser?:  {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      previewAnswers?:  {
        __typename: "ModelPreviewAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewProductsId?: string | null,
      previewUserPreviewProductsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewProductPreviewAnswersId?: string | null,
    previewAnswerQuestionId?: string | null,
  } | null,
};

export type UpdatePreviewAnswerMutationVariables = {
  input: UpdatePreviewAnswerInput,
  condition?: ModelPreviewAnswerConditionInput | null,
};

export type UpdatePreviewAnswerMutation = {
  updatePreviewAnswer?:  {
    __typename: "PreviewAnswer",
    id: string,
    name: string,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewProduct?:  {
      __typename: "PreviewProduct",
      id: string,
      name: string,
      intendedUse: string,
      description: string,
      previewUser?:  {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      previewAnswers?:  {
        __typename: "ModelPreviewAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewProductsId?: string | null,
      previewUserPreviewProductsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewProductPreviewAnswersId?: string | null,
    previewAnswerQuestionId?: string | null,
  } | null,
};

export type DeletePreviewAnswerMutationVariables = {
  input: DeletePreviewAnswerInput,
  condition?: ModelPreviewAnswerConditionInput | null,
};

export type DeletePreviewAnswerMutation = {
  deletePreviewAnswer?:  {
    __typename: "PreviewAnswer",
    id: string,
    name: string,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewProduct?:  {
      __typename: "PreviewProduct",
      id: string,
      name: string,
      intendedUse: string,
      description: string,
      previewUser?:  {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      previewAnswers?:  {
        __typename: "ModelPreviewAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewProductsId?: string | null,
      previewUserPreviewProductsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewProductPreviewAnswersId?: string | null,
    previewAnswerQuestionId?: string | null,
  } | null,
};

export type CreatePreviewProductMutationVariables = {
  input: CreatePreviewProductInput,
  condition?: ModelPreviewProductConditionInput | null,
};

export type CreatePreviewProductMutation = {
  createPreviewProduct?:  {
    __typename: "PreviewProduct",
    id: string,
    name: string,
    intendedUse: string,
    description: string,
    previewUser?:  {
      __typename: "PreviewUser",
      id: string,
      name: string,
      email: string,
      phoneNumber?: string | null,
      area: string,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewUsersId?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewAnswers?:  {
      __typename: "ModelPreviewAnswerConnection",
      items:  Array< {
        __typename: "PreviewAnswer",
        id: string,
        name: string,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        previewProductPreviewAnswersId?: string | null,
        previewAnswerQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewProductsId?: string | null,
    previewUserPreviewProductsId?: string | null,
  } | null,
};

export type UpdatePreviewProductMutationVariables = {
  input: UpdatePreviewProductInput,
  condition?: ModelPreviewProductConditionInput | null,
};

export type UpdatePreviewProductMutation = {
  updatePreviewProduct?:  {
    __typename: "PreviewProduct",
    id: string,
    name: string,
    intendedUse: string,
    description: string,
    previewUser?:  {
      __typename: "PreviewUser",
      id: string,
      name: string,
      email: string,
      phoneNumber?: string | null,
      area: string,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewUsersId?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewAnswers?:  {
      __typename: "ModelPreviewAnswerConnection",
      items:  Array< {
        __typename: "PreviewAnswer",
        id: string,
        name: string,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        previewProductPreviewAnswersId?: string | null,
        previewAnswerQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewProductsId?: string | null,
    previewUserPreviewProductsId?: string | null,
  } | null,
};

export type DeletePreviewProductMutationVariables = {
  input: DeletePreviewProductInput,
  condition?: ModelPreviewProductConditionInput | null,
};

export type DeletePreviewProductMutation = {
  deletePreviewProduct?:  {
    __typename: "PreviewProduct",
    id: string,
    name: string,
    intendedUse: string,
    description: string,
    previewUser?:  {
      __typename: "PreviewUser",
      id: string,
      name: string,
      email: string,
      phoneNumber?: string | null,
      area: string,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewUsersId?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewAnswers?:  {
      __typename: "ModelPreviewAnswerConnection",
      items:  Array< {
        __typename: "PreviewAnswer",
        id: string,
        name: string,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        previewProductPreviewAnswersId?: string | null,
        previewAnswerQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewProductsId?: string | null,
    previewUserPreviewProductsId?: string | null,
  } | null,
};

export type CreatePreviewOrganizationMutationVariables = {
  input: CreatePreviewOrganizationInput,
  condition?: ModelPreviewOrganizationConditionInput | null,
};

export type CreatePreviewOrganizationMutation = {
  createPreviewOrganization?:  {
    __typename: "PreviewOrganization",
    id: string,
    name: string,
    registrationNumber: string,
    previewUsers?:  {
      __typename: "ModelPreviewUserConnection",
      items:  Array< {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePreviewOrganizationMutationVariables = {
  input: UpdatePreviewOrganizationInput,
  condition?: ModelPreviewOrganizationConditionInput | null,
};

export type UpdatePreviewOrganizationMutation = {
  updatePreviewOrganization?:  {
    __typename: "PreviewOrganization",
    id: string,
    name: string,
    registrationNumber: string,
    previewUsers?:  {
      __typename: "ModelPreviewUserConnection",
      items:  Array< {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePreviewOrganizationMutationVariables = {
  input: DeletePreviewOrganizationInput,
  condition?: ModelPreviewOrganizationConditionInput | null,
};

export type DeletePreviewOrganizationMutation = {
  deletePreviewOrganization?:  {
    __typename: "PreviewOrganization",
    id: string,
    name: string,
    registrationNumber: string,
    previewUsers?:  {
      __typename: "ModelPreviewUserConnection",
      items:  Array< {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePreviewUserMutationVariables = {
  input: CreatePreviewUserInput,
  condition?: ModelPreviewUserConditionInput | null,
};

export type CreatePreviewUserMutation = {
  createPreviewUser?:  {
    __typename: "PreviewUser",
    id: string,
    name: string,
    email: string,
    phoneNumber?: string | null,
    area: string,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewUsersId?: string | null,
  } | null,
};

export type UpdatePreviewUserMutationVariables = {
  input: UpdatePreviewUserInput,
  condition?: ModelPreviewUserConditionInput | null,
};

export type UpdatePreviewUserMutation = {
  updatePreviewUser?:  {
    __typename: "PreviewUser",
    id: string,
    name: string,
    email: string,
    phoneNumber?: string | null,
    area: string,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewUsersId?: string | null,
  } | null,
};

export type DeletePreviewUserMutationVariables = {
  input: DeletePreviewUserInput,
  condition?: ModelPreviewUserConditionInput | null,
};

export type DeletePreviewUserMutation = {
  deletePreviewUser?:  {
    __typename: "PreviewUser",
    id: string,
    name: string,
    email: string,
    phoneNumber?: string | null,
    area: string,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewUsersId?: string | null,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    productCode?: string | null,
    gmdnDescription?: string | null,
    deviceClassification?: DeviceClassification | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        name: string,
        version?: number | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productAnswersId?: string | null,
        answerQuestionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productMedflowVersionId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    productCode?: string | null,
    gmdnDescription?: string | null,
    deviceClassification?: DeviceClassification | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        name: string,
        version?: number | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productAnswersId?: string | null,
        answerQuestionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productMedflowVersionId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    productCode?: string | null,
    gmdnDescription?: string | null,
    deviceClassification?: DeviceClassification | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        name: string,
        version?: number | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productAnswersId?: string | null,
        answerQuestionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productMedflowVersionId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input: CreateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    name: string,
    description?: string | null,
    guidingBeforeText?: string | null,
    placeholder?: string | null,
    statement?: string | null,
    tooltip?: string | null,
    answerDataType: AnswerDataType,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    examples?: Array< string | null > | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionRowColumns?:  {
      __typename: "ModelQuestionRowColumnConnection",
      items:  Array< {
        __typename: "QuestionRowColumn",
        id: string,
        name: string,
        order: number,
        answerDataType: AnswerDataType,
        createdAt: string,
        updatedAt: string,
        questionQuestionRowColumnsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input: UpdateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    name: string,
    description?: string | null,
    guidingBeforeText?: string | null,
    placeholder?: string | null,
    statement?: string | null,
    tooltip?: string | null,
    answerDataType: AnswerDataType,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    examples?: Array< string | null > | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionRowColumns?:  {
      __typename: "ModelQuestionRowColumnConnection",
      items:  Array< {
        __typename: "QuestionRowColumn",
        id: string,
        name: string,
        order: number,
        answerDataType: AnswerDataType,
        createdAt: string,
        updatedAt: string,
        questionQuestionRowColumnsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input: DeleteQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    name: string,
    description?: string | null,
    guidingBeforeText?: string | null,
    placeholder?: string | null,
    statement?: string | null,
    tooltip?: string | null,
    answerDataType: AnswerDataType,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    examples?: Array< string | null > | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionRowColumns?:  {
      __typename: "ModelQuestionRowColumnConnection",
      items:  Array< {
        __typename: "QuestionRowColumn",
        id: string,
        name: string,
        order: number,
        answerDataType: AnswerDataType,
        createdAt: string,
        updatedAt: string,
        questionQuestionRowColumnsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuestionLabelMutationVariables = {
  input: CreateQuestionLabelInput,
  condition?: ModelQuestionLabelConditionInput | null,
};

export type CreateQuestionLabelMutation = {
  createQuestionLabel?:  {
    __typename: "QuestionLabel",
    id: string,
    name: string,
    label?:  {
      __typename: "Label",
      id: string,
      name: string,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    labelQuestionLabelsId?: string | null,
    questionQuestionLabelsId?: string | null,
  } | null,
};

export type UpdateQuestionLabelMutationVariables = {
  input: UpdateQuestionLabelInput,
  condition?: ModelQuestionLabelConditionInput | null,
};

export type UpdateQuestionLabelMutation = {
  updateQuestionLabel?:  {
    __typename: "QuestionLabel",
    id: string,
    name: string,
    label?:  {
      __typename: "Label",
      id: string,
      name: string,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    labelQuestionLabelsId?: string | null,
    questionQuestionLabelsId?: string | null,
  } | null,
};

export type DeleteQuestionLabelMutationVariables = {
  input: DeleteQuestionLabelInput,
  condition?: ModelQuestionLabelConditionInput | null,
};

export type DeleteQuestionLabelMutation = {
  deleteQuestionLabel?:  {
    __typename: "QuestionLabel",
    id: string,
    name: string,
    label?:  {
      __typename: "Label",
      id: string,
      name: string,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    labelQuestionLabelsId?: string | null,
    questionQuestionLabelsId?: string | null,
  } | null,
};

export type CreateQuestionRowColumnMutationVariables = {
  input: CreateQuestionRowColumnInput,
  condition?: ModelQuestionRowColumnConditionInput | null,
};

export type CreateQuestionRowColumnMutation = {
  createQuestionRowColumn?:  {
    __typename: "QuestionRowColumn",
    id: string,
    name: string,
    order: number,
    answerDataType: AnswerDataType,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionQuestionRowColumnsId?: string | null,
  } | null,
};

export type UpdateQuestionRowColumnMutationVariables = {
  input: UpdateQuestionRowColumnInput,
  condition?: ModelQuestionRowColumnConditionInput | null,
};

export type UpdateQuestionRowColumnMutation = {
  updateQuestionRowColumn?:  {
    __typename: "QuestionRowColumn",
    id: string,
    name: string,
    order: number,
    answerDataType: AnswerDataType,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionQuestionRowColumnsId?: string | null,
  } | null,
};

export type DeleteQuestionRowColumnMutationVariables = {
  input: DeleteQuestionRowColumnInput,
  condition?: ModelQuestionRowColumnConditionInput | null,
};

export type DeleteQuestionRowColumnMutation = {
  deleteQuestionRowColumn?:  {
    __typename: "QuestionRowColumn",
    id: string,
    name: string,
    order: number,
    answerDataType: AnswerDataType,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionQuestionRowColumnsId?: string | null,
  } | null,
};

export type CreateReportMutationVariables = {
  input: CreateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    id: string,
    name: string,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaireSections?:  {
      __typename: "ModelReportQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "ReportQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        reportReportQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageReportsId?: string | null,
  } | null,
};

export type UpdateReportMutationVariables = {
  input: UpdateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    id: string,
    name: string,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaireSections?:  {
      __typename: "ModelReportQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "ReportQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        reportReportQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageReportsId?: string | null,
  } | null,
};

export type DeleteReportMutationVariables = {
  input: DeleteReportInput,
  condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
  deleteReport?:  {
    __typename: "Report",
    id: string,
    name: string,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaireSections?:  {
      __typename: "ModelReportQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "ReportQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        reportReportQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageReportsId?: string | null,
  } | null,
};

export type CreateReportQuestionnaireMutationVariables = {
  input: CreateReportQuestionnaireInput,
  condition?: ModelReportQuestionnaireConditionInput | null,
};

export type CreateReportQuestionnaireMutation = {
  createReportQuestionnaire?:  {
    __typename: "ReportQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaireSection?:  {
      __typename: "ReportQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      report?:  {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      reportReportQuestionnaireSectionsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionReportQuestionnairesId?: string | null,
    reportReportQuestionnairesId?: string | null,
    reportQuestionnaireSectionReportQuestionnairesId?: string | null,
  } | null,
};

export type UpdateReportQuestionnaireMutationVariables = {
  input: UpdateReportQuestionnaireInput,
  condition?: ModelReportQuestionnaireConditionInput | null,
};

export type UpdateReportQuestionnaireMutation = {
  updateReportQuestionnaire?:  {
    __typename: "ReportQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaireSection?:  {
      __typename: "ReportQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      report?:  {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      reportReportQuestionnaireSectionsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionReportQuestionnairesId?: string | null,
    reportReportQuestionnairesId?: string | null,
    reportQuestionnaireSectionReportQuestionnairesId?: string | null,
  } | null,
};

export type DeleteReportQuestionnaireMutationVariables = {
  input: DeleteReportQuestionnaireInput,
  condition?: ModelReportQuestionnaireConditionInput | null,
};

export type DeleteReportQuestionnaireMutation = {
  deleteReportQuestionnaire?:  {
    __typename: "ReportQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaireSection?:  {
      __typename: "ReportQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      report?:  {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      reportReportQuestionnaireSectionsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionReportQuestionnairesId?: string | null,
    reportReportQuestionnairesId?: string | null,
    reportQuestionnaireSectionReportQuestionnairesId?: string | null,
  } | null,
};

export type CreateReportQuestionnaireSectionMutationVariables = {
  input: CreateReportQuestionnaireSectionInput,
  condition?: ModelReportQuestionnaireSectionConditionInput | null,
};

export type CreateReportQuestionnaireSectionMutation = {
  createReportQuestionnaireSection?:  {
    __typename: "ReportQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    reportReportQuestionnaireSectionsId?: string | null,
  } | null,
};

export type UpdateReportQuestionnaireSectionMutationVariables = {
  input: UpdateReportQuestionnaireSectionInput,
  condition?: ModelReportQuestionnaireSectionConditionInput | null,
};

export type UpdateReportQuestionnaireSectionMutation = {
  updateReportQuestionnaireSection?:  {
    __typename: "ReportQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    reportReportQuestionnaireSectionsId?: string | null,
  } | null,
};

export type DeleteReportQuestionnaireSectionMutationVariables = {
  input: DeleteReportQuestionnaireSectionInput,
  condition?: ModelReportQuestionnaireSectionConditionInput | null,
};

export type DeleteReportQuestionnaireSectionMutation = {
  deleteReportQuestionnaireSection?:  {
    __typename: "ReportQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    reportReportQuestionnaireSectionsId?: string | null,
  } | null,
};

export type CreateRowColumnAnswerMutationVariables = {
  input: CreateRowColumnAnswerInput,
  condition?: ModelRowColumnAnswerConditionInput | null,
};

export type CreateRowColumnAnswerMutation = {
  createRowColumnAnswer?:  {
    __typename: "RowColumnAnswer",
    id: string,
    name: string,
    version?: number | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    questionRowColumn?:  {
      __typename: "QuestionRowColumn",
      id: string,
      name: string,
      order: number,
      answerDataType: AnswerDataType,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionQuestionRowColumnsId?: string | null,
    } | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      name: string,
      version?: number | null,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        description?: string | null,
        productCode?: string | null,
        gmdnDescription?: string | null,
        deviceClassification?: DeviceClassification | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productMedflowVersionId?: string | null,
        owner?: string | null,
      } | null,
      rowColumnAnswers?:  {
        __typename: "ModelRowColumnAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productAnswersId?: string | null,
      answerQuestionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    answerRowColumnAnswersId?: string | null,
    rowColumnAnswerQuestionRowColumnId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateRowColumnAnswerMutationVariables = {
  input: UpdateRowColumnAnswerInput,
  condition?: ModelRowColumnAnswerConditionInput | null,
};

export type UpdateRowColumnAnswerMutation = {
  updateRowColumnAnswer?:  {
    __typename: "RowColumnAnswer",
    id: string,
    name: string,
    version?: number | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    questionRowColumn?:  {
      __typename: "QuestionRowColumn",
      id: string,
      name: string,
      order: number,
      answerDataType: AnswerDataType,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionQuestionRowColumnsId?: string | null,
    } | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      name: string,
      version?: number | null,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        description?: string | null,
        productCode?: string | null,
        gmdnDescription?: string | null,
        deviceClassification?: DeviceClassification | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productMedflowVersionId?: string | null,
        owner?: string | null,
      } | null,
      rowColumnAnswers?:  {
        __typename: "ModelRowColumnAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productAnswersId?: string | null,
      answerQuestionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    answerRowColumnAnswersId?: string | null,
    rowColumnAnswerQuestionRowColumnId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteRowColumnAnswerMutationVariables = {
  input: DeleteRowColumnAnswerInput,
  condition?: ModelRowColumnAnswerConditionInput | null,
};

export type DeleteRowColumnAnswerMutation = {
  deleteRowColumnAnswer?:  {
    __typename: "RowColumnAnswer",
    id: string,
    name: string,
    version?: number | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    questionRowColumn?:  {
      __typename: "QuestionRowColumn",
      id: string,
      name: string,
      order: number,
      answerDataType: AnswerDataType,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionQuestionRowColumnsId?: string | null,
    } | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      name: string,
      version?: number | null,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        description?: string | null,
        productCode?: string | null,
        gmdnDescription?: string | null,
        deviceClassification?: DeviceClassification | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productMedflowVersionId?: string | null,
        owner?: string | null,
      } | null,
      rowColumnAnswers?:  {
        __typename: "ModelRowColumnAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productAnswersId?: string | null,
      answerQuestionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    answerRowColumnAnswersId?: string | null,
    rowColumnAnswerQuestionRowColumnId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateStageMutationVariables = {
  input: CreateStageInput,
  condition?: ModelStageConditionInput | null,
};

export type CreateStageMutation = {
  createStage?:  {
    __typename: "Stage",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseMedflowVersionId?: string | null,
    } | null,
    track?:  {
      __typename: "Track",
      id: string,
      name: string,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      trackMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaireSections?:  {
      __typename: "ModelStageQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "StageQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        stageStageQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseStagesId?: string | null,
    trackStagesId?: string | null,
    stageMedflowVersionId?: string | null,
  } | null,
};

export type UpdateStageMutationVariables = {
  input: UpdateStageInput,
  condition?: ModelStageConditionInput | null,
};

export type UpdateStageMutation = {
  updateStage?:  {
    __typename: "Stage",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseMedflowVersionId?: string | null,
    } | null,
    track?:  {
      __typename: "Track",
      id: string,
      name: string,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      trackMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaireSections?:  {
      __typename: "ModelStageQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "StageQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        stageStageQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseStagesId?: string | null,
    trackStagesId?: string | null,
    stageMedflowVersionId?: string | null,
  } | null,
};

export type DeleteStageMutationVariables = {
  input: DeleteStageInput,
  condition?: ModelStageConditionInput | null,
};

export type DeleteStageMutation = {
  deleteStage?:  {
    __typename: "Stage",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseMedflowVersionId?: string | null,
    } | null,
    track?:  {
      __typename: "Track",
      id: string,
      name: string,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      trackMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaireSections?:  {
      __typename: "ModelStageQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "StageQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        stageStageQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseStagesId?: string | null,
    trackStagesId?: string | null,
    stageMedflowVersionId?: string | null,
  } | null,
};

export type CreateStageQuestionnaireMutationVariables = {
  input: CreateStageQuestionnaireInput,
  condition?: ModelStageQuestionnaireConditionInput | null,
};

export type CreateStageQuestionnaireMutation = {
  createStageQuestionnaire?:  {
    __typename: "StageQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stageQuestionnaireSection?:  {
      __typename: "StageQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageStageQuestionnaireSectionsId?: string | null,
    } | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionStageQuestionnairesId?: string | null,
    stageStageQuestionnairesId?: string | null,
    stageQuestionnaireSectionStageQuestionnairesId?: string | null,
  } | null,
};

export type UpdateStageQuestionnaireMutationVariables = {
  input: UpdateStageQuestionnaireInput,
  condition?: ModelStageQuestionnaireConditionInput | null,
};

export type UpdateStageQuestionnaireMutation = {
  updateStageQuestionnaire?:  {
    __typename: "StageQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stageQuestionnaireSection?:  {
      __typename: "StageQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageStageQuestionnaireSectionsId?: string | null,
    } | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionStageQuestionnairesId?: string | null,
    stageStageQuestionnairesId?: string | null,
    stageQuestionnaireSectionStageQuestionnairesId?: string | null,
  } | null,
};

export type DeleteStageQuestionnaireMutationVariables = {
  input: DeleteStageQuestionnaireInput,
  condition?: ModelStageQuestionnaireConditionInput | null,
};

export type DeleteStageQuestionnaireMutation = {
  deleteStageQuestionnaire?:  {
    __typename: "StageQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stageQuestionnaireSection?:  {
      __typename: "StageQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageStageQuestionnaireSectionsId?: string | null,
    } | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionStageQuestionnairesId?: string | null,
    stageStageQuestionnairesId?: string | null,
    stageQuestionnaireSectionStageQuestionnairesId?: string | null,
  } | null,
};

export type CreateStageQuestionnaireSectionMutationVariables = {
  input: CreateStageQuestionnaireSectionInput,
  condition?: ModelStageQuestionnaireSectionConditionInput | null,
};

export type CreateStageQuestionnaireSectionMutation = {
  createStageQuestionnaireSection?:  {
    __typename: "StageQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageStageQuestionnaireSectionsId?: string | null,
  } | null,
};

export type UpdateStageQuestionnaireSectionMutationVariables = {
  input: UpdateStageQuestionnaireSectionInput,
  condition?: ModelStageQuestionnaireSectionConditionInput | null,
};

export type UpdateStageQuestionnaireSectionMutation = {
  updateStageQuestionnaireSection?:  {
    __typename: "StageQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageStageQuestionnaireSectionsId?: string | null,
  } | null,
};

export type DeleteStageQuestionnaireSectionMutationVariables = {
  input: DeleteStageQuestionnaireSectionInput,
  condition?: ModelStageQuestionnaireSectionConditionInput | null,
};

export type DeleteStageQuestionnaireSectionMutation = {
  deleteStageQuestionnaireSection?:  {
    __typename: "StageQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageStageQuestionnaireSectionsId?: string | null,
  } | null,
};

export type CreateTrackMutationVariables = {
  input: CreateTrackInput,
  condition?: ModelTrackConditionInput | null,
};

export type CreateTrackMutation = {
  createTrack?:  {
    __typename: "Track",
    id: string,
    name: string,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    trackMedflowVersionId?: string | null,
  } | null,
};

export type UpdateTrackMutationVariables = {
  input: UpdateTrackInput,
  condition?: ModelTrackConditionInput | null,
};

export type UpdateTrackMutation = {
  updateTrack?:  {
    __typename: "Track",
    id: string,
    name: string,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    trackMedflowVersionId?: string | null,
  } | null,
};

export type DeleteTrackMutationVariables = {
  input: DeleteTrackInput,
  condition?: ModelTrackConditionInput | null,
};

export type DeleteTrackMutation = {
  deleteTrack?:  {
    __typename: "Track",
    id: string,
    name: string,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    trackMedflowVersionId?: string | null,
  } | null,
};

export type GetAnswerQueryVariables = {
  id: string,
};

export type GetAnswerQuery = {
  getAnswer?:  {
    __typename: "Answer",
    id: string,
    name: string,
    version?: number | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      productCode?: string | null,
      gmdnDescription?: string | null,
      deviceClassification?: DeviceClassification | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productMedflowVersionId?: string | null,
      owner?: string | null,
    } | null,
    rowColumnAnswers?:  {
      __typename: "ModelRowColumnAnswerConnection",
      items:  Array< {
        __typename: "RowColumnAnswer",
        id: string,
        name: string,
        version?: number | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        answerRowColumnAnswersId?: string | null,
        rowColumnAnswerQuestionRowColumnId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productAnswersId?: string | null,
    answerQuestionId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListAnswersQueryVariables = {
  filter?: ModelAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnswersQuery = {
  listAnswers?:  {
    __typename: "ModelAnswerConnection",
    items:  Array< {
      __typename: "Answer",
      id: string,
      name: string,
      version?: number | null,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        description?: string | null,
        productCode?: string | null,
        gmdnDescription?: string | null,
        deviceClassification?: DeviceClassification | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productMedflowVersionId?: string | null,
        owner?: string | null,
      } | null,
      rowColumnAnswers?:  {
        __typename: "ModelRowColumnAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productAnswersId?: string | null,
      answerQuestionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAnswersByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnswersByNameQuery = {
  listAnswersByName?:  {
    __typename: "ModelAnswerConnection",
    items:  Array< {
      __typename: "Answer",
      id: string,
      name: string,
      version?: number | null,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        description?: string | null,
        productCode?: string | null,
        gmdnDescription?: string | null,
        deviceClassification?: DeviceClassification | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productMedflowVersionId?: string | null,
        owner?: string | null,
      } | null,
      rowColumnAnswers?:  {
        __typename: "ModelRowColumnAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productAnswersId?: string | null,
      answerQuestionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeviceClassificationExpressionQueryVariables = {
  id: string,
};

export type GetDeviceClassificationExpressionQuery = {
  getDeviceClassificationExpression?:  {
    __typename: "DeviceClassificationExpression",
    id: string,
    name: string,
    category?: string | null,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    tooltip?: string | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    classificationIfYes?: DeviceClassification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceClassificationExpression?:  {
      __typename: "DeviceClassificationExpression",
      id: string,
      name: string,
      category?: string | null,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      tooltip?: string | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      classificationIfYes?: DeviceClassification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceClassificationExpression?:  {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceClassificationExpressions?:  {
        __typename: "ModelDeviceClassificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
      deviceClassificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceClassificationExpressions?:  {
      __typename: "ModelDeviceClassificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
    deviceClassificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type ListDeviceClassificationExpressionsQueryVariables = {
  filter?: ModelDeviceClassificationExpressionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeviceClassificationExpressionsQuery = {
  listDeviceClassificationExpressions?:  {
    __typename: "ModelDeviceClassificationExpressionConnection",
    items:  Array< {
      __typename: "DeviceClassificationExpression",
      id: string,
      name: string,
      category?: string | null,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      tooltip?: string | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      classificationIfYes?: DeviceClassification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceClassificationExpression?:  {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceClassificationExpressions?:  {
        __typename: "ModelDeviceClassificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
      deviceClassificationExpressionYesNoQuestionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDeviceClassificationExpressionsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeviceClassificationExpressionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeviceClassificationExpressionsByNameQuery = {
  listDeviceClassificationExpressionsByName?:  {
    __typename: "ModelDeviceClassificationExpressionConnection",
    items:  Array< {
      __typename: "DeviceClassificationExpression",
      id: string,
      name: string,
      category?: string | null,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      tooltip?: string | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      classificationIfYes?: DeviceClassification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceClassificationExpression?:  {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceClassificationExpressions?:  {
        __typename: "ModelDeviceClassificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
      deviceClassificationExpressionYesNoQuestionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeviceQualificationExpressionQueryVariables = {
  id: string,
};

export type GetDeviceQualificationExpressionQuery = {
  getDeviceQualificationExpression?:  {
    __typename: "DeviceQualificationExpression",
    id: string,
    name: string,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    qualificationIfYes?: DeviceQualification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceQualificationExpression?:  {
      __typename: "DeviceQualificationExpression",
      id: string,
      name: string,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      qualificationIfYes?: DeviceQualification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceQualificationExpression?:  {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceQualificationExpressions?:  {
        __typename: "ModelDeviceQualificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
      deviceQualificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceQualificationExpressions?:  {
      __typename: "ModelDeviceQualificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
    deviceQualificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type ListDeviceQualificationExpressionsQueryVariables = {
  filter?: ModelDeviceQualificationExpressionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeviceQualificationExpressionsQuery = {
  listDeviceQualificationExpressions?:  {
    __typename: "ModelDeviceQualificationExpressionConnection",
    items:  Array< {
      __typename: "DeviceQualificationExpression",
      id: string,
      name: string,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      qualificationIfYes?: DeviceQualification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceQualificationExpression?:  {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceQualificationExpressions?:  {
        __typename: "ModelDeviceQualificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
      deviceQualificationExpressionYesNoQuestionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDeviceQualificationExpressionsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeviceQualificationExpressionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeviceQualificationExpressionsByNameQuery = {
  listDeviceQualificationExpressionsByName?:  {
    __typename: "ModelDeviceQualificationExpressionConnection",
    items:  Array< {
      __typename: "DeviceQualificationExpression",
      id: string,
      name: string,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      qualificationIfYes?: DeviceQualification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceQualificationExpression?:  {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceQualificationExpressions?:  {
        __typename: "ModelDeviceQualificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
      deviceQualificationExpressionYesNoQuestionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEntityTemplateQueryVariables = {
  id: string,
};

export type GetEntityTemplateQuery = {
  getEntityTemplate?:  {
    __typename: "EntityTemplate",
    id: string,
    name: string,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    entityEnum?: EntityEnum | null,
    entityTemplateHasOne?:  {
      __typename: "EntityTemplateHasOne",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    entityTemplateBelongsTo?:  {
      __typename: "EntityTemplateBelongsTo",
      id: string,
      name: string,
      entityTemplates?:  {
        __typename: "ModelEntityTemplateConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    entityTemplateBelongsToEntityTemplatesId?: string | null,
    entityTemplateEntityTemplateHasOneId?: string | null,
  } | null,
};

export type ListEntityTemplatesQueryVariables = {
  filter?: ModelEntityTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityTemplatesQuery = {
  listEntityTemplates?:  {
    __typename: "ModelEntityTemplateConnection",
    items:  Array< {
      __typename: "EntityTemplate",
      id: string,
      name: string,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      entityEnum?: EntityEnum | null,
      entityTemplateHasOne?:  {
        __typename: "EntityTemplateHasOne",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      entityTemplateBelongsTo?:  {
        __typename: "EntityTemplateBelongsTo",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      entityTemplateBelongsToEntityTemplatesId?: string | null,
      entityTemplateEntityTemplateHasOneId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEntityTemplatesByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEntityTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityTemplatesByNameQuery = {
  listEntityTemplatesByName?:  {
    __typename: "ModelEntityTemplateConnection",
    items:  Array< {
      __typename: "EntityTemplate",
      id: string,
      name: string,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      entityEnum?: EntityEnum | null,
      entityTemplateHasOne?:  {
        __typename: "EntityTemplateHasOne",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      entityTemplateBelongsTo?:  {
        __typename: "EntityTemplateBelongsTo",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      entityTemplateBelongsToEntityTemplatesId?: string | null,
      entityTemplateEntityTemplateHasOneId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEntityTemplateBelongsToQueryVariables = {
  id: string,
};

export type GetEntityTemplateBelongsToQuery = {
  getEntityTemplateBelongsTo?:  {
    __typename: "EntityTemplateBelongsTo",
    id: string,
    name: string,
    entityTemplates?:  {
      __typename: "ModelEntityTemplateConnection",
      items:  Array< {
        __typename: "EntityTemplate",
        id: string,
        name: string,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        entityEnum?: EntityEnum | null,
        createdAt: string,
        updatedAt: string,
        entityTemplateBelongsToEntityTemplatesId?: string | null,
        entityTemplateEntityTemplateHasOneId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEntityTemplateBelongsTosQueryVariables = {
  filter?: ModelEntityTemplateBelongsToFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityTemplateBelongsTosQuery = {
  listEntityTemplateBelongsTos?:  {
    __typename: "ModelEntityTemplateBelongsToConnection",
    items:  Array< {
      __typename: "EntityTemplateBelongsTo",
      id: string,
      name: string,
      entityTemplates?:  {
        __typename: "ModelEntityTemplateConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEntityTemplateBelongTosByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEntityTemplateBelongsToFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityTemplateBelongTosByNameQuery = {
  listEntityTemplateBelongTosByName?:  {
    __typename: "ModelEntityTemplateBelongsToConnection",
    items:  Array< {
      __typename: "EntityTemplateBelongsTo",
      id: string,
      name: string,
      entityTemplates?:  {
        __typename: "ModelEntityTemplateConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEntityTemplateHasOneQueryVariables = {
  id: string,
};

export type GetEntityTemplateHasOneQuery = {
  getEntityTemplateHasOne?:  {
    __typename: "EntityTemplateHasOne",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEntityTemplateHasOnesQueryVariables = {
  filter?: ModelEntityTemplateHasOneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityTemplateHasOnesQuery = {
  listEntityTemplateHasOnes?:  {
    __typename: "ModelEntityTemplateHasOneConnection",
    items:  Array< {
      __typename: "EntityTemplateHasOne",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEntityTemplateHasOnesByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEntityTemplateHasOneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityTemplateHasOnesByNameQuery = {
  listEntityTemplateHasOnesByName?:  {
    __typename: "ModelEntityTemplateHasOneConnection",
    items:  Array< {
      __typename: "EntityTemplateHasOne",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLabelQueryVariables = {
  id: string,
};

export type GetLabelQuery = {
  getLabel?:  {
    __typename: "Label",
    id: string,
    name: string,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLabelsQueryVariables = {
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLabelsQuery = {
  listLabels?:  {
    __typename: "ModelLabelConnection",
    items:  Array< {
      __typename: "Label",
      id: string,
      name: string,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLabelsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLabelsByNameQuery = {
  listLabelsByName?:  {
    __typename: "ModelLabelConnection",
    items:  Array< {
      __typename: "Label",
      id: string,
      name: string,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMedflowVersionQueryVariables = {
  id: string,
};

export type GetMedflowVersionQuery = {
  getMedflowVersion?:  {
    __typename: "MedflowVersion",
    id?: string | null,
    name: string,
    number: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMedflowVersionsQueryVariables = {
  filter?: ModelMedflowVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMedflowVersionsQuery = {
  listMedflowVersions?:  {
    __typename: "ModelMedflowVersionConnection",
    items:  Array< {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMedflowVersionsByNameQueryVariables = {
  name: string,
  number?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedflowVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMedflowVersionsByNameQuery = {
  listMedflowVersionsByName?:  {
    __typename: "ModelMedflowVersionConnection",
    items:  Array< {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPhaseQueryVariables = {
  id: string,
};

export type GetPhaseQuery = {
  getPhase?:  {
    __typename: "Phase",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseMedflowVersionId?: string | null,
  } | null,
};

export type ListPhasesQueryVariables = {
  filter?: ModelPhaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPhasesQuery = {
  listPhases?:  {
    __typename: "ModelPhaseConnection",
    items:  Array< {
      __typename: "Phase",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseMedflowVersionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPhasesByNameQueryVariables = {
  name: string,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPhaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPhasesByNameQuery = {
  listPhasesByName?:  {
    __typename: "ModelPhaseConnection",
    items:  Array< {
      __typename: "Phase",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseMedflowVersionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPreviewAnswerQueryVariables = {
  id: string,
};

export type GetPreviewAnswerQuery = {
  getPreviewAnswer?:  {
    __typename: "PreviewAnswer",
    id: string,
    name: string,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewProduct?:  {
      __typename: "PreviewProduct",
      id: string,
      name: string,
      intendedUse: string,
      description: string,
      previewUser?:  {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      previewAnswers?:  {
        __typename: "ModelPreviewAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewProductsId?: string | null,
      previewUserPreviewProductsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewProductPreviewAnswersId?: string | null,
    previewAnswerQuestionId?: string | null,
  } | null,
};

export type ListPreviewAnswersQueryVariables = {
  filter?: ModelPreviewAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreviewAnswersQuery = {
  listPreviewAnswers?:  {
    __typename: "ModelPreviewAnswerConnection",
    items:  Array< {
      __typename: "PreviewAnswer",
      id: string,
      name: string,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      previewProduct?:  {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewProductPreviewAnswersId?: string | null,
      previewAnswerQuestionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPreviewAnswersByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPreviewAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreviewAnswersByNameQuery = {
  listPreviewAnswersByName?:  {
    __typename: "ModelPreviewAnswerConnection",
    items:  Array< {
      __typename: "PreviewAnswer",
      id: string,
      name: string,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      previewProduct?:  {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewProductPreviewAnswersId?: string | null,
      previewAnswerQuestionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPreviewProductQueryVariables = {
  id: string,
};

export type GetPreviewProductQuery = {
  getPreviewProduct?:  {
    __typename: "PreviewProduct",
    id: string,
    name: string,
    intendedUse: string,
    description: string,
    previewUser?:  {
      __typename: "PreviewUser",
      id: string,
      name: string,
      email: string,
      phoneNumber?: string | null,
      area: string,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewUsersId?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewAnswers?:  {
      __typename: "ModelPreviewAnswerConnection",
      items:  Array< {
        __typename: "PreviewAnswer",
        id: string,
        name: string,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        previewProductPreviewAnswersId?: string | null,
        previewAnswerQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewProductsId?: string | null,
    previewUserPreviewProductsId?: string | null,
  } | null,
};

export type ListPreviewProductsQueryVariables = {
  filter?: ModelPreviewProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreviewProductsQuery = {
  listPreviewProducts?:  {
    __typename: "ModelPreviewProductConnection",
    items:  Array< {
      __typename: "PreviewProduct",
      id: string,
      name: string,
      intendedUse: string,
      description: string,
      previewUser?:  {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      previewAnswers?:  {
        __typename: "ModelPreviewAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewProductsId?: string | null,
      previewUserPreviewProductsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPreviewProductsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPreviewProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreviewProductsByNameQuery = {
  listPreviewProductsByName?:  {
    __typename: "ModelPreviewProductConnection",
    items:  Array< {
      __typename: "PreviewProduct",
      id: string,
      name: string,
      intendedUse: string,
      description: string,
      previewUser?:  {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      previewAnswers?:  {
        __typename: "ModelPreviewAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewProductsId?: string | null,
      previewUserPreviewProductsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPreviewOrganizationQueryVariables = {
  id: string,
};

export type GetPreviewOrganizationQuery = {
  getPreviewOrganization?:  {
    __typename: "PreviewOrganization",
    id: string,
    name: string,
    registrationNumber: string,
    previewUsers?:  {
      __typename: "ModelPreviewUserConnection",
      items:  Array< {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPreviewOrganizationsQueryVariables = {
  filter?: ModelPreviewOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreviewOrganizationsQuery = {
  listPreviewOrganizations?:  {
    __typename: "ModelPreviewOrganizationConnection",
    items:  Array< {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPreviewOrganizationsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPreviewOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreviewOrganizationsByNameQuery = {
  listPreviewOrganizationsByName?:  {
    __typename: "ModelPreviewOrganizationConnection",
    items:  Array< {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPreviewUserQueryVariables = {
  id: string,
};

export type GetPreviewUserQuery = {
  getPreviewUser?:  {
    __typename: "PreviewUser",
    id: string,
    name: string,
    email: string,
    phoneNumber?: string | null,
    area: string,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewUsersId?: string | null,
  } | null,
};

export type ListPreviewUsersQueryVariables = {
  filter?: ModelPreviewUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreviewUsersQuery = {
  listPreviewUsers?:  {
    __typename: "ModelPreviewUserConnection",
    items:  Array< {
      __typename: "PreviewUser",
      id: string,
      name: string,
      email: string,
      phoneNumber?: string | null,
      area: string,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewUsersId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPreviewUsersByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPreviewUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreviewUsersByNameQuery = {
  listPreviewUsersByName?:  {
    __typename: "ModelPreviewUserConnection",
    items:  Array< {
      __typename: "PreviewUser",
      id: string,
      name: string,
      email: string,
      phoneNumber?: string | null,
      area: string,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewUsersId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    productCode?: string | null,
    gmdnDescription?: string | null,
    deviceClassification?: DeviceClassification | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        name: string,
        version?: number | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productAnswersId?: string | null,
        answerQuestionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productMedflowVersionId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      productCode?: string | null,
      gmdnDescription?: string | null,
      deviceClassification?: DeviceClassification | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productMedflowVersionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListProductsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsByNameQuery = {
  listProductsByName?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      productCode?: string | null,
      gmdnDescription?: string | null,
      deviceClassification?: DeviceClassification | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productMedflowVersionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionQueryVariables = {
  id: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    name: string,
    description?: string | null,
    guidingBeforeText?: string | null,
    placeholder?: string | null,
    statement?: string | null,
    tooltip?: string | null,
    answerDataType: AnswerDataType,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    examples?: Array< string | null > | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionRowColumns?:  {
      __typename: "ModelQuestionRowColumnConnection",
      items:  Array< {
        __typename: "QuestionRowColumn",
        id: string,
        name: string,
        order: number,
        answerDataType: AnswerDataType,
        createdAt: string,
        updatedAt: string,
        questionQuestionRowColumnsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQuestionsQueryVariables = {
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListQuestionsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsByNameQuery = {
  listQuestionsByName?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionLabelQueryVariables = {
  id: string,
};

export type GetQuestionLabelQuery = {
  getQuestionLabel?:  {
    __typename: "QuestionLabel",
    id: string,
    name: string,
    label?:  {
      __typename: "Label",
      id: string,
      name: string,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    labelQuestionLabelsId?: string | null,
    questionQuestionLabelsId?: string | null,
  } | null,
};

export type ListQuestionLabelsQueryVariables = {
  filter?: ModelQuestionLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionLabelsQuery = {
  listQuestionLabels?:  {
    __typename: "ModelQuestionLabelConnection",
    items:  Array< {
      __typename: "QuestionLabel",
      id: string,
      name: string,
      label?:  {
        __typename: "Label",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      labelQuestionLabelsId?: string | null,
      questionQuestionLabelsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListQuestionLabelsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionLabelsByNameQuery = {
  listQuestionLabelsByName?:  {
    __typename: "ModelQuestionLabelConnection",
    items:  Array< {
      __typename: "QuestionLabel",
      id: string,
      name: string,
      label?:  {
        __typename: "Label",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      labelQuestionLabelsId?: string | null,
      questionQuestionLabelsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionRowColumnQueryVariables = {
  id: string,
};

export type GetQuestionRowColumnQuery = {
  getQuestionRowColumn?:  {
    __typename: "QuestionRowColumn",
    id: string,
    name: string,
    order: number,
    answerDataType: AnswerDataType,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionQuestionRowColumnsId?: string | null,
  } | null,
};

export type ListQuestionRowColumnsQueryVariables = {
  filter?: ModelQuestionRowColumnFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionRowColumnsQuery = {
  listQuestionRowColumns?:  {
    __typename: "ModelQuestionRowColumnConnection",
    items:  Array< {
      __typename: "QuestionRowColumn",
      id: string,
      name: string,
      order: number,
      answerDataType: AnswerDataType,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionQuestionRowColumnsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListQuestionRowColumnsByNameQueryVariables = {
  name: string,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionRowColumnFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionRowColumnsByNameQuery = {
  listQuestionRowColumnsByName?:  {
    __typename: "ModelQuestionRowColumnConnection",
    items:  Array< {
      __typename: "QuestionRowColumn",
      id: string,
      name: string,
      order: number,
      answerDataType: AnswerDataType,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionQuestionRowColumnsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportQueryVariables = {
  id: string,
};

export type GetReportQuery = {
  getReport?:  {
    __typename: "Report",
    id: string,
    name: string,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaireSections?:  {
      __typename: "ModelReportQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "ReportQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        reportReportQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageReportsId?: string | null,
  } | null,
};

export type ListReportsQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReportsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsByNameQuery = {
  listReportsByName?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportQuestionnaireQueryVariables = {
  id: string,
};

export type GetReportQuestionnaireQuery = {
  getReportQuestionnaire?:  {
    __typename: "ReportQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaireSection?:  {
      __typename: "ReportQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      report?:  {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      reportReportQuestionnaireSectionsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionReportQuestionnairesId?: string | null,
    reportReportQuestionnairesId?: string | null,
    reportQuestionnaireSectionReportQuestionnairesId?: string | null,
  } | null,
};

export type ListReportQuestionnairesQueryVariables = {
  filter?: ModelReportQuestionnaireFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportQuestionnairesQuery = {
  listReportQuestionnaires?:  {
    __typename: "ModelReportQuestionnaireConnection",
    items:  Array< {
      __typename: "ReportQuestionnaire",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      report?:  {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null,
      reportQuestionnaireSection?:  {
        __typename: "ReportQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        reportReportQuestionnaireSectionsId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionReportQuestionnairesId?: string | null,
      reportReportQuestionnairesId?: string | null,
      reportQuestionnaireSectionReportQuestionnairesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReportQuestionnairesByNameQueryVariables = {
  name: string,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportQuestionnaireFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportQuestionnairesByNameQuery = {
  listReportQuestionnairesByName?:  {
    __typename: "ModelReportQuestionnaireConnection",
    items:  Array< {
      __typename: "ReportQuestionnaire",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      report?:  {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null,
      reportQuestionnaireSection?:  {
        __typename: "ReportQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        reportReportQuestionnaireSectionsId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionReportQuestionnairesId?: string | null,
      reportReportQuestionnairesId?: string | null,
      reportQuestionnaireSectionReportQuestionnairesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportQuestionnaireSectionQueryVariables = {
  id: string,
};

export type GetReportQuestionnaireSectionQuery = {
  getReportQuestionnaireSection?:  {
    __typename: "ReportQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    reportReportQuestionnaireSectionsId?: string | null,
  } | null,
};

export type ListReportQuestionnaireSectionsQueryVariables = {
  filter?: ModelReportQuestionnaireSectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportQuestionnaireSectionsQuery = {
  listReportQuestionnaireSections?:  {
    __typename: "ModelReportQuestionnaireSectionConnection",
    items:  Array< {
      __typename: "ReportQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      report?:  {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      reportReportQuestionnaireSectionsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReportQuestionnaireSectionsByNameQueryVariables = {
  name: string,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportQuestionnaireSectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportQuestionnaireSectionsByNameQuery = {
  listReportQuestionnaireSectionsByName?:  {
    __typename: "ModelReportQuestionnaireSectionConnection",
    items:  Array< {
      __typename: "ReportQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      report?:  {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      reportReportQuestionnaireSectionsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRowColumnAnswerQueryVariables = {
  id: string,
};

export type GetRowColumnAnswerQuery = {
  getRowColumnAnswer?:  {
    __typename: "RowColumnAnswer",
    id: string,
    name: string,
    version?: number | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    questionRowColumn?:  {
      __typename: "QuestionRowColumn",
      id: string,
      name: string,
      order: number,
      answerDataType: AnswerDataType,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionQuestionRowColumnsId?: string | null,
    } | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      name: string,
      version?: number | null,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        description?: string | null,
        productCode?: string | null,
        gmdnDescription?: string | null,
        deviceClassification?: DeviceClassification | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productMedflowVersionId?: string | null,
        owner?: string | null,
      } | null,
      rowColumnAnswers?:  {
        __typename: "ModelRowColumnAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productAnswersId?: string | null,
      answerQuestionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    answerRowColumnAnswersId?: string | null,
    rowColumnAnswerQuestionRowColumnId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListRowColumnAnswersQueryVariables = {
  filter?: ModelRowColumnAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRowColumnAnswersQuery = {
  listRowColumnAnswers?:  {
    __typename: "ModelRowColumnAnswerConnection",
    items:  Array< {
      __typename: "RowColumnAnswer",
      id: string,
      name: string,
      version?: number | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      questionRowColumn?:  {
        __typename: "QuestionRowColumn",
        id: string,
        name: string,
        order: number,
        answerDataType: AnswerDataType,
        createdAt: string,
        updatedAt: string,
        questionQuestionRowColumnsId?: string | null,
      } | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        name: string,
        version?: number | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productAnswersId?: string | null,
        answerQuestionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      answerRowColumnAnswersId?: string | null,
      rowColumnAnswerQuestionRowColumnId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRowColumnAnswersByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRowColumnAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRowColumnAnswersByNameQuery = {
  listRowColumnAnswersByName?:  {
    __typename: "ModelRowColumnAnswerConnection",
    items:  Array< {
      __typename: "RowColumnAnswer",
      id: string,
      name: string,
      version?: number | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      questionRowColumn?:  {
        __typename: "QuestionRowColumn",
        id: string,
        name: string,
        order: number,
        answerDataType: AnswerDataType,
        createdAt: string,
        updatedAt: string,
        questionQuestionRowColumnsId?: string | null,
      } | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        name: string,
        version?: number | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productAnswersId?: string | null,
        answerQuestionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      answerRowColumnAnswersId?: string | null,
      rowColumnAnswerQuestionRowColumnId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStageQueryVariables = {
  id: string,
};

export type GetStageQuery = {
  getStage?:  {
    __typename: "Stage",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseMedflowVersionId?: string | null,
    } | null,
    track?:  {
      __typename: "Track",
      id: string,
      name: string,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      trackMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaireSections?:  {
      __typename: "ModelStageQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "StageQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        stageStageQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseStagesId?: string | null,
    trackStagesId?: string | null,
    stageMedflowVersionId?: string | null,
  } | null,
};

export type ListStagesQueryVariables = {
  filter?: ModelStageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStagesQuery = {
  listStages?:  {
    __typename: "ModelStageConnection",
    items:  Array< {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListStagesByNameQueryVariables = {
  name: string,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStagesByNameQuery = {
  listStagesByName?:  {
    __typename: "ModelStageConnection",
    items:  Array< {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStageQuestionnaireQueryVariables = {
  id: string,
};

export type GetStageQuestionnaireQuery = {
  getStageQuestionnaire?:  {
    __typename: "StageQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stageQuestionnaireSection?:  {
      __typename: "StageQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageStageQuestionnaireSectionsId?: string | null,
    } | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionStageQuestionnairesId?: string | null,
    stageStageQuestionnairesId?: string | null,
    stageQuestionnaireSectionStageQuestionnairesId?: string | null,
  } | null,
};

export type ListStageQuestionnairesQueryVariables = {
  filter?: ModelStageQuestionnaireFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStageQuestionnairesQuery = {
  listStageQuestionnaires?:  {
    __typename: "ModelStageQuestionnaireConnection",
    items:  Array< {
      __typename: "StageQuestionnaire",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stageQuestionnaireSection?:  {
        __typename: "StageQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        stageStageQuestionnaireSectionsId?: string | null,
      } | null,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionStageQuestionnairesId?: string | null,
      stageStageQuestionnairesId?: string | null,
      stageQuestionnaireSectionStageQuestionnairesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListStageQuestionnairesByNameQueryVariables = {
  name: string,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStageQuestionnaireFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStageQuestionnairesByNameQuery = {
  listStageQuestionnairesByName?:  {
    __typename: "ModelStageQuestionnaireConnection",
    items:  Array< {
      __typename: "StageQuestionnaire",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stageQuestionnaireSection?:  {
        __typename: "StageQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        stageStageQuestionnaireSectionsId?: string | null,
      } | null,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionStageQuestionnairesId?: string | null,
      stageStageQuestionnairesId?: string | null,
      stageQuestionnaireSectionStageQuestionnairesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStageQuestionnaireSectionQueryVariables = {
  id: string,
};

export type GetStageQuestionnaireSectionQuery = {
  getStageQuestionnaireSection?:  {
    __typename: "StageQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageStageQuestionnaireSectionsId?: string | null,
  } | null,
};

export type ListStageQuestionnaireSectionsQueryVariables = {
  filter?: ModelStageQuestionnaireSectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStageQuestionnaireSectionsQuery = {
  listStageQuestionnaireSections?:  {
    __typename: "ModelStageQuestionnaireSectionConnection",
    items:  Array< {
      __typename: "StageQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageStageQuestionnaireSectionsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListStageQuestionnaireSectionsByNameQueryVariables = {
  name: string,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStageQuestionnaireSectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStageQuestionnaireSectionsByNameQuery = {
  listStageQuestionnaireSectionsByName?:  {
    __typename: "ModelStageQuestionnaireSectionConnection",
    items:  Array< {
      __typename: "StageQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageStageQuestionnaireSectionsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTrackQueryVariables = {
  id: string,
};

export type GetTrackQuery = {
  getTrack?:  {
    __typename: "Track",
    id: string,
    name: string,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    trackMedflowVersionId?: string | null,
  } | null,
};

export type ListTracksQueryVariables = {
  filter?: ModelTrackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTracksQuery = {
  listTracks?:  {
    __typename: "ModelTrackConnection",
    items:  Array< {
      __typename: "Track",
      id: string,
      name: string,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      trackMedflowVersionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTracksByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTrackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTracksByNameQuery = {
  listTracksByName?:  {
    __typename: "ModelTrackConnection",
    items:  Array< {
      __typename: "Track",
      id: string,
      name: string,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      trackMedflowVersionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null,
  owner?: string | null,
};

export type OnCreateAnswerSubscription = {
  onCreateAnswer?:  {
    __typename: "Answer",
    id: string,
    name: string,
    version?: number | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      productCode?: string | null,
      gmdnDescription?: string | null,
      deviceClassification?: DeviceClassification | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productMedflowVersionId?: string | null,
      owner?: string | null,
    } | null,
    rowColumnAnswers?:  {
      __typename: "ModelRowColumnAnswerConnection",
      items:  Array< {
        __typename: "RowColumnAnswer",
        id: string,
        name: string,
        version?: number | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        answerRowColumnAnswersId?: string | null,
        rowColumnAnswerQuestionRowColumnId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productAnswersId?: string | null,
    answerQuestionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAnswerSubscription = {
  onUpdateAnswer?:  {
    __typename: "Answer",
    id: string,
    name: string,
    version?: number | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      productCode?: string | null,
      gmdnDescription?: string | null,
      deviceClassification?: DeviceClassification | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productMedflowVersionId?: string | null,
      owner?: string | null,
    } | null,
    rowColumnAnswers?:  {
      __typename: "ModelRowColumnAnswerConnection",
      items:  Array< {
        __typename: "RowColumnAnswer",
        id: string,
        name: string,
        version?: number | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        answerRowColumnAnswersId?: string | null,
        rowColumnAnswerQuestionRowColumnId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productAnswersId?: string | null,
    answerQuestionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAnswerSubscription = {
  onDeleteAnswer?:  {
    __typename: "Answer",
    id: string,
    name: string,
    version?: number | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      description?: string | null,
      productCode?: string | null,
      gmdnDescription?: string | null,
      deviceClassification?: DeviceClassification | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productMedflowVersionId?: string | null,
      owner?: string | null,
    } | null,
    rowColumnAnswers?:  {
      __typename: "ModelRowColumnAnswerConnection",
      items:  Array< {
        __typename: "RowColumnAnswer",
        id: string,
        name: string,
        version?: number | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        answerRowColumnAnswersId?: string | null,
        rowColumnAnswerQuestionRowColumnId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productAnswersId?: string | null,
    answerQuestionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateDeviceClassificationExpressionSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceClassificationExpressionFilterInput | null,
};

export type OnCreateDeviceClassificationExpressionSubscription = {
  onCreateDeviceClassificationExpression?:  {
    __typename: "DeviceClassificationExpression",
    id: string,
    name: string,
    category?: string | null,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    tooltip?: string | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    classificationIfYes?: DeviceClassification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceClassificationExpression?:  {
      __typename: "DeviceClassificationExpression",
      id: string,
      name: string,
      category?: string | null,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      tooltip?: string | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      classificationIfYes?: DeviceClassification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceClassificationExpression?:  {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceClassificationExpressions?:  {
        __typename: "ModelDeviceClassificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
      deviceClassificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceClassificationExpressions?:  {
      __typename: "ModelDeviceClassificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
    deviceClassificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type OnUpdateDeviceClassificationExpressionSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceClassificationExpressionFilterInput | null,
};

export type OnUpdateDeviceClassificationExpressionSubscription = {
  onUpdateDeviceClassificationExpression?:  {
    __typename: "DeviceClassificationExpression",
    id: string,
    name: string,
    category?: string | null,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    tooltip?: string | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    classificationIfYes?: DeviceClassification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceClassificationExpression?:  {
      __typename: "DeviceClassificationExpression",
      id: string,
      name: string,
      category?: string | null,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      tooltip?: string | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      classificationIfYes?: DeviceClassification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceClassificationExpression?:  {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceClassificationExpressions?:  {
        __typename: "ModelDeviceClassificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
      deviceClassificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceClassificationExpressions?:  {
      __typename: "ModelDeviceClassificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
    deviceClassificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type OnDeleteDeviceClassificationExpressionSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceClassificationExpressionFilterInput | null,
};

export type OnDeleteDeviceClassificationExpressionSubscription = {
  onDeleteDeviceClassificationExpression?:  {
    __typename: "DeviceClassificationExpression",
    id: string,
    name: string,
    category?: string | null,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    tooltip?: string | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    classificationIfYes?: DeviceClassification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceClassificationExpression?:  {
      __typename: "DeviceClassificationExpression",
      id: string,
      name: string,
      category?: string | null,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      tooltip?: string | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      classificationIfYes?: DeviceClassification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceClassificationExpression?:  {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceClassificationExpressions?:  {
        __typename: "ModelDeviceClassificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
      deviceClassificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceClassificationExpressions?:  {
      __typename: "ModelDeviceClassificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceClassificationExpression",
        id: string,
        name: string,
        category?: string | null,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        tooltip?: string | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        classificationIfYes?: DeviceClassification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
        deviceClassificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceClassificationExpressionChildrenDeviceClassificationExpressionsId?: string | null,
    deviceClassificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type OnCreateDeviceQualificationExpressionSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceQualificationExpressionFilterInput | null,
};

export type OnCreateDeviceQualificationExpressionSubscription = {
  onCreateDeviceQualificationExpression?:  {
    __typename: "DeviceQualificationExpression",
    id: string,
    name: string,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    qualificationIfYes?: DeviceQualification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceQualificationExpression?:  {
      __typename: "DeviceQualificationExpression",
      id: string,
      name: string,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      qualificationIfYes?: DeviceQualification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceQualificationExpression?:  {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceQualificationExpressions?:  {
        __typename: "ModelDeviceQualificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
      deviceQualificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceQualificationExpressions?:  {
      __typename: "ModelDeviceQualificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
    deviceQualificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type OnUpdateDeviceQualificationExpressionSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceQualificationExpressionFilterInput | null,
};

export type OnUpdateDeviceQualificationExpressionSubscription = {
  onUpdateDeviceQualificationExpression?:  {
    __typename: "DeviceQualificationExpression",
    id: string,
    name: string,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    qualificationIfYes?: DeviceQualification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceQualificationExpression?:  {
      __typename: "DeviceQualificationExpression",
      id: string,
      name: string,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      qualificationIfYes?: DeviceQualification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceQualificationExpression?:  {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceQualificationExpressions?:  {
        __typename: "ModelDeviceQualificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
      deviceQualificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceQualificationExpressions?:  {
      __typename: "ModelDeviceQualificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
    deviceQualificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type OnDeleteDeviceQualificationExpressionSubscriptionVariables = {
  filter?: ModelSubscriptionDeviceQualificationExpressionFilterInput | null,
};

export type OnDeleteDeviceQualificationExpressionSubscription = {
  onDeleteDeviceQualificationExpression?:  {
    __typename: "DeviceQualificationExpression",
    id: string,
    name: string,
    description?: string | null,
    index?: number | null,
    isRoot?: boolean | null,
    statement?: string | null,
    qualificationIfYes?: DeviceQualification | null,
    operator?: DeviceExpressionOperator | null,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    yesNoQuestion?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    parentDeviceQualificationExpression?:  {
      __typename: "DeviceQualificationExpression",
      id: string,
      name: string,
      description?: string | null,
      index?: number | null,
      isRoot?: boolean | null,
      statement?: string | null,
      qualificationIfYes?: DeviceQualification | null,
      operator?: DeviceExpressionOperator | null,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      yesNoQuestion?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      parentDeviceQualificationExpression?:  {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null,
      childrenDeviceQualificationExpressions?:  {
        __typename: "ModelDeviceQualificationExpressionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
      deviceQualificationExpressionYesNoQuestionId?: string | null,
    } | null,
    childrenDeviceQualificationExpressions?:  {
      __typename: "ModelDeviceQualificationExpressionConnection",
      items:  Array< {
        __typename: "DeviceQualificationExpression",
        id: string,
        name: string,
        description?: string | null,
        index?: number | null,
        isRoot?: boolean | null,
        statement?: string | null,
        qualificationIfYes?: DeviceQualification | null,
        operator?: DeviceExpressionOperator | null,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        createdAt: string,
        updatedAt: string,
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
        deviceQualificationExpressionYesNoQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    deviceQualificationExpressionChildrenDeviceQualificationExpressionsId?: string | null,
    deviceQualificationExpressionYesNoQuestionId?: string | null,
  } | null,
};

export type OnCreateEntityTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionEntityTemplateFilterInput | null,
};

export type OnCreateEntityTemplateSubscription = {
  onCreateEntityTemplate?:  {
    __typename: "EntityTemplate",
    id: string,
    name: string,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    entityEnum?: EntityEnum | null,
    entityTemplateHasOne?:  {
      __typename: "EntityTemplateHasOne",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    entityTemplateBelongsTo?:  {
      __typename: "EntityTemplateBelongsTo",
      id: string,
      name: string,
      entityTemplates?:  {
        __typename: "ModelEntityTemplateConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    entityTemplateBelongsToEntityTemplatesId?: string | null,
    entityTemplateEntityTemplateHasOneId?: string | null,
  } | null,
};

export type OnUpdateEntityTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionEntityTemplateFilterInput | null,
};

export type OnUpdateEntityTemplateSubscription = {
  onUpdateEntityTemplate?:  {
    __typename: "EntityTemplate",
    id: string,
    name: string,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    entityEnum?: EntityEnum | null,
    entityTemplateHasOne?:  {
      __typename: "EntityTemplateHasOne",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    entityTemplateBelongsTo?:  {
      __typename: "EntityTemplateBelongsTo",
      id: string,
      name: string,
      entityTemplates?:  {
        __typename: "ModelEntityTemplateConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    entityTemplateBelongsToEntityTemplatesId?: string | null,
    entityTemplateEntityTemplateHasOneId?: string | null,
  } | null,
};

export type OnDeleteEntityTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionEntityTemplateFilterInput | null,
};

export type OnDeleteEntityTemplateSubscription = {
  onDeleteEntityTemplate?:  {
    __typename: "EntityTemplate",
    id: string,
    name: string,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    entityEnum?: EntityEnum | null,
    entityTemplateHasOne?:  {
      __typename: "EntityTemplateHasOne",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    entityTemplateBelongsTo?:  {
      __typename: "EntityTemplateBelongsTo",
      id: string,
      name: string,
      entityTemplates?:  {
        __typename: "ModelEntityTemplateConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    entityTemplateBelongsToEntityTemplatesId?: string | null,
    entityTemplateEntityTemplateHasOneId?: string | null,
  } | null,
};

export type OnCreateEntityTemplateBelongsToSubscriptionVariables = {
  filter?: ModelSubscriptionEntityTemplateBelongsToFilterInput | null,
};

export type OnCreateEntityTemplateBelongsToSubscription = {
  onCreateEntityTemplateBelongsTo?:  {
    __typename: "EntityTemplateBelongsTo",
    id: string,
    name: string,
    entityTemplates?:  {
      __typename: "ModelEntityTemplateConnection",
      items:  Array< {
        __typename: "EntityTemplate",
        id: string,
        name: string,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        entityEnum?: EntityEnum | null,
        createdAt: string,
        updatedAt: string,
        entityTemplateBelongsToEntityTemplatesId?: string | null,
        entityTemplateEntityTemplateHasOneId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEntityTemplateBelongsToSubscriptionVariables = {
  filter?: ModelSubscriptionEntityTemplateBelongsToFilterInput | null,
};

export type OnUpdateEntityTemplateBelongsToSubscription = {
  onUpdateEntityTemplateBelongsTo?:  {
    __typename: "EntityTemplateBelongsTo",
    id: string,
    name: string,
    entityTemplates?:  {
      __typename: "ModelEntityTemplateConnection",
      items:  Array< {
        __typename: "EntityTemplate",
        id: string,
        name: string,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        entityEnum?: EntityEnum | null,
        createdAt: string,
        updatedAt: string,
        entityTemplateBelongsToEntityTemplatesId?: string | null,
        entityTemplateEntityTemplateHasOneId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEntityTemplateBelongsToSubscriptionVariables = {
  filter?: ModelSubscriptionEntityTemplateBelongsToFilterInput | null,
};

export type OnDeleteEntityTemplateBelongsToSubscription = {
  onDeleteEntityTemplateBelongsTo?:  {
    __typename: "EntityTemplateBelongsTo",
    id: string,
    name: string,
    entityTemplates?:  {
      __typename: "ModelEntityTemplateConnection",
      items:  Array< {
        __typename: "EntityTemplate",
        id: string,
        name: string,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        entityEnum?: EntityEnum | null,
        createdAt: string,
        updatedAt: string,
        entityTemplateBelongsToEntityTemplatesId?: string | null,
        entityTemplateEntityTemplateHasOneId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEntityTemplateHasOneSubscriptionVariables = {
  filter?: ModelSubscriptionEntityTemplateHasOneFilterInput | null,
};

export type OnCreateEntityTemplateHasOneSubscription = {
  onCreateEntityTemplateHasOne?:  {
    __typename: "EntityTemplateHasOne",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEntityTemplateHasOneSubscriptionVariables = {
  filter?: ModelSubscriptionEntityTemplateHasOneFilterInput | null,
};

export type OnUpdateEntityTemplateHasOneSubscription = {
  onUpdateEntityTemplateHasOne?:  {
    __typename: "EntityTemplateHasOne",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEntityTemplateHasOneSubscriptionVariables = {
  filter?: ModelSubscriptionEntityTemplateHasOneFilterInput | null,
};

export type OnDeleteEntityTemplateHasOneSubscription = {
  onDeleteEntityTemplateHasOne?:  {
    __typename: "EntityTemplateHasOne",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLabelSubscriptionVariables = {
  filter?: ModelSubscriptionLabelFilterInput | null,
};

export type OnCreateLabelSubscription = {
  onCreateLabel?:  {
    __typename: "Label",
    id: string,
    name: string,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLabelSubscriptionVariables = {
  filter?: ModelSubscriptionLabelFilterInput | null,
};

export type OnUpdateLabelSubscription = {
  onUpdateLabel?:  {
    __typename: "Label",
    id: string,
    name: string,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLabelSubscriptionVariables = {
  filter?: ModelSubscriptionLabelFilterInput | null,
};

export type OnDeleteLabelSubscription = {
  onDeleteLabel?:  {
    __typename: "Label",
    id: string,
    name: string,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMedflowVersionSubscriptionVariables = {
  filter?: ModelSubscriptionMedflowVersionFilterInput | null,
};

export type OnCreateMedflowVersionSubscription = {
  onCreateMedflowVersion?:  {
    __typename: "MedflowVersion",
    id?: string | null,
    name: string,
    number: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMedflowVersionSubscriptionVariables = {
  filter?: ModelSubscriptionMedflowVersionFilterInput | null,
};

export type OnUpdateMedflowVersionSubscription = {
  onUpdateMedflowVersion?:  {
    __typename: "MedflowVersion",
    id?: string | null,
    name: string,
    number: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMedflowVersionSubscriptionVariables = {
  filter?: ModelSubscriptionMedflowVersionFilterInput | null,
};

export type OnDeleteMedflowVersionSubscription = {
  onDeleteMedflowVersion?:  {
    __typename: "MedflowVersion",
    id?: string | null,
    name: string,
    number: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePhaseSubscriptionVariables = {
  filter?: ModelSubscriptionPhaseFilterInput | null,
};

export type OnCreatePhaseSubscription = {
  onCreatePhase?:  {
    __typename: "Phase",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseMedflowVersionId?: string | null,
  } | null,
};

export type OnUpdatePhaseSubscriptionVariables = {
  filter?: ModelSubscriptionPhaseFilterInput | null,
};

export type OnUpdatePhaseSubscription = {
  onUpdatePhase?:  {
    __typename: "Phase",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseMedflowVersionId?: string | null,
  } | null,
};

export type OnDeletePhaseSubscriptionVariables = {
  filter?: ModelSubscriptionPhaseFilterInput | null,
};

export type OnDeletePhaseSubscription = {
  onDeletePhase?:  {
    __typename: "Phase",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseMedflowVersionId?: string | null,
  } | null,
};

export type OnCreatePreviewAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewAnswerFilterInput | null,
};

export type OnCreatePreviewAnswerSubscription = {
  onCreatePreviewAnswer?:  {
    __typename: "PreviewAnswer",
    id: string,
    name: string,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewProduct?:  {
      __typename: "PreviewProduct",
      id: string,
      name: string,
      intendedUse: string,
      description: string,
      previewUser?:  {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      previewAnswers?:  {
        __typename: "ModelPreviewAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewProductsId?: string | null,
      previewUserPreviewProductsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewProductPreviewAnswersId?: string | null,
    previewAnswerQuestionId?: string | null,
  } | null,
};

export type OnUpdatePreviewAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewAnswerFilterInput | null,
};

export type OnUpdatePreviewAnswerSubscription = {
  onUpdatePreviewAnswer?:  {
    __typename: "PreviewAnswer",
    id: string,
    name: string,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewProduct?:  {
      __typename: "PreviewProduct",
      id: string,
      name: string,
      intendedUse: string,
      description: string,
      previewUser?:  {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      previewAnswers?:  {
        __typename: "ModelPreviewAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewProductsId?: string | null,
      previewUserPreviewProductsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewProductPreviewAnswersId?: string | null,
    previewAnswerQuestionId?: string | null,
  } | null,
};

export type OnDeletePreviewAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewAnswerFilterInput | null,
};

export type OnDeletePreviewAnswerSubscription = {
  onDeletePreviewAnswer?:  {
    __typename: "PreviewAnswer",
    id: string,
    name: string,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewProduct?:  {
      __typename: "PreviewProduct",
      id: string,
      name: string,
      intendedUse: string,
      description: string,
      previewUser?:  {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      previewAnswers?:  {
        __typename: "ModelPreviewAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewProductsId?: string | null,
      previewUserPreviewProductsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewProductPreviewAnswersId?: string | null,
    previewAnswerQuestionId?: string | null,
  } | null,
};

export type OnCreatePreviewProductSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewProductFilterInput | null,
};

export type OnCreatePreviewProductSubscription = {
  onCreatePreviewProduct?:  {
    __typename: "PreviewProduct",
    id: string,
    name: string,
    intendedUse: string,
    description: string,
    previewUser?:  {
      __typename: "PreviewUser",
      id: string,
      name: string,
      email: string,
      phoneNumber?: string | null,
      area: string,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewUsersId?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewAnswers?:  {
      __typename: "ModelPreviewAnswerConnection",
      items:  Array< {
        __typename: "PreviewAnswer",
        id: string,
        name: string,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        previewProductPreviewAnswersId?: string | null,
        previewAnswerQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewProductsId?: string | null,
    previewUserPreviewProductsId?: string | null,
  } | null,
};

export type OnUpdatePreviewProductSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewProductFilterInput | null,
};

export type OnUpdatePreviewProductSubscription = {
  onUpdatePreviewProduct?:  {
    __typename: "PreviewProduct",
    id: string,
    name: string,
    intendedUse: string,
    description: string,
    previewUser?:  {
      __typename: "PreviewUser",
      id: string,
      name: string,
      email: string,
      phoneNumber?: string | null,
      area: string,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewUsersId?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewAnswers?:  {
      __typename: "ModelPreviewAnswerConnection",
      items:  Array< {
        __typename: "PreviewAnswer",
        id: string,
        name: string,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        previewProductPreviewAnswersId?: string | null,
        previewAnswerQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewProductsId?: string | null,
    previewUserPreviewProductsId?: string | null,
  } | null,
};

export type OnDeletePreviewProductSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewProductFilterInput | null,
};

export type OnDeletePreviewProductSubscription = {
  onDeletePreviewProduct?:  {
    __typename: "PreviewProduct",
    id: string,
    name: string,
    intendedUse: string,
    description: string,
    previewUser?:  {
      __typename: "PreviewUser",
      id: string,
      name: string,
      email: string,
      phoneNumber?: string | null,
      area: string,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      previewOrganization?:  {
        __typename: "PreviewOrganization",
        id: string,
        name: string,
        registrationNumber: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      previewOrganizationPreviewUsersId?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    previewAnswers?:  {
      __typename: "ModelPreviewAnswerConnection",
      items:  Array< {
        __typename: "PreviewAnswer",
        id: string,
        name: string,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        createdAt: string,
        updatedAt: string,
        previewProductPreviewAnswersId?: string | null,
        previewAnswerQuestionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewProductsId?: string | null,
    previewUserPreviewProductsId?: string | null,
  } | null,
};

export type OnCreatePreviewOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewOrganizationFilterInput | null,
};

export type OnCreatePreviewOrganizationSubscription = {
  onCreatePreviewOrganization?:  {
    __typename: "PreviewOrganization",
    id: string,
    name: string,
    registrationNumber: string,
    previewUsers?:  {
      __typename: "ModelPreviewUserConnection",
      items:  Array< {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePreviewOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewOrganizationFilterInput | null,
};

export type OnUpdatePreviewOrganizationSubscription = {
  onUpdatePreviewOrganization?:  {
    __typename: "PreviewOrganization",
    id: string,
    name: string,
    registrationNumber: string,
    previewUsers?:  {
      __typename: "ModelPreviewUserConnection",
      items:  Array< {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePreviewOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewOrganizationFilterInput | null,
};

export type OnDeletePreviewOrganizationSubscription = {
  onDeletePreviewOrganization?:  {
    __typename: "PreviewOrganization",
    id: string,
    name: string,
    registrationNumber: string,
    previewUsers?:  {
      __typename: "ModelPreviewUserConnection",
      items:  Array< {
        __typename: "PreviewUser",
        id: string,
        name: string,
        email: string,
        phoneNumber?: string | null,
        area: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewUsersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePreviewUserSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewUserFilterInput | null,
};

export type OnCreatePreviewUserSubscription = {
  onCreatePreviewUser?:  {
    __typename: "PreviewUser",
    id: string,
    name: string,
    email: string,
    phoneNumber?: string | null,
    area: string,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewUsersId?: string | null,
  } | null,
};

export type OnUpdatePreviewUserSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewUserFilterInput | null,
};

export type OnUpdatePreviewUserSubscription = {
  onUpdatePreviewUser?:  {
    __typename: "PreviewUser",
    id: string,
    name: string,
    email: string,
    phoneNumber?: string | null,
    area: string,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewUsersId?: string | null,
  } | null,
};

export type OnDeletePreviewUserSubscriptionVariables = {
  filter?: ModelSubscriptionPreviewUserFilterInput | null,
};

export type OnDeletePreviewUserSubscription = {
  onDeletePreviewUser?:  {
    __typename: "PreviewUser",
    id: string,
    name: string,
    email: string,
    phoneNumber?: string | null,
    area: string,
    previewProducts?:  {
      __typename: "ModelPreviewProductConnection",
      items:  Array< {
        __typename: "PreviewProduct",
        id: string,
        name: string,
        intendedUse: string,
        description: string,
        createdAt: string,
        updatedAt: string,
        previewOrganizationPreviewProductsId?: string | null,
        previewUserPreviewProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    previewOrganization?:  {
      __typename: "PreviewOrganization",
      id: string,
      name: string,
      registrationNumber: string,
      previewUsers?:  {
        __typename: "ModelPreviewUserConnection",
        nextToken?: string | null,
      } | null,
      previewProducts?:  {
        __typename: "ModelPreviewProductConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    previewOrganizationPreviewUsersId?: string | null,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    productCode?: string | null,
    gmdnDescription?: string | null,
    deviceClassification?: DeviceClassification | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        name: string,
        version?: number | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productAnswersId?: string | null,
        answerQuestionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productMedflowVersionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    productCode?: string | null,
    gmdnDescription?: string | null,
    deviceClassification?: DeviceClassification | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        name: string,
        version?: number | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productAnswersId?: string | null,
        answerQuestionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productMedflowVersionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    description?: string | null,
    productCode?: string | null,
    gmdnDescription?: string | null,
    deviceClassification?: DeviceClassification | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        name: string,
        version?: number | null,
        valueString?: string | null,
        valueInt?: number | null,
        valueFloat?: number | null,
        valueBoolean?: boolean | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productAnswersId?: string | null,
        answerQuestionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    productMedflowVersionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
};

export type OnCreateQuestionSubscription = {
  onCreateQuestion?:  {
    __typename: "Question",
    id: string,
    name: string,
    description?: string | null,
    guidingBeforeText?: string | null,
    placeholder?: string | null,
    statement?: string | null,
    tooltip?: string | null,
    answerDataType: AnswerDataType,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    examples?: Array< string | null > | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionRowColumns?:  {
      __typename: "ModelQuestionRowColumnConnection",
      items:  Array< {
        __typename: "QuestionRowColumn",
        id: string,
        name: string,
        order: number,
        answerDataType: AnswerDataType,
        createdAt: string,
        updatedAt: string,
        questionQuestionRowColumnsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
};

export type OnUpdateQuestionSubscription = {
  onUpdateQuestion?:  {
    __typename: "Question",
    id: string,
    name: string,
    description?: string | null,
    guidingBeforeText?: string | null,
    placeholder?: string | null,
    statement?: string | null,
    tooltip?: string | null,
    answerDataType: AnswerDataType,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    examples?: Array< string | null > | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionRowColumns?:  {
      __typename: "ModelQuestionRowColumnConnection",
      items:  Array< {
        __typename: "QuestionRowColumn",
        id: string,
        name: string,
        order: number,
        answerDataType: AnswerDataType,
        createdAt: string,
        updatedAt: string,
        questionQuestionRowColumnsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
};

export type OnDeleteQuestionSubscription = {
  onDeleteQuestion?:  {
    __typename: "Question",
    id: string,
    name: string,
    description?: string | null,
    guidingBeforeText?: string | null,
    placeholder?: string | null,
    statement?: string | null,
    tooltip?: string | null,
    answerDataType: AnswerDataType,
    specialQuestionCategory?: SpecialQuestionCategory | null,
    examples?: Array< string | null > | null,
    explanations?: Array< string | null > | null,
    notes?: Array< string | null > | null,
    questionLabels?:  {
      __typename: "ModelQuestionLabelConnection",
      items:  Array< {
        __typename: "QuestionLabel",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        labelQuestionLabelsId?: string | null,
        questionQuestionLabelsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionRowColumns?:  {
      __typename: "ModelQuestionRowColumnConnection",
      items:  Array< {
        __typename: "QuestionRowColumn",
        id: string,
        name: string,
        order: number,
        answerDataType: AnswerDataType,
        createdAt: string,
        updatedAt: string,
        questionQuestionRowColumnsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQuestionLabelSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionLabelFilterInput | null,
};

export type OnCreateQuestionLabelSubscription = {
  onCreateQuestionLabel?:  {
    __typename: "QuestionLabel",
    id: string,
    name: string,
    label?:  {
      __typename: "Label",
      id: string,
      name: string,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    labelQuestionLabelsId?: string | null,
    questionQuestionLabelsId?: string | null,
  } | null,
};

export type OnUpdateQuestionLabelSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionLabelFilterInput | null,
};

export type OnUpdateQuestionLabelSubscription = {
  onUpdateQuestionLabel?:  {
    __typename: "QuestionLabel",
    id: string,
    name: string,
    label?:  {
      __typename: "Label",
      id: string,
      name: string,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    labelQuestionLabelsId?: string | null,
    questionQuestionLabelsId?: string | null,
  } | null,
};

export type OnDeleteQuestionLabelSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionLabelFilterInput | null,
};

export type OnDeleteQuestionLabelSubscription = {
  onDeleteQuestionLabel?:  {
    __typename: "QuestionLabel",
    id: string,
    name: string,
    label?:  {
      __typename: "Label",
      id: string,
      name: string,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    labelQuestionLabelsId?: string | null,
    questionQuestionLabelsId?: string | null,
  } | null,
};

export type OnCreateQuestionRowColumnSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionRowColumnFilterInput | null,
};

export type OnCreateQuestionRowColumnSubscription = {
  onCreateQuestionRowColumn?:  {
    __typename: "QuestionRowColumn",
    id: string,
    name: string,
    order: number,
    answerDataType: AnswerDataType,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionQuestionRowColumnsId?: string | null,
  } | null,
};

export type OnUpdateQuestionRowColumnSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionRowColumnFilterInput | null,
};

export type OnUpdateQuestionRowColumnSubscription = {
  onUpdateQuestionRowColumn?:  {
    __typename: "QuestionRowColumn",
    id: string,
    name: string,
    order: number,
    answerDataType: AnswerDataType,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionQuestionRowColumnsId?: string | null,
  } | null,
};

export type OnDeleteQuestionRowColumnSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionRowColumnFilterInput | null,
};

export type OnDeleteQuestionRowColumnSubscription = {
  onDeleteQuestionRowColumn?:  {
    __typename: "QuestionRowColumn",
    id: string,
    name: string,
    order: number,
    answerDataType: AnswerDataType,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionQuestionRowColumnsId?: string | null,
  } | null,
};

export type OnCreateReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnCreateReportSubscription = {
  onCreateReport?:  {
    __typename: "Report",
    id: string,
    name: string,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaireSections?:  {
      __typename: "ModelReportQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "ReportQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        reportReportQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageReportsId?: string | null,
  } | null,
};

export type OnUpdateReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnUpdateReportSubscription = {
  onUpdateReport?:  {
    __typename: "Report",
    id: string,
    name: string,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaireSections?:  {
      __typename: "ModelReportQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "ReportQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        reportReportQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageReportsId?: string | null,
  } | null,
};

export type OnDeleteReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnDeleteReportSubscription = {
  onDeleteReport?:  {
    __typename: "Report",
    id: string,
    name: string,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reportQuestionnaireSections?:  {
      __typename: "ModelReportQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "ReportQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        reportReportQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageReportsId?: string | null,
  } | null,
};

export type OnCreateReportQuestionnaireSubscriptionVariables = {
  filter?: ModelSubscriptionReportQuestionnaireFilterInput | null,
};

export type OnCreateReportQuestionnaireSubscription = {
  onCreateReportQuestionnaire?:  {
    __typename: "ReportQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaireSection?:  {
      __typename: "ReportQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      report?:  {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      reportReportQuestionnaireSectionsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionReportQuestionnairesId?: string | null,
    reportReportQuestionnairesId?: string | null,
    reportQuestionnaireSectionReportQuestionnairesId?: string | null,
  } | null,
};

export type OnUpdateReportQuestionnaireSubscriptionVariables = {
  filter?: ModelSubscriptionReportQuestionnaireFilterInput | null,
};

export type OnUpdateReportQuestionnaireSubscription = {
  onUpdateReportQuestionnaire?:  {
    __typename: "ReportQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaireSection?:  {
      __typename: "ReportQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      report?:  {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      reportReportQuestionnaireSectionsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionReportQuestionnairesId?: string | null,
    reportReportQuestionnairesId?: string | null,
    reportQuestionnaireSectionReportQuestionnairesId?: string | null,
  } | null,
};

export type OnDeleteReportQuestionnaireSubscriptionVariables = {
  filter?: ModelSubscriptionReportQuestionnaireFilterInput | null,
};

export type OnDeleteReportQuestionnaireSubscription = {
  onDeleteReportQuestionnaire?:  {
    __typename: "ReportQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaireSection?:  {
      __typename: "ReportQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      report?:  {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      reportReportQuestionnaireSectionsId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionReportQuestionnairesId?: string | null,
    reportReportQuestionnairesId?: string | null,
    reportQuestionnaireSectionReportQuestionnairesId?: string | null,
  } | null,
};

export type OnCreateReportQuestionnaireSectionSubscriptionVariables = {
  filter?: ModelSubscriptionReportQuestionnaireSectionFilterInput | null,
};

export type OnCreateReportQuestionnaireSectionSubscription = {
  onCreateReportQuestionnaireSection?:  {
    __typename: "ReportQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    reportReportQuestionnaireSectionsId?: string | null,
  } | null,
};

export type OnUpdateReportQuestionnaireSectionSubscriptionVariables = {
  filter?: ModelSubscriptionReportQuestionnaireSectionFilterInput | null,
};

export type OnUpdateReportQuestionnaireSectionSubscription = {
  onUpdateReportQuestionnaireSection?:  {
    __typename: "ReportQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    reportReportQuestionnaireSectionsId?: string | null,
  } | null,
};

export type OnDeleteReportQuestionnaireSectionSubscriptionVariables = {
  filter?: ModelSubscriptionReportQuestionnaireSectionFilterInput | null,
};

export type OnDeleteReportQuestionnaireSectionSubscription = {
  onDeleteReportQuestionnaireSection?:  {
    __typename: "ReportQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    report?:  {
      __typename: "Report",
      id: string,
      name: string,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaireSections?:  {
        __typename: "ModelReportQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageReportsId?: string | null,
    } | null,
    reportQuestionnaires?:  {
      __typename: "ModelReportQuestionnaireConnection",
      items:  Array< {
        __typename: "ReportQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionReportQuestionnairesId?: string | null,
        reportReportQuestionnairesId?: string | null,
        reportQuestionnaireSectionReportQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    reportReportQuestionnaireSectionsId?: string | null,
  } | null,
};

export type OnCreateRowColumnAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionRowColumnAnswerFilterInput | null,
  owner?: string | null,
};

export type OnCreateRowColumnAnswerSubscription = {
  onCreateRowColumnAnswer?:  {
    __typename: "RowColumnAnswer",
    id: string,
    name: string,
    version?: number | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    questionRowColumn?:  {
      __typename: "QuestionRowColumn",
      id: string,
      name: string,
      order: number,
      answerDataType: AnswerDataType,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionQuestionRowColumnsId?: string | null,
    } | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      name: string,
      version?: number | null,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        description?: string | null,
        productCode?: string | null,
        gmdnDescription?: string | null,
        deviceClassification?: DeviceClassification | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productMedflowVersionId?: string | null,
        owner?: string | null,
      } | null,
      rowColumnAnswers?:  {
        __typename: "ModelRowColumnAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productAnswersId?: string | null,
      answerQuestionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    answerRowColumnAnswersId?: string | null,
    rowColumnAnswerQuestionRowColumnId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateRowColumnAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionRowColumnAnswerFilterInput | null,
  owner?: string | null,
};

export type OnUpdateRowColumnAnswerSubscription = {
  onUpdateRowColumnAnswer?:  {
    __typename: "RowColumnAnswer",
    id: string,
    name: string,
    version?: number | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    questionRowColumn?:  {
      __typename: "QuestionRowColumn",
      id: string,
      name: string,
      order: number,
      answerDataType: AnswerDataType,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionQuestionRowColumnsId?: string | null,
    } | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      name: string,
      version?: number | null,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        description?: string | null,
        productCode?: string | null,
        gmdnDescription?: string | null,
        deviceClassification?: DeviceClassification | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productMedflowVersionId?: string | null,
        owner?: string | null,
      } | null,
      rowColumnAnswers?:  {
        __typename: "ModelRowColumnAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productAnswersId?: string | null,
      answerQuestionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    answerRowColumnAnswersId?: string | null,
    rowColumnAnswerQuestionRowColumnId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteRowColumnAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionRowColumnAnswerFilterInput | null,
  owner?: string | null,
};

export type OnDeleteRowColumnAnswerSubscription = {
  onDeleteRowColumnAnswer?:  {
    __typename: "RowColumnAnswer",
    id: string,
    name: string,
    version?: number | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    valueString?: string | null,
    valueInt?: number | null,
    valueFloat?: number | null,
    valueBoolean?: boolean | null,
    questionRowColumn?:  {
      __typename: "QuestionRowColumn",
      id: string,
      name: string,
      order: number,
      answerDataType: AnswerDataType,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      questionQuestionRowColumnsId?: string | null,
    } | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      name: string,
      version?: number | null,
      valueString?: string | null,
      valueInt?: number | null,
      valueFloat?: number | null,
      valueBoolean?: boolean | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      question?:  {
        __typename: "Question",
        id: string,
        name: string,
        description?: string | null,
        guidingBeforeText?: string | null,
        placeholder?: string | null,
        statement?: string | null,
        tooltip?: string | null,
        answerDataType: AnswerDataType,
        specialQuestionCategory?: SpecialQuestionCategory | null,
        examples?: Array< string | null > | null,
        explanations?: Array< string | null > | null,
        notes?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        description?: string | null,
        productCode?: string | null,
        gmdnDescription?: string | null,
        deviceClassification?: DeviceClassification | null,
        readers?: Array< string | null > | null,
        editors?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        productMedflowVersionId?: string | null,
        owner?: string | null,
      } | null,
      rowColumnAnswers?:  {
        __typename: "ModelRowColumnAnswerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      productAnswersId?: string | null,
      answerQuestionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    answerRowColumnAnswersId?: string | null,
    rowColumnAnswerQuestionRowColumnId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateStageSubscriptionVariables = {
  filter?: ModelSubscriptionStageFilterInput | null,
};

export type OnCreateStageSubscription = {
  onCreateStage?:  {
    __typename: "Stage",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseMedflowVersionId?: string | null,
    } | null,
    track?:  {
      __typename: "Track",
      id: string,
      name: string,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      trackMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaireSections?:  {
      __typename: "ModelStageQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "StageQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        stageStageQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseStagesId?: string | null,
    trackStagesId?: string | null,
    stageMedflowVersionId?: string | null,
  } | null,
};

export type OnUpdateStageSubscriptionVariables = {
  filter?: ModelSubscriptionStageFilterInput | null,
};

export type OnUpdateStageSubscription = {
  onUpdateStage?:  {
    __typename: "Stage",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseMedflowVersionId?: string | null,
    } | null,
    track?:  {
      __typename: "Track",
      id: string,
      name: string,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      trackMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaireSections?:  {
      __typename: "ModelStageQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "StageQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        stageStageQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseStagesId?: string | null,
    trackStagesId?: string | null,
    stageMedflowVersionId?: string | null,
  } | null,
};

export type OnDeleteStageSubscriptionVariables = {
  filter?: ModelSubscriptionStageFilterInput | null,
};

export type OnDeleteStageSubscription = {
  onDeleteStage?:  {
    __typename: "Stage",
    id: string,
    name: string,
    order: number,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseMedflowVersionId?: string | null,
    } | null,
    track?:  {
      __typename: "Track",
      id: string,
      name: string,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      stages?:  {
        __typename: "ModelStageConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      trackMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    stageQuestionnaireSections?:  {
      __typename: "ModelStageQuestionnaireSectionConnection",
      items:  Array< {
        __typename: "StageQuestionnaireSection",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        stageStageQuestionnaireSectionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        stageReportsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    phaseStagesId?: string | null,
    trackStagesId?: string | null,
    stageMedflowVersionId?: string | null,
  } | null,
};

export type OnCreateStageQuestionnaireSubscriptionVariables = {
  filter?: ModelSubscriptionStageQuestionnaireFilterInput | null,
};

export type OnCreateStageQuestionnaireSubscription = {
  onCreateStageQuestionnaire?:  {
    __typename: "StageQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stageQuestionnaireSection?:  {
      __typename: "StageQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageStageQuestionnaireSectionsId?: string | null,
    } | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionStageQuestionnairesId?: string | null,
    stageStageQuestionnairesId?: string | null,
    stageQuestionnaireSectionStageQuestionnairesId?: string | null,
  } | null,
};

export type OnUpdateStageQuestionnaireSubscriptionVariables = {
  filter?: ModelSubscriptionStageQuestionnaireFilterInput | null,
};

export type OnUpdateStageQuestionnaireSubscription = {
  onUpdateStageQuestionnaire?:  {
    __typename: "StageQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stageQuestionnaireSection?:  {
      __typename: "StageQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageStageQuestionnaireSectionsId?: string | null,
    } | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionStageQuestionnairesId?: string | null,
    stageStageQuestionnairesId?: string | null,
    stageQuestionnaireSectionStageQuestionnairesId?: string | null,
  } | null,
};

export type OnDeleteStageQuestionnaireSubscriptionVariables = {
  filter?: ModelSubscriptionStageQuestionnaireFilterInput | null,
};

export type OnDeleteStageQuestionnaireSubscription = {
  onDeleteStageQuestionnaire?:  {
    __typename: "StageQuestionnaire",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    question?:  {
      __typename: "Question",
      id: string,
      name: string,
      description?: string | null,
      guidingBeforeText?: string | null,
      placeholder?: string | null,
      statement?: string | null,
      tooltip?: string | null,
      answerDataType: AnswerDataType,
      specialQuestionCategory?: SpecialQuestionCategory | null,
      examples?: Array< string | null > | null,
      explanations?: Array< string | null > | null,
      notes?: Array< string | null > | null,
      questionLabels?:  {
        __typename: "ModelQuestionLabelConnection",
        nextToken?: string | null,
      } | null,
      questionRowColumns?:  {
        __typename: "ModelQuestionRowColumnConnection",
        nextToken?: string | null,
      } | null,
      reportQuestionnaires?:  {
        __typename: "ModelReportQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stageQuestionnaireSection?:  {
      __typename: "StageQuestionnaireSection",
      id: string,
      name: string,
      order: number,
      deviceClassification?: DeviceClassification | null,
      stage?:  {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      stageStageQuestionnaireSectionsId?: string | null,
    } | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    questionStageQuestionnairesId?: string | null,
    stageStageQuestionnairesId?: string | null,
    stageQuestionnaireSectionStageQuestionnairesId?: string | null,
  } | null,
};

export type OnCreateStageQuestionnaireSectionSubscriptionVariables = {
  filter?: ModelSubscriptionStageQuestionnaireSectionFilterInput | null,
};

export type OnCreateStageQuestionnaireSectionSubscription = {
  onCreateStageQuestionnaireSection?:  {
    __typename: "StageQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageStageQuestionnaireSectionsId?: string | null,
  } | null,
};

export type OnUpdateStageQuestionnaireSectionSubscriptionVariables = {
  filter?: ModelSubscriptionStageQuestionnaireSectionFilterInput | null,
};

export type OnUpdateStageQuestionnaireSectionSubscription = {
  onUpdateStageQuestionnaireSection?:  {
    __typename: "StageQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageStageQuestionnaireSectionsId?: string | null,
  } | null,
};

export type OnDeleteStageQuestionnaireSectionSubscriptionVariables = {
  filter?: ModelSubscriptionStageQuestionnaireSectionFilterInput | null,
};

export type OnDeleteStageQuestionnaireSectionSubscription = {
  onDeleteStageQuestionnaireSection?:  {
    __typename: "StageQuestionnaireSection",
    id: string,
    name: string,
    order: number,
    deviceClassification?: DeviceClassification | null,
    stage?:  {
      __typename: "Stage",
      id: string,
      name: string,
      order: number,
      medflowVersion?:  {
        __typename: "MedflowVersion",
        id?: string | null,
        name: string,
        number: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseMedflowVersionId?: string | null,
      } | null,
      track?:  {
        __typename: "Track",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        trackMedflowVersionId?: string | null,
      } | null,
      stageQuestionnaires?:  {
        __typename: "ModelStageQuestionnaireConnection",
        nextToken?: string | null,
      } | null,
      stageQuestionnaireSections?:  {
        __typename: "ModelStageQuestionnaireSectionConnection",
        nextToken?: string | null,
      } | null,
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      phaseStagesId?: string | null,
      trackStagesId?: string | null,
      stageMedflowVersionId?: string | null,
    } | null,
    stageQuestionnaires?:  {
      __typename: "ModelStageQuestionnaireConnection",
      items:  Array< {
        __typename: "StageQuestionnaire",
        id: string,
        name: string,
        order: number,
        deviceClassification?: DeviceClassification | null,
        createdAt: string,
        updatedAt: string,
        questionStageQuestionnairesId?: string | null,
        stageStageQuestionnairesId?: string | null,
        stageQuestionnaireSectionStageQuestionnairesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    stageStageQuestionnaireSectionsId?: string | null,
  } | null,
};

export type OnCreateTrackSubscriptionVariables = {
  filter?: ModelSubscriptionTrackFilterInput | null,
};

export type OnCreateTrackSubscription = {
  onCreateTrack?:  {
    __typename: "Track",
    id: string,
    name: string,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    trackMedflowVersionId?: string | null,
  } | null,
};

export type OnUpdateTrackSubscriptionVariables = {
  filter?: ModelSubscriptionTrackFilterInput | null,
};

export type OnUpdateTrackSubscription = {
  onUpdateTrack?:  {
    __typename: "Track",
    id: string,
    name: string,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    trackMedflowVersionId?: string | null,
  } | null,
};

export type OnDeleteTrackSubscriptionVariables = {
  filter?: ModelSubscriptionTrackFilterInput | null,
};

export type OnDeleteTrackSubscription = {
  onDeleteTrack?:  {
    __typename: "Track",
    id: string,
    name: string,
    medflowVersion?:  {
      __typename: "MedflowVersion",
      id?: string | null,
      name: string,
      number: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    stages?:  {
      __typename: "ModelStageConnection",
      items:  Array< {
        __typename: "Stage",
        id: string,
        name: string,
        order: number,
        createdAt: string,
        updatedAt: string,
        phaseStagesId?: string | null,
        trackStagesId?: string | null,
        stageMedflowVersionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    trackMedflowVersionId?: string | null,
  } | null,
};
