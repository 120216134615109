import { CreateLabelInput, DeleteLabelInput, UpdateLabelInput, Label } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyLabelInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name'];

    const entityRelationsKeysEntities: Map<string, EntityName> = new Map([
        ['questionLabels', EntityName.QuestionLabel]
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys,
        entityRelationsKeysEntities
    });
}

export function getFilledLabelInstance(model: Label): FilledEntity {
    const instance = getEmptyLabelInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name === null ? undefined : model.name);

    const questionLabelIds = model.questionLabels === undefined ? [] : model.questionLabels?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('questionLabels', {ids: questionLabelIds, entityName: EntityName.QuestionLabel});

    return instance;
}

export function getCreateLabelInput(instance: FilledEntity): CreateLabelInput {
    const name = instance.getStringMandatory('name');

    return {
        name
    };
}

export function getUpdateLabelInput(instance: FilledEntity): UpdateLabelInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    
    return {
        id,
        name
    };
}

export function getDeleteLabelInput(instance: FilledEntity): DeleteLabelInput {
    const id = instance.getIdMandatory('id');
    
    return {
        id
    };
}