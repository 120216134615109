import * as graphQlApi from '../../../API';

import { useState } from 'react'
import { getQualificationQuestionIds } from './QualificationHelper';
import { getClassificationQuestionIndices, getRuleClassificationExpressions } from './ClassificationHelper';
import { hasAllAnswers, hasAnswer } from './OnboardingHelper';
import { OnboardingStage } from './Assess';

interface OnboardingMenuProps {
    stage: OnboardingStage
    qualificationExpressions: {[key: string]: graphQlApi.DeviceQualificationExpression} 
    qualificationQuestions: {[key: string]: graphQlApi.Question}
    qualificationAnswers: {[key: string]: boolean}
    classificationExpressions: {[key: string]: graphQlApi.DeviceClassificationExpression} 
    classificationQuestions: {[key: string]: graphQlApi.Question}
    classificationAnswers: {[key: string]: boolean}
    lastSeenQualificationQuestionId: string | undefined
    lastSeenClassificationExpressionId: string | undefined
    seeQualificationQuestion: (questionId: string) => void
    seeClassificationExpression: (expressionId: string) => void
    seeQualificationResult: (value: boolean) => void
    seeClassificationResult: (value: boolean) => void
}

export const OnboardingMenu: React.FC<OnboardingMenuProps> = ({ stage, qualificationExpressions, qualificationQuestions, qualificationAnswers, classificationExpressions, classificationQuestions, classificationAnswers,
        lastSeenQualificationQuestionId, lastSeenClassificationExpressionId, seeQualificationQuestion, seeClassificationExpression, seeQualificationResult, seeClassificationResult }) => {
    const [expandedQualification, setExpandedQualification] = useState(false)
    const [expandedClassification, setExpandedClassification] = useState(false)
    const [expandedReport, setExpandedReport] = useState(false)
    
    const hasAllQualificationAnswers = hasAllAnswers(qualificationQuestions, qualificationAnswers)
    const hasAllClassificationAnswers = hasAllAnswers(classificationQuestions, classificationAnswers)
    const qualificationQuestionIds = getQualificationQuestionIds(qualificationExpressions, true)
    const ruleClassificationExpressions = getRuleClassificationExpressions(classificationExpressions)

    function hasBeenQualification() {
        if (stage === OnboardingStage.Qualify || stage === OnboardingStage.Qualified || stage === OnboardingStage.Classify || stage === OnboardingStage.Classified || stage === OnboardingStage.Report) {
            return true
        }

        return false
    }

    function hasBeenClassification() {
        if (stage === OnboardingStage.Classify || stage === OnboardingStage.Classified || stage === OnboardingStage.Report) {
            return true
        }

        return false
    }

    function hasBeenReport() {
        if (stage === OnboardingStage.Report) {
            return true
        }

        return false
    }

    return (
        <nav className="navbar show navbar-vertical navbar-expand-lg py-0" id="sidebar">
            <div className="card">
                <div className="card-body">
                    <div className="collapse navbar-collapse" id="sidebarCollapse">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className={stage === OnboardingStage.Qualify ? "nav-link" : "nav-link text-muted"} role="button" onClick={() => hasBeenQualification() && setExpandedQualification(!expandedQualification)}>
                                    <i className={stage === OnboardingStage.Qualify ? (expandedQualification ? "bi bi-chevron-down" : "bi bi-chevron-right") : "bi bi-dash"}></i>
                                    Qualification
                                    {
                                        hasAllQualificationAnswers &&
                                        <i className="bi bi-check text-success mx-1"></i>
                                    }
                                </a>
                                <div className={stage === OnboardingStage.Qualify && expandedQualification ? "collapse show": "collapse"}>
                                    <ul className="nav nav-sm flex-column mx-2">
                                        {
                                            qualificationQuestionIds.map((questionId, index) => {
                                                const hasAnswerQuestion = hasAnswer(questionId, qualificationAnswers)
                                                const isActiveQuestion = lastSeenQualificationQuestionId === questionId

                                                return <li className="nav-item" key={"qualify-" + questionId}>
                                                    <a className={isActiveQuestion ? 'nav-link font-bold' : 'nav-link'} onClick={() => seeQualificationQuestion(questionId)} role="button">
                                                        Question {1 + index}
                                                        {
                                                            hasAnswerQuestion &&
                                                            <i className="text-success bi bi-check mx-1"></i>
                                                        }
                                                    </a>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className={stage === OnboardingStage.Classify ? "nav-link" : "nav-link text-muted"} role="button" onClick={() => hasBeenClassification() && setExpandedClassification(!expandedClassification)}>
                                    <i className={stage === OnboardingStage.Classify ? (expandedClassification ? "bi bi-chevron-down" : "bi bi-chevron-right") : "bi bi-dash"}></i>
                                    Classification
                                    {
                                        hasAllClassificationAnswers &&
                                        <i className="bi bi-check text-success mx-1"></i>
                                    }
                                </a>
                                <div className={stage === OnboardingStage.Classify && expandedClassification ? "collapse show": "collapse"}>
                                    <ul className="nav nav-sm flex-column mx-2">
                                        {
                                            ruleClassificationExpressions.map((ruleExpression, index) => {
                                                const isActiveRule = lastSeenClassificationExpressionId !== undefined && lastSeenClassificationExpressionId === ruleExpression.id
                                                const questionIndices = getClassificationQuestionIndices(ruleExpression, classificationExpressions)
                                                const questionIds = Object.keys(questionIndices)
                                                const hasAllAnswersQuestions = questionIds.every(questionId => hasAnswer(questionId, classificationAnswers))

                                                return <li className="nav-item" key={"classify-rule-" + ruleExpression.id}>
                                                    <a className={isActiveRule ? 'nav-link font-bold' : 'nav-link'} onClick={() => seeClassificationExpression(ruleExpression.id)} role="button">
                                                        Rule {1 + index}
                                                        {
                                                            hasAllAnswersQuestions &&
                                                            <span><i className="bi bi-check text-success"></i>&nbsp;</span>
                                                        }
                                                    </a>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className={hasBeenReport() ? "nav-link" : "nav-link text-muted"} role="button" onClick={() => hasBeenReport() && setExpandedReport(!expandedReport)}>
                                    <i className={hasBeenReport() ? (expandedReport ? "bi bi-chevron-down" : "bi bi-chevron-right") : "bi bi-dash"}></i>
                                    Report
                                    {
                                        hasAllClassificationAnswers && hasAllQualificationAnswers &&
                                        <i className="bi bi-check text-success mx-1"></i>
                                    }
                                </a>
                                <div className={expandedReport ? "collapse show": "collapse"}>
                                    <ul className="nav nav-sm flex-column mx-2">
                                        <li className="nav-item">
                                            <a className="nav-link" role="button" onClick={() => seeQualificationResult(true)}>
                                                Qualification
                                                {
                                                    hasAllQualificationAnswers &&
                                                    <i className="text-success bi bi-check mx-1"></i>
                                                }
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" role="button" onClick={() => seeClassificationResult(true)}>
                                                Classification
                                                {
                                                    hasAllClassificationAnswers &&
                                                    <i className="text-success bi bi-check mx-1"></i>
                                                }
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}