import React, { useState, useEffect } from 'react'
import { EntityName } from '../../model/EntityName'
import { BooleanViewer } from './primitive-viewers/BooleanViewer'
import { IntViewer } from './primitive-viewers/IntViewer'
import { FloatViewer } from './primitive-viewers/FloatViewer'
import { StringViewer } from './primitive-viewers/StringViewer'
import { RelatedEnumViewer } from '../common/RelatedEnumViewer'
import { FilledEntity } from '../../model/FilledEntity'
import { RelatedEntityViewer } from '../common/RelatedEntityViewer'
import { RelatedsEntityViewer } from '../common/RelatedsEntityViewer'
import { getFilledInstance } from './mixin-helper'

interface GenericViewerProps {
    id: string
    entityName: EntityName
    relationName?: string
    setEntityCreator: (entityName: EntityName) => void
    setEntityViewer: (entityName: EntityName, id: string) => void
    setEntityEditor: (entityName: EntityName, id: string) => void
    setEntityRemover: (entityName: EntityName, id: string) => void
    discardEntityViewer: (entityName: EntityName, id: string) => void
}

export const GenericViewer: React.FC<GenericViewerProps> = ({ id, entityName, relationName, setEntityCreator, setEntityViewer, setEntityEditor, setEntityRemover, discardEntityViewer }) => {
    const [instance, setInstance] = useState<FilledEntity|undefined>(undefined)

    function stringToEnum<T>(str: string|undefined, enumType: T): T[keyof T] | undefined {
        if (str === undefined) {
            return undefined
        }

        return enumType[str as keyof T];
    }

    useEffect(() => {
        const asyncSetInstance = async () => {
            const filledInstance = await getFilledInstance(entityName, id)
            setInstance(filledInstance)
        }

        asyncSetInstance()
    }, [id])

    const name = instance?.getStringMap().get("name")

    if (instance === undefined) {
        return null
    }

    return (
        <div className="card">
            <div className="card-header">
                <h2>View {relationName} {name} <span className="text-gray-500 font-normal">{entityName}</span></h2>
            </div>
            <div className="card-body">
                <>
                {
                    Array.from(instance.getStringMap()).map(([key, value], index) => (
                        <div className="mt-4" key={key}>
                            <StringViewer relationName={key} value={value} />
                        </div>
                    ))
                }
                {
                    Array.from(instance.getIntMap()).map(([key, value], index) => (
                        <div className="mt-4" key={key}>
                            <IntViewer relationName={key} value={value} />
                        </div>
                    ))
                }
                {
                    Array.from(instance.getFloatMap()).map(([key, value], index) => (
                        <div className="mt-4" key={key}>
                            <FloatViewer relationName={key} value={value} />
                        </div>
                    ))
                }
                {
                    Array.from(instance.getBooleanMap()).map(([key, value], index) => (
                        <div className="mt-4" key={key}>
                            <BooleanViewer relationName={key} value={value} />
                        </div>
                    ))
                }
                {
                    Array.from(instance.getAnswerDataTypeMap()).map(([key, value], index) => (
                        <div className="mt-4" key={key}>
                            <RelatedEnumViewer relationName={key} value={value} />
                        </div>
                    ))
                }
                {
                    Array.from(instance.getSpecialQuestionCategoryMap()).map(([key, value], index) => (
                        <div className="mt-4" key={key}>
                            <RelatedEnumViewer relationName={key} value={value} />
                        </div>
                    ))
                }
                {
                    Array.from(instance.getDeviceExpressionOperatorMap()).map(([key, value], index) => (
                        <div className="mt-4" key={key}>
                            <RelatedEnumViewer relationName={key} value={value} />
                        </div>
                    ))
                }
                {
                    Array.from(instance.getDeviceClassificationMap()).map(([key, value], index) => (
                        <div className="mt-4" key={key}>
                            <RelatedEnumViewer relationName={key} value={value} />
                        </div>
                    ))
                }
                {
                    Array.from(instance.getDeviceQualificationMap()).map(([key, value], index) => (
                        <div className="mt-4" key={key}>
                            <RelatedEnumViewer relationName={key} value={value} />
                        </div>
                    ))
                }
                {
                    Array.from(instance.getEntityEnumMap()).map(([key, value], index) => (
                        <div className="mt-4" key={key}>
                            <RelatedEnumViewer relationName={key} value={value} />
                        </div>
                    ))
                }
                {
                    Array.from(instance.getEntityRelationMap()).map(([key, value]) => {
                        const givenEntityNameString = value?.entityName.toString()

                        if (givenEntityNameString === undefined) {
                            return null
                        }

                        const givenEntityName = stringToEnum(givenEntityNameString, EntityName)

                        if (givenEntityName === undefined) {
                            return null
                        }

                        const givenId = value?.id

                        if (givenId === undefined) {
                            return null
                        }

                        return (
                            <div className="mt-4" key={key}>
                                <RelatedEntityViewer relationName={key}
                                entityName={givenEntityName} id={givenId}
                                setEntityCreator={setEntityCreator} setEntityViewer={setEntityViewer} setEntityEditor={setEntityEditor} setEntityRemover={setEntityRemover} />
                            </div>
                        )
                    })
                }
                {
                    Array.from(instance.getEntityRelationsMap()).map(([key, value]) => {
                        const givenEntityNameString = value?.entityName.toString()

                        if (givenEntityNameString === undefined) {
                            return null
                        }

                        const givenEntityName = stringToEnum(givenEntityNameString, EntityName)

                        if (givenEntityName === undefined) {
                            return null
                        }

                        const givenIds = value?.ids

                        if (givenIds === undefined || givenIds.length <= 0) {
                            return null
                        }

                        return (
                            <div className="mt-4" key={key}>
                                <RelatedsEntityViewer relationName={key}
                                entityName={givenEntityName} ids={givenIds}
                                setEntityCreator={setEntityCreator} setEntityViewer={setEntityViewer} setEntityEditor={setEntityEditor} setEntityRemover={setEntityRemover} />
                            </div>
                        )
                    })
                }
                </>
            </div>
        </div>
    )
}