import React, { useEffect, useState } from 'react'
import { RelatedEntityViewer } from '../common/RelatedEntityViewer'
import { EntityName } from '../../model/EntityName'
import { FilledEntity } from '../../model/FilledEntity'
import { listFilledInstances } from './mixin-helper'

interface GenericListerProps {
    entityName: EntityName
    relationName?: string
    setEntityCreator: (entityName: EntityName) => void
    setEntityViewer: (entityName: EntityName, id: string) => void
    setEntityEditor: (entityName: EntityName, id: string) => void
    setEntityRemover: (entityName: EntityName, id: string) => void
}

export const GenericLister: React.FC<GenericListerProps> = ({ entityName, relationName, setEntityCreator, setEntityViewer, setEntityEditor, setEntityRemover }) => {
    const [instances, setInstances] = useState<FilledEntity[]>([])

    useEffect(() => {
        const asyncSetInstances = async () => {
            const instances = await listFilledInstances(entityName)
            setInstances(instances)
        }
    
        asyncSetInstances()    
    }, [])

    return (
        <>
            {
                instances.map((instance) => {
                    const id = instance.getIdMap().get("id")

                    if (id === undefined || id === null || id === "") {
                        return null
                    }

                    return (
                        <div className="mb-4" key={id}>
                            <RelatedEntityViewer
                                relationName={relationName}
                                key={id}
                                entityName={entityName}
                                setEntityCreator={setEntityCreator}
                                setEntityViewer={setEntityViewer}
                                setEntityEditor={setEntityEditor}
                                setEntityRemover={setEntityRemover}
                                id={id}
                            />
                        </div>
                    )
                })
            }
            {
                instances.length <= 0 && 
                <div className="alert mb-4">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <span>No {entityName}. Create one.</span>
                    </div>
                </div>
            }
        </>
    )
}