export const Contact: React.FC = () => {
    return (
        <div>
            <div className="py-4 py-xs-8 py-sm-12 py-md-16 py-lg-24 py-xl-32 py-xxl-32 overflow-hidden">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <h1 className="display-1 text-center text-primary">
                                contact
                            </h1>
                            <h2 className="mb-10 text-center">
                                We are here to help and we read all messages
                            </h2>
                            <p className="lead mb-10 text-center">
                                We are early enough to be informal and responsive. Expect a reply within a few hours to a few days. Kindly include a subject and your preferred way to be reached back, unless it is obvious. Once our communication is established, we can also talk, chat, etc.
                            </p>
                            <div className="mx-2 text-center">
                                <a href="mailto://ms@medflowsoft.com" className="btn btn-lg btn-primary shadow-sm mx-2 px-lg-8">
                                    Write to Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-n1 transform translate-y-1/4">
                    <div className="col-lg-11 col-xl-9 mx-auto">
                        <div className="row g-5">
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md6.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md4.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md3.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md5.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md2.jpg" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
