import { API } from "aws-amplify";
import { CreateQuestionRowColumnInput, DeleteQuestionRowColumnInput, QuestionRowColumn, GetQuestionRowColumnQuery, ListQuestionRowColumnsQuery, UpdateQuestionRowColumnInput } from "../API";
import { createQuestionRowColumn, deleteQuestionRowColumn, updateQuestionRowColumn } from "../graphql/mutations";
import { getQuestionRowColumn, listQuestionRowColumns, listQuestionRowColumnsByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createQuestionRowColumnApi = async (input: CreateQuestionRowColumnInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionRowColumn> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createQuestionRowColumn,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createQuestionRowColumn: QuestionRowColumn } };
    return response.data.createQuestionRowColumn;
}

export const updateQuestionRowColumnApi = async (input: UpdateQuestionRowColumnInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionRowColumn> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updateQuestionRowColumn,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updateQuestionRowColumn: QuestionRowColumn } };
    return response.data.updateQuestionRowColumn;
}

export const deleteQuestionRowColumnApi = async (input: DeleteQuestionRowColumnInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionRowColumn> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deleteQuestionRowColumn,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deleteQuestionRowColumn: QuestionRowColumn } };
    return response.data.deleteQuestionRowColumn;
}

export const listQuestionRowColumnsApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionRowColumn[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listQuestionRowColumns,
    });

    const response = responseRaw as { data: ListQuestionRowColumnsQuery };
    const items = response.data.listQuestionRowColumns?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as QuestionRowColumn[];
    return itemsExisting
}

export const getQuestionRowColumnApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionRowColumn|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getQuestionRowColumn,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetQuestionRowColumnQuery };
    const instance = response.data.getQuestionRowColumn;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as QuestionRowColumn
    return instanceNotUndefined
}

export const listQuestionRowColumnsByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<QuestionRowColumn[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listQuestionRowColumnsByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListQuestionRowColumnsQuery };
    const items = response.data.listQuestionRowColumns?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as QuestionRowColumn[];
    return itemsExisting
}
