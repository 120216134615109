import { CreatePreviewProductInput, DeletePreviewProductInput, PreviewProduct, UpdatePreviewProductInput } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyPreviewProductInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name', 'description', 'intendedUse'];

    const entityRelationKeysEntities: Map<string, EntityName> = new Map([
        ['previewUser', EntityName.PreviewUser],
        ['previewOrganization', EntityName.PreviewOrganization]
    ]);

    const entityRelationsKeysEntities: Map<string, EntityName> = new Map([
        ['previewAnswers', EntityName.PreviewAnswer],
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys,
        entityRelationKeysEntities,
        entityRelationsKeysEntities
    });
}

export function getFilledPreviewProductInstance(model: PreviewProduct): FilledEntity {
    const instance = getEmptyPreviewProductInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name);
    instance.addString('description', model.description);
    instance.addString('intendedUse', model.intendedUse)
    instance.addEntityRelation('previewUser', {entityName: EntityName.PreviewUser, id: model.previewUserPreviewProductsId === null ? undefined : model.previewUserPreviewProductsId});
    instance.addEntityRelation('previewOrganization', {entityName: EntityName.PreviewOrganization, id: model.previewOrganizationPreviewProductsId === null ? undefined : model.previewOrganizationPreviewProductsId});
    
    const previewAnswerIds = model.previewAnswers === undefined ? [] : model.previewAnswers?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('previewAnswers', {ids: previewAnswerIds, entityName: EntityName.PreviewAnswer});

    return instance;
}

export function getCreatePreviewProductInput(instance: FilledEntity): CreatePreviewProductInput {
    const name = instance.getStringMandatory('name');
    const description = instance.getStringMandatory('description');
    const intendedUse = instance.getStringMandatory('intendedUse');
    const previewUserPreviewProductsId = instance.getEntityRelationOptional('previewUser')?.id;
    const previewOrganizationPreviewProductsId = instance.getEntityRelationOptional('previewOrganization')?.id;

    return {
        name,
        description,
        intendedUse,
        previewUserPreviewProductsId,
        previewOrganizationPreviewProductsId        
    };
}

export function getUpdatePreviewProductInput(instance: FilledEntity): UpdatePreviewProductInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const description = instance.getStringOptional('description');
    const intendedUse = instance.getStringOptional('intendedUse');
    const previewUserPreviewProductsId = instance.getEntityRelationOptional('previewUser')?.id;
    const previewOrganizationPreviewProductsId = instance.getEntityRelationOptional('previewOrganization')?.id;

    return {
        id,
        name,
        description,
        intendedUse,
        previewUserPreviewProductsId,
        previewOrganizationPreviewProductsId        
    };
}

export function getDeletePreviewProductInput(instance: FilledEntity): DeletePreviewProductInput {
    const id = instance.getIdMandatory('id');

    return {
        id
    };
}