/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      name
      version
      valueString
      valueInt
      valueFloat
      valueBoolean
      readers
      editors
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      product {
        id
        name
        description
        productCode
        gmdnDescription
        deviceClassification
        readers
        editors
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        productMedflowVersionId
        owner
      }
      rowColumnAnswers {
        items {
          id
          name
          version
          readers
          editors
          valueString
          valueInt
          valueFloat
          valueBoolean
          createdAt
          updatedAt
          answerRowColumnAnswersId
          rowColumnAnswerQuestionRowColumnId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      productAnswersId
      answerQuestionId
      owner
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        version
        valueString
        valueInt
        valueFloat
        valueBoolean
        readers
        editors
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        product {
          id
          name
          description
          productCode
          gmdnDescription
          deviceClassification
          readers
          editors
          createdAt
          updatedAt
          productMedflowVersionId
          owner
        }
        rowColumnAnswers {
          nextToken
        }
        createdAt
        updatedAt
        productAnswersId
        answerQuestionId
        owner
      }
      nextToken
    }
  }
`;
export const listAnswersByName = /* GraphQL */ `
  query ListAnswersByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswersByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        version
        valueString
        valueInt
        valueFloat
        valueBoolean
        readers
        editors
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        product {
          id
          name
          description
          productCode
          gmdnDescription
          deviceClassification
          readers
          editors
          createdAt
          updatedAt
          productMedflowVersionId
          owner
        }
        rowColumnAnswers {
          nextToken
        }
        createdAt
        updatedAt
        productAnswersId
        answerQuestionId
        owner
      }
      nextToken
    }
  }
`;
export const getDeviceClassificationExpression = /* GraphQL */ `
  query GetDeviceClassificationExpression($id: ID!) {
    getDeviceClassificationExpression(id: $id) {
      id
      name
      category
      description
      index
      isRoot
      statement
      tooltip
      explanations
      notes
      classificationIfYes
      operator
      specialQuestionCategory
      yesNoQuestion {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      parentDeviceClassificationExpression {
        id
        name
        category
        description
        index
        isRoot
        statement
        tooltip
        explanations
        notes
        classificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceClassificationExpression {
          id
          name
          category
          description
          index
          isRoot
          statement
          tooltip
          explanations
          notes
          classificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
          deviceClassificationExpressionYesNoQuestionId
        }
        childrenDeviceClassificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
        deviceClassificationExpressionYesNoQuestionId
      }
      childrenDeviceClassificationExpressions {
        items {
          id
          name
          category
          description
          index
          isRoot
          statement
          tooltip
          explanations
          notes
          classificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
          deviceClassificationExpressionYesNoQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
      deviceClassificationExpressionYesNoQuestionId
    }
  }
`;
export const listDeviceClassificationExpressions = /* GraphQL */ `
  query ListDeviceClassificationExpressions(
    $filter: ModelDeviceClassificationExpressionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceClassificationExpressions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category
        description
        index
        isRoot
        statement
        tooltip
        explanations
        notes
        classificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceClassificationExpression {
          id
          name
          category
          description
          index
          isRoot
          statement
          tooltip
          explanations
          notes
          classificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
          deviceClassificationExpressionYesNoQuestionId
        }
        childrenDeviceClassificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
        deviceClassificationExpressionYesNoQuestionId
      }
      nextToken
    }
  }
`;
export const listDeviceClassificationExpressionsByName = /* GraphQL */ `
  query ListDeviceClassificationExpressionsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceClassificationExpressionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceClassificationExpressionsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category
        description
        index
        isRoot
        statement
        tooltip
        explanations
        notes
        classificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceClassificationExpression {
          id
          name
          category
          description
          index
          isRoot
          statement
          tooltip
          explanations
          notes
          classificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
          deviceClassificationExpressionYesNoQuestionId
        }
        childrenDeviceClassificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
        deviceClassificationExpressionYesNoQuestionId
      }
      nextToken
    }
  }
`;
export const getDeviceQualificationExpression = /* GraphQL */ `
  query GetDeviceQualificationExpression($id: ID!) {
    getDeviceQualificationExpression(id: $id) {
      id
      name
      description
      index
      isRoot
      statement
      qualificationIfYes
      operator
      specialQuestionCategory
      yesNoQuestion {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      parentDeviceQualificationExpression {
        id
        name
        description
        index
        isRoot
        statement
        qualificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceQualificationExpression {
          id
          name
          description
          index
          isRoot
          statement
          qualificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
          deviceQualificationExpressionYesNoQuestionId
        }
        childrenDeviceQualificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
        deviceQualificationExpressionYesNoQuestionId
      }
      childrenDeviceQualificationExpressions {
        items {
          id
          name
          description
          index
          isRoot
          statement
          qualificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
          deviceQualificationExpressionYesNoQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
      deviceQualificationExpressionYesNoQuestionId
    }
  }
`;
export const listDeviceQualificationExpressions = /* GraphQL */ `
  query ListDeviceQualificationExpressions(
    $filter: ModelDeviceQualificationExpressionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceQualificationExpressions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        index
        isRoot
        statement
        qualificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceQualificationExpression {
          id
          name
          description
          index
          isRoot
          statement
          qualificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
          deviceQualificationExpressionYesNoQuestionId
        }
        childrenDeviceQualificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
        deviceQualificationExpressionYesNoQuestionId
      }
      nextToken
    }
  }
`;
export const listDeviceQualificationExpressionsByName = /* GraphQL */ `
  query ListDeviceQualificationExpressionsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceQualificationExpressionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceQualificationExpressionsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        index
        isRoot
        statement
        qualificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceQualificationExpression {
          id
          name
          description
          index
          isRoot
          statement
          qualificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
          deviceQualificationExpressionYesNoQuestionId
        }
        childrenDeviceQualificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
        deviceQualificationExpressionYesNoQuestionId
      }
      nextToken
    }
  }
`;
export const getEntityTemplate = /* GraphQL */ `
  query GetEntityTemplate($id: ID!) {
    getEntityTemplate(id: $id) {
      id
      name
      valueInt
      valueFloat
      valueBoolean
      entityEnum
      entityTemplateHasOne {
        id
        name
        createdAt
        updatedAt
      }
      entityTemplateBelongsTo {
        id
        name
        entityTemplates {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      entityTemplateBelongsToEntityTemplatesId
      entityTemplateEntityTemplateHasOneId
    }
  }
`;
export const listEntityTemplates = /* GraphQL */ `
  query ListEntityTemplates(
    $filter: ModelEntityTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        valueInt
        valueFloat
        valueBoolean
        entityEnum
        entityTemplateHasOne {
          id
          name
          createdAt
          updatedAt
        }
        entityTemplateBelongsTo {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        entityTemplateBelongsToEntityTemplatesId
        entityTemplateEntityTemplateHasOneId
      }
      nextToken
    }
  }
`;
export const listEntityTemplatesByName = /* GraphQL */ `
  query ListEntityTemplatesByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityTemplatesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        valueInt
        valueFloat
        valueBoolean
        entityEnum
        entityTemplateHasOne {
          id
          name
          createdAt
          updatedAt
        }
        entityTemplateBelongsTo {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        entityTemplateBelongsToEntityTemplatesId
        entityTemplateEntityTemplateHasOneId
      }
      nextToken
    }
  }
`;
export const getEntityTemplateBelongsTo = /* GraphQL */ `
  query GetEntityTemplateBelongsTo($id: ID!) {
    getEntityTemplateBelongsTo(id: $id) {
      id
      name
      entityTemplates {
        items {
          id
          name
          valueInt
          valueFloat
          valueBoolean
          entityEnum
          createdAt
          updatedAt
          entityTemplateBelongsToEntityTemplatesId
          entityTemplateEntityTemplateHasOneId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEntityTemplateBelongsTos = /* GraphQL */ `
  query ListEntityTemplateBelongsTos(
    $filter: ModelEntityTemplateBelongsToFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityTemplateBelongsTos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        entityTemplates {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEntityTemplateBelongTosByName = /* GraphQL */ `
  query ListEntityTemplateBelongTosByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityTemplateBelongsToFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityTemplateBelongTosByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        entityTemplates {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntityTemplateHasOne = /* GraphQL */ `
  query GetEntityTemplateHasOne($id: ID!) {
    getEntityTemplateHasOne(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listEntityTemplateHasOnes = /* GraphQL */ `
  query ListEntityTemplateHasOnes(
    $filter: ModelEntityTemplateHasOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityTemplateHasOnes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEntityTemplateHasOnesByName = /* GraphQL */ `
  query ListEntityTemplateHasOnesByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityTemplateHasOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityTemplateHasOnesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLabel = /* GraphQL */ `
  query GetLabel($id: ID!) {
    getLabel(id: $id) {
      id
      name
      questionLabels {
        items {
          id
          name
          createdAt
          updatedAt
          labelQuestionLabelsId
          questionQuestionLabelsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLabels = /* GraphQL */ `
  query ListLabels(
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        questionLabels {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLabelsByName = /* GraphQL */ `
  query ListLabelsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabelsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        questionLabels {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMedflowVersion = /* GraphQL */ `
  query GetMedflowVersion($id: ID!) {
    getMedflowVersion(id: $id) {
      id
      name
      number
      createdAt
      updatedAt
    }
  }
`;
export const listMedflowVersions = /* GraphQL */ `
  query ListMedflowVersions(
    $filter: ModelMedflowVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedflowVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMedflowVersionsByName = /* GraphQL */ `
  query ListMedflowVersionsByName(
    $name: String!
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMedflowVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedflowVersionsByName(
      name: $name
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhase = /* GraphQL */ `
  query GetPhase($id: ID!) {
    getPhase(id: $id) {
      id
      name
      order
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      stages {
        items {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        nextToken
      }
      createdAt
      updatedAt
      phaseMedflowVersionId
    }
  }
`;
export const listPhases = /* GraphQL */ `
  query ListPhases(
    $filter: ModelPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        phaseMedflowVersionId
      }
      nextToken
    }
  }
`;
export const listPhasesByName = /* GraphQL */ `
  query ListPhasesByName(
    $name: String!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhasesByName(
      name: $name
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        phaseMedflowVersionId
      }
      nextToken
    }
  }
`;
export const getPreviewAnswer = /* GraphQL */ `
  query GetPreviewAnswer($id: ID!) {
    getPreviewAnswer(id: $id) {
      id
      name
      valueString
      valueInt
      valueFloat
      valueBoolean
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      previewProduct {
        id
        name
        intendedUse
        description
        previewUser {
          id
          name
          email
          phoneNumber
          area
          createdAt
          updatedAt
          previewOrganizationPreviewUsersId
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        previewAnswers {
          nextToken
        }
        createdAt
        updatedAt
        previewOrganizationPreviewProductsId
        previewUserPreviewProductsId
      }
      createdAt
      updatedAt
      previewProductPreviewAnswersId
      previewAnswerQuestionId
    }
  }
`;
export const listPreviewAnswers = /* GraphQL */ `
  query ListPreviewAnswers(
    $filter: ModelPreviewAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreviewAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        valueString
        valueInt
        valueFloat
        valueBoolean
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        previewProduct {
          id
          name
          intendedUse
          description
          createdAt
          updatedAt
          previewOrganizationPreviewProductsId
          previewUserPreviewProductsId
        }
        createdAt
        updatedAt
        previewProductPreviewAnswersId
        previewAnswerQuestionId
      }
      nextToken
    }
  }
`;
export const listPreviewAnswersByName = /* GraphQL */ `
  query ListPreviewAnswersByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPreviewAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreviewAnswersByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        valueString
        valueInt
        valueFloat
        valueBoolean
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        previewProduct {
          id
          name
          intendedUse
          description
          createdAt
          updatedAt
          previewOrganizationPreviewProductsId
          previewUserPreviewProductsId
        }
        createdAt
        updatedAt
        previewProductPreviewAnswersId
        previewAnswerQuestionId
      }
      nextToken
    }
  }
`;
export const getPreviewProduct = /* GraphQL */ `
  query GetPreviewProduct($id: ID!) {
    getPreviewProduct(id: $id) {
      id
      name
      intendedUse
      description
      previewUser {
        id
        name
        email
        phoneNumber
        area
        previewProducts {
          nextToken
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        previewOrganizationPreviewUsersId
      }
      previewOrganization {
        id
        name
        registrationNumber
        previewUsers {
          nextToken
        }
        previewProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      previewAnswers {
        items {
          id
          name
          valueString
          valueInt
          valueFloat
          valueBoolean
          createdAt
          updatedAt
          previewProductPreviewAnswersId
          previewAnswerQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      previewOrganizationPreviewProductsId
      previewUserPreviewProductsId
    }
  }
`;
export const listPreviewProducts = /* GraphQL */ `
  query ListPreviewProducts(
    $filter: ModelPreviewProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreviewProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        intendedUse
        description
        previewUser {
          id
          name
          email
          phoneNumber
          area
          createdAt
          updatedAt
          previewOrganizationPreviewUsersId
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        previewAnswers {
          nextToken
        }
        createdAt
        updatedAt
        previewOrganizationPreviewProductsId
        previewUserPreviewProductsId
      }
      nextToken
    }
  }
`;
export const listPreviewProductsByName = /* GraphQL */ `
  query ListPreviewProductsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPreviewProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreviewProductsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        intendedUse
        description
        previewUser {
          id
          name
          email
          phoneNumber
          area
          createdAt
          updatedAt
          previewOrganizationPreviewUsersId
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        previewAnswers {
          nextToken
        }
        createdAt
        updatedAt
        previewOrganizationPreviewProductsId
        previewUserPreviewProductsId
      }
      nextToken
    }
  }
`;
export const getPreviewOrganization = /* GraphQL */ `
  query GetPreviewOrganization($id: ID!) {
    getPreviewOrganization(id: $id) {
      id
      name
      registrationNumber
      previewUsers {
        items {
          id
          name
          email
          phoneNumber
          area
          createdAt
          updatedAt
          previewOrganizationPreviewUsersId
        }
        nextToken
      }
      previewProducts {
        items {
          id
          name
          intendedUse
          description
          createdAt
          updatedAt
          previewOrganizationPreviewProductsId
          previewUserPreviewProductsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPreviewOrganizations = /* GraphQL */ `
  query ListPreviewOrganizations(
    $filter: ModelPreviewOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreviewOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        registrationNumber
        previewUsers {
          nextToken
        }
        previewProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPreviewOrganizationsByName = /* GraphQL */ `
  query ListPreviewOrganizationsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPreviewOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreviewOrganizationsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        registrationNumber
        previewUsers {
          nextToken
        }
        previewProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPreviewUser = /* GraphQL */ `
  query GetPreviewUser($id: ID!) {
    getPreviewUser(id: $id) {
      id
      name
      email
      phoneNumber
      area
      previewProducts {
        items {
          id
          name
          intendedUse
          description
          createdAt
          updatedAt
          previewOrganizationPreviewProductsId
          previewUserPreviewProductsId
        }
        nextToken
      }
      previewOrganization {
        id
        name
        registrationNumber
        previewUsers {
          nextToken
        }
        previewProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      previewOrganizationPreviewUsersId
    }
  }
`;
export const listPreviewUsers = /* GraphQL */ `
  query ListPreviewUsers(
    $filter: ModelPreviewUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreviewUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phoneNumber
        area
        previewProducts {
          nextToken
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        previewOrganizationPreviewUsersId
      }
      nextToken
    }
  }
`;
export const listPreviewUsersByName = /* GraphQL */ `
  query ListPreviewUsersByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPreviewUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreviewUsersByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        phoneNumber
        area
        previewProducts {
          nextToken
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        previewOrganizationPreviewUsersId
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      description
      productCode
      gmdnDescription
      deviceClassification
      readers
      editors
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      answers {
        items {
          id
          name
          version
          valueString
          valueInt
          valueFloat
          valueBoolean
          readers
          editors
          createdAt
          updatedAt
          productAnswersId
          answerQuestionId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      productMedflowVersionId
      owner
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        productCode
        gmdnDescription
        deviceClassification
        readers
        editors
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        productMedflowVersionId
        owner
      }
      nextToken
    }
  }
`;
export const listProductsByName = /* GraphQL */ `
  query ListProductsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        productCode
        gmdnDescription
        deviceClassification
        readers
        editors
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        productMedflowVersionId
        owner
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      name
      description
      guidingBeforeText
      placeholder
      statement
      tooltip
      answerDataType
      specialQuestionCategory
      examples
      explanations
      notes
      questionLabels {
        items {
          id
          name
          createdAt
          updatedAt
          labelQuestionLabelsId
          questionQuestionLabelsId
        }
        nextToken
      }
      questionRowColumns {
        items {
          id
          name
          order
          answerDataType
          createdAt
          updatedAt
          questionQuestionRowColumnsId
        }
        nextToken
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuestionsByName = /* GraphQL */ `
  query ListQuestionsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionLabel = /* GraphQL */ `
  query GetQuestionLabel($id: ID!) {
    getQuestionLabel(id: $id) {
      id
      name
      label {
        id
        name
        questionLabels {
          nextToken
        }
        createdAt
        updatedAt
      }
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      labelQuestionLabelsId
      questionQuestionLabelsId
    }
  }
`;
export const listQuestionLabels = /* GraphQL */ `
  query ListQuestionLabels(
    $filter: ModelQuestionLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionLabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        label {
          id
          name
          createdAt
          updatedAt
        }
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        labelQuestionLabelsId
        questionQuestionLabelsId
      }
      nextToken
    }
  }
`;
export const listQuestionLabelsByName = /* GraphQL */ `
  query ListQuestionLabelsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionLabelsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        label {
          id
          name
          createdAt
          updatedAt
        }
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        labelQuestionLabelsId
        questionQuestionLabelsId
      }
      nextToken
    }
  }
`;
export const getQuestionRowColumn = /* GraphQL */ `
  query GetQuestionRowColumn($id: ID!) {
    getQuestionRowColumn(id: $id) {
      id
      name
      order
      answerDataType
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      questionQuestionRowColumnsId
    }
  }
`;
export const listQuestionRowColumns = /* GraphQL */ `
  query ListQuestionRowColumns(
    $filter: ModelQuestionRowColumnFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionRowColumns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        answerDataType
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        questionQuestionRowColumnsId
      }
      nextToken
    }
  }
`;
export const listQuestionRowColumnsByName = /* GraphQL */ `
  query ListQuestionRowColumnsByName(
    $name: String!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionRowColumnFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionRowColumnsByName(
      name: $name
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        answerDataType
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        questionQuestionRowColumnsId
      }
      nextToken
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      name
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      reportQuestionnaireSections {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          reportReportQuestionnaireSectionsId
        }
        nextToken
      }
      createdAt
      updatedAt
      stageReportsId
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        reportQuestionnaires {
          nextToken
        }
        reportQuestionnaireSections {
          nextToken
        }
        createdAt
        updatedAt
        stageReportsId
      }
      nextToken
    }
  }
`;
export const listReportsByName = /* GraphQL */ `
  query ListReportsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        reportQuestionnaires {
          nextToken
        }
        reportQuestionnaireSections {
          nextToken
        }
        createdAt
        updatedAt
        stageReportsId
      }
      nextToken
    }
  }
`;
export const getReportQuestionnaire = /* GraphQL */ `
  query GetReportQuestionnaire($id: ID!) {
    getReportQuestionnaire(id: $id) {
      id
      name
      order
      deviceClassification
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      report {
        id
        name
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        reportQuestionnaires {
          nextToken
        }
        reportQuestionnaireSections {
          nextToken
        }
        createdAt
        updatedAt
        stageReportsId
      }
      reportQuestionnaireSection {
        id
        name
        order
        deviceClassification
        report {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        reportQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        reportReportQuestionnaireSectionsId
      }
      createdAt
      updatedAt
      questionReportQuestionnairesId
      reportReportQuestionnairesId
      reportQuestionnaireSectionReportQuestionnairesId
    }
  }
`;
export const listReportQuestionnaires = /* GraphQL */ `
  query ListReportQuestionnaires(
    $filter: ModelReportQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportQuestionnaires(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        deviceClassification
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        report {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        reportQuestionnaireSection {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          reportReportQuestionnaireSectionsId
        }
        createdAt
        updatedAt
        questionReportQuestionnairesId
        reportReportQuestionnairesId
        reportQuestionnaireSectionReportQuestionnairesId
      }
      nextToken
    }
  }
`;
export const listReportQuestionnairesByName = /* GraphQL */ `
  query ListReportQuestionnairesByName(
    $name: String!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportQuestionnairesByName(
      name: $name
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        deviceClassification
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        report {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        reportQuestionnaireSection {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          reportReportQuestionnaireSectionsId
        }
        createdAt
        updatedAt
        questionReportQuestionnairesId
        reportReportQuestionnairesId
        reportQuestionnaireSectionReportQuestionnairesId
      }
      nextToken
    }
  }
`;
export const getReportQuestionnaireSection = /* GraphQL */ `
  query GetReportQuestionnaireSection($id: ID!) {
    getReportQuestionnaireSection(id: $id) {
      id
      name
      order
      deviceClassification
      report {
        id
        name
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        reportQuestionnaires {
          nextToken
        }
        reportQuestionnaireSections {
          nextToken
        }
        createdAt
        updatedAt
        stageReportsId
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
      reportReportQuestionnaireSectionsId
    }
  }
`;
export const listReportQuestionnaireSections = /* GraphQL */ `
  query ListReportQuestionnaireSections(
    $filter: ModelReportQuestionnaireSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportQuestionnaireSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        deviceClassification
        report {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        reportQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        reportReportQuestionnaireSectionsId
      }
      nextToken
    }
  }
`;
export const listReportQuestionnaireSectionsByName = /* GraphQL */ `
  query ListReportQuestionnaireSectionsByName(
    $name: String!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportQuestionnaireSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportQuestionnaireSectionsByName(
      name: $name
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        deviceClassification
        report {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        reportQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        reportReportQuestionnaireSectionsId
      }
      nextToken
    }
  }
`;
export const getRowColumnAnswer = /* GraphQL */ `
  query GetRowColumnAnswer($id: ID!) {
    getRowColumnAnswer(id: $id) {
      id
      name
      version
      readers
      editors
      valueString
      valueInt
      valueFloat
      valueBoolean
      questionRowColumn {
        id
        name
        order
        answerDataType
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        questionQuestionRowColumnsId
      }
      answer {
        id
        name
        version
        valueString
        valueInt
        valueFloat
        valueBoolean
        readers
        editors
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        product {
          id
          name
          description
          productCode
          gmdnDescription
          deviceClassification
          readers
          editors
          createdAt
          updatedAt
          productMedflowVersionId
          owner
        }
        rowColumnAnswers {
          nextToken
        }
        createdAt
        updatedAt
        productAnswersId
        answerQuestionId
        owner
      }
      createdAt
      updatedAt
      answerRowColumnAnswersId
      rowColumnAnswerQuestionRowColumnId
      owner
    }
  }
`;
export const listRowColumnAnswers = /* GraphQL */ `
  query ListRowColumnAnswers(
    $filter: ModelRowColumnAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRowColumnAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        version
        readers
        editors
        valueString
        valueInt
        valueFloat
        valueBoolean
        questionRowColumn {
          id
          name
          order
          answerDataType
          createdAt
          updatedAt
          questionQuestionRowColumnsId
        }
        answer {
          id
          name
          version
          valueString
          valueInt
          valueFloat
          valueBoolean
          readers
          editors
          createdAt
          updatedAt
          productAnswersId
          answerQuestionId
          owner
        }
        createdAt
        updatedAt
        answerRowColumnAnswersId
        rowColumnAnswerQuestionRowColumnId
        owner
      }
      nextToken
    }
  }
`;
export const listRowColumnAnswersByName = /* GraphQL */ `
  query ListRowColumnAnswersByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRowColumnAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRowColumnAnswersByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        version
        readers
        editors
        valueString
        valueInt
        valueFloat
        valueBoolean
        questionRowColumn {
          id
          name
          order
          answerDataType
          createdAt
          updatedAt
          questionQuestionRowColumnsId
        }
        answer {
          id
          name
          version
          valueString
          valueInt
          valueFloat
          valueBoolean
          readers
          editors
          createdAt
          updatedAt
          productAnswersId
          answerQuestionId
          owner
        }
        createdAt
        updatedAt
        answerRowColumnAnswersId
        rowColumnAnswerQuestionRowColumnId
        owner
      }
      nextToken
    }
  }
`;
export const getStage = /* GraphQL */ `
  query GetStage($id: ID!) {
    getStage(id: $id) {
      id
      name
      order
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      phase {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        phaseMedflowVersionId
      }
      track {
        id
        name
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        trackMedflowVersionId
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      stageQuestionnaireSections {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          stageStageQuestionnaireSectionsId
        }
        nextToken
      }
      reports {
        items {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        nextToken
      }
      createdAt
      updatedAt
      phaseStagesId
      trackStagesId
      stageMedflowVersionId
    }
  }
`;
export const listStages = /* GraphQL */ `
  query ListStages(
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      nextToken
    }
  }
`;
export const listStagesByName = /* GraphQL */ `
  query ListStagesByName(
    $name: String!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStagesByName(
      name: $name
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      nextToken
    }
  }
`;
export const getStageQuestionnaire = /* GraphQL */ `
  query GetStageQuestionnaire($id: ID!) {
    getStageQuestionnaire(id: $id) {
      id
      name
      order
      deviceClassification
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      stageQuestionnaireSection {
        id
        name
        order
        deviceClassification
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        stageStageQuestionnaireSectionsId
      }
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      createdAt
      updatedAt
      questionStageQuestionnairesId
      stageStageQuestionnairesId
      stageQuestionnaireSectionStageQuestionnairesId
    }
  }
`;
export const listStageQuestionnaires = /* GraphQL */ `
  query ListStageQuestionnaires(
    $filter: ModelStageQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStageQuestionnaires(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        deviceClassification
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        stageQuestionnaireSection {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          stageStageQuestionnaireSectionsId
        }
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        createdAt
        updatedAt
        questionStageQuestionnairesId
        stageStageQuestionnairesId
        stageQuestionnaireSectionStageQuestionnairesId
      }
      nextToken
    }
  }
`;
export const listStageQuestionnairesByName = /* GraphQL */ `
  query ListStageQuestionnairesByName(
    $name: String!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStageQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStageQuestionnairesByName(
      name: $name
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        deviceClassification
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        stageQuestionnaireSection {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          stageStageQuestionnaireSectionsId
        }
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        createdAt
        updatedAt
        questionStageQuestionnairesId
        stageStageQuestionnairesId
        stageQuestionnaireSectionStageQuestionnairesId
      }
      nextToken
    }
  }
`;
export const getStageQuestionnaireSection = /* GraphQL */ `
  query GetStageQuestionnaireSection($id: ID!) {
    getStageQuestionnaireSection(id: $id) {
      id
      name
      order
      deviceClassification
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
      stageStageQuestionnaireSectionsId
    }
  }
`;
export const listStageQuestionnaireSections = /* GraphQL */ `
  query ListStageQuestionnaireSections(
    $filter: ModelStageQuestionnaireSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStageQuestionnaireSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        deviceClassification
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        stageStageQuestionnaireSectionsId
      }
      nextToken
    }
  }
`;
export const listStageQuestionnaireSectionsByName = /* GraphQL */ `
  query ListStageQuestionnaireSectionsByName(
    $name: String!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStageQuestionnaireSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStageQuestionnaireSectionsByName(
      name: $name
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        deviceClassification
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        stageStageQuestionnaireSectionsId
      }
      nextToken
    }
  }
`;
export const getTrack = /* GraphQL */ `
  query GetTrack($id: ID!) {
    getTrack(id: $id) {
      id
      name
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      stages {
        items {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        nextToken
      }
      createdAt
      updatedAt
      trackMedflowVersionId
    }
  }
`;
export const listTracks = /* GraphQL */ `
  query ListTracks(
    $filter: ModelTrackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTracks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        trackMedflowVersionId
      }
      nextToken
    }
  }
`;
export const listTracksByName = /* GraphQL */ `
  query ListTracksByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTrackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTracksByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        trackMedflowVersionId
      }
      nextToken
    }
  }
`;
