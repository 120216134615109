import { API } from "aws-amplify";
import { CreateStageQuestionnaireSectionInput, DeleteStageQuestionnaireSectionInput, StageQuestionnaireSection, GetStageQuestionnaireSectionQuery, ListStageQuestionnaireSectionsQuery, UpdateStageQuestionnaireSectionInput } from "../API";
import { createStageQuestionnaireSection, deleteStageQuestionnaireSection, updateStageQuestionnaireSection } from "../graphql/mutations";
import { getStageQuestionnaireSection, listStageQuestionnaireSections, listStageQuestionnaireSectionsByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createStageQuestionnaireSectionApi = async (input: CreateStageQuestionnaireSectionInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaireSection> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createStageQuestionnaireSection,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createStageQuestionnaireSection: StageQuestionnaireSection } };
    return response.data.createStageQuestionnaireSection;
}

export const updateStageQuestionnaireSectionApi = async (input: UpdateStageQuestionnaireSectionInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaireSection> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updateStageQuestionnaireSection,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updateStageQuestionnaireSection: StageQuestionnaireSection } };
    return response.data.updateStageQuestionnaireSection;
}

export const deleteStageQuestionnaireSectionApi = async (input: DeleteStageQuestionnaireSectionInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaireSection> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deleteStageQuestionnaireSection,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deleteStageQuestionnaireSection: StageQuestionnaireSection } };
    return response.data.deleteStageQuestionnaireSection;
}

export const listStageQuestionnaireSectionsApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaireSection[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listStageQuestionnaireSections,
    });

    const response = responseRaw as { data: ListStageQuestionnaireSectionsQuery };
    const items = response.data.listStageQuestionnaireSections?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as StageQuestionnaireSection[];
    return itemsExisting
}

export const getStageQuestionnaireSectionApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaireSection|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getStageQuestionnaireSection,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetStageQuestionnaireSectionQuery };
    const instance = response.data.getStageQuestionnaireSection;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as StageQuestionnaireSection
    return instanceNotUndefined
}

export const listStageQuestionnaireSectionsByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<StageQuestionnaireSection[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listStageQuestionnaireSectionsByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListStageQuestionnaireSectionsQuery };
    const items = response.data.listStageQuestionnaireSections?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as StageQuestionnaireSection[];
    return itemsExisting
}
