import { AnswerDataType, DeviceClassification, DeviceExpressionOperator, DeviceQualification, EntityEnum, SpecialQuestionCategory } from '../API';
import { EntityName } from './EntityName';

export interface FilledEntityRelation {
    entityName: EntityName;
    id: string|undefined;
}

export interface FilledEntityRelations {
    entityName: EntityName;
    ids: string[];
}

export interface FilledEntityNamedParameters {
    idKeys?: string[];
    stringKeys?: string[];
    stringArrayKeys?: string[];
    intKeys?: string[];
    floatKeys?: string[];
    booleanKeys?: string[];
    deviceClassificationKeys?: string[];
    deviceQualificationKeys?: string[];
    answerDataTypeKeys?: string[];
    specialQuestionCategoryKeys?: string[];
    deviceExpressionOperatorKeys?: string[];
    entityEnumKeys?: string[];
    entityRelationKeysEntities?: Map<string, EntityName>;
    entityRelationsKeysEntities?: Map<string, EntityName>;
}

export class FilledEntity {
    private idMap: Map<string, string|undefined>;
    private stringMap: Map<string, string|undefined>;
    private stringArrayMap: Map<string, (string)[]|undefined>|undefined;
    private intMap: Map<string, number|undefined>;
    private floatMap: Map<string, number|undefined>;
    private booleanMap: Map<string, boolean|undefined>;
    private deviceClassificationMap: Map<string, DeviceClassification|undefined>;
    private deviceQualificationMap: Map<string, DeviceQualification|undefined>;
    private answerDataTypeMap: Map<string, AnswerDataType|undefined>;
    private specialQuestionCategoryMap: Map<string, SpecialQuestionCategory|undefined>;
    private deviceExpressionOperatorMap: Map<string, DeviceExpressionOperator|undefined>;
    private entityEnumMap: Map<string, EntityEnum|undefined>;
    private entityRelationMap: Map<string, FilledEntityRelation>;
    private entityRelationsMap: Map<string, FilledEntityRelations>;
  
    constructor(params: FilledEntityNamedParameters = {}) {
        this.idMap = new Map<string, string|undefined>();
        this.stringMap = new Map<string, string|undefined>();
        this.stringArrayMap = new Map<string, (string)[]|undefined>();
        this.intMap = new Map<string, number|undefined>();
        this.floatMap = new Map<string, number|undefined>();
        this.booleanMap = new Map<string, boolean|undefined>();
        this.deviceClassificationMap = new Map<string, DeviceClassification|undefined>();
        this.deviceQualificationMap = new Map<string, DeviceQualification|undefined>();
        this.answerDataTypeMap = new Map<string, AnswerDataType|undefined>();
        this.specialQuestionCategoryMap = new Map<string, SpecialQuestionCategory|undefined>();
        this.deviceExpressionOperatorMap = new Map<string, DeviceExpressionOperator|undefined>();
        this.entityEnumMap = new Map<string, EntityEnum|undefined>();
        this.entityRelationMap = new Map<string, FilledEntityRelation>();
        this.entityRelationsMap = new Map<string, FilledEntityRelations>();

        if (params.idKeys) {
            for (const key of params.idKeys) {
                this.idMap.set(key, undefined);
            }
        }

        if (params.stringKeys) {
            for (const key of params.stringKeys) {
                this.stringMap.set(key, undefined);
            }
        }

        if (params.stringArrayKeys) {
            for (const key of params.stringArrayKeys) {
                this.stringArrayMap.set(key, []);
            }
        }

        if (params.intKeys) {
            for (const key of params.intKeys) {
                this.intMap.set(key, undefined);
            }
        }

        if (params.floatKeys) {
            for (const key of params.floatKeys) {
                this.floatMap.set(key, undefined);
            }
        }

        if (params.booleanKeys) {
            for (const key of params.booleanKeys) {
                this.booleanMap.set(key, undefined);
            }
        }

        if (params.deviceClassificationKeys) {
            for (const key of params.deviceClassificationKeys) {
                this.deviceClassificationMap.set(key, undefined);
            }
        }

        if (params.deviceQualificationKeys) {
            for (const key of params.deviceQualificationKeys) {
                this.deviceQualificationMap.set(key, undefined);
            }
        }

        if (params.answerDataTypeKeys) {
            for (const key of params.answerDataTypeKeys) {
                this.answerDataTypeMap.set(key, undefined);
            }
        }

        if (params.specialQuestionCategoryKeys) {
            for (const key of params.specialQuestionCategoryKeys) {
                this.specialQuestionCategoryMap.set(key, undefined);
            }
        }

        if (params.deviceExpressionOperatorKeys) {
            for (const key of params.deviceExpressionOperatorKeys) {
                this.deviceExpressionOperatorMap.set(key, undefined);
            }
        }

        if (params.entityEnumKeys) {
            for (const key of params.entityEnumKeys) {
                this.entityEnumMap.set(key, undefined);
            }
        }

        if (params.entityRelationKeysEntities) {
            for (const [key, value] of params.entityRelationKeysEntities.entries()) {
                this.entityRelationMap.set(key, {entityName: value, id: undefined});
            }
        }

        if (params.entityRelationsKeysEntities) {
            for (const [key, value] of params.entityRelationsKeysEntities.entries()) {
                this.entityRelationsMap.set(key, {entityName: value, ids: []});
            }
        }
    }

    getIdMap(): Map<string, string|undefined> {
        return this.idMap;
    }

    getStringMap(): Map<string, string|undefined> {
        return this.stringMap;
    }

    getStringArrayMap(): Map<string, (string)[]|undefined>|undefined {
        return this.stringArrayMap;
    }

    getIntMap(): Map<string, number|undefined> {
        return this.intMap;
    }

    getFloatMap(): Map<string, number|undefined> {
        return this.floatMap;
    }

    getBooleanMap(): Map<string, boolean|undefined> {
        return this.booleanMap;
    }

    getDeviceClassificationMap(): Map<string, DeviceClassification|undefined> {
        return this.deviceClassificationMap;
    }

    getDeviceQualificationMap(): Map<string, DeviceQualification|undefined> {
        return this.deviceQualificationMap;
    }

    getAnswerDataTypeMap(): Map<string, AnswerDataType|undefined> {
        return this.answerDataTypeMap;
    }

    getSpecialQuestionCategoryMap(): Map<string, SpecialQuestionCategory|undefined> {
        return this.specialQuestionCategoryMap;
    }

    getDeviceExpressionOperatorMap(): Map<string, DeviceExpressionOperator|undefined> {
        return this.deviceExpressionOperatorMap;
    }

    getEntityEnumMap(): Map<string, EntityEnum|undefined> {
        return this.entityEnumMap;
    }

    getEntityRelationMap(): Map<string, FilledEntityRelation> {
        return this.entityRelationMap;
    }

    getEntityRelationsMap(): Map<string, FilledEntityRelations> {
        return this.entityRelationsMap;
    }

    setId(key: string, value: string|undefined): void {
        this.idMap.set(key, value);
    }

    addString(key: string, value: string|undefined): void {
        this.stringMap.set(key, value);
    }

    addStringArray(key: string, value: (string)[]|undefined): void {
        this.stringArrayMap?.set(key, value);
    }

    addStringInstringArray(key: string, value: string): void {
        const stringArray = this.stringArrayMap?.get(key);
        
        if (stringArray) {
            stringArray.push(value);
        }
    }

    addInt(key: string, value: number|undefined): void {
        this.intMap.set(key, value);
    }

    addFloat(key: string, value: number|undefined): void {
        this.floatMap.set(key, value);
    }

    addBoolean(key: string, value: boolean|undefined): void {
        this.booleanMap.set(key, value);
    }

    addDeviceClassification(key: string, value: DeviceClassification|undefined): void {
        this.deviceClassificationMap.set(key, value);
    }

    addDeviceQualification(key: string, value: DeviceQualification|undefined): void {
        this.deviceQualificationMap.set(key, value);
    }

    addAnswerDataType(key: string, value: AnswerDataType|undefined): void {
        this.answerDataTypeMap.set(key, value);
    }

    addSpecialQuestionCategory(key: string, value: SpecialQuestionCategory|undefined): void {
        this.specialQuestionCategoryMap.set(key, value);
    }

    addDeviceExpressionOperator(key: string, value: DeviceExpressionOperator|undefined): void {
        this.deviceExpressionOperatorMap.set(key, value);
    }

    addEntityEnum(key: string, value: EntityEnum|undefined): void {
        this.entityEnumMap.set(key, value);
    }

    addEntityRelation(key: string, value: FilledEntityRelation): void {
        this.entityRelationMap.set(key, value);
    }

    addEntityRelations(key: string, value: FilledEntityRelations): void {
        this.entityRelationsMap.set(key, value);
    }

    addEntityRelationInRelations(key: string, value: FilledEntityRelation): void {
        const relations = this.entityRelationsMap.get(key);

        if (relations && value.id) {
            relations.ids.push(value.id);
        }
    }

    removeString(key: string): void {
        this.stringMap.set(key, undefined);
    }

    removestringArray(key: string): void {
        this.stringArrayMap?.set(key, []);
    }

    removeStringInstringArray(key: string, value: string): void {
        const stringArray = this.stringArrayMap?.get(key);

        if (stringArray) {
            const index = stringArray.indexOf(value);
            
            if (index > -1) {
                stringArray.splice(index, 1);
            }
        }
    }

    removeInt(key: string): void {
        this.intMap.set(key, undefined);
    }

    removeFloat(key: string): void {
        this.floatMap.set(key, undefined);
    }

    removeBoolean(key: string): void {
        this.booleanMap.set(key, undefined);
    }

    removeDeviceClassification(key: string): void {
        this.deviceClassificationMap.set(key, undefined);
    }

    removeDeviceQualification(key: string): void {
        this.deviceQualificationMap.set(key, undefined);
    }

    removeAnswerDataType(key: string): void {
        this.answerDataTypeMap.set(key, undefined);
    }

    removeSpecialQuestionCategory(key: string): void {
        this.specialQuestionCategoryMap.set(key, undefined);
    }

    removeDeviceExpressionOperator(key: string): void {
        this.deviceExpressionOperatorMap.set(key, undefined);
    }

    removeEntityEnum(key: string): void {
        this.entityEnumMap.set(key, undefined);
    }

    removeEntityRelation(key: string): void {
        const entityRelation = this.entityRelationMap.get(key);

        if (entityRelation) {
            entityRelation.id = undefined;
        }
    }

    removeEntityRelations(key: string): void {
        const entityRelations = this.entityRelationsMap.get(key);

        if (entityRelations) {
            entityRelations.ids = [];
        }
    }

    removeEntityRelationInRelations(key: string, value: FilledEntityRelation): void {
        const relations = this.entityRelationsMap.get(key);

        if (relations && value.id) {
            const index = relations.ids.indexOf(value.id);

            if (index > -1) {
                relations.ids.splice(index, 1);
            }
        }
    }

    getIdOptional(key: string): string|undefined {
        const value = this.idMap.get(key)

        if (value === null || value === undefined || value === '') {
            return undefined;
        }

        return value;
    }

    getIdMandatory(key: string): string {
        const value = this.idMap.get(key)

        if (value === null || value === undefined || value === '') {
            alert(`ID for key ${key} is undefined`);
            throw new Error(`ID for key ${key} is undefined`);
        }

        return value;
    }

    getStringOptional(key: string): string|undefined {
        const value = this.stringMap.get(key)

        if (value === null || value === undefined || value === '') {
            return undefined;
        }

        return value;
    }

    getStringMandatory(key: string): string {
        const value = this.stringMap.get(key)

        if (value === null || value === undefined || value === '') {
            alert(`String for key ${key} is undefined`);
            throw new Error(`String for key ${key} is undefined`);
        }

        return value;
    }

    getStringArrayOptional(key: string): string[] {
        const value = this.stringArrayMap?.get(key)

        if (value === null || value === undefined) {
            return [];
        }

        return value.filter((v) => v !== undefined && v !== null && v !== '');
    }

    getStringArrayMandatory(key: string): string[] {
        const value = this.stringArrayMap?.get(key)

        if (value === null || value === undefined) {
            alert(`String array for key ${key} is undefined`);
            throw new Error(`String array for key ${key} is undefined`);
        }

        for (const v of value) {
            if (v === undefined || v === null || v === '') {
                alert(`String array for key ${key} is undefined`);
                throw new Error(`String array for key ${key} is undefined`);
            }
        }

        return value;
    }

    getIntOptional(key: string): number|undefined {
        const value = this.intMap.get(key)

        if (value === null || value === undefined) {
            return undefined;
        }

        return value;
    }

    getIntMandatory(key: string): number {
        const value = this.intMap.get(key)

        if (value === null || value === undefined) {
            alert(`Int for key ${key} is undefined`);
            throw new Error(`Int for key ${key} is undefined`);
        }

        return value;
    }

    getFloatOptional(key: string): number|undefined {
        const value = this.floatMap.get(key)

        if (value === null || value === undefined) {
            return undefined;
        }

        return value;
    }

    getFloatMandatory(key: string): number {
        const value = this.floatMap.get(key)

        if (value === null || value === undefined) {
            alert(`Float for key ${key} is undefined`);
            throw new Error(`Float for key ${key} is undefined`);
        }

        return value;
    }

    getBooleanOptional(key: string): boolean|undefined {
        const value = this.booleanMap.get(key)

        if (value === null || value === undefined) {
            return undefined;
        }

        return value;
    }

    getBooleanMandatory(key: string): boolean {
        const value = this.booleanMap.get(key)

        if (value === null || value === undefined) {
            alert(`Boolean for key ${key} is undefined`);
            throw new Error(`Boolean for key ${key} is undefined`);
        }

        return value;
    }

    getDeviceClassificationOptional(key: string): DeviceClassification|undefined {
        const value = this.deviceClassificationMap.get(key)

        if (value === null || value === undefined) {
            return undefined;
        }

        return value;
    }

    getDeviceClassificationMandatory(key: string): DeviceClassification {
        const value = this.deviceClassificationMap.get(key)

        if (value === null || value === undefined) {
            alert(`DeviceClassification for key ${key} is undefined`);
            throw new Error(`DeviceClassification for key ${key} is undefined`);
        }

        return value;
    }

    getDeviceQualificationOptional(key: string): DeviceQualification|undefined {
        const value = this.deviceQualificationMap.get(key)

        if (value === null || value === undefined) {
            return undefined;
        }

        return value;
    }

    getDeviceQualificationMandatory(key: string): DeviceQualification {
        const value = this.deviceQualificationMap.get(key)

        if (value === null || value === undefined) {
            alert(`DeviceQualification for key ${key} is undefined`);
            throw new Error(`DeviceQualification for key ${key} is undefined`);
        }

        return value;
    }

    getAnswerDataTypeOptional(key: string): AnswerDataType|undefined {
        const value = this.answerDataTypeMap.get(key)

        if (value === null || value === undefined) {
            return undefined;
        }

        return value;
    }

    getAnswerDataTypeMandatory(key: string): AnswerDataType {
        const value = this.answerDataTypeMap.get(key)

        if (value === null || value === undefined) {
            alert(`AnswerDataType for key ${key} is undefined`);
            throw new Error(`AnswerDataType for key ${key} is undefined`);
        }

        return value;
    }

    getSpecialQuestionCategoryOptional(key: string): SpecialQuestionCategory|undefined {
        const value = this.specialQuestionCategoryMap.get(key)

        if (value === null || value === undefined) {
            return undefined;
        }

        return value;
    }

    getSpecialQuestionCategoryMandatory(key: string): SpecialQuestionCategory {
        const value = this.specialQuestionCategoryMap.get(key)

        if (value === null || value === undefined) {
            alert(`SpecialQuestionCategory for key ${key} is undefined`);
            throw new Error(`SpecialQuestionCategory for key ${key} is undefined`);
        }

        return value;
    }

    getDeviceExpressionOperatorOptional(key: string): DeviceExpressionOperator|undefined {
        const value = this.deviceExpressionOperatorMap.get(key)

        if (value === null || value === undefined) {
            return undefined;
        }

        return value;
    }

    getDeviceExpressionOperatorMandatory(key: string): DeviceExpressionOperator {
        const value = this.deviceExpressionOperatorMap.get(key)

        if (value === null || value === undefined) {
            alert(`DeviceExpressionOperator for key ${key} is undefined`);
            throw new Error(`DeviceExpressionOperator for key ${key} is undefined`);
        }

        return value;
    }

    getEntityEnumOptional(key: string): EntityEnum|undefined {
        const value = this.entityEnumMap.get(key)

        if (value === null || value === undefined) {
            return undefined;
        }

        return value;
    }

    getEntityEnumMandatory(key: string): EntityEnum {
        const value = this.entityEnumMap.get(key)

        if (value === null || value === undefined) {
            alert(`EntityEnum for key ${key} is undefined`);
            throw new Error(`EntityEnum for key ${key} is undefined`);
        }

        return value;
    }

    getEntityRelationOptional(key: string): FilledEntityRelation|undefined {
        const value = this.entityRelationMap.get(key)

        if (value === null || value === undefined) {
            return undefined;
        }

        if (value.id === null || value.id === undefined || value.id === '') {
            return undefined;
        }

        return value;
    }

    getEntityRelationMandatory(key: string): FilledEntityRelation {
        const value = this.entityRelationMap.get(key)

        if (value === null || value === undefined) {
            alert(`EntityRelation for key ${key} is undefined`);
            throw new Error(`EntityRelation for key ${key} is undefined`);
        }

        if (value.id === null || value.id === undefined || value.id === '') {
            alert(`EntityRelation for key ${key} is undefined`);
            throw new Error(`EntityRelation for key ${key} is undefined`);
        }

        return value;
    }

    getEntityRelationsOptional(key: string): FilledEntityRelations|undefined {
        const value = this.entityRelationsMap.get(key)

        if (value === null || value === undefined) {
            return undefined;
        }

        return {
            entityName: value.entityName,
            ids: value.ids.filter(id => id !== null && id !== undefined && id !== '')
        }
    }

    getEntityRelationsMandatory(key: string): FilledEntityRelations {
        const value = this.entityRelationsMap.get(key)

        if (value === null || value === undefined) {
            alert(`EntityRelations for key ${key} is undefined`);
            throw new Error(`EntityRelations for key ${key} is undefined`);
        }

        for (const id of value.ids) {
            if (id === null || id === undefined || id === '') {
                alert(`EntityRelations for key ${key} is undefined`);
                throw new Error(`EntityRelations for key ${key} is undefined`);
            }
        }

        return value;
    }
}