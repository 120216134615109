import React, { useState } from 'react'
import { DeviceClassification } from '../../../models';

interface DeviceClassificationRelatedChooserProps {
    relationName: string
    originalEnumValue: DeviceClassification|undefined
    setEnumValue: (enumValue: DeviceClassification|undefined) => void
}

export const DeviceClassificationRelatedChooser: React.FC<DeviceClassificationRelatedChooserProps> = ({ relationName, originalEnumValue, setEnumValue }) => {
    const map = new Map(Object.entries(DeviceClassification) as [string, DeviceClassification][]);

    const [selectedEnumValue, setSelectedEnumValue] = useState<DeviceClassification|undefined>(undefined)

    function setEnumValueString(event: React.ChangeEvent<HTMLSelectElement>): void {
        const enumValueString: string = event.target.value
        const enumValue = map.get(enumValueString)
        setSelectedEnumValue(enumValue)
        setEnumValue(enumValue)
    }

    const entityNameKeys = Object.keys(DeviceClassification).filter((key) => isNaN(Number(key)))
    const entityNameVals = Object.values(DeviceClassification)
    
    return (
        <div className="mt-4">
            <label className="form-label" htmlFor={'select-' + relationName}>{relationName}</label>
            <select name="entityName" className="form-select" id={'select-' + relationName} onChange={setEnumValueString} value={selectedEnumValue || originalEnumValue || undefined}>
                <option key="">Select an option</option>
                {
                    entityNameKeys.map(function(key, i) {
                        const value = entityNameVals[i]
                        return <option key={relationName + key} value={value}>{value}</option>
                    })
                }
            </select>
        </div>
    )
}