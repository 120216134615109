interface LandingPanelProps {
    startQualification: () => void
}

export const LandingPanel: React.FC<LandingPanelProps> = (props) => {
    // Do nothing.
    return (
        <div>
            <div className="py-4 py-xs-8 py-sm-12 py-md-16 py-lg-24 py-xl-32 py-xxl-32 overflow-hidden">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <h1 className="display-1 text-center">
                                <span className="text-primary">assess</span>
                            </h1>
                            <h2 className="mb-10 text-center">
                                Qualify and then classify your medical device
                            </h2>
                            <p className="mb-10">
                                Medical devices are products used to diagnose, prevent, alleviate, or treat diseases, disabilities, or injuries. Medical devices range from wheelchairs to diagnostic tests, pacemakers, mobile phone apps, and advanced surgical equipments.
                            </p>
                            <p className="mb-10">
                                Medical devices are regulated by EU regulations with the purpose of ensuring patient safety. The EU requires manufacturers to provide documentation proving the device works as intended and that the benefits of use outweigh any potential risks. Additionally, manufacturers must monitor the safety and performance of the devices once on the market.
                            </p>
                            <p className="mb-10">
                                The EU regulation we use is the <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32017R0745" target="_blank" className="font-bold">EU Medical Device Regulation (MDR)</a> (no. 217/745). This means we do not cover devices intended for examining samples from the human body, e.g., COVID self-tests, pregnancy tests, and advanced laboratory blood tests.
                            </p>
                            <p className="mb-10">
                                Medical devices are categorized into risk classes, which are used to determine the safety and performance requirements that must be met before the manufacturer can market the device in the EU. Class I is associated with the lowest risk, while Class III is associated with the highest risk.
                            </p>
                            <table className="table table-nowrap table-spaced">
                                <thead className="">
                                    <tr className="bg-white">
                                        <th scope="col">Class</th>
                                        <th scope="col">Examples</th>
                                        <th scope="col">Risk</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white">
                                        <td>
                                            <div className="align-items-center d-flex">
                                                <div className="icon icon-shape rounded-3 text-lg bg-primary text-white d-none d-sm-flex">
                                                    <i className="bi bi-1-circle"></i>
                                                </div>
                                                <div className="ms-3">
                                                    <a className="text-heading font-semibold" href="#">
                                                        <span className="d-none d-sm-flex">Class</span> I
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="overflow-hidden">
                                            <img src="/images/class-examples/1_bandage.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Bandage" />
                                            <img src="/images/class-examples/1_bed.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Bed" />
                                            <img src="/images/class-examples/1_crutch.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Crutch" />
                                            <img src="/images/class-examples/1_protection_mask.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Protection mask" />
                                            <img src="/images/class-examples/1_stethoscope.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Stethoscope" />
                                            <img src="/images/class-examples/1_walker.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Walker" />
                                            <img src="/images/class-examples/1_wheel_bed.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Wheel bed" />
                                            <img src="/images/class-examples/1_wheelchair.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Wheelchair" />
                                        </td>
                                        <td>
                                            <span className="badge rounded-pill bg-opacity-30 bg-primary text-primary">Lowest</span>
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-shape rounded-3 text-lg bg-primary text-white d-none d-sm-flex">
                                                    <i className="bi bi-2-circle"></i>
                                                    <i className="bi bi-arrow-down"></i>
                                                </div>
                                                <div className="ms-3">
                                                    <a className="text-heading font-semibold" href="#">
                                                    <span className="d-none d-sm-flex">Class</span> IIa
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="overflow-hidden">
                                            <img src="/images/class-examples/2a_dental_filling.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Dental filling" />
                                            <img src="/images/class-examples/2a_hearing_aid.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Hearing aid" />
                                            <img src="/images/class-examples/2a_inhaler.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Inhaler" />
                                            <img src="/images/class-examples/2a_saliva.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Saliva" />
                                            <img src="/images/class-examples/2a_scalpel.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Scalpel" />
                                            <img src="/images/class-examples/2a_scissors.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Scissors" />
                                            <img src="/images/class-examples/2a_syringe.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Syringe" />
                                            <img src="/images/class-examples/2a_thermometer.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Thermometer" />
                                            <img src="/images/class-examples/2a_tonometer.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Tonometer" />
                                            <img src="/images/class-examples/2a_tweezers.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Tweezers" />
                                            <img src="/images/class-examples/2a_ultrasound.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Ultrasound" />
                                        </td>
                                        <td>
                                            <span className="badge rounded-pill bg-opacity-30 bg-primary text-primary">Lower</span>
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-shape rounded-3 text-lg bg-primary text-white d-none d-sm-flex">
                                                    <i className="bi bi-2-circle"></i>
                                                    <i className="bi bi-arrow-up"></i>
                                                </div>
                                                <div className="ms-3">
                                                    <a className="text-heading font-semibold" href="#">
                                                    <span className="d-none d-sm-flex">Class</span> IIb
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="overflow-hidden">
                                            <img src="/images/class-examples/2b_ct_scanner.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="CT scanner" />
                                            <img src="/images/class-examples/2b_dental_implant.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Dental implant" />
                                            <img src="/images/class-examples/2b_heart_monitor.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Heart monitor" />
                                            <img src="/images/class-examples/2b_infusion_pump.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Infusion pump" />
                                            <img src="/images/class-examples/2b_insulin_pen.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Insulin pen" />
                                            <img src="/images/class-examples/2b_lens.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Lens" />
                                            <img src="/images/class-examples/2b_oxygen_mask.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Oxygen mask" />
                                            <img src="/images/class-examples/2b_xray.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="X-ray" />
                                        </td>
                                        <td>
                                            <span className="badge rounded-pill bg-opacity-30 bg-primary text-primary">Higher</span>
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-shape rounded-3 text-lg bg-primary text-white d-none d-sm-flex">
                                                    <i className="bi bi-3-circle"></i>
                                                </div>
                                                <div className="ms-3">
                                                    <a className="text-heading font-semibold" href="#">
                                                    <span className="d-none d-sm-flex">Class</span> III
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="overflow-hidden">
                                            <img src="/images/class-examples/3_aed.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="AED" />
                                            <img src="/images/class-examples/3_dialysis.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Dialysis" />
                                            <img src="/images/class-examples/3_heart.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Heart" />
                                            <img src="/images/class-examples/3_implant.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Implant" />
                                            <img src="/images/class-examples/3_pacemaker.png" className="w-4 w-sm-6 w-md-8 mx-1" alt="Pacemaker" />
                                        </td>
                                        <td>
                                            <span className="badge rounded-pill bg-opacity-30 bg-primary text-primary">Highest</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p className="text-center text-muted mb-10 text-xs">Icons from <a href="https://icons8.com/" target="_blank">Icons8</a></p>
                            <p className="mb-10">
                                This assessment will first qualify your device as medical or not medical. If it is a medical device, it will classify based on the answers to the questions. We advise you to answer all questions. The assessment will give you a good indication of the classification of your device.
                            </p>

                            <div className="mx-n2 text-center">
                                <a href="#" onClick={() => props.startQualification()}  className="btn btn-lg btn-primary shadow-sm mx-2 px-lg-8">
                                    Start Qualification
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-n1 transform translate-y-1/4">
                    <div className="col-lg-11 col-xl-9 mx-auto">
                        <div className="row g-5">
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md6.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md4.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md3.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md5.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md2.jpg" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}