import { useEffect, useState } from 'react';
import { AnswerNotFoundException, CountryNotFoundException, OperatorNotImplementedException } from '../../../algorithms/CustomExceptions';
import { DeviceClassificationNode, DeviceClassifier } from '../../../algorithms/DeviceClassifier';
import { DeviceQualificationNode, DeviceQualifier } from '../../../algorithms/DeviceQualifier';
import { getExportedPDFNew } from '../../../algorithms/QCPDFExporter';
import * as graphQlApi from '../../../API';
import { getQualificationQuestionIds, renderQualificationQuestion } from './QualificationHelper';
import { getClassificationAbbreviatedText, renderClassificationExpression } from './ClassificationHelper';

interface ReportPanelProps {
    previewUser: graphQlApi.PreviewUser|undefined
    previewProduct: graphQlApi.PreviewProduct|undefined
    previewOrganization: graphQlApi.PreviewOrganization|undefined
    qualificationExpressions: {[key: string]: graphQlApi.DeviceQualificationExpression} 
    qualificationQuestions: {[key: string]: graphQlApi.Question}
    qualificationAnswers: {[key: string]: boolean}
    classificationExpressions: {[key: string]: graphQlApi.DeviceClassificationExpression} 
    classificationQuestions: {[key: string]: graphQlApi.Question}
    classificationAnswers: {[key: string]: boolean}
    showQualificationResult: boolean
    showClassificationResult: boolean
    seeQualificationResult: (value: boolean) => void
    seeClassificationResult: (value: boolean) => void
}

export const ReportPanel: React.FC<ReportPanelProps> = ({ previewUser, previewProduct, previewOrganization, qualificationExpressions, qualificationQuestions, qualificationAnswers, classificationExpressions, classificationQuestions, classificationAnswers, showQualificationResult, showClassificationResult, seeQualificationResult, seeClassificationResult }) => {
    const [showUnansweredQuestionsQualification, setShowUnansweredQuestionsQualification] = useState<boolean>(false)
    const [showUnansweredQuestionsClassification, setShowUnansweredQuestionsClassification] = useState<boolean>(false)
    const [exportingPdf, setExportingPdf] = useState<boolean>(false)

    useEffect(() => {
        if (exportingPdf) {
            exportPDF()
        }
    }, [exportingPdf])

    function qualify(): DeviceQualificationNode | undefined {
        const deviceQualifier = new DeviceQualifier(qualificationExpressions, qualificationAnswers)
        
        try {
            const deviceQualifications = deviceQualifier.qualify()
            
            if (deviceQualifications.length <= 0) {
                return undefined
            }

            return deviceQualifications[0]
        } catch (e) {
            if (e instanceof AnswerNotFoundException) {
                console.log(e.name, e.message)
                return undefined
            }
            
            if (e instanceof CountryNotFoundException) {
                console.log(e.name, e.message)
                return undefined
            }
            
            if (e instanceof OperatorNotImplementedException) {
                console.log(e.name, e.message)
                return undefined
            }
        }
    }

    function classify(): DeviceClassificationNode | undefined {
        const deviceClassifier = new DeviceClassifier(classificationExpressions, classificationAnswers)        

        try {
            const deviceClassifications = deviceClassifier.classify()

            if (deviceClassifications.length <= 0) {
                return undefined
            }

            return deviceClassifications[0]
        } catch (e) {
            if (e instanceof AnswerNotFoundException) {
                console.log(e.name, e.message)
                return undefined
            }
            
            if (e instanceof CountryNotFoundException) {
                console.log(e.name, e.message)
                return undefined
            }
            
            if (e instanceof OperatorNotImplementedException) {
                console.log(e.name, e.message)
                return undefined
            }
        }
    }

    async function exportPDF() {
        if (qualification === undefined || classification === undefined) {
            return
        }

        const exportedPDF = await getExportedPDFNew(qualification, classification, previewUser, previewOrganization, previewProduct,
            qualificationExpressions, qualificationQuestions, qualificationAnswers,
            classificationExpressions, classificationQuestions, classificationAnswers)

        const blob = new Blob([exportedPDF], {type: 'application/pdf'})
        window.open(URL.createObjectURL(blob))

        setExportingPdf(false)
    }

    async function showExportedPDF() {
        setExportingPdf(true)
    }

    const qualification = qualify()
    const qualificationQuestionIds = getQualificationQuestionIds(qualificationExpressions, true)
    const isMedicalDevice = qualification !== undefined && qualification.qualification === graphQlApi.DeviceQualification.MEDICAL_DEVICE_MDR_ARTICLE_2_1

    const classification = classify()

    const isEuClassI = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.EU_CLASS_1
    const isEuClassIIa = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.EU_CLASS_2_A
    const isEuClassIIb = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.EU_CLASS_2_B
    const isEuClassIII = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.EU_CLASS_3
    const isEuClassAny = isEuClassI || isEuClassIIa || isEuClassIIb || isEuClassIII

    const isUsClassI = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.US_CLASS_1
    const isUsClassII = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.US_CLASS_2
    const isUsClassIII = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.US_CLASS_3
    const isUsClassAny = isUsClassI || isUsClassII || isUsClassIII

    const isCanadaClassI = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_1
    const isCanadaClassII = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_2
    const isCanadaClassIII = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_3
    const isCanadaClassIV = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_4
    const isCanadaClassAny = isCanadaClassI || isCanadaClassII || isCanadaClassIII || isCanadaClassIV

    const isClassAny = isEuClassAny || isUsClassAny || isCanadaClassAny

    return (
        <div>
            <h1 className="mb-8">Report</h1>
            <div>
                <h2 className="mb-4">Qualification</h2>
                {
                    qualification !== undefined &&
                    <div className="card mb-4">
                        <div className="card-body">
                            <h3 className="mb-2">Result:&nbsp;{isMedicalDevice ? <span className="text-success">medical device</span>: <span className="text-danger">not a medical device</span>}</h3>
                            {isMedicalDevice &&
                                <div>
                                    <p>This device meets the criteria for being a medical device.</p>
                                </div>
                            }
                            {!isMedicalDevice &&
                                <div>
                                    <p>This device does not meet any of the criteria for being a medical device.</p>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    qualification !== undefined && !showQualificationResult &&
                    <div className="my-6">
                        <a role="button" className="btn btn-success me-2" onClick={() => seeQualificationResult(true)}>Show Detailed Report</a>
                    </div>
                }
                {
                    qualification !== undefined && showQualificationResult &&
                    <div className="my-6">
                        <a role="button" className="btn btn-secondary me-2" onClick={() => seeQualificationResult(false)}>Hide Detailed Report</a>
                    </div>
                }
            </div>
            {showQualificationResult &&
                <div className="card mb-4">
                    <div className="card-body">
                        <h2 className="mb-4">All Rules</h2>
                        <div className="form-check form-switch mb-4">
                            <input className="form-check-input" type="checkbox" name="switch_show_unanswered_questions_qualification" id="switch_show_unanswered_questions_qualification" checked={showUnansweredQuestionsQualification} onChange={() => setShowUnansweredQuestionsQualification(!showUnansweredQuestionsQualification)} />
                            <label className="form-check-label ms-2" htmlFor="switch_show_unanswered_questions_qualification">
                            Show unanswered questions
                            </label>
                        </div>
                        {
                            qualification !== undefined && qualificationQuestionIds.map(questionId => {
                                const question = qualificationQuestions[questionId]

                                return <div key={'qualification-question-' + questionId}>
                                    {
                                        renderQualificationQuestion(question, qualificationExpressions, qualificationAnswers, undefined, qualification, showUnansweredQuestionsQualification)
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
            }
            <hr />
            <div>
                <h2 className="mb-4">Classification</h2>
                {
                    isClassAny &&
                    <div className="card mb-4">
                        <div className="card-body">
                            <h3 className="mb-2">Result:&nbsp;
                                <span className="text-success">class {getClassificationAbbreviatedText(classification.classification)}</span>
                            </h3>
                            {isEuClassAny && <p className="mb-2">The classification was done according to the European Union (EU) Medical Device Regulation.</p>}
                            {isUsClassAny && <p className="mb-2">The classification was done according to the United States (US) Medical Device Regulation.</p>}
                            {isCanadaClassAny && <p className="mb-2">The classification was done according to the Canada (CA) Medical Device Regulation.</p>}                            
                        </div>
                    </div>
                }
                {
                    !isClassAny &&
                    <div className="card mb-4">
                        <div className="card-body">
                            <h3 className="mb-2">Result:&nbsp;
                                <span className="text-danger">no class observed</span>
                            </h3>
                            <p className="mb-2">This device does not meet the criteria for any class.</p>
                        </div>
                    </div>
                }
                {
                    !showClassificationResult &&
                    <div className="my-6">
                        <a role="button" className="btn btn-success me-2" onClick={() => seeClassificationResult(true)}>Show Detailed Report</a>
                    </div>
                }
                {
                    showClassificationResult &&
                    <div className="my-6">
                        <a role="button" className="btn btn-secondary me-2" onClick={() => seeClassificationResult(false)}>Hide Detailed Report</a>
                    </div>
                }
            </div>
            {showClassificationResult &&
                <div className="card mb-4">
                    <div className="card-body">
                        <h2 className="mb-4">All Rules</h2>
                        <div className="form-check form-switch mb-4">
                            <input className="form-check-input" type="checkbox" name="switch_show_unanswered_questions_classification" id="switch_show_unanswered_questions_classification" checked={showUnansweredQuestionsClassification} onChange={() => setShowUnansweredQuestionsClassification(!showUnansweredQuestionsClassification)} />
                            <label className="form-check-label ms-2" htmlFor="switch_show_unanswered_questions_classification">
                            Show unanswered questions
                            </label>
                        </div>
                        {
                            classification !== undefined &&
                                renderClassificationExpression(classificationExpressions[classification.expressionId], classificationExpressions, 0, 0, classificationQuestions, classificationAnswers, classification, classification, undefined, showUnansweredQuestionsClassification, true)
                        }
                    </div>
                </div>
            }
            <hr />
            <div>
                <h2 className="mb-4">Export</h2>
                <p>Export the qualification and classification in PDF.</p>
                {
                    <div className="my-6">
                        {exportingPdf &&
                            <a role="button" className="btn btn-secondary me-2">Exporting PDF...</a>
                        }
                        {!exportingPdf &&
                            <a role="button" className="btn btn-success me-2" onClick={showExportedPDF}>Export PDF</a>
                        }
                    </div>
                }
            </div>
        </div>
    )
}