import { CreatePreviewAnswerInput, DeletePreviewAnswerInput, PreviewAnswer, UpdatePreviewAnswerInput } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyPreviewAnswerInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name', 'valueString'];
    const intKeys = ['valueInt'];
    const floatKeys = ['valueFloat'];
    const booleanKeys = ['valueBoolean'];

    const entityRelationKeysEntities: Map<string, EntityName> = new Map([
        ['question', EntityName.Question],
        ['previewProduct', EntityName.PreviewProduct],
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys,
        intKeys,
        floatKeys,
        booleanKeys,
        entityRelationKeysEntities
    });
}

export function getFilledPreviewAnswerInstance(model: PreviewAnswer): FilledEntity {
    const instance = getEmptyPreviewAnswerInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name === null ? undefined : model.name);
    instance.addString('valueString', model.valueString === null ? undefined : model.valueString);
    instance.addInt('valueInt', model.valueInt === null ? undefined : model.valueInt);
    instance.addFloat('valueFloat', model.valueFloat === null ? undefined : model.valueFloat);
    instance.addBoolean('valueBoolean', model.valueBoolean === null ? undefined : model.valueBoolean);
    instance.addEntityRelation('question', {entityName: EntityName.Question, id: model.previewAnswerQuestionId === null ? undefined : model.previewAnswerQuestionId});
    instance.addEntityRelation('previewProduct', {entityName: EntityName.PreviewProduct, id: model.previewProductPreviewAnswersId === null ? undefined : model.previewProductPreviewAnswersId});
    return instance;
}

export function getCreatePreviewAnswerInput(instance: FilledEntity): CreatePreviewAnswerInput {
    const name = instance.getStringMandatory('name');
    const valueString = instance.getStringOptional('valueString');
    const valueInt = instance.getIntOptional('valueInt');
    const valueFloat = instance.getFloatOptional('valueFloat')
    const valueBoolean = instance.getBooleanOptional('valueBoolean');
    const previewProductPreviewAnswersId = instance.getEntityRelationOptional('previewProduct')?.id
    const previewAnswerQuestionId = instance.getEntityRelationOptional('question')?.id

    return {
        name,
        valueString,
        valueInt,
        valueFloat,
        valueBoolean,
        previewProductPreviewAnswersId,
        previewAnswerQuestionId
    };
}

export function getUpdatePreviewAnswerInput(instance: FilledEntity): UpdatePreviewAnswerInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const valueString = instance.getStringOptional('valueString');
    const valueInt = instance.getIntOptional('valueInt');
    const valueFloat = instance.getFloatOptional('valueFloat')
    const valueBoolean = instance.getBooleanOptional('valueBoolean');
    const previewProductPreviewAnswersId = instance.getEntityRelationOptional('previewProduct')?.id
    const previewAnswerQuestionId = instance.getEntityRelationOptional('question')?.id

    return {
        id,
        name,
        valueString,
        valueInt,
        valueFloat,
        valueBoolean,
        previewProductPreviewAnswersId,
        previewAnswerQuestionId
    };
}

export function getDeletePreviewAnswerInput(instance: FilledEntity): DeletePreviewAnswerInput {
    const id = instance.getIdMandatory('id');

    return {
        id
    };
}
