export const Terms: React.FC = () => {
    return (
        <div>
            <div className="py-4 py-xs-8 py-sm-12 py-md-16 py-lg-24 py-xl-32 py-xxl-32 overflow-hidden">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <h1 className="display-1 text-center text-primary">
                                terms
                            </h1>
                            <h2 className="mb-10 text-center">
                                Last updated: 25/Mar/2023
                            </h2>
                            <ul>
                                <li className="mb-2">
                                    <span className="font-bold">Acceptance of Terms</span>: By using our service, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our service.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Registration</span>: To access our service, you may be required to register and provide accurate, current, and complete information about yourself. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Use of Service</span>: You agree to use our service only for lawful purposes and in accordance with these Terms of Service. You are responsible for your own conduct and any data, text, or other content that you submit, post, or display using our service.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">User Content</span>: By submitting, posting, or displaying content on or through our service, you grant us a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, and display such content in any media or distribution methods.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Prohibited Activities</span>: You agree not to engage in any activities that violate any applicable law, regulation, or guideline, or that could damage, disable, overburden, or impair our service or interfere with any other party's use of our service.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Termination</span>: We reserve the right to terminate your access to our service at any time, for any reason, with or without notice. Upon termination, all rights and licenses granted to you will cease immediately.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Disclaimer</span>: Our service is provided "as is" and "as available," without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We make no warranties about the accuracy, reliability, completeness, or timeliness of our service, content, or other materials. We do not warrant that our service will meet your requirements or that our service will be uninterrupted, timely, secure, or error-free. We do not warrant that the results that may be obtained from the use of our service will be accurate or reliable. You agree that from time to time we may remove our service for indefinite periods of time or cancel our service at any time, without notice to you.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Limitation of Liability</span>: To the fullest extent permitted by law, we will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, good-will, or other intangible losses, resulting from (i) your access to or use of or inability to access or use our service; (ii) any conduct or content of any third party on our service; (iii) any content obtained from our service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Changes to Terms of Service</span>: We reserve the right to modify these Terms of Service at any time. Your continued use of our service after any changes are made constitutes your acceptance of the updated Terms of Service.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Governing Law</span>: These Terms of Service and any dispute or claim arising out of or in connection with them shall be governed by and construed in accordance with the laws of the applicable jurisdiction.
                                </li>
                                <li className="mb-2">
                                    <span className="font-bold">Contact Us</span>: If you have any questions or concerns regarding these Terms of Service, please contact us using the contact information provided on our website.
                                </li>
                            </ul>
                            <h1 className="display-1 text-center text-primary">
                                🤝
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-n1 transform translate-y-1/4">
                    <div className="col-lg-11 col-xl-9 mx-auto">
                        <div className="row g-5">
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md6.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md4.jpg" />
                        </div>
                        <div className="col">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md3.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2 transform translate-y-1/5" src="images/md5.jpg" />
                        </div>
                        <div className="col d-none d-sm-block">
                            <img alt="" className="img-fluid rounded-2 my-2" src="images/md2.jpg" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
