export enum EntityName {
    
    // Entities
    DeviceClassificationExpression,
    DeviceQualificationExpression,
    EntityTemplate,
    EntityTemplateBelongsTo,
    EntityTemplateHasOne,
    Label,
    MedflowVersion,
    Question,
    QuestionLabel,
    QuestionRowColumn,
    Phase,
    Stage,
    StageQuestionnaire,
    StageQuestionnaireSection,
    Track,
    Product,
    Answer,
    RowColumnAnswer,
    Report,
    ReportQuestionnaire,
    ReportQuestionnaireSection,
    PreviewProduct,
    PreviewAnswer,
    PreviewOrganization,
    PreviewUser
}