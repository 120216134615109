import { CreateQuestionInput, DeleteQuestionInput, UpdateQuestionInput, AnswerDataType, SpecialQuestionCategory, Question } from '../API';
import { EntityName } from './EntityName';
import { FilledEntity } from './FilledEntity';

export function getEmptyQuestionInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['description', 'guidingBeforeText', 'placeholder', 'name', 'statement', 'tooltip'];
    const stringArrayKeys = ['examples', 'explanations', 'notes'];

    const answerDataTypeKeys = ['answerDataType'];
    const specialQuestionCategoryKeys = ['specialQuestionCategory'];

    const entityRelationsKeysEntities: Map<string, EntityName> = new Map([
        ['questionLabels', EntityName.QuestionLabel],
        ['questionRowColumns', EntityName.QuestionRowColumn],
        ['reportQuestionnaires', EntityName.ReportQuestionnaire],
        ['stageQuestionnaires', EntityName.StageQuestionnaire],
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys,
        stringArrayKeys,
        answerDataTypeKeys,
        specialQuestionCategoryKeys,
        entityRelationsKeysEntities
    });
}

export function getFilledQuestionInstance(model: Question): FilledEntity {
    const examplesNotNull = model.examples === null ? undefined : model.examples
    const examplesFinal = examplesNotNull === undefined ? [] : examplesNotNull.filter(example => example !== null) as string[];

    const explanationsNotNull = model.explanations === null ? undefined : model.explanations
    const explanationsFinal = explanationsNotNull === undefined ? [] : explanationsNotNull.filter(explanation => explanation !== null) as string[];

    const notesNotNull = model.notes === null ? undefined : model.notes
    const notesFinal = notesNotNull === undefined ? [] : notesNotNull.filter(note => note !== null) as string[];
    
    const instance = getEmptyQuestionInstance();
    instance.setId('id', model.id);
    instance.addString('description', model.description === null ? undefined : model.description);
    instance.addString('name', model.name === null ? undefined : model.name);
    instance.addString('guidingBeforeText', model.guidingBeforeText === null ? undefined : model.guidingBeforeText);
    instance.addString('placeholder', model.placeholder === null ? undefined : model.placeholder);
    instance.addString('statement', model.statement === null ? undefined : model.statement);
    instance.addString('tooltip', model.tooltip === null ? undefined : model.tooltip);
    instance.addAnswerDataType('answerDataType', model.answerDataType === null ? undefined : model.answerDataType);
    instance.addSpecialQuestionCategory('specialQuestionCategory', model.specialQuestionCategory === null ? undefined : model.specialQuestionCategory);
    instance.addStringArray('examples', examplesFinal);
    instance.addStringArray('explanations', explanationsFinal);
    instance.addStringArray('notes', notesFinal);

    const questionLabelIds = model.questionLabels === undefined ? [] : model.questionLabels?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('questionLabels', {ids: questionLabelIds, entityName: EntityName.QuestionLabel});

    const questionRowColumnIds = model.questionRowColumns === undefined ? [] : model.questionRowColumns?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('questionRowColumns', {ids: questionRowColumnIds, entityName: EntityName.QuestionRowColumn});

    const reportQuestionnaireIds = model.reportQuestionnaires === undefined ? [] : model.reportQuestionnaires?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('reportQuestionnaires', {ids: reportQuestionnaireIds, entityName: EntityName.ReportQuestionnaire});

    const stageQuestionnaireIds = model.stageQuestionnaires === undefined ? [] : model.stageQuestionnaires?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('stageQuestionnaires', {ids: stageQuestionnaireIds, entityName: EntityName.StageQuestionnaire});

    return instance;
}

export function getCreateQuestionInput(instance: FilledEntity): CreateQuestionInput {
    const name = instance.getStringMandatory('name');
    const description = instance.getStringOptional('description');
    const guidingBeforeText = instance.getStringOptional('guidingBeforeText');
    const placeholder = instance.getStringOptional('placeholder');
    const statement = instance.getStringOptional('statement');
    const tooltip = instance.getStringOptional('tooltip');
    const answerDataType = instance.getAnswerDataTypeMandatory('answerDataType');
    const specialQuestionCategory = instance.getSpecialQuestionCategoryOptional('specialQuestionCategory');
    const examples = instance.getStringArrayOptional('examples');
    const explanations = instance.getStringArrayOptional('explanations');
    const notes = instance.getStringArrayOptional('notes');

    return {
        name,
        description,
        guidingBeforeText,
        placeholder,
        statement,
        tooltip,
        answerDataType,
        specialQuestionCategory,
        examples,
        explanations,
        notes
    };
}

export function getUpdateQuestionInput(instance: FilledEntity): UpdateQuestionInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const description = instance.getStringOptional('description');
    const guidingBeforeText = instance.getStringOptional('guidingBeforeText');
    const placeholder = instance.getStringOptional('placeholder');
    const statement = instance.getStringOptional('statement');
    const tooltip = instance.getStringOptional('tooltip');
    const answerDataType = instance.getAnswerDataTypeOptional('answerDataType');
    const specialQuestionCategory = instance.getSpecialQuestionCategoryOptional('specialQuestionCategory');
    const examples = instance.getStringArrayOptional('examples');
    const explanations = instance.getStringArrayOptional('explanations');
    const notes = instance.getStringArrayOptional('notes');

    return {
        id,
        name,
        description,
        guidingBeforeText,
        placeholder,
        statement,
        tooltip,
        answerDataType,
        specialQuestionCategory,
        examples,
        explanations,
        notes
    };
}

export function getDeleteQuestionInput(instance: FilledEntity): DeleteQuestionInput {
    const id = instance.getIdMandatory('id');
    
    return {
        id
    };
}