import React from 'react'
import { EntityName } from '../../model/EntityName'
import { GenericRelatedsViewer } from '../mixins/GenericRelatedsViewer'

interface RelatedsEntityViewerProps {
    relationName: string|undefined
    entityName: EntityName
    ids: string[]
    setEntityCreator: (entityName: EntityName) => void
    setEntityViewer: (entityName: EntityName, id: string) => void
    setEntityEditor: (entityName: EntityName, id: string) => void
    setEntityRemover: (entityName: EntityName, id: string) => void
}

export const RelatedsEntityViewer: React.FC<RelatedsEntityViewerProps> = ({ relationName, entityName, ids, setEntityCreator, setEntityViewer, setEntityEditor, setEntityRemover }) => {
    return (
        <GenericRelatedsViewer ids={ids} entityName={entityName} setEntityCreator={setEntityCreator} setEntityViewer={setEntityViewer} setEntityEditor={setEntityEditor} setEntityRemover={setEntityRemover} relationName={relationName} />
    )
}