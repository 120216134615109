import React from 'react'
import { EntityName } from '../../model/EntityName'
import { GenericLister } from '../mixins/GenericLister'

interface EntityListerProps {
    relationName?: string
    entityName: EntityName
    setEntityCreator: (entityName: EntityName) => void
    setEntityViewer: (entityName: EntityName, id: string) => void
    setEntityEditor: (entityName: EntityName, id: string) => void
    setEntityRemover: (entityName: EntityName, id: string) => void
}

export const EntityLister: React.FC<EntityListerProps> = ({ relationName, entityName, setEntityCreator, setEntityViewer, setEntityEditor, setEntityRemover }) => {
    return (
        <div>
            <GenericLister relationName={relationName} entityName={entityName} setEntityCreator={setEntityCreator} setEntityEditor={setEntityEditor} setEntityRemover={setEntityRemover} setEntityViewer={setEntityViewer} />
            <button className="btn btn-primary btn-sm mt-4" onClick={() => setEntityCreator(entityName)}>Create {entityName}</button>
        </div>
    )
}