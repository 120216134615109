import React from 'react'
import { EntityName } from '../../model/EntityName'
import { GenericEditor } from '../mixins/GenericEditor'

interface EntityEditorProps {
    entityName: EntityName
    id: string
    setEntityViewer: (entityName: EntityName, id: string) => void
    setEntityRemover: (entityName: EntityName, id: string) => void
    discardEntityEditor: (entityName: EntityName, id: string) => void
}

export const EntityEditor: React.FC<EntityEditorProps> = ({ entityName, id, setEntityViewer, setEntityRemover, discardEntityEditor }) => {
    function cancel(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault()
        discardEntityEditor(entityName, id)
    }

    return (
        <div className="card">
            <div className="card-body">
                <GenericEditor entityName={entityName} id={id} discardEntityEditor={discardEntityEditor} />
            </div>
            <div className="card-footer">
                <button className="btn btn-primary btn-sm" onClick={cancel}>Cancel</button>
            </div>
        </div>
    )
}