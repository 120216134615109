import React, { useEffect, useState } from 'react'

interface StringViewerProps {
    relationName: string
    value: string|undefined
}

export const StringViewer: React.FC<StringViewerProps> = ({ relationName, value }) => {
    return (
        <>
            <label className="form-label" htmlFor={'textarea-' + relationName}>{relationName}</label>
            <textarea id={'textarea-' + relationName} value={value} disabled className="form-control w-full" />
        </>
    )
}