import { EntityName } from "../../model/EntityName"
import { FilledEntity } from "../../model/FilledEntity"

const getEmptyInstance = async (entityName: EntityName) => {
    const model = await import(`../../model/${entityName}`)
    const getEmptyInstance = model[`getEmpty${entityName}Instance`]
    
    if (typeof getEmptyInstance === 'function') {
        const emptyInstance: FilledEntity = getEmptyInstance()
        return emptyInstance
    }

    return undefined
}

const listFilledInstances = async (entityName: EntityName) => {
    const api = await import(`../../api-wrapper/${entityName}`)
    const listApi = api[`list${entityName}sApi`]

    if (typeof listApi === 'function') {
        const instancesRaw = await listApi()

        const model = await import(`../../model/${entityName}`)
        const getFilledInstance = model[`getFilled${entityName}Instance`]
        
        if (typeof getFilledInstance === 'function') {
            const instances = instancesRaw.map(getFilledInstance)
            return instances
        }
    }

    return []
}

const getFilledInstance = async (entityName: EntityName, id: string) => {
    const api = await import(`../../api-wrapper/${entityName}`)
    const getApi = api[`get${entityName}Api`]

    if (typeof getApi === 'function') {
        const instance = await getApi(id)

        if (instance === undefined) {
            return
        }

        const model = await import(`../../model/${entityName}`)
        const getFilledInstance = model[`getFilled${entityName}Instance`]
        
        if (typeof getFilledInstance === 'function') {
            const filledInstance = getFilledInstance(instance)
            return filledInstance
        }
    }

    return undefined
}

const createFilledInstance = async (entityName: EntityName, instance: FilledEntity|undefined) => {
    if (instance === undefined) {
        return
    }

    const model = await import(`../../model/${entityName}`)
    const createInput = model[`getCreate${entityName}Input`]

    if (typeof createInput === 'function') {
        const input = createInput(instance)

        const api = await import(`../../api-wrapper/${entityName}`)
        const createApi = api[`create${entityName}Api`]

        if (typeof createApi === 'function') {
            await createApi(input)
        }
    }
}

const updateFilledInstance = async (entityName: EntityName, instance: FilledEntity|undefined) => {
    if (instance === undefined) {
        return
    }

    const model = await import(`../../model/${entityName}`)
    const getUpdateInput = model[`getUpdate${entityName}Input`]
    
    if (typeof getUpdateInput === 'function') {
        const input = getUpdateInput(instance)

        const api = await import(`../../api-wrapper/${entityName}`)
        const updateApi = api[`update${entityName}Api`]

        if (typeof updateApi === 'function') {
            await updateApi(input)
        }
    }
}

const deleteFilledInstance = async (entityName: EntityName, instance: FilledEntity) => {
    const model = await import(`../../model/${entityName}`)
    const getDeleteInput = model[`getDelete${entityName}Input`]
    
    if (typeof getDeleteInput === 'function') {
        const input = getDeleteInput(instance)

        const api = await import(`../../api-wrapper/${entityName}`)
        const deleteApi = api[`delete${entityName}Api`]

        if (typeof deleteApi === 'function') {
            await deleteApi(input)
        }
    }
}

export {
    getEmptyInstance,
    listFilledInstances,
    getFilledInstance,
    createFilledInstance,
    updateFilledInstance,
    deleteFilledInstance
}