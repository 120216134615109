import React, { useEffect, useState } from 'react'

interface StringChooserProps {
    relationName: string
    value: string|undefined
    setValue: (value: string|undefined) => void
}

export const StringChooser: React.FC<StringChooserProps> = ({ relationName, value, setValue }) => {
    const [innerValue, setInnerValue] = useState<string|undefined>(value)

    useEffect(() => {
        setInnerValue(value)
    }, [value])

    function setValueString(event: React.ChangeEvent<HTMLInputElement>): void {
        setInnerValue(event.target.value)
        setValue(event.target.value)
    }

    return (
        <>
            <span className="form-label">{relationName}</span>
            <input id={relationName} type="text" value={innerValue === null ? undefined : innerValue} onChange={setValueString} className="form-control w-full" />
        </>
    )
}