import { API } from "aws-amplify";
import { CreatePhaseInput, DeletePhaseInput, Phase, GetPhaseQuery, ListPhasesQuery, UpdatePhaseInput } from "../API";
import { createPhase, deletePhase, updatePhase } from "../graphql/mutations";
import { getPhase, listPhases, listPhasesByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createPhaseApi = async (input: CreatePhaseInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Phase> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createPhase,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createPhase: Phase } };
    return response.data.createPhase;
}

export const updatePhaseApi = async (input: UpdatePhaseInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Phase> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updatePhase,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updatePhase: Phase } };
    return response.data.updatePhase;
}

export const deletePhaseApi = async (input: DeletePhaseInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Phase> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deletePhase,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deletePhase: Phase } };
    return response.data.deletePhase;
}

export const listPhasesApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Phase[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listPhases,
    });

    const response = responseRaw as { data: ListPhasesQuery };
    const items = response.data.listPhases?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as Phase[];
    return itemsExisting
}

export const getPhaseApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Phase|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getPhase,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetPhaseQuery };
    const instance = response.data.getPhase;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as Phase
    return instanceNotUndefined
}

export const listPhasesByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<Phase[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listPhasesByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListPhasesQuery };
    const items = response.data.listPhases?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as Phase[];
    return itemsExisting
}
