import { useState } from 'react';
import { DeviceClassificationNode, DeviceClassifier } from '../../../algorithms/DeviceClassifier';
import * as graphQlApi from '../../../API';
import { createPreviewAnswerApi } from '../../../api-wrapper/PreviewAnswer';
import { getCreatePreviewProductInput, getEmptyPreviewProductInstance } from '../../../model/PreviewProduct';
import { createPreviewProductApi } from '../../../api-wrapper/PreviewProduct';
import { EntityName } from '../../../model/EntityName';
import { getCreatePreviewAnswerInput } from '../../../model/PreviewAnswer';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';
import { getCreatePreviewUserInput, getEmptyPreviewUserInstance } from '../../../model/PreviewUser';
import { createPreviewUserApi } from '../../../api-wrapper/PreviewUser';
import { getCreatePreviewOrganizationInput, getEmptyPreviewOrganizationInstance } from '../../../model/PreviewOrganization';
import { createPreviewOrganizationApi } from '../../../api-wrapper/PreviewOrganization';
import { PreviewOrganization, PreviewProduct, PreviewUser } from '../../../API';
import { getClassificationAbbreviatedText } from './ClassificationHelper';

interface ClassifiedPanelProps {
    startReport: () => void
    expressions: {[key: string]: graphQlApi.DeviceClassificationExpression}
    questions: {[key: string]: graphQlApi.Question}
    answers: {[key: string]: boolean}
    setProduct: (product: PreviewProduct|undefined) => void
    setOrganization: (organization: PreviewOrganization|undefined) => void
    setUser: (user: PreviewUser|undefined) => void
}

export const ClassifiedPanel: React.FC<ClassifiedPanelProps> = ({ startReport, expressions, questions, answers, setProduct, setOrganization, setUser }) => {
    const [userName, setUserName] = useState<string|undefined>(undefined)
    const [userEmail, setUserEmail] = useState<string|undefined>(undefined)
    const [userPhoneNumber, setUserPhoneNumber] = useState<string|undefined>(undefined)
    const [userArea, setUserArea] = useState<string|undefined>(undefined)
    const [deviceName, setDeviceName] = useState<string|undefined>(undefined)
    const [deviceIntendedUse, setDeviceIntendedUse] = useState<string|undefined>(undefined)
    const [deviceDescription, setDeviceDescription] = useState<string|undefined>(undefined)
    const [companyName, setCompanyName] = useState<string|undefined>(undefined)
    const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState<string|undefined>(undefined)

    const [userNameError, setUserNameError] = useState<string|undefined|false>(undefined)
    const [userEmailError, setUserEmailError] = useState<string|undefined|false>(undefined)
    const [userPhoneNumberError, setUserPhoneNumberError] = useState<string|undefined|false>(undefined)
    const [userAreaError, setUserAreaError] = useState<string|undefined|false>(undefined)
    const [deviceNameError, setDeviceNameError] = useState<string|undefined|false>(undefined)
    const [deviceIntendedUseError, setDeviceIntendedUseError] = useState<string|undefined|false>(undefined)
    const [deviceDescriptionError, setDeviceDescriptionError] = useState<string|undefined|false>(undefined)
    const [companyNameError, setCompanyNameError] = useState<string|undefined|false>(undefined)
    const [companyRegistrationNumberError, setCompanyRegistrationNumberError] = useState<string|undefined|false>(undefined)

    const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false)
    
    function classify(): DeviceClassificationNode | undefined {
        const deviceClassifier = new DeviceClassifier(expressions, answers)        
        const deviceClassifications = deviceClassifier.classify()

        if (deviceClassifications.length <= 0) {
            return undefined
        }

        return deviceClassifications[0]
    }

    const classification = classify()

    const isEuClassI = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.EU_CLASS_1
    const isEuClassIIa = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.EU_CLASS_2_A
    const isEuClassIIb = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.EU_CLASS_2_B
    const isEuClassIII = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.EU_CLASS_3
    const isEuClassAny = isEuClassI || isEuClassIIa || isEuClassIIb || isEuClassIII

    const isUsClassI = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.US_CLASS_1
    const isUsClassII = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.US_CLASS_2
    const isUsClassIII = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.US_CLASS_3
    const isUsClassAny = isUsClassI || isUsClassII || isUsClassIII

    const isCanadaClassI = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_1
    const isCanadaClassII = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_2
    const isCanadaClassIII = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_3
    const isCanadaClassIV = classification !== undefined && classification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_4
    const isCanadaClassAny = isCanadaClassI || isCanadaClassII || isCanadaClassIII || isCanadaClassIV

    const isClassAny = isEuClassAny || isUsClassAny || isCanadaClassAny

    function setUserNameValue(event: React.ChangeEvent<HTMLInputElement>): void {
        setUserName(event.target.value)

        if (!event.target.value || event.target.value === "") {
            setUserNameError('Please enter your name.')
        } else {
            setUserNameError(false)
        }
    }

    function setUserEmailValue(event: React.ChangeEvent<HTMLInputElement>): void {
        setUserEmail(event.target.value)

        if (!event.target.value || event.target.value === "") {
            setUserEmailError('Please enter your email.')
        } else {
            if (validateEmail(event.target.value)) {
                setUserEmailError(false)
            } else {
                setUserEmailError('Please enter a valid email.')
            }
        }
    }

    function setUserPhoneNumberValue(event: React.ChangeEvent<HTMLInputElement>): void {
        setUserPhoneNumber(event.target.value)

        if (!event.target.value || event.target.value === "") {
            setUserPhoneNumberError('Please enter your phone number.')
        } else {
            setUserPhoneNumberError(false)
        }
    }

    function setUserAreaValue(event: React.ChangeEvent<HTMLSelectElement>): void {
        setUserArea(event.target.value)

        if (!event.target.value || event.target.value === "") {
            setUserAreaError('Please enter your area.')
        } else {
            setUserAreaError(false)
        }
    }

    function setDeviceNameValue(event: React.ChangeEvent<HTMLInputElement>): void {
        setDeviceName(event.target.value)

        if (!event.target.value || event.target.value === "") {
            setDeviceNameError('Please enter the name of your device.')
        } else {
            setDeviceNameError(false)
        }
    }

    function setDeviceIntendedUseValue(event: React.ChangeEvent<HTMLTextAreaElement>): void {
        setDeviceIntendedUse(event.target.value)

        if (!event.target.value || event.target.value === "") {
            setDeviceIntendedUseError('Please enter the intended use of your device.')
        } else {
            setDeviceIntendedUseError(false)
        }
    }

    function setDeviceDescriptionValue(event: React.ChangeEvent<HTMLTextAreaElement>): void {
        setDeviceDescription(event.target.value)

        if (!event.target.value || event.target.value === "") {
            setDeviceDescriptionError(undefined)
        } else {
            setDeviceDescriptionError(false)
        }
    }

    function setCompanyNameValue(event: React.ChangeEvent<HTMLInputElement>): void {
        setCompanyName(event.target.value)
        setCompanyNameError(false)
    }

    function setCompanyRegistrationNumberValue(event: React.ChangeEvent<HTMLInputElement>): void {
        setCompanyRegistrationNumber(event.target.value)
        setCompanyRegistrationNumberError(false)
    }

    function validateEmail(email: string) {
        return String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    }

    async function uploadPreviewOrganization(): Promise<PreviewOrganization> {
        const instance = getEmptyPreviewOrganizationInstance()
        instance.addString('name', companyName)
        instance.addString('registrationNumber', companyRegistrationNumber)

        const input = getCreatePreviewOrganizationInput(instance)
        return await createPreviewOrganizationApi(input, GRAPHQL_AUTH_MODE.AWS_IAM)
    }

    async function uploadPreviewUser(companyId: string|undefined): Promise<PreviewUser> {
        const instance = getEmptyPreviewUserInstance()
        instance.addString('name', userName)
        instance.addString('email', userEmail)
        instance.addString('phoneNumber', userPhoneNumber)
        instance.addString('area', userArea)
        instance.addEntityRelation('previewOrganization', {id: companyId, entityName: EntityName.PreviewOrganization})

        const input = getCreatePreviewUserInput(instance)
        return await createPreviewUserApi(input, GRAPHQL_AUTH_MODE.AWS_IAM)
    }

    async function uploadPreviewProduct(userId: string, companyId: string|undefined): Promise<PreviewProduct> {
        const instance = getEmptyPreviewProductInstance()
        instance.addString('name', deviceName)
        instance.addString('description', deviceDescription)
        instance.addString('intendedUse', deviceIntendedUse)
        instance.addEntityRelation('previewUser', {id: userId, entityName: EntityName.PreviewUser})
        instance.addEntityRelation('previewOrganization', {id: companyId, entityName: EntityName.PreviewOrganization})

        const input = getCreatePreviewProductInput(instance)
        return await createPreviewProductApi(input, GRAPHQL_AUTH_MODE.AWS_IAM)
    }

    async function uploadPreviewAnswer(productId: string, questionId: string, answerValue: boolean): Promise<string> {
        const instance = getEmptyPreviewProductInstance()
        const nameNull = questions[questionId].name
        const name: string|undefined = nameNull === null ? undefined : nameNull
        instance.addString('name', name)
        instance.addBoolean('valueBoolean', answerValue)
        instance.addEntityRelation('previewProduct', {id: productId, entityName: EntityName.PreviewProduct})
        instance.addEntityRelation('question', {id: questionId, entityName: EntityName.Question})

        const input = getCreatePreviewAnswerInput(instance)
        const created = await createPreviewAnswerApi(input, GRAPHQL_AUTH_MODE.AWS_IAM)
        return created.id
    }

    async function uploadPreviewProductAnswers(productId: string) {
        const answerPromises = Object.entries(answers).map(async (answerTuple) => {
            const [questionId, answerValue] = answerTuple
            return await uploadPreviewAnswer(productId, questionId, answerValue)
        })

        return await Promise.all(answerPromises)
    }

    async function uploadInformation() {
        if (!hasAllInfo) {
            console.warn('Some mandatory fields are not yet filled.', userName, userEmail, userPhoneNumber, userArea, deviceName, deviceIntendedUse, deviceDescription)
            alert("Some mandatory fields are not yet filled.")
            return
        }

        setIsLoadingButton(true)

        var previewOrganization: PreviewOrganization|undefined = undefined

        if (hasCompanyName && hasCompanyRegistrationNumber) {
            previewOrganization = await uploadPreviewOrganization()
        }

        setOrganization(previewOrganization)

        const previewOrganizationId = previewOrganization?.id
        const previewUser = await uploadPreviewUser(previewOrganizationId)
        setUser(previewUser)

        const previewUserId = previewUser?.id
        const previewProduct = await uploadPreviewProduct(previewUserId, previewOrganizationId)
        setProduct(previewProduct)
        
        const previewProductId = previewProduct?.id
        await uploadPreviewProductAnswers(previewProductId)

        setIsLoadingButton(false)
        startReport()
    }

    const areaOptions = [
        { value: undefined, label: undefined },
        { value: 'EuropeanUnion', label: 'European Union' },
        { value: 'UnitedStates', label: 'United States' },
        { value: 'Canada', label: 'Canada' },
        { value: 'Other', label: 'Other' },
      ];

    const hasUserName = userName !== undefined && userName !== "" && userName !== null
    const hasUserEmail = userEmail !== undefined && userEmail !== "" && userEmail !== null
    const hasUserPhoneNumber = userPhoneNumber !== undefined && userPhoneNumber !== "" && userPhoneNumber !== null
    const hasUserArea = userArea !== undefined && userArea !== "" && userArea !== null
    const hasDeviceName = deviceName !== undefined && deviceName !== "" && deviceName !== null
    const hasDeviceIntendedUse = deviceIntendedUse !== undefined && deviceIntendedUse !== "" && deviceIntendedUse !== ""
    const hasDeviceDescription = deviceDescription !== undefined && deviceDescription !== "" && deviceDescription !== ""
    const hasCompanyName = companyName !== undefined && companyName !== "" && companyName !== null
    const hasCompanyRegistrationNumber = companyRegistrationNumber !== undefined && companyRegistrationNumber !== "" && companyRegistrationNumber !== null
    const hasAllInfo = hasUserName && hasUserEmail && hasUserPhoneNumber && hasUserArea && hasDeviceName && hasDeviceIntendedUse && hasDeviceDescription && validateEmail(userEmail)

    return (
        <div>
            <h1 className="mb-4">Classification</h1>
            <div className="my-4">
                <p><i className="bi bi-exclamation-circle text-warning" />  The class at this level is decided by the highest class from the rules below with questions answered Yes.</p>
            </div>
            {
                isEuClassAny &&
                <div className="card mb-4">
                    <div className="card-body">
                        <h2 className="mb-2">Result: class {getClassificationAbbreviatedText(classification.classification)}</h2>
                        <p className="mb-2">The classification was done according to the European Union (EU) Medical Device Regulation.</p>
                    </div>
                </div>
            }
            {
                isUsClassAny &&
                <div className="card mb-4">
                    <div className="card-body">
                        <h2 className="mb-2">Result: class {getClassificationAbbreviatedText(classification.classification)}</h2>
                        <p className="mb-2">The classification was done according to the United States (US) Medical Device Regulation.</p>
                    </div>
                </div>
            }
            {
                isCanadaClassAny &&
                <div className="card mb-4">
                    <div className="card-body">
                        <h2 className="mb-2">Result: class {getClassificationAbbreviatedText(classification.classification)}</h2>
                        <p className="mb-2">The classification was done according to the Canadian (CA) Medical Device Regulation.</p>
                    </div>
                </div>
            }
            {
                !isClassAny &&
                <div className="card mb-4">
                    <div className="card-body">
                        <h2 className="mb-2">Result:&nbsp;
                            <span className="text-danger">no class observed</span>
                        </h2>
                        <p className="mb-2">This device does not meet the criteria for any class.</p>
                    </div>
                </div>
            }
            <h2 className="mt-10 mb-2">Get in Touch</h2>
            <p className="mb-8">Leave us your contact details to unlock the report and receive personalized help on your medical device certification journey.</p>
            <form className="mb-4">
                <hr />
                <h3 className="mt-10 mb-2">Your Info</h3>
                <div className="row mb-4">
                    <div className="col-md-6">
                        <div className="">
                            <label className="form-label" htmlFor="name">Name <span className="text-primary">*</span></label>
                            <input type="text" className={"form-control " + (userNameError !== undefined && userNameError !== false ? 'is-invalid' : (userNameError === false ? 'is-valid' : ''))} id="name" onChange={setUserNameValue} value={userName} disabled={isLoadingButton} />
                            {userNameError !== undefined && userNameError !== false &&
                                <span className="mt-2 invalid-feedback">{userNameError}</span>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                    <div className="">
                            <label className="form-label" htmlFor="email">Email <span className="text-primary">*</span></label>
                            <input type="email" className={"form-control " + (userEmailError !== undefined && userEmailError !== false ? 'is-invalid' : (userEmailError === false ? 'is-valid' : ''))} id="email" onChange={setUserEmailValue} value={userEmail} disabled={isLoadingButton} />
                            {userEmailError !== undefined && userEmailError !== false &&
                                <span className="mt-2 invalid-feedback">{userEmailError}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className="row g-5">
                    <div className="col-md-6">
                        <div className="">
                            <label className="form-label" htmlFor="phone_number">Phone Number <span className="text-primary">*</span></label>
                            <input type="tel" className={"form-control " + (userPhoneNumberError !== undefined && userPhoneNumberError !== false ? 'is-invalid' : (userPhoneNumberError === false ? 'is-valid' : ''))} id="phone_number" onChange={setUserPhoneNumberValue} value={userPhoneNumber} disabled={isLoadingButton} />
                            {userPhoneNumberError !== undefined && userPhoneNumberError !== false &&
                                <span className="mt-2 invalid-feedback">{userPhoneNumberError}</span>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                        <label className="form-label" htmlFor="country">Geographical Area <span className="text-primary">*</span></label>
                        <select className={"form-select " + (userAreaError !== undefined && userAreaError !== false ? 'is-invalid' : (userAreaError === false ? 'is-valid' : ''))} id="country" placeholder="Your area" aria-label="Your area" value={userArea} onChange={setUserAreaValue} disabled={isLoadingButton}>
                            {areaOptions.map(areaOption => (
                                <option key={'geographic-area-' + areaOption.value} value={areaOption.value}>
                                    {areaOption.label}
                                </option>
                            ))}
                        </select>
                        {userAreaError !== undefined && userAreaError !== false &&
                            <span className="mt-2 invalid-feedback">{userAreaError}</span>
                        }
                        </div>
                    </div>
                    <hr />
                    <h3 className="mt-10 mb-2">Your Device</h3>
                    <div className="col-12">
                        <label className="form-label" htmlFor="device_name">Name <span className="text-primary">*</span></label>
                        <input type="text" className={"form-control " + (deviceNameError !== undefined && deviceNameError !== false ? 'is-invalid' : (deviceNameError === false ? 'is-valid' : ''))} id="device_name" value={deviceName} onChange={setDeviceNameValue} disabled={isLoadingButton} />
                        {deviceNameError !== undefined && deviceNameError !== false &&
                            <span className="mt-2 invalid-feedback">{deviceNameError}</span>
                        }
                    </div>
                    <div className="col-12">
                        <label className="form-label" htmlFor="device_intended_use">Intended Use <span className="text-primary">*</span></label>
                        <textarea className={"form-control " + (deviceIntendedUseError !== undefined && deviceIntendedUseError !== false ? 'is-invalid' : (deviceIntendedUseError === false ? 'is-valid' : ''))} id="device_intended_use" value={deviceIntendedUse} onChange={setDeviceIntendedUseValue} disabled={isLoadingButton} />
                        {deviceIntendedUseError !== undefined && deviceIntendedUseError !== false &&
                            <span className="mt-2 invalid-feedback">{deviceIntendedUseError}</span>
                        }
                    </div>
                    <div className="col-12">
                        <label className="form-label" htmlFor="device_description">Description <span className="text-primary">*</span></label>
                        <textarea className={"form-control " + (deviceDescriptionError !== undefined && deviceDescriptionError !== false ? 'is-invalid' : (deviceDescriptionError === false ? 'is-valid' : ''))} id="device_description" value={deviceDescription} onChange={setDeviceDescriptionValue} disabled={isLoadingButton} />
                        {deviceDescriptionError !== undefined && deviceDescriptionError !== false &&
                            <span className="mt-2 invalid-feedback">{deviceDescriptionError}</span>
                        }
                    </div>
                    <hr />
                    <h3 className="mt-10 mb-2">Your Company</h3>
                    <div className="col-12">
                        <label className="form-label" htmlFor="company_name">Name</label>
                        <input type="text" className={"form-control " + (companyNameError !== undefined && companyNameError !== false ? 'is-invalid' : (companyNameError === false ? 'is-valid' : ''))} id="company_name" value={companyName} onChange={setCompanyNameValue} disabled={isLoadingButton} />
                        {companyNameError !== undefined && companyNameError !== false &&
                            <span className="mt-2 invalid-feedback">{companyNameError}</span>
                        }
                    </div>
                    <div className="col-12">
                        <label className="form-label" htmlFor="company_registration_number">Registration Number</label>
                        <input type="text" className={"form-control " + (companyRegistrationNumberError !== undefined && companyRegistrationNumberError !== false ? 'is-invalid' : (companyRegistrationNumberError === false ? 'is-valid' : ''))} id="company_name" value={companyRegistrationNumber} onChange={setCompanyRegistrationNumberValue} disabled={isLoadingButton} />
                        {companyRegistrationNumberError !== undefined && companyRegistrationNumberError !== false &&
                            <span className="mt-2 invalid-feedback">{companyRegistrationNumberError}</span>
                        }
                    </div>
                </div>
            </form>
            <p className="mt-10 mb-4">By clicking, you agree that <b>medflow</b> will store all provided company, person, and device info, and further contact you by email or phone.</p>
            {isLoadingButton &&
                <a role="button" className="btn btn-disabled">Creating Report...</a>
            }
            {!isLoadingButton &&
                <a role="button" className={hasAllInfo ? "btn btn-primary": "btn btn-disabled"} onClick={() => hasAllInfo && uploadInformation()}>See Report</a>
            }
        </div>
    )
}