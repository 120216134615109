/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      name
      version
      valueString
      valueInt
      valueFloat
      valueBoolean
      readers
      editors
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      product {
        id
        name
        description
        productCode
        gmdnDescription
        deviceClassification
        readers
        editors
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        productMedflowVersionId
        owner
      }
      rowColumnAnswers {
        items {
          id
          name
          version
          readers
          editors
          valueString
          valueInt
          valueFloat
          valueBoolean
          createdAt
          updatedAt
          answerRowColumnAnswersId
          rowColumnAnswerQuestionRowColumnId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      productAnswersId
      answerQuestionId
      owner
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      name
      version
      valueString
      valueInt
      valueFloat
      valueBoolean
      readers
      editors
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      product {
        id
        name
        description
        productCode
        gmdnDescription
        deviceClassification
        readers
        editors
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        productMedflowVersionId
        owner
      }
      rowColumnAnswers {
        items {
          id
          name
          version
          readers
          editors
          valueString
          valueInt
          valueFloat
          valueBoolean
          createdAt
          updatedAt
          answerRowColumnAnswersId
          rowColumnAnswerQuestionRowColumnId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      productAnswersId
      answerQuestionId
      owner
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      name
      version
      valueString
      valueInt
      valueFloat
      valueBoolean
      readers
      editors
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      product {
        id
        name
        description
        productCode
        gmdnDescription
        deviceClassification
        readers
        editors
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
        createdAt
        updatedAt
        productMedflowVersionId
        owner
      }
      rowColumnAnswers {
        items {
          id
          name
          version
          readers
          editors
          valueString
          valueInt
          valueFloat
          valueBoolean
          createdAt
          updatedAt
          answerRowColumnAnswersId
          rowColumnAnswerQuestionRowColumnId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      productAnswersId
      answerQuestionId
      owner
    }
  }
`;
export const createDeviceClassificationExpression = /* GraphQL */ `
  mutation CreateDeviceClassificationExpression(
    $input: CreateDeviceClassificationExpressionInput!
    $condition: ModelDeviceClassificationExpressionConditionInput
  ) {
    createDeviceClassificationExpression(input: $input, condition: $condition) {
      id
      name
      category
      description
      index
      isRoot
      statement
      tooltip
      explanations
      notes
      classificationIfYes
      operator
      specialQuestionCategory
      yesNoQuestion {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      parentDeviceClassificationExpression {
        id
        name
        category
        description
        index
        isRoot
        statement
        tooltip
        explanations
        notes
        classificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceClassificationExpression {
          id
          name
          category
          description
          index
          isRoot
          statement
          tooltip
          explanations
          notes
          classificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
          deviceClassificationExpressionYesNoQuestionId
        }
        childrenDeviceClassificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
        deviceClassificationExpressionYesNoQuestionId
      }
      childrenDeviceClassificationExpressions {
        items {
          id
          name
          category
          description
          index
          isRoot
          statement
          tooltip
          explanations
          notes
          classificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
          deviceClassificationExpressionYesNoQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
      deviceClassificationExpressionYesNoQuestionId
    }
  }
`;
export const updateDeviceClassificationExpression = /* GraphQL */ `
  mutation UpdateDeviceClassificationExpression(
    $input: UpdateDeviceClassificationExpressionInput!
    $condition: ModelDeviceClassificationExpressionConditionInput
  ) {
    updateDeviceClassificationExpression(input: $input, condition: $condition) {
      id
      name
      category
      description
      index
      isRoot
      statement
      tooltip
      explanations
      notes
      classificationIfYes
      operator
      specialQuestionCategory
      yesNoQuestion {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      parentDeviceClassificationExpression {
        id
        name
        category
        description
        index
        isRoot
        statement
        tooltip
        explanations
        notes
        classificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceClassificationExpression {
          id
          name
          category
          description
          index
          isRoot
          statement
          tooltip
          explanations
          notes
          classificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
          deviceClassificationExpressionYesNoQuestionId
        }
        childrenDeviceClassificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
        deviceClassificationExpressionYesNoQuestionId
      }
      childrenDeviceClassificationExpressions {
        items {
          id
          name
          category
          description
          index
          isRoot
          statement
          tooltip
          explanations
          notes
          classificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
          deviceClassificationExpressionYesNoQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
      deviceClassificationExpressionYesNoQuestionId
    }
  }
`;
export const deleteDeviceClassificationExpression = /* GraphQL */ `
  mutation DeleteDeviceClassificationExpression(
    $input: DeleteDeviceClassificationExpressionInput!
    $condition: ModelDeviceClassificationExpressionConditionInput
  ) {
    deleteDeviceClassificationExpression(input: $input, condition: $condition) {
      id
      name
      category
      description
      index
      isRoot
      statement
      tooltip
      explanations
      notes
      classificationIfYes
      operator
      specialQuestionCategory
      yesNoQuestion {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      parentDeviceClassificationExpression {
        id
        name
        category
        description
        index
        isRoot
        statement
        tooltip
        explanations
        notes
        classificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceClassificationExpression {
          id
          name
          category
          description
          index
          isRoot
          statement
          tooltip
          explanations
          notes
          classificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
          deviceClassificationExpressionYesNoQuestionId
        }
        childrenDeviceClassificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
        deviceClassificationExpressionYesNoQuestionId
      }
      childrenDeviceClassificationExpressions {
        items {
          id
          name
          category
          description
          index
          isRoot
          statement
          tooltip
          explanations
          notes
          classificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
          deviceClassificationExpressionYesNoQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      deviceClassificationExpressionChildrenDeviceClassificationExpressionsId
      deviceClassificationExpressionYesNoQuestionId
    }
  }
`;
export const createDeviceQualificationExpression = /* GraphQL */ `
  mutation CreateDeviceQualificationExpression(
    $input: CreateDeviceQualificationExpressionInput!
    $condition: ModelDeviceQualificationExpressionConditionInput
  ) {
    createDeviceQualificationExpression(input: $input, condition: $condition) {
      id
      name
      description
      index
      isRoot
      statement
      qualificationIfYes
      operator
      specialQuestionCategory
      yesNoQuestion {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      parentDeviceQualificationExpression {
        id
        name
        description
        index
        isRoot
        statement
        qualificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceQualificationExpression {
          id
          name
          description
          index
          isRoot
          statement
          qualificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
          deviceQualificationExpressionYesNoQuestionId
        }
        childrenDeviceQualificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
        deviceQualificationExpressionYesNoQuestionId
      }
      childrenDeviceQualificationExpressions {
        items {
          id
          name
          description
          index
          isRoot
          statement
          qualificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
          deviceQualificationExpressionYesNoQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
      deviceQualificationExpressionYesNoQuestionId
    }
  }
`;
export const updateDeviceQualificationExpression = /* GraphQL */ `
  mutation UpdateDeviceQualificationExpression(
    $input: UpdateDeviceQualificationExpressionInput!
    $condition: ModelDeviceQualificationExpressionConditionInput
  ) {
    updateDeviceQualificationExpression(input: $input, condition: $condition) {
      id
      name
      description
      index
      isRoot
      statement
      qualificationIfYes
      operator
      specialQuestionCategory
      yesNoQuestion {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      parentDeviceQualificationExpression {
        id
        name
        description
        index
        isRoot
        statement
        qualificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceQualificationExpression {
          id
          name
          description
          index
          isRoot
          statement
          qualificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
          deviceQualificationExpressionYesNoQuestionId
        }
        childrenDeviceQualificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
        deviceQualificationExpressionYesNoQuestionId
      }
      childrenDeviceQualificationExpressions {
        items {
          id
          name
          description
          index
          isRoot
          statement
          qualificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
          deviceQualificationExpressionYesNoQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
      deviceQualificationExpressionYesNoQuestionId
    }
  }
`;
export const deleteDeviceQualificationExpression = /* GraphQL */ `
  mutation DeleteDeviceQualificationExpression(
    $input: DeleteDeviceQualificationExpressionInput!
    $condition: ModelDeviceQualificationExpressionConditionInput
  ) {
    deleteDeviceQualificationExpression(input: $input, condition: $condition) {
      id
      name
      description
      index
      isRoot
      statement
      qualificationIfYes
      operator
      specialQuestionCategory
      yesNoQuestion {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      parentDeviceQualificationExpression {
        id
        name
        description
        index
        isRoot
        statement
        qualificationIfYes
        operator
        specialQuestionCategory
        yesNoQuestion {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        parentDeviceQualificationExpression {
          id
          name
          description
          index
          isRoot
          statement
          qualificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
          deviceQualificationExpressionYesNoQuestionId
        }
        childrenDeviceQualificationExpressions {
          nextToken
        }
        createdAt
        updatedAt
        deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
        deviceQualificationExpressionYesNoQuestionId
      }
      childrenDeviceQualificationExpressions {
        items {
          id
          name
          description
          index
          isRoot
          statement
          qualificationIfYes
          operator
          specialQuestionCategory
          createdAt
          updatedAt
          deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
          deviceQualificationExpressionYesNoQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      deviceQualificationExpressionChildrenDeviceQualificationExpressionsId
      deviceQualificationExpressionYesNoQuestionId
    }
  }
`;
export const createEntityTemplate = /* GraphQL */ `
  mutation CreateEntityTemplate(
    $input: CreateEntityTemplateInput!
    $condition: ModelEntityTemplateConditionInput
  ) {
    createEntityTemplate(input: $input, condition: $condition) {
      id
      name
      valueInt
      valueFloat
      valueBoolean
      entityEnum
      entityTemplateHasOne {
        id
        name
        createdAt
        updatedAt
      }
      entityTemplateBelongsTo {
        id
        name
        entityTemplates {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      entityTemplateBelongsToEntityTemplatesId
      entityTemplateEntityTemplateHasOneId
    }
  }
`;
export const updateEntityTemplate = /* GraphQL */ `
  mutation UpdateEntityTemplate(
    $input: UpdateEntityTemplateInput!
    $condition: ModelEntityTemplateConditionInput
  ) {
    updateEntityTemplate(input: $input, condition: $condition) {
      id
      name
      valueInt
      valueFloat
      valueBoolean
      entityEnum
      entityTemplateHasOne {
        id
        name
        createdAt
        updatedAt
      }
      entityTemplateBelongsTo {
        id
        name
        entityTemplates {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      entityTemplateBelongsToEntityTemplatesId
      entityTemplateEntityTemplateHasOneId
    }
  }
`;
export const deleteEntityTemplate = /* GraphQL */ `
  mutation DeleteEntityTemplate(
    $input: DeleteEntityTemplateInput!
    $condition: ModelEntityTemplateConditionInput
  ) {
    deleteEntityTemplate(input: $input, condition: $condition) {
      id
      name
      valueInt
      valueFloat
      valueBoolean
      entityEnum
      entityTemplateHasOne {
        id
        name
        createdAt
        updatedAt
      }
      entityTemplateBelongsTo {
        id
        name
        entityTemplates {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      entityTemplateBelongsToEntityTemplatesId
      entityTemplateEntityTemplateHasOneId
    }
  }
`;
export const createEntityTemplateBelongsTo = /* GraphQL */ `
  mutation CreateEntityTemplateBelongsTo(
    $input: CreateEntityTemplateBelongsToInput!
    $condition: ModelEntityTemplateBelongsToConditionInput
  ) {
    createEntityTemplateBelongsTo(input: $input, condition: $condition) {
      id
      name
      entityTemplates {
        items {
          id
          name
          valueInt
          valueFloat
          valueBoolean
          entityEnum
          createdAt
          updatedAt
          entityTemplateBelongsToEntityTemplatesId
          entityTemplateEntityTemplateHasOneId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEntityTemplateBelongsTo = /* GraphQL */ `
  mutation UpdateEntityTemplateBelongsTo(
    $input: UpdateEntityTemplateBelongsToInput!
    $condition: ModelEntityTemplateBelongsToConditionInput
  ) {
    updateEntityTemplateBelongsTo(input: $input, condition: $condition) {
      id
      name
      entityTemplates {
        items {
          id
          name
          valueInt
          valueFloat
          valueBoolean
          entityEnum
          createdAt
          updatedAt
          entityTemplateBelongsToEntityTemplatesId
          entityTemplateEntityTemplateHasOneId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntityTemplateBelongsTo = /* GraphQL */ `
  mutation DeleteEntityTemplateBelongsTo(
    $input: DeleteEntityTemplateBelongsToInput!
    $condition: ModelEntityTemplateBelongsToConditionInput
  ) {
    deleteEntityTemplateBelongsTo(input: $input, condition: $condition) {
      id
      name
      entityTemplates {
        items {
          id
          name
          valueInt
          valueFloat
          valueBoolean
          entityEnum
          createdAt
          updatedAt
          entityTemplateBelongsToEntityTemplatesId
          entityTemplateEntityTemplateHasOneId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEntityTemplateHasOne = /* GraphQL */ `
  mutation CreateEntityTemplateHasOne(
    $input: CreateEntityTemplateHasOneInput!
    $condition: ModelEntityTemplateHasOneConditionInput
  ) {
    createEntityTemplateHasOne(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateEntityTemplateHasOne = /* GraphQL */ `
  mutation UpdateEntityTemplateHasOne(
    $input: UpdateEntityTemplateHasOneInput!
    $condition: ModelEntityTemplateHasOneConditionInput
  ) {
    updateEntityTemplateHasOne(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntityTemplateHasOne = /* GraphQL */ `
  mutation DeleteEntityTemplateHasOne(
    $input: DeleteEntityTemplateHasOneInput!
    $condition: ModelEntityTemplateHasOneConditionInput
  ) {
    deleteEntityTemplateHasOne(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createLabel = /* GraphQL */ `
  mutation CreateLabel(
    $input: CreateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    createLabel(input: $input, condition: $condition) {
      id
      name
      questionLabels {
        items {
          id
          name
          createdAt
          updatedAt
          labelQuestionLabelsId
          questionQuestionLabelsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLabel = /* GraphQL */ `
  mutation UpdateLabel(
    $input: UpdateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    updateLabel(input: $input, condition: $condition) {
      id
      name
      questionLabels {
        items {
          id
          name
          createdAt
          updatedAt
          labelQuestionLabelsId
          questionQuestionLabelsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLabel = /* GraphQL */ `
  mutation DeleteLabel(
    $input: DeleteLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    deleteLabel(input: $input, condition: $condition) {
      id
      name
      questionLabels {
        items {
          id
          name
          createdAt
          updatedAt
          labelQuestionLabelsId
          questionQuestionLabelsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMedflowVersion = /* GraphQL */ `
  mutation CreateMedflowVersion(
    $input: CreateMedflowVersionInput!
    $condition: ModelMedflowVersionConditionInput
  ) {
    createMedflowVersion(input: $input, condition: $condition) {
      id
      name
      number
      createdAt
      updatedAt
    }
  }
`;
export const updateMedflowVersion = /* GraphQL */ `
  mutation UpdateMedflowVersion(
    $input: UpdateMedflowVersionInput!
    $condition: ModelMedflowVersionConditionInput
  ) {
    updateMedflowVersion(input: $input, condition: $condition) {
      id
      name
      number
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedflowVersion = /* GraphQL */ `
  mutation DeleteMedflowVersion(
    $input: DeleteMedflowVersionInput!
    $condition: ModelMedflowVersionConditionInput
  ) {
    deleteMedflowVersion(input: $input, condition: $condition) {
      id
      name
      number
      createdAt
      updatedAt
    }
  }
`;
export const createPhase = /* GraphQL */ `
  mutation CreatePhase(
    $input: CreatePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    createPhase(input: $input, condition: $condition) {
      id
      name
      order
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      stages {
        items {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        nextToken
      }
      createdAt
      updatedAt
      phaseMedflowVersionId
    }
  }
`;
export const updatePhase = /* GraphQL */ `
  mutation UpdatePhase(
    $input: UpdatePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    updatePhase(input: $input, condition: $condition) {
      id
      name
      order
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      stages {
        items {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        nextToken
      }
      createdAt
      updatedAt
      phaseMedflowVersionId
    }
  }
`;
export const deletePhase = /* GraphQL */ `
  mutation DeletePhase(
    $input: DeletePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    deletePhase(input: $input, condition: $condition) {
      id
      name
      order
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      stages {
        items {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        nextToken
      }
      createdAt
      updatedAt
      phaseMedflowVersionId
    }
  }
`;
export const createPreviewAnswer = /* GraphQL */ `
  mutation CreatePreviewAnswer(
    $input: CreatePreviewAnswerInput!
    $condition: ModelPreviewAnswerConditionInput
  ) {
    createPreviewAnswer(input: $input, condition: $condition) {
      id
      name
      valueString
      valueInt
      valueFloat
      valueBoolean
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      previewProduct {
        id
        name
        intendedUse
        description
        previewUser {
          id
          name
          email
          phoneNumber
          area
          createdAt
          updatedAt
          previewOrganizationPreviewUsersId
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        previewAnswers {
          nextToken
        }
        createdAt
        updatedAt
        previewOrganizationPreviewProductsId
        previewUserPreviewProductsId
      }
      createdAt
      updatedAt
      previewProductPreviewAnswersId
      previewAnswerQuestionId
    }
  }
`;
export const updatePreviewAnswer = /* GraphQL */ `
  mutation UpdatePreviewAnswer(
    $input: UpdatePreviewAnswerInput!
    $condition: ModelPreviewAnswerConditionInput
  ) {
    updatePreviewAnswer(input: $input, condition: $condition) {
      id
      name
      valueString
      valueInt
      valueFloat
      valueBoolean
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      previewProduct {
        id
        name
        intendedUse
        description
        previewUser {
          id
          name
          email
          phoneNumber
          area
          createdAt
          updatedAt
          previewOrganizationPreviewUsersId
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        previewAnswers {
          nextToken
        }
        createdAt
        updatedAt
        previewOrganizationPreviewProductsId
        previewUserPreviewProductsId
      }
      createdAt
      updatedAt
      previewProductPreviewAnswersId
      previewAnswerQuestionId
    }
  }
`;
export const deletePreviewAnswer = /* GraphQL */ `
  mutation DeletePreviewAnswer(
    $input: DeletePreviewAnswerInput!
    $condition: ModelPreviewAnswerConditionInput
  ) {
    deletePreviewAnswer(input: $input, condition: $condition) {
      id
      name
      valueString
      valueInt
      valueFloat
      valueBoolean
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      previewProduct {
        id
        name
        intendedUse
        description
        previewUser {
          id
          name
          email
          phoneNumber
          area
          createdAt
          updatedAt
          previewOrganizationPreviewUsersId
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        previewAnswers {
          nextToken
        }
        createdAt
        updatedAt
        previewOrganizationPreviewProductsId
        previewUserPreviewProductsId
      }
      createdAt
      updatedAt
      previewProductPreviewAnswersId
      previewAnswerQuestionId
    }
  }
`;
export const createPreviewProduct = /* GraphQL */ `
  mutation CreatePreviewProduct(
    $input: CreatePreviewProductInput!
    $condition: ModelPreviewProductConditionInput
  ) {
    createPreviewProduct(input: $input, condition: $condition) {
      id
      name
      intendedUse
      description
      previewUser {
        id
        name
        email
        phoneNumber
        area
        previewProducts {
          nextToken
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        previewOrganizationPreviewUsersId
      }
      previewOrganization {
        id
        name
        registrationNumber
        previewUsers {
          nextToken
        }
        previewProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      previewAnswers {
        items {
          id
          name
          valueString
          valueInt
          valueFloat
          valueBoolean
          createdAt
          updatedAt
          previewProductPreviewAnswersId
          previewAnswerQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      previewOrganizationPreviewProductsId
      previewUserPreviewProductsId
    }
  }
`;
export const updatePreviewProduct = /* GraphQL */ `
  mutation UpdatePreviewProduct(
    $input: UpdatePreviewProductInput!
    $condition: ModelPreviewProductConditionInput
  ) {
    updatePreviewProduct(input: $input, condition: $condition) {
      id
      name
      intendedUse
      description
      previewUser {
        id
        name
        email
        phoneNumber
        area
        previewProducts {
          nextToken
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        previewOrganizationPreviewUsersId
      }
      previewOrganization {
        id
        name
        registrationNumber
        previewUsers {
          nextToken
        }
        previewProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      previewAnswers {
        items {
          id
          name
          valueString
          valueInt
          valueFloat
          valueBoolean
          createdAt
          updatedAt
          previewProductPreviewAnswersId
          previewAnswerQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      previewOrganizationPreviewProductsId
      previewUserPreviewProductsId
    }
  }
`;
export const deletePreviewProduct = /* GraphQL */ `
  mutation DeletePreviewProduct(
    $input: DeletePreviewProductInput!
    $condition: ModelPreviewProductConditionInput
  ) {
    deletePreviewProduct(input: $input, condition: $condition) {
      id
      name
      intendedUse
      description
      previewUser {
        id
        name
        email
        phoneNumber
        area
        previewProducts {
          nextToken
        }
        previewOrganization {
          id
          name
          registrationNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        previewOrganizationPreviewUsersId
      }
      previewOrganization {
        id
        name
        registrationNumber
        previewUsers {
          nextToken
        }
        previewProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      previewAnswers {
        items {
          id
          name
          valueString
          valueInt
          valueFloat
          valueBoolean
          createdAt
          updatedAt
          previewProductPreviewAnswersId
          previewAnswerQuestionId
        }
        nextToken
      }
      createdAt
      updatedAt
      previewOrganizationPreviewProductsId
      previewUserPreviewProductsId
    }
  }
`;
export const createPreviewOrganization = /* GraphQL */ `
  mutation CreatePreviewOrganization(
    $input: CreatePreviewOrganizationInput!
    $condition: ModelPreviewOrganizationConditionInput
  ) {
    createPreviewOrganization(input: $input, condition: $condition) {
      id
      name
      registrationNumber
      previewUsers {
        items {
          id
          name
          email
          phoneNumber
          area
          createdAt
          updatedAt
          previewOrganizationPreviewUsersId
        }
        nextToken
      }
      previewProducts {
        items {
          id
          name
          intendedUse
          description
          createdAt
          updatedAt
          previewOrganizationPreviewProductsId
          previewUserPreviewProductsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePreviewOrganization = /* GraphQL */ `
  mutation UpdatePreviewOrganization(
    $input: UpdatePreviewOrganizationInput!
    $condition: ModelPreviewOrganizationConditionInput
  ) {
    updatePreviewOrganization(input: $input, condition: $condition) {
      id
      name
      registrationNumber
      previewUsers {
        items {
          id
          name
          email
          phoneNumber
          area
          createdAt
          updatedAt
          previewOrganizationPreviewUsersId
        }
        nextToken
      }
      previewProducts {
        items {
          id
          name
          intendedUse
          description
          createdAt
          updatedAt
          previewOrganizationPreviewProductsId
          previewUserPreviewProductsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePreviewOrganization = /* GraphQL */ `
  mutation DeletePreviewOrganization(
    $input: DeletePreviewOrganizationInput!
    $condition: ModelPreviewOrganizationConditionInput
  ) {
    deletePreviewOrganization(input: $input, condition: $condition) {
      id
      name
      registrationNumber
      previewUsers {
        items {
          id
          name
          email
          phoneNumber
          area
          createdAt
          updatedAt
          previewOrganizationPreviewUsersId
        }
        nextToken
      }
      previewProducts {
        items {
          id
          name
          intendedUse
          description
          createdAt
          updatedAt
          previewOrganizationPreviewProductsId
          previewUserPreviewProductsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPreviewUser = /* GraphQL */ `
  mutation CreatePreviewUser(
    $input: CreatePreviewUserInput!
    $condition: ModelPreviewUserConditionInput
  ) {
    createPreviewUser(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      area
      previewProducts {
        items {
          id
          name
          intendedUse
          description
          createdAt
          updatedAt
          previewOrganizationPreviewProductsId
          previewUserPreviewProductsId
        }
        nextToken
      }
      previewOrganization {
        id
        name
        registrationNumber
        previewUsers {
          nextToken
        }
        previewProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      previewOrganizationPreviewUsersId
    }
  }
`;
export const updatePreviewUser = /* GraphQL */ `
  mutation UpdatePreviewUser(
    $input: UpdatePreviewUserInput!
    $condition: ModelPreviewUserConditionInput
  ) {
    updatePreviewUser(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      area
      previewProducts {
        items {
          id
          name
          intendedUse
          description
          createdAt
          updatedAt
          previewOrganizationPreviewProductsId
          previewUserPreviewProductsId
        }
        nextToken
      }
      previewOrganization {
        id
        name
        registrationNumber
        previewUsers {
          nextToken
        }
        previewProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      previewOrganizationPreviewUsersId
    }
  }
`;
export const deletePreviewUser = /* GraphQL */ `
  mutation DeletePreviewUser(
    $input: DeletePreviewUserInput!
    $condition: ModelPreviewUserConditionInput
  ) {
    deletePreviewUser(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      area
      previewProducts {
        items {
          id
          name
          intendedUse
          description
          createdAt
          updatedAt
          previewOrganizationPreviewProductsId
          previewUserPreviewProductsId
        }
        nextToken
      }
      previewOrganization {
        id
        name
        registrationNumber
        previewUsers {
          nextToken
        }
        previewProducts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      previewOrganizationPreviewUsersId
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      description
      productCode
      gmdnDescription
      deviceClassification
      readers
      editors
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      answers {
        items {
          id
          name
          version
          valueString
          valueInt
          valueFloat
          valueBoolean
          readers
          editors
          createdAt
          updatedAt
          productAnswersId
          answerQuestionId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      productMedflowVersionId
      owner
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      description
      productCode
      gmdnDescription
      deviceClassification
      readers
      editors
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      answers {
        items {
          id
          name
          version
          valueString
          valueInt
          valueFloat
          valueBoolean
          readers
          editors
          createdAt
          updatedAt
          productAnswersId
          answerQuestionId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      productMedflowVersionId
      owner
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      description
      productCode
      gmdnDescription
      deviceClassification
      readers
      editors
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      answers {
        items {
          id
          name
          version
          valueString
          valueInt
          valueFloat
          valueBoolean
          readers
          editors
          createdAt
          updatedAt
          productAnswersId
          answerQuestionId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      productMedflowVersionId
      owner
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      name
      description
      guidingBeforeText
      placeholder
      statement
      tooltip
      answerDataType
      specialQuestionCategory
      examples
      explanations
      notes
      questionLabels {
        items {
          id
          name
          createdAt
          updatedAt
          labelQuestionLabelsId
          questionQuestionLabelsId
        }
        nextToken
      }
      questionRowColumns {
        items {
          id
          name
          order
          answerDataType
          createdAt
          updatedAt
          questionQuestionRowColumnsId
        }
        nextToken
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      name
      description
      guidingBeforeText
      placeholder
      statement
      tooltip
      answerDataType
      specialQuestionCategory
      examples
      explanations
      notes
      questionLabels {
        items {
          id
          name
          createdAt
          updatedAt
          labelQuestionLabelsId
          questionQuestionLabelsId
        }
        nextToken
      }
      questionRowColumns {
        items {
          id
          name
          order
          answerDataType
          createdAt
          updatedAt
          questionQuestionRowColumnsId
        }
        nextToken
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      name
      description
      guidingBeforeText
      placeholder
      statement
      tooltip
      answerDataType
      specialQuestionCategory
      examples
      explanations
      notes
      questionLabels {
        items {
          id
          name
          createdAt
          updatedAt
          labelQuestionLabelsId
          questionQuestionLabelsId
        }
        nextToken
      }
      questionRowColumns {
        items {
          id
          name
          order
          answerDataType
          createdAt
          updatedAt
          questionQuestionRowColumnsId
        }
        nextToken
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionLabel = /* GraphQL */ `
  mutation CreateQuestionLabel(
    $input: CreateQuestionLabelInput!
    $condition: ModelQuestionLabelConditionInput
  ) {
    createQuestionLabel(input: $input, condition: $condition) {
      id
      name
      label {
        id
        name
        questionLabels {
          nextToken
        }
        createdAt
        updatedAt
      }
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      labelQuestionLabelsId
      questionQuestionLabelsId
    }
  }
`;
export const updateQuestionLabel = /* GraphQL */ `
  mutation UpdateQuestionLabel(
    $input: UpdateQuestionLabelInput!
    $condition: ModelQuestionLabelConditionInput
  ) {
    updateQuestionLabel(input: $input, condition: $condition) {
      id
      name
      label {
        id
        name
        questionLabels {
          nextToken
        }
        createdAt
        updatedAt
      }
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      labelQuestionLabelsId
      questionQuestionLabelsId
    }
  }
`;
export const deleteQuestionLabel = /* GraphQL */ `
  mutation DeleteQuestionLabel(
    $input: DeleteQuestionLabelInput!
    $condition: ModelQuestionLabelConditionInput
  ) {
    deleteQuestionLabel(input: $input, condition: $condition) {
      id
      name
      label {
        id
        name
        questionLabels {
          nextToken
        }
        createdAt
        updatedAt
      }
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      labelQuestionLabelsId
      questionQuestionLabelsId
    }
  }
`;
export const createQuestionRowColumn = /* GraphQL */ `
  mutation CreateQuestionRowColumn(
    $input: CreateQuestionRowColumnInput!
    $condition: ModelQuestionRowColumnConditionInput
  ) {
    createQuestionRowColumn(input: $input, condition: $condition) {
      id
      name
      order
      answerDataType
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      questionQuestionRowColumnsId
    }
  }
`;
export const updateQuestionRowColumn = /* GraphQL */ `
  mutation UpdateQuestionRowColumn(
    $input: UpdateQuestionRowColumnInput!
    $condition: ModelQuestionRowColumnConditionInput
  ) {
    updateQuestionRowColumn(input: $input, condition: $condition) {
      id
      name
      order
      answerDataType
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      questionQuestionRowColumnsId
    }
  }
`;
export const deleteQuestionRowColumn = /* GraphQL */ `
  mutation DeleteQuestionRowColumn(
    $input: DeleteQuestionRowColumnInput!
    $condition: ModelQuestionRowColumnConditionInput
  ) {
    deleteQuestionRowColumn(input: $input, condition: $condition) {
      id
      name
      order
      answerDataType
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      questionQuestionRowColumnsId
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      name
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      reportQuestionnaireSections {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          reportReportQuestionnaireSectionsId
        }
        nextToken
      }
      createdAt
      updatedAt
      stageReportsId
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      name
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      reportQuestionnaireSections {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          reportReportQuestionnaireSectionsId
        }
        nextToken
      }
      createdAt
      updatedAt
      stageReportsId
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      name
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      reportQuestionnaireSections {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          reportReportQuestionnaireSectionsId
        }
        nextToken
      }
      createdAt
      updatedAt
      stageReportsId
    }
  }
`;
export const createReportQuestionnaire = /* GraphQL */ `
  mutation CreateReportQuestionnaire(
    $input: CreateReportQuestionnaireInput!
    $condition: ModelReportQuestionnaireConditionInput
  ) {
    createReportQuestionnaire(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      report {
        id
        name
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        reportQuestionnaires {
          nextToken
        }
        reportQuestionnaireSections {
          nextToken
        }
        createdAt
        updatedAt
        stageReportsId
      }
      reportQuestionnaireSection {
        id
        name
        order
        deviceClassification
        report {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        reportQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        reportReportQuestionnaireSectionsId
      }
      createdAt
      updatedAt
      questionReportQuestionnairesId
      reportReportQuestionnairesId
      reportQuestionnaireSectionReportQuestionnairesId
    }
  }
`;
export const updateReportQuestionnaire = /* GraphQL */ `
  mutation UpdateReportQuestionnaire(
    $input: UpdateReportQuestionnaireInput!
    $condition: ModelReportQuestionnaireConditionInput
  ) {
    updateReportQuestionnaire(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      report {
        id
        name
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        reportQuestionnaires {
          nextToken
        }
        reportQuestionnaireSections {
          nextToken
        }
        createdAt
        updatedAt
        stageReportsId
      }
      reportQuestionnaireSection {
        id
        name
        order
        deviceClassification
        report {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        reportQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        reportReportQuestionnaireSectionsId
      }
      createdAt
      updatedAt
      questionReportQuestionnairesId
      reportReportQuestionnairesId
      reportQuestionnaireSectionReportQuestionnairesId
    }
  }
`;
export const deleteReportQuestionnaire = /* GraphQL */ `
  mutation DeleteReportQuestionnaire(
    $input: DeleteReportQuestionnaireInput!
    $condition: ModelReportQuestionnaireConditionInput
  ) {
    deleteReportQuestionnaire(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      report {
        id
        name
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        reportQuestionnaires {
          nextToken
        }
        reportQuestionnaireSections {
          nextToken
        }
        createdAt
        updatedAt
        stageReportsId
      }
      reportQuestionnaireSection {
        id
        name
        order
        deviceClassification
        report {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        reportQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        reportReportQuestionnaireSectionsId
      }
      createdAt
      updatedAt
      questionReportQuestionnairesId
      reportReportQuestionnairesId
      reportQuestionnaireSectionReportQuestionnairesId
    }
  }
`;
export const createReportQuestionnaireSection = /* GraphQL */ `
  mutation CreateReportQuestionnaireSection(
    $input: CreateReportQuestionnaireSectionInput!
    $condition: ModelReportQuestionnaireSectionConditionInput
  ) {
    createReportQuestionnaireSection(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      report {
        id
        name
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        reportQuestionnaires {
          nextToken
        }
        reportQuestionnaireSections {
          nextToken
        }
        createdAt
        updatedAt
        stageReportsId
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
      reportReportQuestionnaireSectionsId
    }
  }
`;
export const updateReportQuestionnaireSection = /* GraphQL */ `
  mutation UpdateReportQuestionnaireSection(
    $input: UpdateReportQuestionnaireSectionInput!
    $condition: ModelReportQuestionnaireSectionConditionInput
  ) {
    updateReportQuestionnaireSection(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      report {
        id
        name
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        reportQuestionnaires {
          nextToken
        }
        reportQuestionnaireSections {
          nextToken
        }
        createdAt
        updatedAt
        stageReportsId
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
      reportReportQuestionnaireSectionsId
    }
  }
`;
export const deleteReportQuestionnaireSection = /* GraphQL */ `
  mutation DeleteReportQuestionnaireSection(
    $input: DeleteReportQuestionnaireSectionInput!
    $condition: ModelReportQuestionnaireSectionConditionInput
  ) {
    deleteReportQuestionnaireSection(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      report {
        id
        name
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        reportQuestionnaires {
          nextToken
        }
        reportQuestionnaireSections {
          nextToken
        }
        createdAt
        updatedAt
        stageReportsId
      }
      reportQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionReportQuestionnairesId
          reportReportQuestionnairesId
          reportQuestionnaireSectionReportQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
      reportReportQuestionnaireSectionsId
    }
  }
`;
export const createRowColumnAnswer = /* GraphQL */ `
  mutation CreateRowColumnAnswer(
    $input: CreateRowColumnAnswerInput!
    $condition: ModelRowColumnAnswerConditionInput
  ) {
    createRowColumnAnswer(input: $input, condition: $condition) {
      id
      name
      version
      readers
      editors
      valueString
      valueInt
      valueFloat
      valueBoolean
      questionRowColumn {
        id
        name
        order
        answerDataType
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        questionQuestionRowColumnsId
      }
      answer {
        id
        name
        version
        valueString
        valueInt
        valueFloat
        valueBoolean
        readers
        editors
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        product {
          id
          name
          description
          productCode
          gmdnDescription
          deviceClassification
          readers
          editors
          createdAt
          updatedAt
          productMedflowVersionId
          owner
        }
        rowColumnAnswers {
          nextToken
        }
        createdAt
        updatedAt
        productAnswersId
        answerQuestionId
        owner
      }
      createdAt
      updatedAt
      answerRowColumnAnswersId
      rowColumnAnswerQuestionRowColumnId
      owner
    }
  }
`;
export const updateRowColumnAnswer = /* GraphQL */ `
  mutation UpdateRowColumnAnswer(
    $input: UpdateRowColumnAnswerInput!
    $condition: ModelRowColumnAnswerConditionInput
  ) {
    updateRowColumnAnswer(input: $input, condition: $condition) {
      id
      name
      version
      readers
      editors
      valueString
      valueInt
      valueFloat
      valueBoolean
      questionRowColumn {
        id
        name
        order
        answerDataType
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        questionQuestionRowColumnsId
      }
      answer {
        id
        name
        version
        valueString
        valueInt
        valueFloat
        valueBoolean
        readers
        editors
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        product {
          id
          name
          description
          productCode
          gmdnDescription
          deviceClassification
          readers
          editors
          createdAt
          updatedAt
          productMedflowVersionId
          owner
        }
        rowColumnAnswers {
          nextToken
        }
        createdAt
        updatedAt
        productAnswersId
        answerQuestionId
        owner
      }
      createdAt
      updatedAt
      answerRowColumnAnswersId
      rowColumnAnswerQuestionRowColumnId
      owner
    }
  }
`;
export const deleteRowColumnAnswer = /* GraphQL */ `
  mutation DeleteRowColumnAnswer(
    $input: DeleteRowColumnAnswerInput!
    $condition: ModelRowColumnAnswerConditionInput
  ) {
    deleteRowColumnAnswer(input: $input, condition: $condition) {
      id
      name
      version
      readers
      editors
      valueString
      valueInt
      valueFloat
      valueBoolean
      questionRowColumn {
        id
        name
        order
        answerDataType
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        questionQuestionRowColumnsId
      }
      answer {
        id
        name
        version
        valueString
        valueInt
        valueFloat
        valueBoolean
        readers
        editors
        question {
          id
          name
          description
          guidingBeforeText
          placeholder
          statement
          tooltip
          answerDataType
          specialQuestionCategory
          examples
          explanations
          notes
          createdAt
          updatedAt
        }
        product {
          id
          name
          description
          productCode
          gmdnDescription
          deviceClassification
          readers
          editors
          createdAt
          updatedAt
          productMedflowVersionId
          owner
        }
        rowColumnAnswers {
          nextToken
        }
        createdAt
        updatedAt
        productAnswersId
        answerQuestionId
        owner
      }
      createdAt
      updatedAt
      answerRowColumnAnswersId
      rowColumnAnswerQuestionRowColumnId
      owner
    }
  }
`;
export const createStage = /* GraphQL */ `
  mutation CreateStage(
    $input: CreateStageInput!
    $condition: ModelStageConditionInput
  ) {
    createStage(input: $input, condition: $condition) {
      id
      name
      order
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      phase {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        phaseMedflowVersionId
      }
      track {
        id
        name
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        trackMedflowVersionId
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      stageQuestionnaireSections {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          stageStageQuestionnaireSectionsId
        }
        nextToken
      }
      reports {
        items {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        nextToken
      }
      createdAt
      updatedAt
      phaseStagesId
      trackStagesId
      stageMedflowVersionId
    }
  }
`;
export const updateStage = /* GraphQL */ `
  mutation UpdateStage(
    $input: UpdateStageInput!
    $condition: ModelStageConditionInput
  ) {
    updateStage(input: $input, condition: $condition) {
      id
      name
      order
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      phase {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        phaseMedflowVersionId
      }
      track {
        id
        name
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        trackMedflowVersionId
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      stageQuestionnaireSections {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          stageStageQuestionnaireSectionsId
        }
        nextToken
      }
      reports {
        items {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        nextToken
      }
      createdAt
      updatedAt
      phaseStagesId
      trackStagesId
      stageMedflowVersionId
    }
  }
`;
export const deleteStage = /* GraphQL */ `
  mutation DeleteStage(
    $input: DeleteStageInput!
    $condition: ModelStageConditionInput
  ) {
    deleteStage(input: $input, condition: $condition) {
      id
      name
      order
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      phase {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        phaseMedflowVersionId
      }
      track {
        id
        name
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        stages {
          nextToken
        }
        createdAt
        updatedAt
        trackMedflowVersionId
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      stageQuestionnaireSections {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          stageStageQuestionnaireSectionsId
        }
        nextToken
      }
      reports {
        items {
          id
          name
          createdAt
          updatedAt
          stageReportsId
        }
        nextToken
      }
      createdAt
      updatedAt
      phaseStagesId
      trackStagesId
      stageMedflowVersionId
    }
  }
`;
export const createStageQuestionnaire = /* GraphQL */ `
  mutation CreateStageQuestionnaire(
    $input: CreateStageQuestionnaireInput!
    $condition: ModelStageQuestionnaireConditionInput
  ) {
    createStageQuestionnaire(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      stageQuestionnaireSection {
        id
        name
        order
        deviceClassification
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        stageStageQuestionnaireSectionsId
      }
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      createdAt
      updatedAt
      questionStageQuestionnairesId
      stageStageQuestionnairesId
      stageQuestionnaireSectionStageQuestionnairesId
    }
  }
`;
export const updateStageQuestionnaire = /* GraphQL */ `
  mutation UpdateStageQuestionnaire(
    $input: UpdateStageQuestionnaireInput!
    $condition: ModelStageQuestionnaireConditionInput
  ) {
    updateStageQuestionnaire(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      stageQuestionnaireSection {
        id
        name
        order
        deviceClassification
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        stageStageQuestionnaireSectionsId
      }
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      createdAt
      updatedAt
      questionStageQuestionnairesId
      stageStageQuestionnairesId
      stageQuestionnaireSectionStageQuestionnairesId
    }
  }
`;
export const deleteStageQuestionnaire = /* GraphQL */ `
  mutation DeleteStageQuestionnaire(
    $input: DeleteStageQuestionnaireInput!
    $condition: ModelStageQuestionnaireConditionInput
  ) {
    deleteStageQuestionnaire(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      question {
        id
        name
        description
        guidingBeforeText
        placeholder
        statement
        tooltip
        answerDataType
        specialQuestionCategory
        examples
        explanations
        notes
        questionLabels {
          nextToken
        }
        questionRowColumns {
          nextToken
        }
        reportQuestionnaires {
          nextToken
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
      }
      stageQuestionnaireSection {
        id
        name
        order
        deviceClassification
        stage {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        createdAt
        updatedAt
        stageStageQuestionnaireSectionsId
      }
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      createdAt
      updatedAt
      questionStageQuestionnairesId
      stageStageQuestionnairesId
      stageQuestionnaireSectionStageQuestionnairesId
    }
  }
`;
export const createStageQuestionnaireSection = /* GraphQL */ `
  mutation CreateStageQuestionnaireSection(
    $input: CreateStageQuestionnaireSectionInput!
    $condition: ModelStageQuestionnaireSectionConditionInput
  ) {
    createStageQuestionnaireSection(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
      stageStageQuestionnaireSectionsId
    }
  }
`;
export const updateStageQuestionnaireSection = /* GraphQL */ `
  mutation UpdateStageQuestionnaireSection(
    $input: UpdateStageQuestionnaireSectionInput!
    $condition: ModelStageQuestionnaireSectionConditionInput
  ) {
    updateStageQuestionnaireSection(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
      stageStageQuestionnaireSectionsId
    }
  }
`;
export const deleteStageQuestionnaireSection = /* GraphQL */ `
  mutation DeleteStageQuestionnaireSection(
    $input: DeleteStageQuestionnaireSectionInput!
    $condition: ModelStageQuestionnaireSectionConditionInput
  ) {
    deleteStageQuestionnaireSection(input: $input, condition: $condition) {
      id
      name
      order
      deviceClassification
      stage {
        id
        name
        order
        medflowVersion {
          id
          name
          number
          createdAt
          updatedAt
        }
        phase {
          id
          name
          order
          createdAt
          updatedAt
          phaseMedflowVersionId
        }
        track {
          id
          name
          createdAt
          updatedAt
          trackMedflowVersionId
        }
        stageQuestionnaires {
          nextToken
        }
        stageQuestionnaireSections {
          nextToken
        }
        reports {
          nextToken
        }
        createdAt
        updatedAt
        phaseStagesId
        trackStagesId
        stageMedflowVersionId
      }
      stageQuestionnaires {
        items {
          id
          name
          order
          deviceClassification
          createdAt
          updatedAt
          questionStageQuestionnairesId
          stageStageQuestionnairesId
          stageQuestionnaireSectionStageQuestionnairesId
        }
        nextToken
      }
      createdAt
      updatedAt
      stageStageQuestionnaireSectionsId
    }
  }
`;
export const createTrack = /* GraphQL */ `
  mutation CreateTrack(
    $input: CreateTrackInput!
    $condition: ModelTrackConditionInput
  ) {
    createTrack(input: $input, condition: $condition) {
      id
      name
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      stages {
        items {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        nextToken
      }
      createdAt
      updatedAt
      trackMedflowVersionId
    }
  }
`;
export const updateTrack = /* GraphQL */ `
  mutation UpdateTrack(
    $input: UpdateTrackInput!
    $condition: ModelTrackConditionInput
  ) {
    updateTrack(input: $input, condition: $condition) {
      id
      name
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      stages {
        items {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        nextToken
      }
      createdAt
      updatedAt
      trackMedflowVersionId
    }
  }
`;
export const deleteTrack = /* GraphQL */ `
  mutation DeleteTrack(
    $input: DeleteTrackInput!
    $condition: ModelTrackConditionInput
  ) {
    deleteTrack(input: $input, condition: $condition) {
      id
      name
      medflowVersion {
        id
        name
        number
        createdAt
        updatedAt
      }
      stages {
        items {
          id
          name
          order
          createdAt
          updatedAt
          phaseStagesId
          trackStagesId
          stageMedflowVersionId
        }
        nextToken
      }
      createdAt
      updatedAt
      trackMedflowVersionId
    }
  }
`;
