import { FilledEntity } from './FilledEntity';

import {
    CreateStageQuestionnaireSectionInput,
    DeleteStageQuestionnaireSectionInput,
    StageQuestionnaireSection,
    UpdateStageQuestionnaireSectionInput,
} from '../API';
import { EntityName } from './EntityName';

export function getEmptyStageQuestionnaireSectionInstance(): FilledEntity {
    const idKeys = ['id'];
    const stringKeys = ['name'];
    const intKeys = ['order'];
    const deviceClassificationKeys = ['deviceClassification'];

    const entityRelationKeysEntities: Map<string, EntityName> = new Map([
        ['stage', EntityName.Stage],
    ]);

    const entityRelationsKeysEntities: Map<string, EntityName> = new Map([
        ['stageQuestionnaires', EntityName.StageQuestionnaire]
    ]);

    return new FilledEntity({
        idKeys,
        stringKeys,
        intKeys,
        deviceClassificationKeys,
        entityRelationKeysEntities,
        entityRelationsKeysEntities
    });
}

export function getFilledStageQuestionnaireSectionInstance(model: StageQuestionnaireSection): FilledEntity {
    const instance = getEmptyStageQuestionnaireSectionInstance();
    instance.setId('id', model.id);
    instance.addString('name', model.name === null ? undefined : model.name);
    instance.addInt('order', model.order === null ? undefined : model.order);
    instance.addDeviceClassification('deviceClassification', model.deviceClassification === null ? undefined : model.deviceClassification);
    instance.addEntityRelation('stage', { entityName: EntityName.Stage, id: model.stageStageQuestionnaireSectionsId === null ? undefined : model.stageStageQuestionnaireSectionsId});

    const stageQuestionnaireIds = model.stageQuestionnaires === undefined ? [] : model.stageQuestionnaires?.items?.filter(item => item !== undefined && item !== null).map(item => item?.id) as string[];
    instance.addEntityRelations('stageQuestionnaires', {ids: stageQuestionnaireIds, entityName: EntityName.StageQuestionnaire});

    return instance;
}

export function getCreateStageQuestionnaireSectionInput(instance: FilledEntity): CreateStageQuestionnaireSectionInput {
    const name = instance.getStringMandatory('name');
    const order = instance.getIntMandatory('order');
    const deviceClassification = instance.getDeviceClassificationOptional('deviceClassification');
    const stageStageQuestionnaireSectionsId = instance.getEntityRelationOptional('stage')?.id;

    return {
        name,
        order,
        deviceClassification,
        stageStageQuestionnaireSectionsId
    };
}

export function getDeleteStageQuestionnaireSectionInput(instance: FilledEntity): DeleteStageQuestionnaireSectionInput {
    const id = instance.getIdMandatory('id');

    return {
        id
    };
}

export function getUpdateStageQuestionnaireSectionInput(instance: FilledEntity): UpdateStageQuestionnaireSectionInput {
    const id = instance.getIdMandatory('id');
    const name = instance.getStringOptional('name');
    const order = instance.getIntOptional('order');
    const deviceClassification = instance.getDeviceClassificationOptional('deviceClassification');
    const stageStageQuestionnaireSectionsId = instance.getEntityRelationOptional('stage')?.id;

    return {
        id,
        name,
        order,
        deviceClassification,
        stageStageQuestionnaireSectionsId
    };
}