import React, { useState } from "react";
import { EntitySelector } from "./EntitySelector"
import { EntityLister } from "../common/EntityLister";
import { EntityViewer } from "../common/EntityViewer"
import { EntityCreator } from "../common/EntityCreator"
import { EntityEditor } from "../common/EntityEditor"
import { EntityRemover } from "../common/EntityRemover"
import { EntityName } from "../../model/EntityName";

enum StackAction {
    List,
    View,
    Create,
    Edit,
    Remove
}

interface StackElement {
    entityName: EntityName,
    action: StackAction,
    id?: string
}

interface EditorPanelProps {
    userGroups: string[]
}

export const EditorPanel: React.FC<EditorPanelProps> = ({ userGroups }) => {
    const initialStack: StackElement[] = []
    const [stack, setStack] = useState(initialStack);

    function setEntityName(entityNameString: string): void {
        const entityName = stringToEnum(entityNameString, EntityName)
        
        if (entityName === undefined) {
            return
        }

        const stackElement: StackElement = {
            entityName: entityName,
            action: StackAction.List
        }

        setStack([stackElement])
    }

    function stringToEnum<T>(str: string|undefined, enumType: T): T[keyof T] | undefined {
        if (str === undefined) {
            return undefined
        }

        return enumType[str as keyof T];
    }

    function setEntityComponent(entityName: EntityName, action: StackAction): void {
        const hasElement = stack.some(stackElement =>
            stackElement.action === action && stackElement.entityName === entityName
        )

        if (hasElement) {
            return
        }

        const stackElement: StackElement = {
            entityName: entityName,
            action: action
        }

        setStack([...stack, stackElement])
    }

    function setEntityComponentWithId(entityName: EntityName, id: string, action: StackAction): void {
        const hasElement = stack.some(stackElement =>
            stackElement.action === action && stackElement.entityName === entityName && stackElement.id === id
        )

        if (hasElement) {
            return
        }

        const stackElement: StackElement = {
            entityName: entityName,
            action: action,
            id: id
        }

        setStack([...stack, stackElement])
    }

    function setEntityViewer(entityName: EntityName, id: string): void {
        setEntityComponentWithId(entityName, id, StackAction.View)
    }

    function setEntityCreator(entityName: EntityName): void {
        setEntityComponent(entityName, StackAction.Create)
    }

    function setEntityEditor(entityName: EntityName, id: string): void {
        setEntityComponentWithId(entityName, id, StackAction.Edit)
    }

    function setEntityRemover(entityName: EntityName, id: string): void {
        setEntityComponentWithId(entityName, id, StackAction.Remove)
    }

    function popEntityComponentWithId(entityName: EntityName, id: String, action: StackAction, discardElement: boolean): void {
        const index = stack.findIndex(stackElement =>
            stackElement.action === action && stackElement.entityName === entityName && stackElement.id === id
        )

        const stackFiltered = stack.slice(0, index + (discardElement ? 0: 1))
        setStack(stackFiltered)
    }

    function popEntityComponent(entityName: EntityName, action: StackAction, discardElement: boolean): void {
        const index = stack.findIndex(stackElement =>
            stackElement.action === action && stackElement.entityName === entityName
        )

        const stackFiltered = stack.slice(0, index + (discardElement ? 0: 1))
        setStack(stackFiltered)
    }

    function discardEntityViewer(entityName: EntityName, id: string): void {
        popEntityComponentWithId(entityName, id, StackAction.View, true)
    }

    function discardEntityCreator(entityName: EntityName): void {
        popEntityComponent(entityName, StackAction.Edit, true)
    }

    function discardEntityEditor(entityName: EntityName, id: string): void {
        popEntityComponentWithId(entityName, id, StackAction.Edit, true)
    }

    function discardEntityRemover(entityName: EntityName, id: string): void {
        popEntityComponentWithId(entityName, id, StackAction.Remove, true)
    }

    return (
        <div className="panel container max-w-screen-xl my-8">
           <h1 className="text-4xl font-semibold text-primary">Editor Panel</h1>
            <hr />
            <EntitySelector setEntityName={setEntityName} />
            <hr />
            {stack.length > 0 &&
                <div className="card mb-4">
                    <div className="card-body">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item text-black">Navigation</li>
                                {
                                    stack.slice(0, stack.length).map((stackElement, index) => {
                                        return (
                                            <li className="breadcrumb-item" key={stackElement.entityName + StackAction[stackElement.action]}>
                                                {
                                                    index < stack.length - 1 && 
                                                        <a href="#" onClick={
                                                            () => {
                                                                if (stackElement.id) {
                                                                    popEntityComponentWithId(stackElement.entityName, stackElement.id, stackElement.action, false)
                                                                } else {
                                                                    popEntityComponent(stackElement.entityName, stackElement.action, false)
                                                                }
                                                            }
                                                        }>{StackAction[stackElement.action]} {stackElement.entityName} {stackElement.id}</a>
                                                }
                                                {
                                                    index >= stack.length - 1 && 
                                                    <a onClick={
                                                        () => {
                                                            if (stackElement.id) {
                                                                popEntityComponentWithId(stackElement.entityName, stackElement.id, stackElement.action, false)
                                                            } else {
                                                                popEntityComponent(stackElement.entityName, stackElement.action, false)
                                                            }
                                                        }
                                                    }>{StackAction[stackElement.action]} {stackElement.entityName} {stackElement.id}</a>
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ol>
                        </nav>
                    </div>
                </div>
            }
            {
                stack.slice(stack.length - 1, stack.length).map(stackElement => {
                    switch (stackElement.action) {
                        case StackAction.List:
                            return <EntityLister
                                key={stackElement.entityName + StackAction[stackElement.action]}
                                entityName={stackElement.entityName}
                                setEntityCreator={setEntityCreator}
                                setEntityViewer={setEntityViewer}
                                setEntityEditor={setEntityEditor}
                                setEntityRemover={setEntityRemover}
                            />

                        case StackAction.View:
                            if (stackElement.id !== undefined) {
                                return <EntityViewer key={stackElement.entityName + StackAction[stackElement.action] + stackElement.id}
                                    id={stackElement.id}
                                    entityName={stackElement.entityName}
                                    setEntityCreator={setEntityCreator}
                                    setEntityViewer={setEntityViewer}
                                    setEntityEditor={setEntityEditor}
                                    setEntityRemover={setEntityRemover}
                                    discardEntityViewer={discardEntityViewer}
                                />
                            }

                            return null

                        case StackAction.Edit:
                            if (stackElement.id !== undefined) {
                                return <EntityEditor key={stackElement.entityName + StackAction[stackElement.action] + stackElement.id}
                                    id={stackElement.id}
                                    entityName={stackElement.entityName}
                                    setEntityViewer={setEntityViewer}
                                    setEntityRemover={setEntityRemover}
                                    discardEntityEditor={discardEntityEditor}
                                />
                            }

                            return null

                        case StackAction.Remove:
                            if (stackElement.id !== undefined) {
                                return <EntityRemover key={stackElement.entityName + StackAction[stackElement.action] + stackElement.id}
                                    id={stackElement.id}
                                    entityName={stackElement.entityName}
                                    discardEntityRemover={discardEntityRemover}
                                />
                            }

                            return null

                        case StackAction.Create:
                            return <EntityCreator key={stackElement.entityName + StackAction[stackElement.action]}
                                userGroups={userGroups}
                                entityName={stackElement.entityName}
                                discardEntityCreator={discardEntityCreator}
                            />
                    }
                })
            }
        </div>
    )
}