import React from 'react'
import { EntityName } from '../../model/EntityName'
import { GenericViewer } from '../mixins/GenericViewer'

interface EntityViewerProps {
    entityName: EntityName
    id: string
    setEntityCreator: (entityName: EntityName) => void
    setEntityViewer: (entityName: EntityName, id: string) => void
    setEntityEditor: (entityName: EntityName, id: string) => void
    setEntityRemover: (entityName: EntityName, id: string) => void
    discardEntityViewer: (entityName: EntityName, id: string) => void
}

export const EntityViewer: React.FC<EntityViewerProps> = ({ entityName, id, setEntityCreator, setEntityViewer, setEntityEditor, setEntityRemover, discardEntityViewer }) => {
    function cancel(event: React.MouseEvent<HTMLButtonElement>): void {
        event.preventDefault()
        discardEntityViewer(entityName, id)
    }
    
    return (
        <div className="card">
            <div className="card-body">
                <GenericViewer entityName={entityName} id={id} discardEntityViewer={discardEntityViewer}  setEntityCreator={setEntityCreator} setEntityEditor={setEntityEditor} setEntityRemover={setEntityRemover} setEntityViewer={setEntityViewer}/>
            </div>
            <div className="card-footer">
                <button className="btn btn-primary btn-sm" onClick={cancel}>Cancel</button>
            </div>
        </div>
    )
}