import { DeviceClassificationNode } from '../../../algorithms/DeviceClassifier';
import * as graphQlApi from '../../../API';
import { DeviceClassification, DeviceExpressionOperator } from '../../../API';

function getRuleClassificationExpressions(expressions: {[key: string]: graphQlApi.DeviceClassificationExpression}): graphQlApi.DeviceClassificationExpression[] {
    const expressionsList = Object.values(expressions)

    return expressionsList
        .filter(expression => expression.isRoot)
        .flatMap(expression => getChildrenClassificationExpressions(expression.id, expressionsList, true))
}

function getRootClassificationExpressions(expressions: {[key: string]: graphQlApi.DeviceClassificationExpression}, sorted: boolean): graphQlApi.DeviceClassificationExpression[] {
    const rootExpressions = Object.entries(expressions)
        .filter(([_, expression]) => expression.isRoot)
        .map(([_, expression]) => expression)

    if (!sorted) {
        return rootExpressions
    }

    return rootExpressions
        .sort(sortClassificationExpressions)
}

function sortClassificationExpressions(expression1: graphQlApi.DeviceClassificationExpression, expression2: graphQlApi.DeviceClassificationExpression): number {
    if (expression1.index === undefined || expression1.index === null) {
        return 0
    }

    if (expression2.index === undefined || expression2.index === null) {
        return 0
    }

    const diff = expression1.index - expression2.index
    return diff
}

function getChildrenClassificationExpressions(expressionId: string, expressions: graphQlApi.DeviceClassificationExpression[], sorted: boolean): graphQlApi.DeviceClassificationExpression[] {
    const childrenExpressions = Object.entries(expressions)
        .map(([_, expression]) => expression)
        .filter(expression => expression.deviceClassificationExpressionChildrenDeviceClassificationExpressionsId === expressionId)

    if (!sorted) {
        return childrenExpressions
    }

    return childrenExpressions
        .sort(sortClassificationExpressions)
}

function _getClassificationQuestionIds(expression: graphQlApi.DeviceClassificationExpression, expressions: graphQlApi.DeviceClassificationExpression[], sorted: boolean): string[] {
    const questionIds: string[] = []
    const yesNoQuestionId = expression.deviceClassificationExpressionYesNoQuestionId !== null ? expression.deviceClassificationExpressionYesNoQuestionId : undefined

    if (yesNoQuestionId !== undefined) {
        questionIds.push(yesNoQuestionId)
    }

    const childrenExpressions = getChildrenClassificationExpressions(expression.id, expressions, sorted)

    childrenExpressions.forEach(childExpression => {
        const childQuestionIds = _getClassificationQuestionIds(childExpression, expressions, sorted)
        questionIds.push(...childQuestionIds)
    })

    return questionIds
}

function _getClassificationRulesAndQuestionIds(expressions: {[key: string]: graphQlApi.DeviceClassificationExpression}, sorted: boolean): [number, string, number, string][] {
    const rootExpressions = getRootClassificationExpressions(expressions, sorted)
    const expressionsList = Object.entries(expressions).map(([_, expression]) => expression)
    var array: [number, string, number, string][] = []

    rootExpressions.forEach((rootExpression) => {
        const childrenExpressions = getChildrenClassificationExpressions(rootExpression.id, expressionsList, sorted)
            
        childrenExpressions.forEach((childExpression, index) => {
            const classificationQuestionIds = _getClassificationQuestionIds(childExpression, expressionsList, sorted)

            classificationQuestionIds.forEach((questionId, jndex) => {
                array.push([index, childExpression.id, jndex, questionId])
            })
        })
    })

    return array
}

function getFirstClassificationExpressionId(expressions: {[key: string]: graphQlApi.DeviceClassificationExpression}, sorted: boolean): string | undefined {
    const rulesAndQuestionIds = _getClassificationRulesAndQuestionIds(expressions, sorted)

    if (rulesAndQuestionIds.length <= 0) {
        return undefined
    }

    return rulesAndQuestionIds[0][1]
}

function getPreviousClassificationExpressionId(expressionId: string, expressions: {[key: string]: graphQlApi.DeviceClassificationExpression}, sorted: boolean): string | undefined {
    const rulesAndQuestionIds = _getClassificationRulesAndQuestionIds(expressions, sorted)
    const currentRuleAndQuestionId = rulesAndQuestionIds.find(([_, currentExpressionId, __, ___]) => currentExpressionId === expressionId)

    if (currentRuleAndQuestionId === undefined) {
        return undefined
    }

    const currentIndex = rulesAndQuestionIds.indexOf(currentRuleAndQuestionId)
    const prevIndex = currentIndex - 1

    if (prevIndex < 0) {
        return undefined
    }

    return rulesAndQuestionIds[prevIndex][1]
}

function getNextClassificationExpressionId(expressionId: string, expressions: {[key: string]: graphQlApi.DeviceClassificationExpression}, sorted: boolean): string | undefined {
    const rulesAndQuestionIds = _getClassificationRulesAndQuestionIds(expressions, sorted).reverse()
    const currentRuleAndQuestionId = rulesAndQuestionIds.find(([_, currentExpressionId, __, ___]) => currentExpressionId === expressionId)

    if (currentRuleAndQuestionId === undefined) {
        return undefined
    }

    const currentIndex = rulesAndQuestionIds.indexOf(currentRuleAndQuestionId)
    const nextIndex = currentIndex - 1

    if (nextIndex >= rulesAndQuestionIds.length) {
        return undefined
    }

    return rulesAndQuestionIds[nextIndex][1]
}

function getClassificationQuestionIndices(expression: graphQlApi.DeviceClassificationExpression, expressions: {[key: string]: graphQlApi.DeviceClassificationExpression}): {[key: string]: number} {
    const questionIndices: {[key: string]: number} = {}

    var startIndex = 0
    const questionId = expression.deviceClassificationExpressionYesNoQuestionId !== undefined && expression.deviceClassificationExpressionYesNoQuestionId !== null ? expression.deviceClassificationExpressionYesNoQuestionId : undefined

    if (questionId !== undefined) {
        questionIndices[questionId] = startIndex
        startIndex += 1
    }

    const childExpressions = getChildrenClassificationExpressions(expression.id, Object.values(expressions), true)

    childExpressions.forEach(childExpression => {
        const childQuestionIndices = getClassificationQuestionIndices(childExpression, expressions)
        
        Object.keys(childQuestionIndices).forEach(key => {
            questionIndices[key] = childQuestionIndices[key] + startIndex
        })

        startIndex += Object.keys(childQuestionIndices).length
    })

    return questionIndices
}

function getClassificationText(classification: graphQlApi.DeviceClassification | undefined): string {
    switch (classification) {
        case graphQlApi.DeviceClassification.EU_CLASS_1:
            return "EU Class 1"
        case graphQlApi.DeviceClassification.EU_CLASS_2_A:
            return "EU Class 2A"
        case graphQlApi.DeviceClassification.EU_CLASS_2_B:
            return "EU Class 2B"
        case graphQlApi.DeviceClassification.EU_CLASS_3:
            return "EU Class 3"
        case graphQlApi.DeviceClassification.US_CLASS_1:
            return "US Class 1"
        case graphQlApi.DeviceClassification.US_CLASS_2:
            return "US Class 2"
        case graphQlApi.DeviceClassification.US_CLASS_3:
            return "US Class 3"
        case graphQlApi.DeviceClassification.CANADA_CLASS_1:
            return "Canada Class 1"
        case graphQlApi.DeviceClassification.CANADA_CLASS_2:
            return "Canada Class 2"
        case graphQlApi.DeviceClassification.CANADA_CLASS_3:
            return "Canada Class 3"
        case graphQlApi.DeviceClassification.CANADA_CLASS_4:
            return "Canada Class 4"
    }

    return "No class"
}

function getClassificationAbbreviatedText(classification: graphQlApi.DeviceClassification | undefined): string {
    switch (classification) {
        case graphQlApi.DeviceClassification.EU_CLASS_1:
            return "1"
        case graphQlApi.DeviceClassification.EU_CLASS_2_A:
            return "2A"
        case graphQlApi.DeviceClassification.EU_CLASS_2_B:
            return "2B"
        case graphQlApi.DeviceClassification.EU_CLASS_3:
            return "3"
        case graphQlApi.DeviceClassification.US_CLASS_1:
            return "1"
        case graphQlApi.DeviceClassification.US_CLASS_2:
            return "2"
        case graphQlApi.DeviceClassification.US_CLASS_3:
            return "3"
        case graphQlApi.DeviceClassification.CANADA_CLASS_1:
            return "1"
        case graphQlApi.DeviceClassification.CANADA_CLASS_2:
            return "2"
        case graphQlApi.DeviceClassification.CANADA_CLASS_3:
            return "3"
        case graphQlApi.DeviceClassification.CANADA_CLASS_4:
            return "4"
    }

    return "-"
}

function isQuestionContributor(onContributionPath: boolean, operator: DeviceExpressionOperator | undefined | null, rootClassification: DeviceClassification | undefined, classification: DeviceClassification | undefined, classificationFromQuestion: DeviceClassification | undefined, classificationFromLevelsBelow: DeviceClassification | undefined): boolean {
    if (!onContributionPath) {
        return false
    }

    if (rootClassification === undefined) {
        return false
    }

    if (classification !== rootClassification) {
        return false
    }

    if (classification !== classificationFromQuestion) {
        return false
    }

    if (operator === DeviceExpressionOperator.RIGHT_OVERRIDE_OF && classificationFromLevelsBelow === classification) {
        return false
    }

    return true
}

function areLevelsBelowContributors(onContributionPath: boolean, rootClassification: DeviceClassification | undefined, classification: DeviceClassification | undefined, classificationFromLevelsBelow: DeviceClassification | undefined): boolean {
    if (!onContributionPath) {
        return false
    }

    if (rootClassification === undefined) {
        return false
    }

    if (classification !== rootClassification) {
        return false
    }

    if (classification !== classificationFromLevelsBelow) {
        return false
    }

    return true
}

function renderClassificationExpression(expression: graphQlApi.DeviceClassificationExpression, expressions: {[key: string]: graphQlApi.DeviceClassificationExpression}, expressionIndex: number, expressionDepth: number, questions: {[key: string]: graphQlApi.Question}, answers: {[key: string]: boolean}, classification: DeviceClassificationNode | undefined, rootClassification: DeviceClassificationNode | undefined, provideAnswer: ((questionId: string, answer: boolean) => void) | undefined, showUnansweredQuestions: boolean, onContributionPath: boolean, questionIndices: {[key: string]: number} = {}) {
    const questionIndicesFinal = expressionDepth === 1 ? getClassificationQuestionIndices(expression, expressions): questionIndices
    const expressionsList = Object.values(expressions)
    const childrenExpressions = getChildrenClassificationExpressions(expression.id, expressionsList, true)
    const isClassified = classification !== undefined && classification.classification !== undefined && classification.classification !== null
    const isLeaf = childrenExpressions.length === 0
    const questionId = expression.deviceClassificationExpressionYesNoQuestionId !== undefined && expression.deviceClassificationExpressionYesNoQuestionId !== null ? expression.deviceClassificationExpressionYesNoQuestionId : undefined
    const hasQuestion = questionId !== undefined
    const isAnsweredYes = !hasQuestion || hasQuestion && answers[questionId] === true
    const hasAnsweredChildren = classification?.classificationFromChildren !== undefined && classification?.classificationFromChildren !== null

    const questionOnContributionPath = isQuestionContributor(onContributionPath, expression.operator, rootClassification?.classification, classification?.classification, classification?.classificationFromQuestion, classification?.classificationFromChildren)
    const levelsBelowOnContributionPath = areLevelsBelowContributors(onContributionPath, rootClassification?.classification, classification?.classification, classification?.classificationFromChildren)

    if (!showUnansweredQuestions && !isClassified) {
        return null
    }

    var textTheory = ""
    var textPractice = ""

    const textClass = classification? getClassificationAbbreviatedText(classification.classification) : "-"
    const textClassQuestion = classification ? getClassificationAbbreviatedText(classification.classificationFromQuestion) : "-"
    const textClassChildren = classification ? getClassificationAbbreviatedText(classification.classificationFromChildren) : "-"

    if (expressionDepth === 0) {
        textTheory = "The final class " + textClass + " is decided by the highest class from the rules below with questions answered yes."

        if (classification !== undefined) {
            const decidingRulesNameValueText = classification.children.filter((child) => {
                return child.classification === classification.classification
            }).map((child) => {
                const expression = expressions[child.expressionId]
                const index = expression.index !== null && expression.index !== undefined ? expression.index : 0
                return 'Rule ' + (1 + index)
            }).join(' and ')
    
            textPractice = "The rule/rules in which questions answered yes contributed to this final class are: " + decidingRulesNameValueText + "."
        }
    } else {
        if (expression.operator === graphQlApi.DeviceExpressionOperator.MAX_OF) {
            if (hasQuestion && childrenExpressions.length > 0) {
                textTheory = "The class " + textClass + " from this level is decided by the highest class from the question if answered yes and the levels below (equal priorities)."
            } else if (hasQuestion) {
                textTheory = "The class " + textClass + " from this level is decided by the class from the question if answered yes."
            } else if (childrenExpressions.length > 0) {
                textTheory = "The class " + textClass + " from this level is decided by the class from the levels below."
            }
        } else if (expression.operator === graphQlApi.DeviceExpressionOperator.RIGHT_OVERRIDE_OF) {
            if (hasQuestion && childrenExpressions.length > 0) {
                textTheory = "The class " + textClass + " from this level is decided by the highest class from the levels below (first priority) and then from the question (second priority)."
            } else if (hasQuestion) {
                textTheory = "The class " + textClass + " from this level is decided by the class from the question if answered yes."
            } else if (childrenExpressions.length > 0) {
                textTheory = "The class " + textClass + " from this level is decided by the class from the levels below."
            }
        }

        if (hasQuestion) {
            if (isAnsweredYes) {
                textPractice = (textPractice === "" ? "" : " ") + "The question at this level was answered yes and tried to contribute with the class " + textClassQuestion + "."
            } else {
                textPractice = (textPractice === "" ? "" : " ") + "The question at this level was answered no."
            }
        } else {
            textPractice = (textPractice === "" ? "" : " ") + "There was no question at this level."
        }

        if (childrenExpressions.length > 0) {
            if (hasAnsweredChildren) {
                textPractice = (textPractice === "" ? "" : " ") + "The levels below had at least a question answered yes and tried to contribute with the class " + textClassChildren + "."
            } else {
                textPractice = (textPractice === "" ? "" : " ") + "The levels below had no questions answered yes."
            }
        } else {
            textPractice = (textPractice === "" ? "" : " ") + "There are no levels below."
        }

        if (classification !== undefined) {
            const questionContributed = classification.classification !== undefined && classification.classificationFromQuestion === classification.classification
            const levelsContributed = classification.classification !== undefined && classification.classificationFromChildren === classification.classification

            if (questionContributed && levelsContributed) {
                textPractice = (textPractice === "" ? "" : " ") + "Both question and levels below contributed to the class " + textClass + " at this level."
            } else if (questionContributed) {
                textPractice = (textPractice === "" ? "" : " ") + "Only the question answered yes contributed with its class " + textClassQuestion + " to the class " + textClass + " at this level."

                if (childrenExpressions.length > 0) {
                    if (hasAnsweredChildren) {
                        textPractice = (textPractice === "" ? "" : " ") + "Despite having questions answered yes, the levels below did not contribute, because its class " + textClassChildren + "  is smaller than the class " + textClassQuestion + " of the question."
                    } else {
                        textPractice = (textPractice === "" ? "" : " ") + "The levels below did not contribute, because it had no questions answered yes."
                    }
                }
            } else if (levelsContributed) {
                textPractice = (textPractice === "" ? "" : " ") + "Only the levels below with at least a question answered yes contributed with its resulting class " + textClassChildren + " to the class " + textClass + " at this level."

                if (hasQuestion) {
                    if (isAnsweredYes) {
                        if (expression.operator === graphQlApi.DeviceExpressionOperator.MAX_OF) {
                            textPractice = (textPractice === "" ? "" : " ") + "Despite being answered yes, the question at this level did not contribute, because its class " + textClassQuestion + " is smaller than the class " + textClassChildren + " of the levels below."
                        } else if (expression.operator === graphQlApi.DeviceExpressionOperator.RIGHT_OVERRIDE_OF) {
                            textPractice = (textPractice === "" ? "" : " ") + "Despite being answered yes, the question at this level did not contribute, because there were questions answered yes in the levels below."
                        }
                    } else {
                        textPractice = (textPractice === "" ? "" : " ") + "The question at this level did not contribute, because it was answered no."
                    }
                }
            } else {
                textPractice = (textPractice === "" ? "" : " ") + "Neither the question nor the levels below contributed to the class " + textClass + " at this level."
            }
        }
    }

    return <div className={expressionDepth > 0 ? "card mb-4" : "mb-4"}>
        <div className={expressionDepth > 0 ? "card-body": ""}>
            {expressionDepth === 1 &&
                <h2 className="mb-4">
                    {classification !== undefined && classification?.classification === rootClassification?.classification && classification?.classification !== undefined &&
                        <div className="icon icon-shape w-9 h-9 bg-success text-white rounded-circle">
                            {textClass}
                        </div>
                    }
                    {classification !== undefined && classification?.classification !== rootClassification?.classification &&
                        <div className="icon icon-shape w-9 h-9 bg-secondary text-white rounded-circle">
                            {textClass}
                        </div>
                    }
                    {classification !== undefined &&
                        <span>&nbsp;</span>
                    }
                    Rule {1 + expressionIndex} &nbsp;
                    {expression.tooltip !== undefined && expression.tooltip !== null &&
                        <i className="bi bi-info-circle text-warning" data-bs-toggle="tooltip" data-bs-placement="bottom" title={expression.tooltip} />
                    }
                </h2>
            }
            {expressionDepth > 1 &&
                <h2 className="mb-4">
                    {classification !== undefined && classification?.classification === rootClassification?.classification &&
                        <div className="icon icon-shape w-9 h-9 bg-success text-white rounded-circle">
                            {textClass}
                        </div>
                    }
                    {classification !== undefined && classification?.classification !== rootClassification?.classification &&
                        <div className="icon icon-shape w-9 h-9 bg-secondary text-white rounded-circle">
                            {textClass}
                        </div>
                    }                    
                    {classification !== undefined &&
                        <span>&nbsp;</span>
                    }
                    Level Below &nbsp;
                    {expression.tooltip !== undefined && expression.tooltip !== null &&
                        <i className="bi bi-info-circle text-warning" data-bs-toggle="tooltip" data-bs-placement="bottom" title={expression.tooltip} />
                    }
                </h2>
            }
            {expressionDepth === 1 && expression.statement !== undefined && expression.statement !== null &&
                <div className="mb-4">
                    <p>{expression.statement}</p>
                </div>
            }
            {expressionDepth === 1 && expression.description !== undefined && expression.description !== null &&
                <div className="mb-4">
                    <h4><a role="button" data-bs-toggle="collapse" aria-expanded="false" href={"#collapseExpressionDescription" + expression.id} aria-controls={"collapseExpressionDescription" + expression.id}>Description</a></h4>
                    <p className="collapse" id={"collapseExpressionDescription" + expression.id}>{expression.description}</p>
                </div>
            }
            {expressionDepth === 1 && expression.explanations !== undefined && expression.explanations !== null && expression.explanations?.length > 0 &&
                <div className="mt-4">
                    <h4><a data-bs-toggle="collapse" aria-expanded="false" href={"#collapseExpressionExplanations" + expression.id} aria-controls={"collapseExpressionExplanations" + expression.id}>Explanations</a></h4>
                    <ul className="collapse" id={"collapseExpressionExplanations" + expression.id}>
                        {expression.explanations?.map((explanation, index) => (
                            <li key={index}>{explanation}</li>
                        ))}
                    </ul>
                </div>
            }
            {expressionDepth === 1 && expression.notes !== undefined && expression.notes !== null && expression.notes?.length > 0 &&
                <div className="mt-4">
                    <h4><a data-bs-toggle="collapse" aria-expanded="false" href={"#collapseExpressionNotes" + expression.id} aria-controls={"collapseExpressionNotes" + expression.id}>Notes</a></h4>
                    <ul className="collapse" id={"collapseExpressionNotes" + expression.id}>
                        {expression.notes?.map((note, index) => (
                            <li key={index}>{note}</li>
                        ))}
                    </ul>
                </div>
            }
            <div className="mt-4">
                <p><i className="bi bi-exclamation-circle text-warning" /> {textTheory} {textPractice}</p>
            </div>
            {isClassified &&
                <div className="mt-4">
                    {hasQuestion && <div className="d-block"><span className="font-bold">Class from the question at this level</span>: {textClassQuestion}</div>}
                    {!isLeaf &&
                        <div className="d-block"><span className="font-bold">Class from the lower levels</span>: {textClassChildren}</div>
                    }
                    <div className="d-block"><span className="font-bold">Final class at this level</span>: {textClass}</div>
                </div>
            }
            <hr />
            <div className="mx-6">
            {
                renderClassificationQuestion(questionIndicesFinal, questions, expression, answers, provideAnswer, classification, rootClassification, questionOnContributionPath)
            }
            </div>
            {isAnsweredYes &&
                childrenExpressions.map((childExpression, childIndex) => {
                    const childClassification = classification !== undefined ? classification.children.find(node => node.expressionId === childExpression.id) : undefined

                    return <div className="mt-4" key={'classification-expression-child' + childExpression.id}>
                        {renderClassificationExpression(childExpression, expressions, childIndex, 1 + expressionDepth, questions, answers, childClassification, rootClassification, provideAnswer, showUnansweredQuestions, levelsBelowOnContributionPath, questionIndicesFinal)}
                    </div>
                })
            }
        </div>
    </div>
}

function renderClassificationQuestion(questionIndices: {[key: string]: number}, questions: {[key: string]: graphQlApi.Question}, expression: graphQlApi.DeviceClassificationExpression, answers: {[key: string]: boolean}, provideAnswer: ((questionId: string, answer: boolean) => void) | undefined, classification: DeviceClassificationNode | undefined, rootClassification: DeviceClassificationNode | undefined, onContributionPath: boolean) {
    const questionId = expression.deviceClassificationExpressionYesNoQuestionId

    if (questionId === undefined || questionId === null) {
        return null
    }

    const hasAnswer = answers.hasOwnProperty(questionId)
    const answer = hasAnswer ? answers[questionId] : undefined
    const index = questionIndices.hasOwnProperty(questionId) ? 1 + questionIndices[questionId] : undefined
    const question = questions[questionId]
    const classificationIfYes = expression.classificationIfYes === null ? undefined:  expression.classificationIfYes
    const isClassified = classification !== undefined
    const answerYesNo = answer === true ? "yes" : "no"

    const isEuClassI = rootClassification !== undefined && rootClassification.classification === graphQlApi.DeviceClassification.EU_CLASS_1
    const isEuClassIIa = rootClassification !== undefined && rootClassification.classification === graphQlApi.DeviceClassification.EU_CLASS_2_A
    const isEuClassIIb = rootClassification !== undefined && rootClassification.classification === graphQlApi.DeviceClassification.EU_CLASS_2_B
    const isEuClassIII = rootClassification !== undefined && rootClassification.classification === graphQlApi.DeviceClassification.EU_CLASS_3
    const isEuClassAny = isEuClassI || isEuClassIIa || isEuClassIIb || isEuClassIII

    const isUsClassI = rootClassification !== undefined && rootClassification.classification === graphQlApi.DeviceClassification.US_CLASS_1
    const isUsClassII = rootClassification !== undefined && rootClassification.classification === graphQlApi.DeviceClassification.US_CLASS_2
    const isUsClassIII = rootClassification !== undefined && rootClassification.classification === graphQlApi.DeviceClassification.US_CLASS_3
    const isUsClassAny = isUsClassI || isUsClassII || isUsClassIII

    const isCanadaClassI = rootClassification !== undefined && rootClassification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_1
    const isCanadaClassII = rootClassification !== undefined && rootClassification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_2
    const isCanadaClassIII = rootClassification !== undefined && rootClassification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_3
    const isCanadaClassIV = rootClassification !== undefined && rootClassification.classification === graphQlApi.DeviceClassification.CANADA_CLASS_4
    const isCanadaClassAny = isCanadaClassI || isCanadaClassII || isCanadaClassIII || isCanadaClassIV

    const isClassAny = isEuClassAny || isUsClassAny || isCanadaClassAny

    const answeredContributed = onContributionPath && answer !== undefined && classification?.classification !== undefined && classification?.classificationFromQuestion === rootClassification?.classification && classification?.classificationFromQuestion === classification?.classification
    const justAnswered = answer !== undefined && classification?.classificationFromQuestion !== rootClassification?.classification
    const notAnsweredContributed = answer === undefined && !isClassAny
    const justNotAnswered = answer === undefined && isClassAny
    const textClassIfYes = classificationIfYes ? getClassificationAbbreviatedText(classificationIfYes) : "-"
    const textClassQuestion = classification ? getClassificationAbbreviatedText(classification.classificationFromQuestion) : "-"

    return <div className="d-flex pb-3">
        <div className="flex-none me-3">
            {isClassified && answeredContributed &&
                <div className="icon icon-shape w-9 h-9 bg-success text-white rounded-circle">
                    {textClassQuestion}
                </div>
            }
            {isClassified && justAnswered &&
                <div className="icon icon-shape w-9 h-9 bg-secondary text-white rounded-circle">
                    {textClassQuestion}
                </div>
            }
            {isClassified && notAnsweredContributed &&
                <div className="icon icon-shape w-9 h-9 bg-danger text-white rounded-circle">
                    <i className="bi bi-x"></i>
                </div>
            }
            {isClassified && justNotAnswered &&
                <div className="icon icon-shape w-9 h-9 bg-secondary text-white rounded-circle">
                    <i className="bi bi-x"></i>
                </div>
            }
            {!isClassified &&
                <div className="icon icon-shape w-9 h-9 bg-secondary text-white rounded-circle">
                    {textClassIfYes}
                </div>
            }
        </div>
        <div className="flex-fill">
            <div className="d-block font-bold">
                <h3 className="mb-2">Question {index}
                &nbsp;
                    {question.tooltip !== undefined && question.tooltip !== null &&
                        <i className="bi bi-info-circle text-warning" data-bs-toggle="tooltip" data-bs-placement="bottom" title={question.tooltip} />
                    }
                </h3>
            </div>
            <div className="d-block">
                {question.statement}
            </div>
            {question.description !== undefined && question.description !== null &&
                <div className="mb-4">
                    <h4><a role="button" data-bs-toggle="collapse" aria-expanded="false" href={"#collapseQuestionDescription" + question.id} aria-controls={"collapseQuestionDescription" + question.id}>Description</a></h4>
                    <p className="collapse" id={"collapseQuestionDescription" + question.id}>{question.description}</p>
                </div>
            }
            {question.explanations !== undefined && question.explanations !== null && question.explanations?.length > 0 &&
                <div className="mt-4">
                    <h4><a data-bs-toggle="collapse" aria-expanded="false" href={"#collapseQuestionExplanations" + question.id} aria-controls={"collapseQuestionExplanations" + question.id}>Explanations</a></h4>
                    <ul className="collapse" id={"collapseQuestionExplanations" + question.id}>
                        {question.explanations?.map((explanation, index) => (
                            <li key={index}>{explanation}</li>
                        ))}
                    </ul>
                </div>
            }
            {question.examples !== undefined && question.examples !== null && question.examples?.length > 0 &&
                <div className="mt-4">
                    <h4><a data-bs-toggle="collapse" aria-expanded="false" href={"#collapseQuestionExamples" + question.id} aria-controls={"collapseQuestionExamples" + question.id}>Examples</a></h4>
                    <ul className="collapse" id={"collapseQuestionExamples" + question.id}>
                        {question.examples?.map((example, index) => (
                            <li key={index}>{example}</li>
                        ))}
                    </ul>
                </div>
            }
            {question.notes !== undefined && question.notes !== null && question.notes?.length > 0 &&
                <div className="mt-4">
                    <h4><a data-bs-toggle="collapse" aria-expanded="false" href={"#collapseQuestionNotes" + question.id} aria-controls={"collapseQuestionNotes" + question.id}>Notes</a></h4>
                    <ul className="collapse" id={"collapseQuestionNotes" + question.id}>
                        {question.notes?.map((note, index) => (
                            <li key={index}>{note}</li>
                        ))}
                    </ul>
                </div>
            }
            {!isClassified &&
                <div className="mt-4">
                    <hr />
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name={"classification-answer-" + questionId} id={"classification-answer-yes-" + questionId} checked={answer === true} onChange={() => provideAnswer !== undefined && provideAnswer(questionId, true)} />
                        <label className="form-check-label" htmlFor={"classification-answer-yes-" + questionId}>
                        Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name={"classification-answer" + questionId} id={"classification-answer-no-" + questionId} checked={answer === false} onChange={() => provideAnswer !== undefined && provideAnswer(questionId, false)} />
                        <label className="form-check-label" htmlFor={"classification-answer-no-" + questionId}>
                        No
                        </label>
                    </div>
                </div>
            }
            {isClassified && answeredContributed &&
                <div className="mt-4">
                    <div className="d-block text-primary mt-1">Your answer <span className="font-bold">{answerYesNo}</span> decided the overall classification result.</div>
                </div>
            }
            {isClassified && notAnsweredContributed &&
                <div className="mt-4">
                    <div className="d-block text-danger mt-1">Your answer <span className="font-bold">{answerYesNo}</span> decided the overall classification result.</div>
                </div>
            }
            {isClassified && justAnswered &&
                <div className="mt-4">
                    <div className="d-block text-muted mt-1">Your answer <span className="font-bold">{answerYesNo}</span> did not decide the overall classification result.</div>
                </div>
            }

            {isClassified && justNotAnswered &&
                <div className="mt-4">
                    <div className="d-block text-muted mt-1">Your answer <span className="font-bold">{answerYesNo}</span> did not decide the overall classification result.</div>
                </div>
            }
        </div>
    </div>
}

export {
    getRootClassificationExpressions,
    getChildrenClassificationExpressions,
    getPreviousClassificationExpressionId,
    getNextClassificationExpressionId,
    getFirstClassificationExpressionId,
    getClassificationQuestionIndices,
    getRuleClassificationExpressions,
    getClassificationText,
    getClassificationAbbreviatedText,
    renderClassificationQuestion,
    renderClassificationExpression
}