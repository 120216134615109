import * as graphQlApi from '../../../API';
import { getNextClassificationExpressionId, getPreviousClassificationExpressionId, renderClassificationExpression } from './ClassificationHelper';
import { hasAllAnswers } from './OnboardingHelper';

interface ClassificationPanelProps {
    seeExpression: (expressionId: string) => void
    answerQuestion: (questionId: string, answer: boolean) => void
    expressions: {[key: string]: graphQlApi.DeviceClassificationExpression}
    answerAllMissing: () => void
    questions: {[key: string]: graphQlApi.Question}
    answers: {[key: string]: boolean}
    lastSeenExpressionId: string | undefined
    startClassified: () => void
}

export const ClassificationPanel: React.FC<ClassificationPanelProps> = ({ seeExpression, answerQuestion, expressions, answerAllMissing, questions, answers, lastSeenExpressionId, startClassified }) => {
    function setPreviousExpression() {
        if (lastSeenExpressionId === undefined) {
            return
        }

        const prevExpressionId = getPreviousClassificationExpressionId(lastSeenExpressionId, expressions, true)

        if (prevExpressionId === undefined) {
            return
        }
        
        window.scrollTo(0, 0)
        seeExpression(prevExpressionId)
    }

    function hasPreviousExpression() {
        if (lastSeenExpressionId === undefined) {
            return false
        }

        const prevExpressionId = getPreviousClassificationExpressionId(lastSeenExpressionId, expressions, true)

        if (prevExpressionId === undefined) {
            return false
        }
        
        return true
    }

    function setNextExpression() {
        if (lastSeenExpressionId === undefined) {
            return
        }

        const nextExpressionId = getNextClassificationExpressionId(lastSeenExpressionId, expressions, true)

        if (nextExpressionId === undefined) {
            return
        }
        
        window.scrollTo(0, 0)
        seeExpression(nextExpressionId)
    }

    function hasNextExpression() {
        if (lastSeenExpressionId === undefined) {
            return false
        }

        const nextExpressionId = getNextClassificationExpressionId(lastSeenExpressionId, expressions, true)

        if (nextExpressionId === undefined) {
            return false
        }
        
        return true
    }

    const areAllQuestionsAnswered = hasAllAnswers(questions, answers)
    const expression = lastSeenExpressionId !== undefined && expressions.hasOwnProperty(lastSeenExpressionId) ? expressions[lastSeenExpressionId] : undefined
    const expressionIndex = expression?.index !== undefined && expression?.index !== null ? expression.index : 0

    return (
        <div>
            <h1 className="mb-4">Classification</h1>
            <div className="my-4">
                <p><i className="bi bi-exclamation-circle text-warning" /> The final class is decided by the highest class from the rules below with questions answered yes.</p>
            </div>
            {expression !== undefined && renderClassificationExpression(expression, expressions, expressionIndex, 1, questions, answers, undefined, undefined, answerQuestion, true, false)}
            <div className="mt-6">
                {areAllQuestionsAnswered &&
                    <a role="button" className="btn btn-success me-2" onClick={() => startClassified()}>Classify Device</a>
                }
                {hasNextExpression() &&
                    <a role="button" className="btn btn-primary me-2" onClick={() => setNextExpression()}>Next Rule</a>
                }
                {hasPreviousExpression() &&
                    <a role="button" className="btn btn-link me-2" onClick={() => setPreviousExpression()}>Previous Rule</a>
                }
                {!areAllQuestionsAnswered &&
                    <a role="button" className="btn btn-link me-2" onClick={() => answerAllMissing()}>Answer All Missing Questions</a>
                }
            </div>
        </div>
    )
}