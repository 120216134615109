// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const DeviceClassification = {
  "EU_CLASS_1": "EU_CLASS_1",
  "EU_CLASS_2_A": "EU_CLASS_2_A",
  "EU_CLASS_2_B": "EU_CLASS_2_B",
  "EU_CLASS_3": "EU_CLASS_3",
  "US_CLASS_1": "US_CLASS_1",
  "US_CLASS_2": "US_CLASS_2",
  "US_CLASS_3": "US_CLASS_3",
  "CANADA_CLASS_1": "CANADA_CLASS_1",
  "CANADA_CLASS_2": "CANADA_CLASS_2",
  "CANADA_CLASS_3": "CANADA_CLASS_3",
  "CANADA_CLASS_4": "CANADA_CLASS_4"
};

const SpecialQuestionCategory = {
  "EU_QUALIFICATION_MEDICAL_DEVICE": "EU_QUALIFICATION_MEDICAL_DEVICE",
  "EU_QUALIFICATION_ACCESSORY_FOR_MEDICAL_DEVICE": "EU_QUALIFICATION_ACCESSORY_FOR_MEDICAL_DEVICE",
  "EU_QUALIFICATION_MEDICAL_DEVICE_WITHOUT_INTENDED_MEDICAL_PURPOSE": "EU_QUALIFICATION_MEDICAL_DEVICE_WITHOUT_INTENDED_MEDICAL_PURPOSE",
  "EU_CLASSIFICATION": "EU_CLASSIFICATION",
  "US_CLASSIFICATION": "US_CLASSIFICATION",
  "CANADA_CLASSIFICATION": "CANADA_CLASSIFICATION"
};

const DeviceQualification = {
  "MEDICAL_DEVICE_MDR_ARTICLE_2_1": "MEDICAL_DEVICE_MDR_ARTICLE_2_1",
  "ACCESSORY_FOR_MEDICAL_DEVICE_MDR_ARTICLE_2_2": "ACCESSORY_FOR_MEDICAL_DEVICE_MDR_ARTICLE_2_2",
  "MEDICAL_DEVICE_WITHOUT_INTENDED_MEDICAL_PURPOSE_MDR_ANNEX_XVI": "MEDICAL_DEVICE_WITHOUT_INTENDED_MEDICAL_PURPOSE_MDR_ANNEX_XVI"
};

const AnswerDataType = {
  "BOOLEAN": "BOOLEAN",
  "INT": "INT",
  "FLOAT": "FLOAT",
  "ROW": "ROW",
  "STRING": "STRING"
};

const DeviceExpressionOperator = {
  "MAX_OF": "MAX_OF",
  "RIGHT_OVERRIDE_OF": "RIGHT_OVERRIDE_OF"
};

const EntityEnum = {
  "ENUM1": "ENUM1",
  "ENUM2": "ENUM2"
};

const { Answer, DeviceClassificationExpression, DeviceQualificationExpression, EntityTemplate, EntityTemplateBelongsTo, EntityTemplateHasOne, Label, MedflowVersion, Phase, PreviewAnswer, PreviewProduct, PreviewOrganization, PreviewUser, Product, Question, QuestionLabel, QuestionRowColumn, Report, ReportQuestionnaire, ReportQuestionnaireSection, RowColumnAnswer, Stage, StageQuestionnaire, StageQuestionnaireSection, Track } = initSchema(schema);

export {
  Answer,
  DeviceClassificationExpression,
  DeviceQualificationExpression,
  EntityTemplate,
  EntityTemplateBelongsTo,
  EntityTemplateHasOne,
  Label,
  MedflowVersion,
  Phase,
  PreviewAnswer,
  PreviewProduct,
  PreviewOrganization,
  PreviewUser,
  Product,
  Question,
  QuestionLabel,
  QuestionRowColumn,
  Report,
  ReportQuestionnaire,
  ReportQuestionnaireSection,
  RowColumnAnswer,
  Stage,
  StageQuestionnaire,
  StageQuestionnaireSection,
  Track,
  DeviceClassification,
  SpecialQuestionCategory,
  DeviceQualification,
  AnswerDataType,
  DeviceExpressionOperator,
  EntityEnum
};