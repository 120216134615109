import { API } from "aws-amplify";
import { CreatePreviewProductInput, DeletePreviewProductInput, PreviewProduct, GetPreviewProductQuery, ListPreviewProductsQuery, UpdatePreviewProductInput } from "../API";
import { createPreviewProduct, deletePreviewProduct, updatePreviewProduct } from "../graphql/mutations";
import { getPreviewProduct, listPreviewProducts, listPreviewProductsByName } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';

const defaultAuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS

export const createPreviewProductApi = async (input: CreatePreviewProductInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewProduct> => {
    const responseRaw = await API.graphql({
        authMode,
        query: createPreviewProduct,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { createPreviewProduct: PreviewProduct } };
    return response.data.createPreviewProduct;
}

export const updatePreviewProductApi = async (input: UpdatePreviewProductInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewProduct> => {
    const responseRaw = await API.graphql({
        authMode,
        query: updatePreviewProduct,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { updatePreviewProduct: PreviewProduct } };
    return response.data.updatePreviewProduct;
}

export const deletePreviewProductApi = async (input: DeletePreviewProductInput, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewProduct> => {
    const responseRaw = await API.graphql({
        authMode,
        query: deletePreviewProduct,
        variables: {
            input
        },
    });

    const response = responseRaw as { data: { deletePreviewProduct: PreviewProduct } };
    return response.data.deletePreviewProduct;
}

export const listPreviewProductsApi = async (authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewProduct[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listPreviewProducts,
    });

    const response = responseRaw as { data: ListPreviewProductsQuery };
    const items = response.data.listPreviewProducts?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as PreviewProduct[];
    return itemsExisting
}

export const getPreviewProductApi = async (id: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewProduct|undefined> => {
    const responseRaw = await API.graphql({
        authMode,
        query: getPreviewProduct,
        variables: {
            id
        },
    });
    
    const response = responseRaw as { data: GetPreviewProductQuery };
    const instance = response.data.getPreviewProduct;

    if (instance === null) {
        return undefined
    }

    const instanceNotUndefined = instance as PreviewProduct
    return instanceNotUndefined
}

export const listPreviewProductsByNameApi = async (name: string, authMode: GRAPHQL_AUTH_MODE = defaultAuthMode): Promise<PreviewProduct[]> => {
    const responseRaw = await API.graphql({
        authMode,
        query: listPreviewProductsByName,
        variables: {
            name
        },
    });

    const response = responseRaw as { data: ListPreviewProductsQuery };
    const items = response.data.listPreviewProducts?.items;

    if (items === undefined) {
        return []
    }

    const itemsExisting = items.filter(item => item !== null && item !== undefined) as PreviewProduct[];
    return itemsExisting
}
