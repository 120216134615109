import React, { useEffect, useState } from 'react'

interface FloatChooserProps {
    relationName: string
    value: number|undefined
    setValue: (value: number|undefined) => void
}

export const FloatChooser: React.FC<FloatChooserProps> = ({ relationName, value, setValue }) => {
    const [innerValue, setInnerValue] = useState<number|undefined>(value)

    useEffect(() => {
        setInnerValue(value)
    }, [value])

    function setValueFloat(event: React.ChangeEvent<HTMLInputElement>): void {
        const floatValue = parseFloat(event.target.value)
        const floatValueNumber = isNaN(floatValue) ? undefined: floatValue
        setInnerValue(floatValueNumber)
        setValue(floatValueNumber)
    }

    return (
        <>
            <span className="form-label">{relationName}</span>
            <input id={relationName} type="number" value={innerValue === null ? undefined : innerValue} onChange={setValueFloat} className="form-control w-full" />
        </>
    )
}